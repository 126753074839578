import React, { useState, useEffect, useRef } from 'react';
import SearchAllResults from '../SearchAllResults';
import { useMediaQuery } from 'react-responsive';
import { useSidebar3 } from '../StarNavigationBar/Sidebar3Context';
import { useLiveVisitors } from '../LiveVisitorsContext';
import { useNavigate } from 'react-router-dom';
import { BiSearch } from "react-icons/bi";
import './ContentsRecommendation.css';

function ContentsRecommendation() {
    const isScreenWidthInRange = useMediaQuery({
        query: '(min-width: 992px)'
    });

    const { setProfileBeingViewed } = useLiveVisitors();
    const navbarRef = useRef(null);
    const containerRef = useRef(null);
    const [userData, setUserData] = useState({});
    const [searchAllResults, setSearchAllResults] = useState([]);
    const [imageFailed, setImageFailed] = useState(false);
    const [input, setInput] = useState("");
    const [noResultsFound, setNoResultsFound] = useState(false);
    const { isSidebarOpen, setSidebarOpen } = useSidebar3();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchData = (value) => {
        if (!value) {
            setSearchAllResults([]);
            setLoading(false);
            return;
        }

        setLoading(true);

        const encodedValue = encodeURIComponent(value);

        fetch(`/api/search_all/?query=${encodedValue}&size=10`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setSearchAllResults(data);
                setLoading(false);
                if (data.length === 0) {
                    setTimeout(() => setNoResultsFound(true), 250);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching user data:', error);
            });
    };

    const handleChange = (value) => {
        setInput(value);
        setNoResultsFound(false);
        fetchData(value);
    };

    useEffect(() => {
        let lastScrollTop = 0;
        const delta = 5;

        const onScroll = () => {
            const st = containerRef.current?.scrollTop;

            if (Math.abs(lastScrollTop - st) <= delta) return;

            if (st > lastScrollTop) {
                // Scroll Down
                navbarRef.current?.classList.add("scroll-down");
                navbarRef.current?.classList.remove("scroll-up");
            } else {
                // Scroll Up
                navbarRef.current?.classList.add("scroll-up");
                navbarRef.current?.classList.remove("scroll-down");
            }

            lastScrollTop = st;
        };

        containerRef.current?.addEventListener('scroll', onScroll);

        return () => {
            containerRef.current?.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        fetch('/api/get_user_profile_data/')
            .then(response => response.json())
            .then(data => {
                setUserData(data);
                setImageFailed(false);
            })
            .catch(error => console.error('Error fetching user data:'));
    }, []);

    const handleClickProfile = () => {
        setSidebarOpen(!isSidebarOpen);
    };
    

    const handleUserClick = (username) => {
        setProfileBeingViewed(username); 
        navigate(`/${username}/shopPosts`);
    };


    const handleStarPostingClick = (user_id, product_code) => {
        const starposting = searchAllResults.find(result => result.type === 'starposting' && result.data.starposting.product_code === product_code);
        if (starposting) {
            const user = starposting.data.user;
            const isSoldOut = starposting.data.starposting.is_soldout;

            if (user) {
                setProfileBeingViewed(user.username);
                const encodedProductCode = encodeURIComponent(product_code);

                // Determine the correct path based on `is_soldout`
                const path = isSoldOut ? 'soldOut' : 'shopPosts';
                navigate(`/${user.username}/${path}/${encodedProductCode}`);
            }
        }
    };
    
    const generateUniqueKey = (result) => {
        if (result.type === 'user' && result.data.user) {
            return `${result.data.user.id}-${result.data.user.username}`;
        } else if (result.type === 'starposting' && result.data.starposting) {
            return `${result.data.starposting.user_id}-${result.data.starposting.product_code}`;
        } else {
            return `no-id-no-key-${Math.random().toString(36).substr(2, 9)}`;
        }
    };
    
    if (!isScreenWidthInRange) {
        return null;
    }
    return (
      <div className="Recommendation-container" ref={containerRef}>
        <div className="Recomandation-header mt-3" ref={navbarRef}>
          <form className="d-flex form-recommendation-search" role="search">
            <div className="search-container-recommendation">
              <BiSearch
                size={18}
                className="bi bi-search search-icon-recommendation"
              />
              <input
                className="form-control me-2 search-input-recommendation"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={input}
                onChange={(e) => handleChange(e.target.value)}
              />
            </div>
          </form>

          <div className="profile" onClick={handleClickProfile}>
            {userData.profile_picture && !imageFailed ? (
              <img
                src={userData.profile_picture}
                alt={userData.first_name}
                className="profile-picture"
                onError={() => setImageFailed(true)}
              />
            ) : (
              <div className="profile-picture no-profile-picture">
                {userData.first_name
                  ? userData.first_name.slice(0, 1).toUpperCase()
                  : null}
              </div>
            )}
          </div>
        </div>

        <div
          className={`recommendation-results-container ${
            input ? "active" : ""
          }`}
        >
          {loading && (
            <div className="search-loader-container">
              <div className="search-loader"></div>
            </div>
          )}

          {input && searchAllResults.length > 0 ? (
            <div className="search-results-container">
              {searchAllResults.map((result) => {
                const key = generateUniqueKey(result);
                return (
                  <SearchAllResults
                    key={key}
                    type={result.type}
                    user={result.type === "user" ? result.data : null}
                    starposting={
                      result.type === "starposting"
                        ? result.data.starposting
                        : null
                    }
                    loading={loading}
                    onUserClick={handleUserClick}
                    onStarPostingClick={handleStarPostingClick}
                  />
                );
              })}
            </div>
          ) : noResultsFound ? (
            <div className="no-result">No results found</div>
          ) : null}
        </div>

        <div className="recommendation-heade-text">
          <h5>Trends for you</h5>
        </div>
        <div className="container-postRecommendation"></div>
      </div>
    );
}

export default ContentsRecommendation;




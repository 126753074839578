import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLocation } from '../../GetUserCurrentLocationContext';
import { YourShopHeaderPostContext } from '../../StarNavigationBar/YourShopHeaderPostContext';
import { BiArrowBack } from "react-icons/bi";
import usflag from "../../../assets/flags/us.gif";
import './HeaderHome.css';

function Wallet() {
  const {
    csrftoken,
    clearFetchedTransactionIds,
    transactionPage,
    setTransactionPage,
    fetchedTransactionSet,
    combinedTransactions,
    setCombinedTransactions,
  } = useContext(YourShopHeaderPostContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedGtn, setSelectedGtn] = useState(null);
  const [totalGtn, setTotalGtn] = useState(0);
  const { userLocation } = useUserLocation();
  const countryCode = userLocation?.country;
  const [isTokenBalanceLoading, setIsTokenBalanceLoading] = useState(true);
  const [userBalance, setUserBalance] = useState({ currency: "", balance: 0 });
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("Goofan Wallet");
  const [walletBalanceError, setWalletBalanceError] = useState(null);
  const [transactionsScrollLoading, setTransactionsScrollLoading] =
    useState(false);
  const [transactionPageHasMore, setTransactionsPageHasMore] = useState(true);
  const transactionsObserver = useRef();
  const [minWithdrawError, setMinWithdrawError] = useState("");
  const [insufficientFundsError, setInsufficientFundsError] = useState("");
  const [showGtnSuccessModal, setShowGtnSuccessModal] = useState(false);
  const [gtnSuccessDetails, setGtnSuccessDetails] = useState({
    amount: 0,
    days: 0,
  });

  useEffect(() => {
    fetchUserTokenBalance();
    fetchUserBalance();
  }, []);

  useEffect(() => {
    fetchCombinedTransactions();
  }, [transactionPage]);

  const transactionsElementRef = useCallback(
    (node) => {
      if (transactionsScrollLoading) return;
      if (transactionsObserver.current)
        transactionsObserver.current.disconnect();
      transactionsObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && transactionPageHasMore) {
          setTransactionPage((prevPage) => prevPage + 1);
        }
      });
      if (node) transactionsObserver.current.observe(node);
    },
    [transactionsScrollLoading, transactionPageHasMore]
  );

  const fetchCombinedTransactions = async () => {
    if (transactionPage !== 1) {
      setTransactionsScrollLoading(true);
    }

    try {
      const response = await fetch(
        `/api/combined-transactions/?page=${transactionPage}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data && data.results) {
        // Filter new transactions that haven't been fetched yet
        const newTxn = data.results.filter((transaction) => {
          const compositeId = `${transaction.id}-${transaction.type}`;
          if (!fetchedTransactionSet.has(compositeId)) {
            fetchedTransactionSet.add(compositeId);
            return true;
          }
          return false;
        });

        // Update combined transactions with the filtered new transactions
        setCombinedTransactions((prevTxn) => [...prevTxn, ...newTxn]);

        // Update the flag to indicate whether more pages are available
        setTransactionsPageHasMore(data.next !== null);
      }
    } catch (error) {
      console.error("Error fetching combined transactions:", error);
    } finally {
      if (transactionPage !== 1) {
        setTransactionsScrollLoading(false);
      }
    }
  };

  const fetchUserTokenBalance = async () => {
    try {
      const response = await fetch("/api/user-token-balance/");
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0 && data[0].balance !== undefined) {
          const balance = data[0].balance;
          setTotalGtn(balance);
        } else {
          setTotalGtn(0);
        }
      } else {
        console.error("Failed to fetch user balance.");
      }
    } catch (error) {
      console.error("Error fetching user balance:", error);
    } finally {
      setIsTokenBalanceLoading(false);
    }
  };
  const handleBackToHome = () => {
    navigate("/home");
    clearFetchedTransactionIds();
    setTransactionPage(1);
    setCombinedTransactions([]);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedGtn(null);
    setWalletBalanceError(null);
    setInsufficientFundsError("");
    setMinWithdrawError("");
    // clearFetchedTransactionIds();
    setTransactionPage(1);
  };

  const handleGtnSelect = (amount) => {
    setSelectedGtn(amount);
  };

  // Base prices in Tanzanian Shillings (Tsh)
  const basePrices = {
    5: 1250, // 1250 Tsh for 5 GTN
    10: 2500, // 2500 Tsh for 10 GTN
    50: 12500, // 12500 Tsh for 50 GTN
    100: 25000, // 25000 Tsh for 100 GTN
  };

  // Fixed conversion rate from Tsh to USD
  const usdConversionRate = 0.0006; // rate: 1 Tsh = 0.0006 USD

  // Function to determine currency and rate based on user location
  const getCurrencyDetails = (location) => {
    if (location === "TZ") {
      return {
        flag: usflag, // Used in displaying balance
        code: "Tsh", // Used in gtn price
        rate: 1, // Used in gtn rate conversion
      };
    } else {
      return {
        flag: usflag,
        code: "USD",
        rate: usdConversionRate,
      };
    }
  };

  // Get currency details based on the user's location
  const { flag, code, rate } = getCurrencyDetails(countryCode);

  // Dynamically calculate prices based on the rate
  const options = Object.keys(basePrices).map((amount) => ({
    amount: parseInt(amount),
    price: (basePrices[amount] * rate).toFixed(2),
  }));

  const getTransactionDescription = (transaction) => {
    if (transaction.type === "token") {
      return `Purchased`;
    } else if (transaction.type === "balance") {
      if (transaction.transaction_type === "purchase") {
        return `Purchased`;
      } else if (transaction.transaction_type === "withdrawal") {
        return `Withdrawal`;
      } else {
        return `Transaction of ${transaction.amount} ${transaction.currency}`;
      }
    } else {
      return `Unknown transaction type`;
    }
  };

  const fetchUserBalance = async () => {
    try {
      const response = await fetch("/api/user-balance/");
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0 && data[0].balance && data[0].currency) {
          setUserBalance({
            currency: data[0].currency,
            balance: parseFloat(data[0].balance),
          });
        } else {
          setUserBalance({ currency: "USD", balance: 0 });
        }
      } else {
        console.error("Failed to fetch user balance.");
      }
    } catch (error) {
      console.error("Error fetching user balance:", error);
    }
  };

  // const handleDeposit = async (amount) => {
  //   try {
  //     const response = await fetch("/api/balance-transactions/", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-CSRFToken": csrftoken,
  //       },
  //       body: JSON.stringify({
  //         transaction_type: "deposit",
  //         amount: amount,
  //         currency: code,
  //       }),
  //     });

  //     if (response.ok) {
  //       fetchUserBalance();
  //       fetchBalanceTransactions();
  //     } else {
  //       console.error("Failed to deposit funds.");
  //     }
  //   } catch (error) {
  //     console.error("Error depositing funds:", error);
  //   }
  // };

  const handleWithdrawChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value < 500) {
      setMinWithdrawError("Minimum withdrawal amount is 500 USD.");
      setInsufficientFundsError("");
    } else if (value > userBalance.balance) {
      setMinWithdrawError("");
      setInsufficientFundsError("Insufficient funds.");
    } else {
      setMinWithdrawError("");
      setInsufficientFundsError("");
    }
  };

  const handleWithdraw = async () => {
    const amount = parseFloat(document.getElementById("withdraw-amount").value);
    if (amount < 500) {
      setMinWithdrawError("Minimum withdrawal amount is 500 USD.");
      return;
    } else if (amount > userBalance.balance) {
      setInsufficientFundsError("Insufficient funds.");
      return;
    } else {
      setMinWithdrawError("");
      setInsufficientFundsError("");
    }

    try {
      const response = await fetch("/api/balance-transactions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          transaction_type: "withdrawal",
          amount: amount,
          currency: userBalance.currency,
        }),
      });

      if (response.ok) {
        fetchUserBalance();
        fetchCombinedTransactions();
        handleCloseModal();
      } else {
        console.error("Failed to withdraw funds.");
      }
    } catch (error) {
      console.error("Error withdrawing funds:", error);
    }
  };

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
    setWalletBalanceError("");
  };

  const handleBuy = async () => {
    if (selectedPaymentMethod === "Goofan Wallet") {
      // Find the selected GTN option
      const selectedOption = options.find(
        (option) => option.amount === selectedGtn
      );

      if (selectedOption) {
        // Convert price to USD (if needed)
        const priceInUsd = parseFloat(
          (basePrices[selectedGtn] * usdConversionRate).toFixed(2)
        );
        const missingAmount = (priceInUsd - userBalance.balance).toFixed(2);

        // Check if the user has enough balance
        if (
          selectedPaymentMethod === "Goofan Wallet" &&
          userBalance.balance < priceInUsd
        ) {
          setWalletBalanceError(
            `Insufficient Balance. Your current balance is $${userBalance.balance}. You are short by $${missingAmount}.`
          );
          return;
        }
        try {
          const response = await fetch("/api/token-transactions/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
            body: JSON.stringify({
              amount: selectedGtn.toFixed(2),
              price_paid: priceInUsd,
              currency: "USD",
              payment_method: selectedPaymentMethod,
            }),
          });

          if (response.ok) {
            const days =
              selectedGtn === 5 || selectedGtn === 10
                ? 7
                : selectedGtn === 50
                ? 30
                : 60;
            // Fetch the updated balances and transactions after successful purchase
            fetchUserTokenBalance();
            fetchUserBalance();
            fetchCombinedTransactions();
            handleCloseModal();
            setGtnSuccessDetails({
              amount: selectedGtn,
              days: days,
            });
            setShowGtnSuccessModal(true);
          } else {
            console.error("Failed to purchase GTN.");
          }
        } catch (error) {
          console.error("Error purchasing GTN:", error);
        }
      }
    }
  };

  return (
    <div className="wallet-container">
      <div className="header-wallet">
        <div className="top-header-wallet">
          <div className="Wallet-Back-btn" onClick={handleBackToHome}>
            <BiArrowBack
              size={20}
              color="currentColor"
              className="bi bi-arrow-left"
            />
          </div>
          <h1 className="header-wallet-text">Wallet</h1>
          <div className="empty-div-remove-it"></div>
        </div>
        <div className="your-balances">Your balances</div>
        <div className="lower-header-wallet">
          {!isTokenBalanceLoading && userLocation && (
            <>
              <div className="token-icon">
                <div>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <linearGradient
                        id="gold-gradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="100%"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: "#FFD700", stopOpacity: 1 }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: "#FFA500", stopOpacity: 1 }}
                        />
                      </linearGradient>
                      <filter
                        id="shadow"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                      >
                        <feGaussianBlur in="SourceAlpha" stdDeviation="50" />
                        <feOffset dx="1" dy="1" result="offsetblur" />
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                    </defs>
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill="#f0c14b"
                      stroke="url(#gold-gradient)"
                      strokeWidth="1"
                    />
                    <path
                      d="M8 2C8.55228 2 9 2.44772 9 3C9 3.55228 8.55228 4 8 4C7.44772 4 7 3.55228 7 3C7 2.44772 7.44772 2 8 2ZM8 5C9.10457 5 10 5.89543 10 7V9C10 10.1046 9.10457 11 8 11H7V12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12V11H4C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9H5V7C5 5.89543 5.89543 5 7 5H8ZM6 7V9H7V7H6ZM10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289C8.90237 5.68342 8.90237 6.31658 9.29289 6.70711C9.68342 7.09763 10.3166 7.09763 10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289ZM10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071C9.68342 11.0976 10.3166 11.0976 10.7071 10.7071C11.0976 10.3166 11.0976 9.68342 10.7071 9.29289Z"
                      fill="black"
                      opacity="0.7"
                    />
                  </svg>{" "}
                  <span className="Gtn">GTN</span>
                </div>
                <span className="total-gtn">{Number(totalGtn).toFixed(2)}</span>
              </div>
              <div className="balance">
                <div className="flag-currency">
                  {flag && (
                    <img src={flag} alt={`flag`} className="currency-flag" />
                  )}
                  <span className="balance-currency">
                    {userBalance.currency}
                  </span>
                </div>
                <span className="balance-amount">
                  {userBalance?.balance.toFixed(2)}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="withdraw-buytoken">
          <button className="buy-token" onClick={() => handleOpenModal("buy")}>
            Buy GTN
          </button>
          <button
            className="withdraw-fund"
            onClick={() => handleOpenModal("withdraw")}
          >
            Withdraw
          </button>
        </div>
      </div>
      {showGtnSuccessModal && (
        <div className="gtn-success-modal">
          <h4 className="gtn-success-title">Transaction Complete</h4>
          <p className="gtn-success-message">
            You have successfully received <strong>{gtnSuccessDetails.amount} GTN</strong> to use within <strong>{gtnSuccessDetails.days} days</strong>.
          </p>
          <div className="gtn-success-modal-btns">
            <button
              className="btn-ok-gtn"
              onClick={(e) => {
                e.stopPropagation();
                setShowGtnSuccessModal(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}

      <div className="wallet-contents">
        <h4 className="text-center">Transactions</h4>
        <div className="transactions-list">
          {combinedTransactions.length > 0 ? (
            combinedTransactions.map((transaction, index) => (
              <div
                key={`${transaction.id}-${transaction.type}`}
                // key={`${transaction.id}-${index}-${transaction.type}`}
                className="transaction-item"
                ref={
                  index === combinedTransactions.length - 1
                    ? transactionsElementRef
                    : null
                }
              >
                <div className="transaction-description">
                  <span className="transaction-type">
                    {getTransactionDescription(transaction)}
                  </span>
                  {transaction.payment_method && (
                    <span className="transaction-payment-method">
                      {transaction.payment_method}
                    </span>
                  )}
                </div>
                <div className="transaction-details">
                  <span className="transaction-amount">
                    {transaction.amount}{" "}
                    {transaction.type === "token"
                      ? "GTN"
                      : transaction.currency}
                  </span>
                  {/* Conditionally render the price only for non-withdrawal transactions */}
                  {transaction.transaction_type !== "withdrawal" && (
                    <span className="transaction-price">
                      {transaction.price_paid
                        ? `${transaction.currency} ${transaction.price_paid}`
                        : transaction.description || "N/A"}
                    </span>
                  )}
                </div>
                <div className="transaction-timestamps">
                  <span className="transaction-timestamp">
                    <strong>PT:</strong>{" "}
                    {new Date(transaction.timestamp).toLocaleString()}
                  </span>
                  {transaction.expiration_date && (
                    <span className="transaction-expiration">
                      <strong>EXP:</strong>{" "}
                      {new Date(transaction.expiration_date).toLocaleString()}
                    </span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="no-transaction">No transactions yet.</p>
          )}
        </div>
        <div className="scroll-loader-spinner-container">
          {transactionsScrollLoading && (
            <div className="scroll-loader-spinner"></div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div
            className="wallet-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {modalContent === "buy" ? (
              <div className="buy-gtn-options">
                <h4>Select GTN Amount</h4>
                <div className="gtn-options">
                  {options.map((option) => (
                    <div
                      key={option.amount}
                      className={`gtn-option ${
                        selectedGtn === option.amount ? "selected" : ""
                      }`}
                      onClick={() => handleGtnSelect(option.amount)}
                    >
                      <div className="gtn-amount">{option.amount} GTN</div>
                      <div className="option-price">
                        {code} {option.price}
                      </div>
                    </div>
                  ))}
                </div>
                {/* Error message if balance is insufficient */}
                {walletBalanceError && (
                  <p className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {walletBalanceError}
                  </p>
                )}
                {selectedGtn && (
                  <div className="payment-methods">
                    <h4>Select Payment Method</h4>
                    <div className="payment-options">
                      <div className="gooofan-wallet payment-option">
                        <label htmlFor="gooofan-wallet">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="Goofan Wallet"
                            checked={selectedPaymentMethod === "Goofan Wallet"}
                            onChange={handlePaymentMethodChange}
                            id="gooofan-wallet"
                          />
                          <span className="custom-radio"></span>
                          Goofan Wallet
                        </label>
                      </div>

                      <div className="card payment-option">
                        <label htmlFor="card">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="Card"
                            checked={selectedPaymentMethod === "Card"}
                            onChange={handlePaymentMethodChange}
                            id="card"
                          />
                          <span className="custom-radio"></span>
                          Card
                        </label>
                      </div>

                      <div className="tigo-pesa payment-option">
                        <label htmlFor="tigo-pesa">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="Tigo Pesa"
                            checked={selectedPaymentMethod === "Tigo Pesa"}
                            onChange={handlePaymentMethodChange}
                            id="tigo-pesa"
                          />
                          <span className="custom-radio"></span>
                          Tigo Pesa
                        </label>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="send-payment-btn"
                      onClick={handleBuy}
                      disabled={!selectedGtn || !selectedPaymentMethod}
                    >
                      Buy {selectedGtn} GTN
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="withdraw-options">
                <h4 className="withdraw-fund-modal">Withdraw Funds</h4>
                {minWithdrawError && (
                  <p className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {minWithdrawError}
                  </p>
                )}
                {insufficientFundsError && (
                  <p className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {insufficientFundsError}
                  </p>
                )}
                <input
                  type="number"
                  className="form-control"
                  id="withdraw-amount"
                  name="withdraw-amount"
                  onChange={handleWithdrawChange}
                  placeholder="Enter amount to withdraw"
                />
                <button
                  className="withdraw-fund-confirm"
                  onClick={handleWithdraw}
                >
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Wallet;


import React, { useEffect, useRef, useState } from "react";
import lozad from "lozad";
import "./LazyLoad.css";

const placeholderSrc =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpteGxpbms9Imh0dHA6Ly93d3cub3JnLzE5OTkveGxpbmsiPg0KICAgIDxyZWN0IHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIiBmaWxsPSIjZjBmMGYwIi8+DQogICAgPHRleHQgeD0iMTAwIiB5PSIxMDAiIGZvbnQtc2l6ZT0iMjAiIGZvbnQtZmFpbHk9IkFyaWFsLCBzYW5zLXNlcmlmIiBmaWxsPSIjY2NjIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBhbGlnbm0tYmFzZWxpbmU9Im1pZGRsZSI+DQogICAgICAgIExvYWRpbmcuLi4NCiAgICA8L3RleHQ+DQo8L3N2Zz4=";

function GlobalLazyImage({ src, alt, className, onError }) {
  const imageRef = useRef(null);
  // const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   const observer = lozad(imageRef.current, {
  //     loaded: () => {
  //       setIsLoaded(false);
  //     },
  //   });
  //   observer.observe();

  //   return () => {
  //     observer.observer.disconnect();
  //   };
  // }, [src]);

  useEffect(() => {
    const observer = lozad(imageRef.current);
    observer.observe();

    return () => {
      observer.observer.disconnect();
    };
  }, [src]);

  return (
    <img
      ref={imageRef}
      data-src={src}
      src={placeholderSrc}
      alt={alt}
      className={`${className} lazy-placeholder`}
      // className={`${className} ${isLoaded ? "" : "lazy-placeholder"}`}
      onError={onError}
    />
  );
}

export default GlobalLazyImage;







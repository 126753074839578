//components/LazyImage.jsx
import React, { useEffect, useRef } from 'react';
import lozad from 'lozad';

function LazyImage({ src, alt, className, onError }) {
    const imageRef = useRef(null);
    


    const handleClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const observer = lozad(imageRef.current);
        observer.observe();
    }, []);

    return (
       <img 
        data-src={src} 
        alt={alt} 
        className={`${className} lozad`} 
        onError={onError} 
        ref={imageRef}  
        onClick={handleClick} 
        />
    );
}

export default LazyImage;

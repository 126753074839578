// ChatContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { FooterContext } from "../StarNavigationBar/FooterContext";
import { useNavigate} from "react-router-dom";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const navigate = useNavigate();
  const { showFooter, setShowFooter } = useContext(FooterContext);
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const {
    setMessages,
    currentConversationId,
    userData,
    currentRecipient,
    setLastReadMessage,
    setFirstUnreadMessage,
    setChatOpenStatus,
    setUnreadLabel,
    socketRef,
    updateChatOpenStatus,
    currentConversationIdRef,
    setMessageImagePreview,
    previewedMessageId,
    socketRequestRef,
    setNewRequestCount,
    setPostRequestMusic,
    postRequestMusic,
    setReceivedRequests,

    setUnreadMessagesCount,
    setSelectedUser,
    setMessagePage,
    setChatState,
    clearFetchedMessageIds,
    setCurrentConversationId,
    markMessagesAsRead,
    isWideScreen,
    receivedRequests,
  } = useChatAndMusicPosts();

  const [messageInput, setMessageInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [clickCounts, setClickCounts] = useState({});
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  //For deleting message on tripleClick and reset
  // Store click counts for each message by ID
  const [activeMessageId, setActiveMessageId] = useState(null); // Track the current active message
  const [timer, setTimer] = useState(null); // Timer for resetting the counter

  const sendMessage = async (
    message,
    tempId = null,
    isRetry = false,
    file = null
  ) => {
    if (isSending) return;
    if (!message.trim() && !selectedFile && !file) return;
    setIsSending(true);
    setUploadProgress(null);

    try {
      // Upload the file if present
      const imageUrl = await handleFileUpload(file, isRetry);
      if (imageUrl === false) return;

      tempId = tempId || uuidv4();
      const recipientUsername = currentRecipient;

      // Add or retry message in UI
      if (!isRetry) {
        addNewMessageToUI(tempId, message, recipientUsername, imageUrl);
      } else {
        retryMessageInUI(tempId);
      }

      resetInputState();

      // Attempt to send the message
      await attemptSendMessageWithRetry(
        message,
        tempId,
        recipientUsername,
        imageUrl
      );

      updateMessageReadStatus(tempId);
      updateChatStatus();
      adjustTextareaHeight();
    } catch (error) {
      console.error("Message sending failed:", error);
      markMessageAsFailed(tempId); // Mark the message as failed in the UI
    } finally {
      setIsSending(false);
    }
  };

  // Handle uploading image in the chat with progress tracking
  const uploadChatImage = async (file, onUploadProgress) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/upload_Chatimage/", formData, {
        headers: {
          "X-CSRFToken": csrftoken,
        },
        onUploadProgress,
      });

      return response.data.file_url;
    } catch (error) {
      console.error("Error uploading chat image:", error);
      throw error;
    }
  };

  // Helper function: Upload file (if present) and track progress
  const handleFileUpload = async (file, isRetry) => {
    if ((selectedFile || file) && !isRetry) {
      try {
        const fileToUpload = selectedFile || file;
        return await uploadChatImage(fileToUpload, (progressEvent) => {
          const percentComplete = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentComplete);
        });
      } catch (error) {
        console.error("Image upload failed:", error);
        setUploadProgress(null);
        return false;
      }
    }
    return null;
  };

  // Helper function: Add new message to the UI
  const addNewMessageToUI = (tempId, message, recipientUsername, imageUrl) => {
    const newMessage = {
      temp_id: tempId,
      sender: userData.username,
      recipient: recipientUsername,
      text: message,
      timestamp: new Date().toISOString(),
      conversationId: currentConversationId,
      isLoading: true,
      isError: false,
      isReceived: false,
      file: selectedFile,
      isSeen: false,
    };
    if (imageUrl) newMessage.image = imageUrl;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  // Helper function: Update UI for retry message attempt
  const retryMessageInUI = (tempId) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.temp_id === tempId
          ? { ...msg, isLoading: true, isError: false }
          : msg
      )
    );
  };

  // Helper function: Reset input state after sending
  const resetInputState = () => {
    setMessageInput("");
    setSelectedImage(null);
    setSelectedFile(null);
    setSendButtonDisabled(true);
    setUploadProgress(null);
  };

  // Helper function: Attempt to send message with retry mechanism
  const attemptSendMessageWithRetry = (
    message,
    tempId,
    recipientUsername,
    imageUrl
  ) => {
    return new Promise((resolve, reject) => {
      let timeoutCleared = false;
      const timeoutId = setTimeout(() => {
        if (!timeoutCleared) {
          reject();
        }
      }, 30000);

      const sendMessage = () => {
        if (socketRef.current.readyState !== WebSocket.OPEN) {
          setTimeout(sendMessage, 1000);
          return;
        }

        socketRef.current.send(
          JSON.stringify({
            text: message,
            temp_id: tempId,
            recipient: recipientUsername,
            sender: userData.username,
            conversationId: currentConversationId,
            image: imageUrl,
          })
        );

        const messageConfirmationHandler = (event) => {
          const parsedData = JSON.parse(event.data);
          if (parsedData.acknowledged_temp_id === tempId) {
            clearTimeout(timeoutId);
            timeoutCleared = true;
            socketRef.current.removeEventListener(
              "message",
              messageConfirmationHandler
            );
            markMessageAsSent(tempId);
            resolve();
          }
        };

        socketRef.current.addEventListener(
          "message",
          messageConfirmationHandler
        );
      };

      sendMessage();
    });
  };

  // Helper function: Mark message as sent in UI
  const markMessageAsSent = (tempId) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.temp_id === tempId
          ? { ...msg, isLoading: false, isError: false }
          : msg
      )
    );
  };

  // Helper function: Mark message as failed in UI
  const markMessageAsFailed = (tempId) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.temp_id === tempId
          ? { ...msg, isLoading: false, isError: true }
          : msg
      )
    );
  };

  // Helper function: Update message read status
  const updateMessageReadStatus = (tempId) => {
    setLastReadMessage((prevLastRead) => ({
      ...prevLastRead,
      [currentConversationId]: tempId,
    }));
    setFirstUnreadMessage((prevFirstUnread) => ({
      ...prevFirstUnread,
      [currentConversationId]: null,
    }));
    setUnreadLabel((prevLabel) => ({
      ...prevLabel,
      [currentConversationId]: null,
    }));
  };

  // Helper function: Update chat open status
  const updateChatStatus = () => {
    updateChatOpenStatus(currentConversationId, true).then((updatedStatus) => {
      if (updatedStatus !== null) {
        setChatOpenStatus(updatedStatus);
        fetch(
          `/api/messages/${currentConversationId}/get_chat_open_status/?t=${new Date().getTime()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => setChatOpenStatus(data.chat_open))
          .catch((error) =>
            console.error("Error fetching chat open status:", error)
          );
      }
    });
  };

  // Helper function: Adjust textarea height
  const adjustTextareaHeight = () => {
    const textarea = document.querySelector('textarea[name="message"]');
    if (textarea) textarea.style.height = "auto";
  };

  // Retry function to handle click on error icon
  const retryMessage = (event, msg) => {
    event.stopPropagation();
    sendMessage(msg.text || "", msg.temp_id, true, msg.file);
  };

  // Function to send seen acknowledgment for a message using temp_id
  function sendSeenAcknowledgment(tempId) {
    if (!tempId) return;
    if (socketRef.current) {
      socketRef.current.send(
        JSON.stringify({
          type: "seen_acknowledgment",
          temp_id: tempId,
          conversationId: currentConversationIdRef.current,
        })
      );
    } else {
      console.warn(
        "socketRef.current is null. Unable to send seen acknowledgment."
      );
    }
  }

  // Function to delete message
  const deleteMessage = async (id, tempId) => {
    // Optimistically remove the message from the local state
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message.id !== id)
    );

    let response;

    if (id) {
      response = await fetch(`/api/messages/${id}/delete_message/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
    } else if (tempId) {
      // Fetch the message using temp_id
      response = await fetch(`/api/messages/get_message/?temp_id=${tempId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const messageId = data.id;

        // Delete the message using the fetched id
        response = await fetch(`/api/messages/${messageId}/delete_message/`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        });
      }
    }

    if (!response.ok) {
      // If the deletion fails, revert the optimistic update
      setMessages((prevMessages) => [
        ...prevMessages,
        { id, temp_id: tempId, text: "Failed to delete message", error: true },
      ]);
      console.error("Failed to delete message");
    }
  };

  // Timer delay (3 seconds for user comfort)
  const RESET_DELAY = 3000; // 3 seconds
  const handleMessageClick = (event, id, tempId, isPreview = false) => {
    event.stopPropagation();

    // If the clicked message is different from the currently active one, reset the previous message counter
    if (id !== activeMessageId) {
      setClickCounts((prevCounts) => ({
        ...prevCounts,
        [activeMessageId]: 0, // Reset the counter of the previous message
        [id]: 1, // Start the counter at 1 for the new message
      }));
      setActiveMessageId(id); // Set the new active message
    } else {
      // Increment the counter for the same message
      setClickCounts((prevCounts) => ({
        ...prevCounts,
        [id]: (prevCounts[id] || 0) + 1,
      }));
    }

    // Reset background color after 3 seconds of inactivity
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setClickCounts((prevCounts) => ({
          ...prevCounts,
          [id]: 0, // Reset the counter for the current message
        }));
        setActiveMessageId(null); // Reset active message ID
      }, RESET_DELAY)
    );

    // Check if the count reached 3, delete the message
    if ((clickCounts[id] || 0) === 2) {
      // When it reaches 3 (index 2), delete
      deleteMessage(id, tempId);
      setClickCounts((prevCounts) => ({
        ...prevCounts,
        [id]: 0, // Reset the counter after deletion
      }));
      setActiveMessageId(null); // Reset the active message ID

      // If it's an image preview, close the preview after deletion
      if (isPreview) {
        closeMessageImagePreview();
      }
    }
  };

  const getMessageBackgroundColor = (id) => {
    const count = clickCounts[id] || 0;
    if (count === 1) return { backgroundColor: "#f8f9fa" };
    if (count === 2) return { backgroundColor: "#ffcccc" };
    if (count === 3) return { backgroundColor: "#d3ffd3" };
    return {}; // Default, no background color if count is 0 or undefined
  };

  const closeMessageImagePreview = () => {
    setMessageImagePreview(null);

    // Reset the click count for the previewed message
    setClickCounts((prevCounts) => {
      return { ...prevCounts, [previewedMessageId]: 0 };
    });
  };

  const scrollToBottom = () => {
    const messagesContainer = document.querySelector(".messages-container");
    if (messagesContainer) {
      const isNearBottom =
        messagesContainer.scrollHeight - messagesContainer.scrollTop <=
        messagesContainer.clientHeight + 100;
      if (isNearBottom) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }
  };

  // // Helper function: Update request count
  // const updateRequestCount = (conversationId) => {
  //   setNewRequestCount((prevCount) => {
  //     const newCount = { ...prevCount };
  //     if (newCount[conversationId]) {
  //       newCount[conversationId]--;
  //       if (newCount[conversationId] <= 0) delete newCount[conversationId];
  //     }
  //     return newCount;
  //   });
  // };

  // // Helper function: Send WebSocket message
  // const sendSocketMessage = (type, payload) => {
  //   if (socketRequestRef.current) {
  //     socketRequestRef.current.send(JSON.stringify({ type, ...payload }));
  //   }
  // };

  // const handleCloseMusicRequest = async (tempRequestId) => {
  //   try {
  //     const userRequests = await fetchUserRequests();

  //     // Find the corresponding request
  //     const correspondingRequest = userRequests.find(
  //       (request) => request.tempRequestId === tempRequestId
  //     );

  //     if (!correspondingRequest) return; // Exit if no request is found

  //     const tempMusicId = correspondingRequest.music.tempMusicId;
  //     const { id, sender, recipient, conversation, request_accepted } =
  //       correspondingRequest;

  //     if (sender.username === userData.username) {
  //       // Case 1: Sender closes before accepted
  //       // Case 2: Sender closes after accepted
  //       const messageType = request_accepted
  //         ? "request_closed_by_sender" // Case 2
  //         : "request_canceled"; // Case 1

  //       await updateRequestClosedStatus(id); // Mark the request as closed
  //       sendSocketMessage(messageType, {
  //         request_id: id,
  //         sender,
  //         conversationId: conversation.conversationId,
  //         tempMusicId,
  //         tempRequestId,
  //       });
  //     } else if (recipient.username === userData.username) {
  //       // Case 3: Recipient closes before accepted
  //       // Case 4: Recipient closes after accepted
  //       const messageType = request_accepted
  //         ? "session_left_by_recipient" // Case 4
  //         : "rejected_request"; // Case 3

  //       if (messageType === "rejected_request") {
  //         // Handle rejection separately
  //         await handleRejectRequest(id, tempMusicId);
  //       } else {
  //         await updateRequestClosedStatus(id); // Mark the request as closed
  //       }

  //       sendSocketMessage(messageType, {
  //         request_id: id,
  //         recipient,
  //         conversationId: conversation.conversationId,
  //         tempMusicId,
  //         tempRequestId,
  //       });
  //     }

  //     // Remove the closed request from postMusicRequest state
  //     setPostRequestMusic((prev) =>
  //       prev.filter(
  //         (music) =>
  //           music.tempMusicId !== tempMusicId &&
  //           music.tempRequestId !== tempRequestId
  //       )
  //     );

  //     // Update the request count
  //     updateRequestCount(conversation.conversationId);
  //   } catch (error) {
  //     console.error("Error closing music request:", error);
  //   }
  // };

  // const handleRejectRequest = async (requestId, musicId = null) => {
  //   try {
  //     const response = await fetch(`/api/music/reject_request/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-CSRFToken": csrftoken,
  //       },
  //       body: JSON.stringify({ request_id: requestId }),
  //     });
  //     if (!response.ok) throw new Error("Failed to reject request.");

  //     const data = await response.json();
  //     if (data.success) {
  //       setReceivedRequests((prev) =>
  //         prev.filter((request) => request.id !== requestId)
  //       );

  //       if (musicId) {
  //         setPostRequestMusic((prev) =>
  //           prev.filter((music) => music.id !== musicId)
  //         );
  //       }

  //       sendSocketMessage("rejected_request", {
  //         request_id: requestId,
  //         conversationId: currentConversationId,
  //       });

  //       updateRequestCount(currentConversationId);
  //     } else {
  //       console.error("Error rejecting request:", data.error);
  //     }
  //   } catch (error) {
  //     console.error("Error rejecting request:", error);
  //   }
  // };

  // Helper: Fetch user requests
  const fetchUserRequests = async () => {
    try {
      const response = await fetch(`/api/music/user_requests/`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching user requests:", error);
      return [];
    }
  };

  // Updated handleCloseMusicRequest function
  const handleCloseMusicRequest = async (tempRequestId) => {
    // Fetch the user's requests
    const userRequests = await fetchUserRequests();

    // Find the corresponding request
    const correspondingRequest = userRequests.find(
      (request) => request.tempRequestId === tempRequestId
    );

    if (correspondingRequest) {
      const tempMusicId = correspondingRequest.music.tempMusicId;

      if (correspondingRequest.sender.username === userData.username) {
        if (!correspondingRequest.request_accepted) {
          // Case 1: Sender closes before accepted
          await updateRequestClosedStatus(correspondingRequest.id);
          socketRequestRef.current.send(
            JSON.stringify({
              type: "request_canceled",
              request_id: correspondingRequest.id,
              sender: correspondingRequest.sender,
              conversationId: correspondingRequest.conversation.conversationId,
              tempMusicId: tempMusicId,
              tempRequestId: tempRequestId,
            })
          );
        } else {
          // Case 2: Sender closes after accepted
          await updateRequestClosedStatus(correspondingRequest.id);
          socketRequestRef.current.send(
            JSON.stringify({
              type: "request_closed_by_sender",
              request_id: correspondingRequest.id,
              sender: correspondingRequest.sender,
              conversationId: correspondingRequest.conversation.conversationId,
              tempMusicId: tempMusicId,
              tempRequestId: tempRequestId,
            })
          );
        }
      } else if (
        correspondingRequest.recipient.username === userData.username
      ) {
        if (!correspondingRequest.request_accepted) {
          // Case 3: Recipient closes before accepted
          await handleRejectRequest(correspondingRequest.id, tempRequestId);
          socketRequestRef.current.send(
            JSON.stringify({
              type: "rejected_request",
              request_id: correspondingRequest.id,
              recipient: correspondingRequest.recipient,
              conversationId: correspondingRequest.conversation.conversationId,
              tempMusicId: tempMusicId,
              tempRequestId: tempRequestId,
            })
          );
        } else {
          // Case 4: Recipient closes after accepted
          await updateRequestClosedStatus(correspondingRequest.id);
          socketRequestRef.current.send(
            JSON.stringify({
              type: "session_left_by_recipient",
              request_id: correspondingRequest.id,
              recipient: correspondingRequest.recipient,
              conversationId: correspondingRequest.conversation.conversationId,
              tempMusicId: tempMusicId,
              tempRequestId: tempRequestId,
            })
          );
        }
      }
      // Update the state to remove the closed request from postMusicRequest
      setPostRequestMusic((prevMusic) =>
        prevMusic.filter(
          (music) =>
            !(
              music.tempMusicId === tempMusicId &&
              music.tempRequestId === tempRequestId
            )
        )
      );

      // Decrement request count on cancellation, rejection, or closing
      setNewRequestCount((prevCount) => {
        const newCount = { ...prevCount };
        if (newCount[correspondingRequest.conversation.conversationId]) {
          newCount[correspondingRequest.conversation.conversationId]--;
          if (newCount[correspondingRequest.conversation.conversationId] <= 0) {
            delete newCount[correspondingRequest.conversation.conversationId];
          }
        }
        return newCount;
      });
    }
  };

  // Function to handle rejecting a request
  const handleRejectRequest = async (requestId, musicId = null) => {
    try {
      const response = await fetch(`/api/music/reject_request/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ request_id: requestId }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error(`Error rejecting request: ${data.error}`);
      }

      // Update received requests state
      setReceivedRequests((prevRequests) =>
        prevRequests.filter((request) => request.id !== requestId)
      );

      // Update post request music state
      if (musicId) {
        setPostRequestMusic((prevMusic) =>
          prevMusic.filter((music) => music.id !== musicId)
        );
      } else {
        const rejectedRequest = receivedRequests.find(
          (request) => request.id === requestId
        );
        if (rejectedRequest) {
          setPostRequestMusic((prevMusic) =>
            prevMusic.filter((music) => music.id !== rejectedRequest.music.id)
          );
        }
      }

      // Fetch the user's requests
      const userRequests = await fetchUserRequests();
      // Find the corresponding request
      const correspondingRequest = userRequests.find(
        (request) => request.music.id === musicId && request.id === requestId
      );
      if (!correspondingRequest) {
        return;
      }

      // Notify the sender via WebSocket
      if (correspondingRequest.recipient.username === userData.username) {
        if (!correspondingRequest.request_accepted) {
          socketRequestRef.current.send(
            JSON.stringify({
              type: "rejected_request",
              request_id: correspondingRequest.id,
              sender: correspondingRequest.sender,
              conversationId: correspondingRequest.conversation.conversationId,
            })
          );
        }
      }

      // Decrement request count on rejection
      setNewRequestCount((prevCount) => {
        const newCount = { ...prevCount };
        if (newCount[correspondingRequest.conversation.conversationId]) {
          newCount[correspondingRequest.conversation.conversationId]--;
          if (newCount[correspondingRequest.conversation.conversationId] <= 0) {
            delete newCount[correspondingRequest.conversation.conversationId];
          }
        }
        return newCount;
      });
    } catch (error) {}
  };

  //function to update the request as closed, when reject or close it so that to not be rendered
  const updateRequestClosedStatus = async (requestId) => {
    try {
      const response = await fetch("/api/music/close_request/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ request_id: requestId }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data.success;
    } catch (error) {
      console.error("Error updating request closed status:");
      return false;
    }
  };

  //handle to navigate to Conversation lists
  const handleBackToConvoList = () => {
    // Navigate immediately
    navigate(`${isWideScreen ? "/home" : ""}/start-chat`);

    //Rendering the footer Nav
    setShowFooter(!showFooter);
    localStorage.setItem("showFooter", JSON.stringify(!showFooter));

    // Perform the asynchronous operations without blocking navigation

    (async () => {
      try {
        // Mark messages as read when closing the chat
        await markMessagesAsRead(currentConversationId);

        // Clear firstUnreadMessage and update unreadMessagesCount
        setFirstUnreadMessage((prevFirstUnread) => {
          const updatedFirstUnread = {
            ...prevFirstUnread,
            [currentConversationId]: null,
          };
          setUnreadMessagesCount((prevCount) => ({
            ...prevCount,
            [currentConversationId]: 0,
          }));
          return updatedFirstUnread;
        });

        const updatedStatus = await updateChatOpenStatus(
          currentConversationId,
          false
        );
        if (updatedStatus !== null) {
          setChatOpenStatus(updatedStatus);
        }

        setChatState(false);
        localStorage.setItem("chatState", "false");
        // Remove data from localStorage
        localStorage.removeItem("currentConversationId");
        localStorage.removeItem("currentRecipient");
        localStorage.removeItem("selectedUser");

        // Reset state
        setSelectedUser(null);
        setCurrentConversationId(null);
        setMessagePage(1);
        setMessages([]);
        clearFetchedMessageIds();
      } catch (error) {
        console.error("Error during back-to-convo state updates:", error);
      }
    })();
  };

  useEffect(() => {
    const handlePopState = (event) => {
      // Check if there's a valid conversation ID and ensure popstate is user-initiated
      if (event.state && currentConversationId) {
        handleBackToConvoList();
      }
    };

    // Add listener for back/forward navigation
    window.addEventListener("popstate", handlePopState);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [currentConversationId]);

  return (
    <ChatContext.Provider
      value={{
        // updateRequestCount,
        // sendSocketMessage,
        sendMessage,
        retryMessage,
        sendSeenAcknowledgment,
        messageInput,
        setMessageInput,
        sendButtonDisabled,
        setSendButtonDisabled,
        selectedImage,
        setSelectedImage,
        selectedFile,
        setSelectedFile,
        uploadProgress,
        setUploadProgress,
        isSending,
        setIsSending,
        croppedImage,
        setCroppedImage,
        originalImage,
        setOriginalImage,
        previewImage,
        setPreviewImage,
        getMessageBackgroundColor,
        clickCounts,
        setClickCounts,
        closeMessageImagePreview,
        handleMessageClick,
        acceptedRequests,
        setAcceptedRequests,
        scrollToBottom,
        handleRejectRequest,
        handleCloseMusicRequest,
        fetchUserRequests,
        handleBackToConvoList,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  return useContext(ChatContext);
};

import React, {} from "react";
import { useChat } from "./ChatContext";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { AiOutlineClose } from "react-icons/ai";
import "../StarNavigationBar/chat.css";


const MessageListImagePreview = () => {
  const {
    getMessageBackgroundColor,
    closeMessageImagePreview,
    handleMessageClick,
  } = useChat();
  const { previewedMessageId, messageImagePreview, previewedMessageTempId } =
    useChatAndMusicPosts();

  return (
    <div
      className="overlay"
      onClick={closeMessageImagePreview}
      style={getMessageBackgroundColor(previewedMessageId)}
    >
      <div
        className="message-thumbnail-preview"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={messageImagePreview}
          alt=""
          className="message-preview-image"
          onClick={(event) =>
            handleMessageClick(
              event,
              previewedMessageId,
              previewedMessageTempId,
              true
            )
          }
        />
        <button
          onClick={closeMessageImagePreview}
          className="close-icon-message-thumbnail-preview"
        >
          <i className="bi bi-x icon-message-image-preview-close"></i>
        </button>
      </div>
    </div>
  );
};

export default MessageListImagePreview;

// FullPreviewModal.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useLikesComments } from '../LikesCommentsContext';
import DOMPurify from 'dompurify';
import { formatTimeElapsed } from '../utils/formatTimeElapsed';
import {
  AiFillHeart,
  AiOutlineClose,
  AiOutlineFullscreenExit,
  AiOutlineShareAlt,
} from "react-icons/ai";
import './FullPreviewModal.css';

const FullPreviewModal = ({setPosts }) => {
  const {
    starUpdatePostFullPreview,
    closeFullPreviewModal,
    selectedPost,
    setSelectedPost,
    handleLike,
    comments,
    setComments,
    newComment,
    setNewComment,
    handleComment,
    commentsLoading,
    fetchComments, 
    setCommentsLoading,
    handleCommentClick,
    isCommentEmpty,
    setIsCommentEmpty,
    elapsedTime,
    setElapsedTime,
    renderMedia,
    topPricedPosts,
    setTopPricedPosts,
    bestSellingPosts,
    setBestSellingPosts,
    starAwardPosts,
    setStarAwardPosts,
    topPricedArchivedPosts,
    setTopPricedArchivedPosts,
    adminTopPricedPosts,
    setAdminTopPricedPosts,
    adminStarAwardPosts,
    setAdminStarAwardPosts,
    adminBestSellingPosts,
    setAdminBestSellingPosts,
    bestSellingArchivedPosts,
    setBestSellingArchivedPosts,
    starAwardArchivedPosts,
    setStarAwardArchivedPosts,
    formatDateString,
    commentInputRef,
    updatePostsLikeState,
    updatePostCountComments,
    commentPage, 
    lastCommentElementRef,
    scrollLoadingComment,
    handleNavigateToSelectedPost,
    fullPreviewModalOpenSource

  } = useLikesComments();
  const commentSectionRef = useRef(null);
  const [shareMessage, setShareMessage] = useState("");
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setElapsedTime(Date.now());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (commentSectionRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !commentsLoading && comments.length === 0) {
            setCommentsLoading(true);
            fetchComments(selectedPost.id).finally(() => {
              setCommentsLoading(false);
            });
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(commentSectionRef.current);
      return () => observer.disconnect();
    }
  }, [commentSectionRef]);

  //Fetch comments when user opens modal,
  useEffect(() => {
      if (selectedPost) {
            fetchComments(selectedPost.id, commentPage);
      }
    }, [starUpdatePostFullPreview, commentPage]);

  if (!starUpdatePostFullPreview || !selectedPost) return null;

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
    setIsCommentEmpty(e.target.value.trim() === '');
  };

   const postComment = async () => {
    const newCommentData = await handleComment(
      selectedPost,
      topPricedPosts,
      setTopPricedPosts,
      bestSellingPosts,
      setBestSellingPosts,
      starAwardPosts,
      setStarAwardPosts,
      topPricedArchivedPosts,
      setTopPricedArchivedPosts,
      adminTopPricedPosts,
      setAdminTopPricedPosts,
      adminStarAwardPosts,
      setAdminStarAwardPosts,
      adminBestSellingPosts,
      setAdminBestSellingPosts,
      bestSellingArchivedPosts,
      setBestSellingArchivedPosts,
      starAwardArchivedPosts,
      setStarAwardArchivedPosts,
      updatePostCountComments
    );
  };

  const handleShareClick = () => {
    // Dynamically get the URL and headline for the selected post
    const url = selectedPost?.full_star_update_url || selectedPost?.url;
    const title = selectedPost?.headline || "Check out this news!";

    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: "Check out this news!",
          url: url,
        })
        .catch(() => {
          // Fallback: Copy to clipboard if sharing fails
          navigator.clipboard.writeText(url).then(() => {
            setShareMessage("Copied to clipboard");
            setTimeout(() => {
              setShareMessage("");
            }, 2000);
          });
        });
    } else {
      // Fallback for browsers that don't support the Web Share API
      navigator.clipboard.writeText(url).then(() => {
        setShareMessage("Copied to clipboard");
        setTimeout(() => {
          setShareMessage("");
        }, 2000);
      });
    }
  };


  const buttonCommentPostStatus = isCommentEmpty ? 'post-button-disabled' : 'post-button-enabled';

  return (
    <div className="full-preview-modal">
      <div className="full-preview-modal-content">
        <div className="full-preview-modal-header">
          <div className="full-preview-close" onClick={closeFullPreviewModal}>
            <AiOutlineClose
              size={22}
              color="currentColor"
              className="bi bi-x"
            />
          </div>
          {fullPreviewModalOpenSource === "StarUpdateHeaderWithSearch" && (
            <div
              className="navigate-to-post"
              onClick={() =>
                handleNavigateToSelectedPost(selectedPost.id, setPosts)
              }
            >
              <AiOutlineFullscreenExit
                size={22}
                color="currentColor"
                className="bi bi-fullscreen-exit"
              />
            </div>
          )}
          <div className="full-preview-share">
            <AiOutlineShareAlt
              size={22}
              color="currentColor"
              className="bi bi-share"
              onClick={handleShareClick}
            />
          </div>
        </div>
        <div className="shareMessageContainer">
          <div className="shareMessage">
            {shareMessage && (
              <div className="share-message">{shareMessage}</div>
            )}
          </div>
        </div>
        <div className="full-preview-modal-headline">
          <h2>{selectedPost.headline}</h2>
        </div>
        <div className="full-preview-modal-body">
          {renderMedia(selectedPost.news_cover, selectedPost.headline)}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(selectedPost.body),
            }}
          ></div>

          <div className="full-preview-modal-footer">
            <div className="full-preview-publish-date-by">
              <p>
                Published on: {formatDateString(selectedPost.publication_date)}
              </p>
              <p>
                By:{" "}
                {selectedPost.posted_by
                  ? `${selectedPost.posted_by.first_name} ${selectedPost.posted_by.last_name}`
                  : "Unknown"}
              </p>
            </div>
            <div className="Like-full-preview">
              <div className="full-preview-svg-likes">
                <AiFillHeart
                  size={20}
                  color={
                    selectedPost.liked_by_user ? "#f0c14b" : "currentColor"
                  }
                  className="bi bi-heart"
                  onClick={() =>
                    handleLike(
                      selectedPost,
                      null,
                      null,
                      setSelectedPost,
                      updatePostsLikeState
                    )
                  }
                />
                <span
                  className="text-like-full-preview"
                  style={{
                    color: selectedPost.liked_by_user
                      ? "#f0c14b"
                      : "currentColor",
                  }}
                >
                  {selectedPost.liked_by_user ? " Like" : " Like"}
                </span>
                <span className="total-likes">
                  {selectedPost.total_likes ?? ""}
                </span>
              </div>
            </div>
          </div>
          <div className="comments-section" ref={commentSectionRef}>
            <h3>Comments</h3>
            <div ref={commentInputRef}></div>
            {commentsLoading ? (
              <div className="full-preview-modal-loader-container">
                <div className="loader"></div>
              </div>
            ) : comments.length > 0 ? (
              comments.map((comment, index) => (
                <div
                  key={comment.id}
                  className="comment"
                  onClick={(event) => handleCommentClick(event, comment.id)}
                  ref={
                    comments.length === index + 1 ? lastCommentElementRef : null
                  }
                >
                  <div>
                    <strong>{comment.user}</strong>
                    <span className="comment-date">
                      {formatTimeElapsed(comment.comment_date)}
                    </span>
                  </div>
                  <p className="comment-text">{comment.comment_body}</p>
                </div>
              ))
            ) : (
              <p className="text-center">No comment, Be first to comment!</p>
            )}
          </div>
          <div className="scroll-loader-spinner-container-comment-modal">
            {scrollLoadingComment && (
              <div className="scroll-loader-spinner"></div>
            )}
          </div>
        </div>
        <div className="comment-form">
          <input
            type="text"
            value={newComment}
            onChange={handleInputChange}
            placeholder="Add a comment..."
            className="comment-input"
          />
          <button
            onClick={postComment}
            className={`post-button ${buttonCommentPostStatus}`}
            disabled={isCommentEmpty}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};

export default FullPreviewModal;



import React, { createContext, useState, useEffect, useContext } from "react";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";

const StaticContext = createContext();

export const StaticProvider = ({ children }) => {
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [selectedMusic, setSelectedMusic] = useState(null);
  const [userAllMusicData, setUserAllMusicData] = useState({});
  const [musicPaymentData, setMusicPaymentData] = useState({});
  const [musicDurationData, setMusicDurationData] = useState("");
  const [userAllMusicDurationData, setUserAllMusicDurationData] = useState("");
  const [selectedStatistic, setSelectedStatistic] = useState("visits");

  const [visitData, setVisitData] = useState({
    visits_7_days: 0,
    visits_30_days: 0,
    visits_90_days: 0,
  });
  const [earningData, setEarningData] = useState({
    earnings_7_days: 0,
    earnings_previous_7_days: 0,
    earnings_30_days: 0,
    earnings_90_days: 0,
    percentage_change: 0,
  });

  const fetchUserAllMusicData = async () => {
    try {
      const response = await fetch("/api/music-payment-history/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
      const data = await response.json();
      setUserAllMusicData(data);

      const durationResponse = await fetch(
        "/api/play-history/total-music-duration/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      const durationData = await durationResponse.json();
      setUserAllMusicDurationData(durationData.total_played_duration);
    } catch (error) {
      console.error(
        "Error fetching music payment or duration data:",
        error
      );
    }
  };

  const fetchMusicData = async (musicId) => {
    try {
      const paymentResponse = await fetch(
        `/api/music-payment-history/individual-music-data/?music_id=${musicId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      const paymentData = await paymentResponse.json();
      setMusicPaymentData(paymentData);

      const durationResponse = await fetch(
        `/api/play-history/individual-song-duration/?music_id=${musicId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      const durationData = await durationResponse.json();
      setMusicDurationData(durationData.total_played_duration);
    } catch (error) {
      console.error("Error fetching music payment or duration data:", error);
    }
  };

  const fetchData = async (url, setData) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
      const data = await response.json();
      if (selectedStatistic === "earnings") {
        setData({
          earnings_7_days: data.earnings_7_days || 0,
          earnings_previous_7_days: data.earnings_previous_7_days || 0,
          earnings_30_days: data.earnings_30_days || 0,
          earnings_90_days: data.earnings_90_days || 0,
          percentage_change: data.percentage_change || 0,
        });
      } else {
        setData({
          visits_7_days: data.visits_7_days || 0,
          visits_30_days: data.visits_30_days || 0,
          visits_90_days: data.visits_90_days || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserAllMusicData();
  }, [csrftoken]);

  useEffect(() => {
    if (selectedMusic) {
      fetchMusicData(selectedMusic.value);
    }
  }, [selectedMusic, csrftoken]);

  useEffect(() => {
    if (selectedStatistic === "visits") {
      fetchData("/api/profile-visits/stats/", setVisitData);
    } else if (selectedStatistic === "earnings") {
      fetchData("/api/balance-history/stats/", setEarningData);
    }
  }, [csrftoken, selectedStatistic]);

  const percentageChange = earningData.percentage_change || 0;

  return (
    <StaticContext.Provider
      value={{
        selectedMusic,
        setSelectedMusic,
        userAllMusicData,
        setUserAllMusicData,
        musicPaymentData,
        setMusicPaymentData,
        musicDurationData,
        setMusicDurationData,
        userAllMusicDurationData,
        setUserAllMusicDurationData,
        selectedStatistic,
        setSelectedStatistic,
        visitData,
        setVisitData,
        earningData,
        setEarningData,
        fetchUserAllMusicData,
        fetchMusicData,
        fetchData,
        percentageChange,
      }}
    >
      {children}
    </StaticContext.Provider>
  );
};

export const useStatic = () => useContext(StaticContext);



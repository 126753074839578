// musicNavigation.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useChatAndMusicPosts } from '../StarNavigationBar/ChatAndMusicPostsContext';

function MusicNav() {
  const {isWideScreen} = useChatAndMusicPosts
  const location = useLocation();

  return (
    <div className="music-links-container">
      <Link
        to={isWideScreen ? "/home/music/upload-form" : "/music/upload-form"}
        className={
          location.pathname === "/music/upload-form" ||
          location.pathname === "/home/music/upload-form"
            ? "active-section-music"
            : ""
        }
      >
        Upload Music
      </Link>
      <Link
        to={isWideScreen ? "/home/music/music-posts" : "/music/music-posts"}
        className={
          location.pathname === "/music/music-posts" ||
          location.pathname === "/home/music/music-posts"
            ? "active-section-music"
            : ""
        }
      >
        Musics
      </Link>
      <Link
        to={isWideScreen ? "/home/music/your-musics" : "/music/your-musics"}
        className={
          location.pathname === "/music/your-musics" ||
          location.pathname === "/home/music/your-musics"
            ? "active-section-music"
            : ""
        }
      >
        Your Musics
      </Link>
    </div>
  );
}

export default MusicNav;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";
import './HeaderHome.css';

function Gift() {
const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/home');
  };
  return (
    <div className="gift-container">
      <header className="header-gift">
        <div className="Gift-Back-btn" onClick={handleBackToHome}>
          <BiArrowBack
            size={20}
            color="currentColor"
            className="bi bi-arrow-left"
          />
        </div>
      </header>
      <div className="gift-contents">
        <h1></h1>
      </div>
    </div>
  );
}
export default Gift;

import React, {useContext} from 'react';
import DeliveryCostCalculator from './StarNavigationBar/DeliveryCostCalculator';
import { YourShopHeaderPostContext } from './StarNavigationBar/YourShopHeaderPostContext';
import { useOwnModalsContext } from './OwnModalsContext';
import { BiArrowBack } from "react-icons/bi";

const ConfirmationModalYourShopPosts = () => {
  const {  selectedPostToBuy } = useContext(YourShopHeaderPostContext);
   const {
     isModalConfirmOpen,
     setIsModalConfirmOpen,
     setIsPaymentMethodModalOpen,
     country,
     address,
     zipCode,
     houseApartment,
     contactNumber,
     deliveryType,
     favoriteBusOrFerry,
     favoriteCourier,
     setDeliveryDetails,
     exchangeRateLoading,
     distanceLoading,
   } = useOwnModalsContext();

const handleConfirmReviedProduct = () => {
setIsPaymentMethodModalOpen(true);
};

const handleCloseModalConfirm = () =>{
  setIsModalConfirmOpen(false);
 }
 

  return (
    <>
      {/* Confirmation Modal */}
      {isModalConfirmOpen && selectedPostToBuy && (
        <div
          className="confirm-modal-overlay"
          onClick={handleCloseModalConfirm}
        >
          <div
            className="confirm-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="confirm-modal-header">
              <div
                className="close-form-modal"
                onClick={handleCloseModalConfirm}
              >
                <BiArrowBack
                  size={20}
                  color="currentColor"
                  className="bi bi-arrow-left"
                />
              </div>
              <div>
                <h4 className="text-confirm-modal">Details Confirmation</h4>
              </div>
              <div className="empty-div-change-it"></div>
            </div>
            <div className="post-info">
              <h2>{selectedPostToBuy?.product_name}</h2>
              {/* Other post details as needed */}
            </div>
            <div className="delivery-info">
              <h4>Shipping & Fee Details</h4>
              <div className="delivery-details">
                <p>
                  Delivery Type: <span>{deliveryType.label}</span>
                </p>
                {deliveryType.value === "busFerry" && favoriteBusOrFerry && (
                  <p>
                    {" "}
                    Favorite Bus or Ferry:{" "}
                    <span>{favoriteBusOrFerry.label}</span>
                  </p>
                )}
                {deliveryType.value === "airplane" && favoriteCourier && (
                  <p>
                    {" "}
                    Favorite Courier: <span>{favoriteCourier.label}</span>
                  </p>
                )}
                {deliveryType.value !== "selfPickUp" && (
                  <div className="address-details">
                    {country && (
                      <p>
                        {" "}
                        Country: <span>{country.label}</span>
                      </p>
                    )}
                    {address && (
                      <p>
                        {" "}
                        Address: <span>{address}</span>
                      </p>
                    )}
                    {houseApartment && (
                      <p>
                        House/Apartment: <span>{houseApartment}</span>
                      </p>
                    )}
                    {zipCode && (
                      <p>
                        {" "}
                        Zip Code: <span>{zipCode}</span>
                      </p>
                    )}
                    {contactNumber && (
                      <p>
                        {" "}
                        Contact Number: <span>{contactNumber}</span>
                      </p>
                    )}
                  </div>
                )}
                {deliveryType.value === "selfPickUp" && (
                  <p>You chose to pick up the package yourself.</p>
                )}
                <div className="cost-calculator">
                  <DeliveryCostCalculator
                    productPrice={selectedPostToBuy?.price}
                    productPriceUSD={selectedPostToBuy?.converted_price?.USD}
                    productCurrency={selectedPostToBuy?.currency}
                    productLocation={selectedPostToBuy?.product_location}
                    buyerAddress={address}
                    deliveryType={deliveryType.value}
                    country={country?.label}
                    onCalculate={setDeliveryDetails}
                  />
                </div>
              </div>
            </div>
            <button
              className={`confirm-confirm-button ${
                exchangeRateLoading || distanceLoading ? "disabled" : ""
              }`}
              onClick={handleConfirmReviedProduct}
              disabled={exchangeRateLoading || distanceLoading}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModalYourShopPosts;

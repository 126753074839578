import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PostStarUpdateNav from './PostStarUpdateNav';
import { useLikesComments } from '../LikesCommentsContext'; 
import './Admin.css';

function PostStarUpdateOutlet() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setPage,  
  setAdminTopPricedPosts,
  setAdminStarAwardPosts,
  handleCancelSearchForm,
  setAdminBestSellingPosts, 
  clearFetchedPostIds}= useLikesComments();

  useEffect(() => {
    if (location.pathname === "/adminGooofan/star-nav/posts") {
      navigate("/adminGooofan/star-nav/posts/updates/best-selling");
    }
  }, [location, navigate]);

   useEffect(() => {
    setPage(1);
    setAdminTopPricedPosts([]);
    setAdminStarAwardPosts([]);
    setAdminBestSellingPosts([]);
    clearFetchedPostIds();
    handleCancelSearchForm();
  }, [location.pathname, setPage, setAdminBestSellingPosts, setAdminStarAwardPosts, setAdminTopPricedPosts]);

  return (
    <div>
      <PostStarUpdateNav />
      <>
       <Outlet />
      </>
    </div>
  );
}

export default PostStarUpdateOutlet;


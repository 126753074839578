import React, {
  useEffect,
  useState,
  useLayoutEffect
} from "react";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { useChat } from "./ChatContext";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import useAlignSpinner from "../hooks/useAlignSpinner";
import "../StarNavigationBar/chat.css";


const MessageList = () => {
  const {
    retryMessage,
    sendSeenAcknowledgment,
    getMessageBackgroundColor,
    handleMessageClick,
    scrollToBottom,
  } = useChat();
  const {
    messages,
    setMessages,
    currentConversationId,
    userData,
    lastMessageRef,
    firstUnreadMessage,
    chatOpenStatus,
    unreadLabel,
    messageScrollLoading,
    chatContainerRef,
    setMessageImagePreview,
    setPreviewedMessageId,
    setPreviewedMessageTempId,
    messagePageHasMore,
    handleMessageScroll,
    isMessageFetching,
    fetchedMessagesCount,
    currentConversationIdRef,
    fetchConversationData,
    messagePage,
    selectedUser,
  } = useChatAndMusicPosts();
  // Variable for preventing scrolling to bottom when message deleted
  const [prevMessagesLength, setPrevMessagesLength] = useState(0);

  //Use useEffect to fetch data when conversationId or messagePage changes
  useEffect(() => {
    if (currentConversationId) {
      fetchConversationData(currentConversationId, selectedUser?.username);
    }
  }, [messagePage]);

  const combinedRef = (node, i) => {
    if (i === messages.length - 1) {
      lastMessageRef.current = node;
    }
  };

  const openMessageImagePreview = (event, image, id, tempId) => {
    event.stopPropagation();
    setMessageImagePreview(image);
    setPreviewedMessageId(id);
    setPreviewedMessageTempId(tempId);
  };

  // Function to parse URLs and turn them into clickable links
  const parseTextWithLinks = (text) => {
    // Regex to match URLs, but capture the domain and path only
    const urlPattern =
      /\b((?:https?:\/\/)?(?:www\.)?[-A-Z0-9+&@#/%=~_|$?!:,.;]*\.[A-Z]{2,6}[-A-Z0-9+&@#/%=~_|$]*)/gi;

    return text.split(urlPattern).map((part, index) => {
      if (part && urlPattern.test(part)) {
        // If the URL does not start with "http" or "https", prepend "https://"
        const href =
          part.startsWith("http://") || part.startsWith("https://")
            ? part
            : `https://${part}`;
        return (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  // Observer setup for message acknowledgment

  useEffect(() => {
    if (messages.length === 0) return; // Guard clause for empty messages array

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const tempId = entry.target.getAttribute("data-temp-id");
          const message = messages.find((msg) => msg.temp_id === tempId);

          if (entry.isIntersecting && message) {
            // Only send acknowledgment if the message is received, unseen, and in the current conversation
            if (
              !message.isSeen &&
              message.conversationId === currentConversationIdRef.current &&
              message.sender !== userData.username // Ensures we are only marking received messages as seen
            ) {
              // Send seen acknowledgment using temp_id
              sendSeenAcknowledgment(tempId);

              // Update state to mark the message as seen
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.temp_id === tempId ? { ...msg, isSeen: true } : msg
                )
              );
            }
          }
        });
      },
      { threshold: 1.0 } // Trigger only when the entire message is in view
    );

    // Select message elements to observe
    const messageElements = document.querySelectorAll(".message-received");
    messageElements.forEach((element) => observer.observe(element));

    // Cleanup observer on component unmount or messages change
    return () => {
      messageElements.forEach((element) => observer.unobserve(element));
    };
  }, [messages, currentConversationIdRef]);

  useLayoutEffect(() => {
    // Check if there are messages and lastMessageRef is available
    // Also check if the length of the messages array has increased to prevent from scrolling to bottom when messages are deleted
    // Also check if the total number of messages exceeds the number of fetched messages
    // Also check if new messages are not currently being fetched
    if (
      messages.length > 0 &&
      lastMessageRef.current &&
      messages.length > prevMessagesLength &&
      messages.length > fetchedMessagesCount &&
      !isMessageFetching
    ) {
      setTimeout(() => {
        lastMessageRef.current.scrollIntoView({ block: "end" });
      }, 0);
    }
    setPrevMessagesLength(messages.length);
  }, [messages, isMessageFetching]);

  //Fetch more messages when user scrolls
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleMessageScroll);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleMessageScroll);
      }
    };
  }, [messagePageHasMore]);

  // hook to align the spinner center horizontally
  return (
    <div className="messages-container" ref={chatContainerRef}>
      <div className="scroll-loader-spinner-container-message">
        {messageScrollLoading && <div className="scroll-loader-spinner"></div>}
      </div>
      <div className="messages">
        {messages
          .filter((msg) => {
            const isCurrentConversation =
              msg.conversationId === currentConversationId;
            return isCurrentConversation;
          })
          .map((msg, i) => {
            const timestamp = msg.timestamp
              ? new Date(Date.parse(msg.timestamp))
              : new Date();
            const timeString = timestamp.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            const dateString = timestamp.toLocaleDateString("en-US", {
              weekday: "long",
              day: "numeric",
              month: "long",
            });
            const isUnreadMessage =
              firstUnreadMessage[currentConversationId] &&
              firstUnreadMessage[currentConversationId].temp_id ===
                msg.temp_id &&
              msg.sender !== userData.username;

            return (
              <React.Fragment key={i}>
                {i === 0 ||
                (i > 0 &&
                  messages[i - 1].timestamp &&
                  msg.timestamp &&
                  new Date(messages[i - 1].timestamp).toDateString() !==
                    timestamp.toDateString()) ? (
                  <div className="date">{dateString}</div>
                ) : null}

                {!chatOpenStatus &&
                  isUnreadMessage &&
                  unreadLabel[currentConversationId] &&
                  unreadLabel[currentConversationId].unreadLabel && (
                    <div className="unread-messages-marker">
                      {unreadLabel[currentConversationId].unreadLabel}
                    </div>
                  )}
                <div
                  className={
                    msg.sender === userData.username || msg.is_current_user
                      ? "message-sent"
                      : "message-received"
                  }
                  key={msg.id}
                  ref={(node) => combinedRef(node, i)}
                  onClick={(event) =>
                    handleMessageClick(event, msg.id, msg.temp_id)
                  }
                  style={getMessageBackgroundColor(msg.id)}
                  data-temp-id={msg.temp_id}
                  data-conversation-id={msg.conversationId}
                >
                  <p className="message-content">
                    {parseTextWithLinks(msg.text)}
                  </p>

                  {msg.image && (
                    <img
                      src={msg.image}
                      alt=""
                      className="thumbnail-message-image"
                      onClick={(event) =>
                        openMessageImagePreview(
                          event,
                          msg.image,
                          msg.id,
                          msg.temp_id
                        )
                      }
                    />
                  )}
                  <div className="time-spanner">
                    <div className="time">{timeString}</div>
                    {msg.isLoading ? (
                      <div className="loading-spinner"></div>
                    ) : msg.isError ? (
                      <i
                        className="bi bi-exclamation-circle-fill message-error-icon"
                        onClick={(event) => retryMessage(event, msg)}
                      ></i>
                    ) : msg.sender === userData.username ||
                      msg.is_current_user ? (
                      msg.isSeen ? (
                        <BiCheckDouble className="message-seen-icon" />
                      ) : msg.isReceived ? (
                        <BiCheck className="message-received-icon" />
                      ) : null
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default MessageList;

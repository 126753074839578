// components/StarHomeComponents/Header.jsx
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Offcanvas } from 'bootstrap';
import { Link } from 'react-router-dom';
import { useUserLogout } from "../UserLogoutContext";
import { useNavigate } from "react-router-dom";
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import { useSidebar3 } from '../StarNavigationBar/Sidebar3Context';
import { useUserRole } from '../UserRoleContext';
import gooofanLogo from "../../assets/images/gooofan-logo.svg";
import './Header.css';

import {
  BiUser,
  BiFlag,
  BiHelpCircle,
  BiLock,
  BiShare,
  BiLogOut,
  BiSearch,
  BiBell,
  BiShield,
  BiMenu,
  BiCrown,
} from "react-icons/bi"; 
import { AiOutlineStar } from "react-icons/ai";

function Header() {
  const { handleLogout } = useUserLogout();
  const { handleShareGooofan, shareMessage } = useSidebar3();
  const offcanvasRef = useRef(null);
  const fanStarHeaderRef = useRef(null);
  const logoFanRef = useRef(null);
  const menuButtonRef = useRef(null);
  const [userData, setUserData] = useState({});
  const [imageFailed, setImageFailed] = useState(false);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');
  const { newNotificationCount } = useContext(YourShopHeaderPostContext);
  const { role, loading, error } = useUserRole();

   //For user profile
  useEffect(() => {
        fetch('/api/get_user_profile_data/')  
            .then(response => response.json())
            .then(data => {
                setUserData(data);
                setImageFailed(false);  // Reset the imageFailed state whenever userData changes
            })
            .catch(error => console.error('Error fetching user data:', error));
    }, []);
  
 //API For validating user if is super user or staff
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await fetch('/api/admin_auth/');
        if (response.ok) {
          const data = await response.json();
          setUserRole(data.role);
        } else {
          // Handle error
          console.error('Failed to fetch user role');
        }
      } catch (error) {
        // Handle error
        console.error('Failed to fetch user role:', error);
      }
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    const offcanvasElement = offcanvasRef.current;
    const fanStarHeader = fanStarHeaderRef.current;
    const logoFan = logoFanRef.current;
    const menuButton = menuButtonRef.current;

    if (offcanvasElement && fanStarHeader && logoFan && menuButton) {
      // Save the original styles so they can be reset later
      let originalHeaderTransform = fanStarHeader.style.transform;
      let originalLogoMarginLeft = logoFan.style.marginLeft;

      const handleShow = () => {
        // Get the width of the off-canvas and the total screen width
        let offcanvasWidth = offcanvasElement.offsetWidth;
        let screenWidth = window.innerWidth;

        // Calculate the remaining width after the off-canvas is shown
        let remainingWidth = screenWidth - offcanvasWidth;

        // Move the fan-star-header to the right by the width of the off-canvas
        fanStarHeader.style.transform = `translateX(${offcanvasWidth}px)`;

        // Hide the menu button when the off-canvas is shown
        menuButton.style.display = "none";

        // Get the width of the logo
        let logoWidth = logoFan.offsetWidth;

        // Center the logo in the remaining space
        let logoMarginLeft = (remainingWidth - logoWidth) / 2;

        // Apply the calculated margin to the logo
        logoFan.style.marginLeft = `${logoMarginLeft}px`;
      };

      const handleHide = () => {
        // Reset the fan-star-header and logo to their original styles
        fanStarHeader.style.transform = originalHeaderTransform;
        logoFan.style.marginLeft = originalLogoMarginLeft;

        // Show the menu button again when the off-canvas is hidden
        menuButton.style.display = "block";
      };

      // Attach event listeners for when the off-canvas is shown or hidden
      offcanvasElement.addEventListener("show.bs.offcanvas", handleShow);
      offcanvasElement.addEventListener("hide.bs.offcanvas", handleHide);

      // Cleanup: Remove the event listeners when the component is unmounted
      return () => {
        offcanvasElement.removeEventListener("show.bs.offcanvas", handleShow);
        offcanvasElement.removeEventListener("hide.bs.offcanvas", handleHide);
      };
    }
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      const offcanvasElement = offcanvasRef.current;
      const isClickInside = offcanvasElement.contains(event.target);
      const offcanvas = document.querySelector(".offcanvas.show");

      if (!isClickInside && offcanvas) {
        const bsOffcanvas = Offcanvas.getInstance(offcanvas);
        bsOffcanvas.hide();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearchClick = () => {
    navigate("/search");
  };

 return (
   <>
     <div className="container-fan-star-header">
       <div className="fan-star-header" ref={fanStarHeaderRef}>
         <button
           id="menuButton"
           className="menu-button"
           type="button"
           data-bs-toggle="offcanvas"
           data-bs-target="#offcanvasWithBothOptions"
           aria-controls="offcanvasWithBothOptions"
           ref={menuButtonRef}
         >
           <BiMenu size={30} />
         </button>
         <img
           className="logo-fan-star"
           src={gooofanLogo}
           alt="Logo"
           ref={logoFanRef}
         />
         <div className="header-icon-holder">
           <div
             className="search-icon"
             onClick={(e) => {
               e.stopPropagation();
               handleSearchClick();
             }}
           >
             <BiSearch size={22} />
           </div>
           <Link to="/notifications" className="notification-button">
             <BiBell size={22} />
             {newNotificationCount > 0 && (
               <span className="new-notification-badge-count">
                 {newNotificationCount}
               </span>
             )}
           </Link>
           <Link to="/wallet" className="wallet-button">
             <svg
               stroke="currentColor"
               fill="currentColor"
               strokeWidth="0"
               viewBox="0 0 24 24"
               height="34"
               width="34"
               xmlns="http://www.w3.org/2000/svg"
             >
               <rect
                 x="3"
                 y="7"
                 width="18"
                 height="10"
                 rx="2"
                 ry="2"
                 stroke="currentColor"
                 strokeWidth="1.2"
                 fill="none"
               />

               <path
                 d="M4 10C3.44772 10 3 10.4477 3 11C3 11.5523 3.44772 12 4 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H4Z"
                 stroke="currentColor"
                 strokeWidth="1"
                 fill="none"
               />

               <circle
                 cx="19"
                 cy="12"
                 r="1.5"
                 stroke="currentColor"
                 strokeWidth="1"
                 fill="none"
               />
             </svg>
           </Link>
         </div>
       </div>
     </div>

     <div
       className="offcanvas offcanvas-start offcanvas-starFan-profile"
       data-bs-scroll="true"
       data-bs-backdrop="false"
       id="offcanvasWithBothOptions"
       ref={offcanvasRef}
     >
       <div className="offcanvas-header">
         <div className="profile">
           {userData.profile_picture && !imageFailed ? (
             <img
               src={userData.profile_picture}
               alt={userData.first_name}
               className="profile-picture"
               onError={() => setImageFailed(true)}
             />
           ) : (
             <div className="profile-picture no-profile-picture">
               {userData.first_name
                 ? userData.first_name.slice(0, 1).toUpperCase()
                 : null}
             </div>
           )}
         </div>
         <div className="offcanvas_username-display">{userData.username}</div>
       </div>
       <div className="offcanvas-body offcanvas-sm-screenHome d-md-none">
         <div className="fan-star-menuBarItem">
           {/* Conditionally render "You Own" and "Subscribed" links */}
           {role === "star" && (
             <>
               <Link to="/you-own">
                 <BiCrown size={20} />
                 <span>You Own</span>
               </Link>

               <Link to="/subscribed">
                 <AiOutlineStar size={20} />
                 <span>Subscribed</span>
               </Link>
             </>
           )}
           <Link to="/account">
             <BiUser size={20} />
             <span>Account</span>
           </Link>
           <Link to="/report">
             <BiFlag size={20} />
             <span>Report</span>
           </Link>
           <a href="/faq/">
             <BiHelpCircle size={20} />
             <span>FAQ</span>
           </a>
           <a href="/privacy-policy/">
             <BiLock size={20} />
             <span>Terms & Privacy</span>
           </a>

           <hr className="separator-line" />
           <Link onClick={handleShareGooofan}>
             <BiShare size={20} />
             <span>Share Gooofan</span>
           </Link>
           {shareMessage && <p className="share-message">{shareMessage}</p>}
           <Link onClick={handleLogout}>
             <BiLogOut size={20} />
             <span>Log out</span>
           </Link>
           <div className="spacer-for-adminSection"></div>
           {/* Conditionally render the icon and link based on user's role */}
           {userRole === "staff" || userRole === "superuser" ? (
             <Link to="/adminGooofan/homescreen" className="admin-section">
               <BiShield size={20} />
             </Link>
           ) : null}
         </div>
       </div>
     </div>
   </>
 );

}

export default Header;


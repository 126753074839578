// YourMusics.jsx
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { FooterContext } from "../StarNavigationBar/FooterContext";
import { BiArrowBack, BiDotsVerticalRounded, BiTrash } from "react-icons/bi";
import CustomAudioPlayerWithProvider from "../CustomAudioPlayer";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import "./YourMusics.css";

const YourMusicPosts = () => {
  const {isWideScreen} = useChatAndMusicPosts();
  const yourMusicContainerRef = useRef(null);
  const {
    showMenu,
    setShowMenu,
    menuRef,
    copyLinkText,
    iconClass,
    sharePostText,
    shareIconClass,
    csrftoken,
  } = useContext(YourShopHeaderPostContext);
  const {
    musicPosts,
    setMusicPosts,
    yourMusicPage,
    setYourMusicPage,
    handleMusicOptions,
    handleOutsideClick,
    handleCopyLink,
    handleSharePost,
  } = useContext(FooterContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [yourMusicScrollLoading, setYourMusicScrollLoading] = useState(false);
  const [yourMusicFirstLoad, setYourMusicFirstLoad] = useState(true);
  const [yourMusicPageHasMore, setYourMusicPageHasMore] = useState(true);
  const yourMusicObserver = useRef();

  useEffect(() => {
    fetchUserMusicPosts();
  }, [yourMusicPage]);

  const lastYourMusicElementRef = useCallback(
    (node) => {
      if (yourMusicScrollLoading) return;
      if (yourMusicObserver.current) yourMusicObserver.current.disconnect();
      yourMusicObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && yourMusicPageHasMore) {
          setYourMusicPage((prevPage) => prevPage + 1);
        }
      });
      if (node) yourMusicObserver.current.observe(node);
    },
    [yourMusicScrollLoading, yourMusicPageHasMore]
  );

  const fetchUserMusicPosts = async () => {
    if (yourMusicFirstLoad) {
      setLoading(true);
    } else if (yourMusicPage !== 1) {
      setYourMusicScrollLoading(true);
    }
    try {
      const response = await fetch(
        `/api/music/my_music_posts/?page=${yourMusicPage}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setMusicPosts((prevPosts) => [...prevPosts, ...data.results]);
      setYourMusicPageHasMore(data.next !== null);
    } catch (error) {
      console.error("Error fetching user music posts:", error);
    } finally {
      if (yourMusicFirstLoad) {
        setLoading(false);
        setYourMusicFirstLoad(false);
      }
      if (yourMusicPage !== 1) {
        setYourMusicScrollLoading(false);
      }
    }
  };

  const handleBackToUploadMusic = (event) => {
    event.stopPropagation();
    navigate(`${isWideScreen ? "/home" : ""}/music/upload-form`);
    setYourMusicPage(1);
    setMusicPosts([]);
  };

  useEffect(() => {
    if (musicPosts.length > 0) {
      // Create a new showMenu object with a key for each music ID
      const newShowMenu = musicPosts.reduce((acc, music) => {
        acc[music.id] = false;
        return acc;
      }, {});
      setShowMenu(newShowMenu);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [musicPosts]);

  const handleDeleteMusic = async (musicId) => {
    try {
      const response = await fetch(`/api/music/delete-music`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ music_id: musicId }),
      });

      if (response.ok) {
        // Remove the music from the UI
        setMusicPosts((prevMusics) =>
          prevMusics.filter((music) => music.id !== musicId)
        );
      } else {
        const errorData = await response.json();
        console.error("Error deleting post:", errorData);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <>
      <div className="header-your-music" onClick={(e) => e.stopPropagation()}>
        <div className="yourMusicBack-btn" onClick={handleBackToUploadMusic}>
          <BiArrowBack
            size={20}
            color="currentColor"
            className="bi bi-arrow-left"
          />
        </div>
        <div className="your-music-text">Your Music Posts</div>
        <div className=""></div>
      </div>
      <div className="your-music-posts">
        <div className="yourmusicPosts-container" ref={yourMusicContainerRef}>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : musicPosts.length === 0 ? (
            <p className="posts-yourmusic">
              You've not posted any music yet, post to start earn.
            </p>
          ) : (
            musicPosts.map((music, index) => (
              <div
                key={music.id}
                className="yourmusic-wrapper-musicpost-audioplayer"
                ref={
                  musicPosts.length === index + 1
                    ? lastYourMusicElementRef
                    : null
                }
              >
                <div className="musicPost">
                  {music.cover_image ? (
                    <img
                      src={music.cover_image}
                      alt={`${music.song_title} cover`}
                      className="musicPost-cover-image"
                    />
                  ) : (
                    <div className="musicPost-cover-placeholder">
                      {music.song_title.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="musicPost-info">
                    <h3 className="musicPost-artist">{music.artist}</h3>
                    <p className="musicPost-song-title">{music.song_title}</p>
                    {music.featuring && (
                      <p className="musicPost-featuring">
                        Feat. {music.featuring.replace(/,/g, " &")}
                      </p>
                    )}
                  </div>
                  <div className="musicPost-more-options" ref={menuRef}>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMusicOptions(music.id);
                      }}
                    >
                      <BiDotsVerticalRounded size={20} />
                    </button>
                    {showMenu[music.id] && (
                      <div
                        className={`menu ${showMenu[music.id] ? "show" : ""}`}
                      >
                        <div
                          className="share-post-copy"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyLink(music.id);
                          }}
                        >
                          {iconClass}
                          <span className="copy-link">{copyLinkText}</span>
                        </div>
                        <div
                          className="share-post"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSharePost(music.id);
                          }}
                        >
                          {shareIconClass}
                          <span className="share-post-word">
                            {sharePostText}
                          </span>
                        </div>
                        <div
                          className="delete-post"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteMusic(music.id);
                          }}
                        >
                          <BiTrash size={16} />
                          <span className="text-delete">Delete</span>
                        </div>
                        {/* Other menu items... */}
                      </div>
                    )}
                  </div>
                </div>
                <CustomAudioPlayerWithProvider
                  audioSrc={music.audio_file}
                  musicId={music.id}
                />
                <div className="scroll-loader-spinner-container">
                  {yourMusicScrollLoading && (
                    <div className="scroll-loader-spinner"></div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default YourMusicPosts;

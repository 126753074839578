// AdminPostArchiveloader.jsx
import React, { useState, useEffect, useContext } from 'react';
import VideoPlayer from "../VideoPlayer";
import GlobalLazyImage from '../GlobalLazyImage';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';

function AdminPostArchiveLoader() {
    const [archivedPosts, setArchivedPosts] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const { csrftoken } = useContext(YourShopHeaderPostContext);

    useEffect(() => {
        fetch('/api/AdminPosts/archived/')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch archived posts');
                }
            })
            .then(data => {
                // Delay setting the posts and loading state by 1  second
                    setTimeout(() => {
                    setArchivedPosts(data);
                    setIsLoading(false); 
                }, 1000);
               
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

   const handleRepost = (post) => {
     fetch(`/api/AdminPosts/${post.id}/repost/`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         "X-CSRFToken": csrftoken,
       },
     })
       .then((response) => {
         if (response.ok) {
           setArchivedPosts(archivedPosts.filter((p) => p.id !== post.id));
         } else {
           return response.json().then((data) => {
             throw new Error(data.error);
           });
         }
       })
       .catch((error) => {
         console.error("Error:", error);
         setError(error.message);
       });
   };



    const handleDelete = (post) => {
        const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];

        fetch(`/api/AdminPosts/${post.id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
        })
        .then(response => {
            if (response.ok) {
                setArchivedPosts(archivedPosts.filter(p => p.id !== post.id));
            } else {
                throw new Error('Failed to delete post');
            }
        })
        .catch((error) => {
            
        });
    };

return (
  <div className="Container admin-post-list-container">
    {isLoading ? (
      <p>Loading posts...</p>
    ) : (
      <>
        {archivedPosts.length > 0 ? (
          <div className="admin-post-list">
            {archivedPosts.map((post, index) => (
              <div key={index} className="admin-postPanel">
                <div className="admin-post-title">
                  {post.title ?? "No title"}
                </div>
                {post.link && (
                  <div className="admin-post-link">
                    <a href={post.link}>{post.action ?? "No action"}</a>
                  </div>
                )}
                <div className="admin-post-media">
                  {post.images?.map((image, index) => (
                    <GlobalLazyImage
                      key={image.id}
                      src={image.image}
                      alt=""
                      className="admin-post-image"
                    />
                  ))}
                  {post.videos?.map((video, index) => (
                    <VideoPlayer
                      key={video.id}
                      src={video.video}
                      type={video.type}
                      preload="none"
                    />
                  ))}
                </div>
                {error && (
                  <p className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>{" "}
                    {error}
                  </p>
                )}
                <div className="admin-post-actions">
                  <button onClick={() => handleRepost(post)}>Repost</button>
                  <button onClick={() => handleDelete(post)}>Delete</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No archived post yet.</p>
        )}
      </>
    )}
  </div>
);
}

export default AdminPostArchiveLoader;

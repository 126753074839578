import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import React, { Suspense, lazy } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 
import "./App.css";
import { ToastContainer } from "react-toastify";
import StarNavigationBar from "./components/StarNavigationBar/Navigation";
//Sidebar
import Home from "./components/StarNavigationBar/Home";
import Search from "./components/StarNavigationBar/Search";
import ExploreShops from "./components/StarNavigationBar/ExploreShops";
// import Chat from "./components/StarNavigationBar/Chat";
import ConversationList from "./components/StarNavigationBar/ConversationList";
import Report from "./components/StarHomeComponents/HeaderHomeComponents/Report";
import YouOwn from "./components/StarNavigationBar/YouOwn";
//Footer Nav
import Subscriber from "./components/StarNavigationBar/Subscriber";
import UploadPost from "./components/StarNavigationBar/UploadPost";
import YourShop from "./components/StarNavigationBar/YourShop";
import Subscribed from "./components/StarNavigationBar/Subscribed";

// Your shop Nav
import YourShopOrders from "./components/StarNavigationBar/YourShopOrders";
import YourShopPosts from "./components/StarNavigationBar/YourShopPost";
import YourShopSoldOut from "./components/StarNavigationBar/YourShopSoldOut";

// Import Admin component and its subcomponents
import Admin from "./components/AdminComponents/Admin";
import HomeScreenOutlet from "./components/AdminComponents/HomeScreenOutlet";
import AdminPosts from "./components/AdminComponents/AdminPosts";
import AdminPostForm from "./components/AdminComponents/AdminPostForm";
import AdminPostArchive from "./components/AdminComponents/AdminPostArchive";

import StarUpdateForm from "./components/AdminComponents/StarsUpdateForm";
import StarsUpdateOutlet from "./components/AdminComponents/StarsUpdateOutlet";
import BestSellingArchieves from "./components/AdminComponents/BestSellingArchieves";
import BestSellingPosts from "./components/AdminComponents/BestSellingPosts";
import StarAwardArchieves from "./components/AdminComponents/StarAwardArchieves";
import StarAwardPosts from "./components/AdminComponents/StarAwardPosts";
import TopPricedArchieves from "./components/AdminComponents/TopPricedArchieves";
import TopPricedPosts from "./components/AdminComponents/TopPricedPosts";
import StarUpdateArchieveOutlet from "./components/AdminComponents/StarUpdateArchieveOutlet";
import PostStarUpdateOutlet from "./components/AdminComponents/PostStarUpdateOutlet";
import { UploadPostProvider } from "./components/StarNavigationBar/UploadPostContext";

// Import music component and its subcomponents
import Music from "./components/MusicSectionComponents/Music";
import MusicPosts from "./components/MusicSectionComponents/MusicPosts";
import UploadMusic from "./components/MusicSectionComponents/UploadMusic";
import YourMusics from "./components/MusicSectionComponents/YourMusics";
import SharedMusicModal from "./components/MusicSectionComponents/SharedMusicModal";

//For message
import { PinnedSelectedUserProvider } from "./components/StarNavigationBar/pinnedSelectedUserContext";
import { ChatProvider } from "./components/MessageSection/ChatContext";
import { ChatAndMusicPostsProvider } from "./components/StarNavigationBar/ChatAndMusicPostsContext";

//for sidebar3
import { Sidebar3Provider } from "./components/StarNavigationBar/Sidebar3Context";
import { FooterProvider } from "./components/StarNavigationBar/FooterContext";
import { UserLogoutProvider } from "./components/UserLogoutContext";

//for YourShopHeader and YourShopPost
import { YourShopHeaderPostProvider } from "./components/StarNavigationBar/YourShopHeaderPostContext";
import LiveVisitorsProvider from "./components/LiveVisitorsContext";
import { LikesCommentsProvider } from "./components/LikesCommentsContext";
import { OwnModalsProvider } from "./components/OwnModalsContext";
import { GetUserCurrentLocationProvider } from "./components/GetUserCurrentLocationContext";
import { PlayersProvider } from "./components/PlayersContext";
import { StaticProvider } from "./components/StarHomeComponents/StaticContext";

import Gift from "./components/StarHomeComponents/HeaderHomeComponents/Gift";
import Notification from "./components/StarHomeComponents/HeaderHomeComponents/Notification";
import Wallet from "./components/StarHomeComponents/HeaderHomeComponents/Wallet";
import Account from "./components/StarHomeComponents/HeaderHomeComponents/Account";

import AllStatics from "./components/StarHomeComponents/AllStatics";
import BestSelling from "./components/StarsUpdateComponents/BestSelling";
import TopPriced from "./components/StarsUpdateComponents/TopPriced";
import StarAwards from "./components/StarsUpdateComponents/StarAwards";
import SharedStarUpdateModal from "./components/StarsUpdateComponents/SharedStarUpdateModal";
import { UserRoleProvider } from "./components/UserRoleContext";
import ChatRoom from "./components/MessageSection/ChatRoom";
// Lazy load music component and its subcomponents
// const Music = lazy(() => import("./components/MusicSectionComponents/Music"));
// const MusicPosts = lazy(() => import("./components/MusicSectionComponents/MusicPosts"));
// const UploadMusic = lazy(() => import("./components/MusicSectionComponents/UploadMusic"));
// const YourMusics = lazy(() => import("./components/MusicSectionComponents/YourMusics"));

function App() {
  return (
    <Router basename="/gof">
      <div className="container-app">
        <UserRoleProvider>
          <UserLogoutProvider>
            <Sidebar3Provider>
              <YourShopHeaderPostProvider>
                <FooterProvider>
                  <PinnedSelectedUserProvider>
                    <ChatAndMusicPostsProvider>
                      <ChatProvider>
                        <LiveVisitorsProvider>
                          <LikesCommentsProvider>
                            <GetUserCurrentLocationProvider>
                              <OwnModalsProvider>
                                <PlayersProvider>
                                  <StaticProvider>
                                    <UploadPostProvider>
                                      <StarNavigationBar />
                                      <ToastContainer />

                                      <Routes>
                                        {" "}
                                        {/* Change to Routes */}
                                        {/* <Route
                                          path="/home"
                                          element={<Home />}
                                        />{" "} */}
                                        {/* Nested Routes for Larger Screens(992) */}
                                        <Route path="/home" element={<Home />}>
                                          <Route
                                            path="start-chat"
                                            element={<ConversationList />}
                                          />
                                          <Route
                                            path="start-chat/:conversationId/:uniqueString"
                                            element={<ChatRoom />}
                                          />
                                          <Route
                                            path="upload"
                                            element={<UploadPost />}
                                          />
                                          <Route
                                            path="you-own"
                                            element={<YouOwn />}
                                          />{" "}
                                          <Route
                                            path="subscribed"
                                            element={<Subscribed />}
                                          />{" "}
                                          <Route
                                            path="report"
                                            element={<Report />}
                                          />{" "}
                                          <Route
                                            path="gift"
                                            element={<Gift />}
                                          />{" "}
                                          <Route
                                            path="notifications"
                                            element={<Notification />}
                                          />{" "}
                                          <Route
                                            path="wallet"
                                            element={<Wallet />}
                                          />{" "}
                                          <Route
                                            path="account"
                                            element={<Account />}
                                          />{" "}
                                          <Route
                                            path="subscriber"
                                            element={<Subscriber />}
                                          />{" "}
                                          <Route
                                            path="all-statics"
                                            element={<AllStatics />}
                                          />
                                          {/* Music section routes A for screens above 992px */}
                                          <Route
                                            path="music/*"
                                            element={<Music />}
                                          >
                                            <Route
                                              path="upload-form"
                                              element={<UploadMusic />}
                                            />
                                            <Route
                                              path="music-posts"
                                              element={<MusicPosts />}
                                            />
                                            <Route
                                              path="your-musics"
                                              element={<YourMusics />}
                                            />
                                          </Route>
                                        </Route>
                                        <Route
                                          path="/start-chat"
                                          element={<ConversationList />}
                                        />
                                        <Route
                                          path="/start-chat/:conversationId/:uniqueString"
                                          element={<ChatRoom />}
                                        />
                                        <Route
                                          path="/search"
                                          element={<Search />}
                                        />{" "}
                                        <Route
                                          path="/explore-shops"
                                          element={<ExploreShops />}
                                        />{" "}
                                        <Route
                                          path="/all-statics"
                                          element={<AllStatics />}
                                        />{" "}
                                        <Route
                                          path="/you-own"
                                          element={<YouOwn />}
                                        />{" "}
                                        <Route
                                          path="/subscribed"
                                          element={<Subscribed />}
                                        />{" "}
                                        <Route
                                          path="/report"
                                          element={<Report />}
                                        />{" "}
                                        <Route
                                          path="/gift"
                                          element={<Gift />}
                                        />{" "}
                                        <Route
                                          path="/notifications"
                                          element={<Notification />}
                                        />{" "}
                                        <Route
                                          path="/wallet"
                                          element={<Wallet />}
                                        />{" "}
                                        <Route
                                          path="/account"
                                          element={<Account />}
                                        />{" "}
                                        {/* <Route
                                          path="/start-chat/:conversationId/:uniqueString"
                                          element={<Chat />}
                                        /> */}
                                        {/* <Route
                                          path="/start-chat/:conversationId/:uniqueString"
                                          element={<ChatRoom />}
                                        />
                                        <Route
                                          path="/start-chat"
                                          element={<ConversationList />}
                                        /> */}
                                        <Route
                                          path="/subscriber"
                                          element={<Subscriber />}
                                        />{" "}
                                        <Route
                                          path="/upload"
                                          element={<UploadPost />}
                                        />{" "}
                                        {/* Admin section routes */}
                                        <Route>
                                          <Route
                                            path="/adminGooofan/*"
                                            element={<Admin />}
                                          >
                                            <Route
                                              path="homescreen/*"
                                              element={<HomeScreenOutlet />}
                                            >
                                              <Route
                                                path="post-form"
                                                element={<AdminPostForm />}
                                              />
                                              <Route
                                                path="posts"
                                                element={<AdminPosts />}
                                              />
                                              <Route
                                                path="post-archive"
                                                element={<AdminPostArchive />}
                                              />
                                            </Route>
                                            <Route
                                              path="star-nav/*"
                                              element={<StarsUpdateOutlet />}
                                            >
                                              <Route
                                                path="update-form"
                                                element={<StarUpdateForm />}
                                              />
                                              <Route
                                                path="posts/*"
                                                element={
                                                  <PostStarUpdateOutlet />
                                                }
                                              >
                                                <Route
                                                  path="updates/best-selling"
                                                  element={<BestSellingPosts />}
                                                />
                                                <Route
                                                  path="updates/top-priced"
                                                  element={<TopPricedPosts />}
                                                />
                                                <Route
                                                  path="updates/star-award"
                                                  element={<StarAwardPosts />}
                                                />
                                              </Route>
                                              <Route
                                                path="post-archive/*"
                                                element={
                                                  <StarUpdateArchieveOutlet />
                                                }
                                              >
                                                <Route
                                                  path="archive/best-selling"
                                                  element={
                                                    <BestSellingArchieves />
                                                  }
                                                />
                                                <Route
                                                  path="archive/top-priced"
                                                  element={
                                                    <TopPricedArchieves />
                                                  }
                                                />
                                                <Route
                                                  path="archive/star-award"
                                                  element={
                                                    <StarAwardArchieves />
                                                  }
                                                />
                                              </Route>
                                            </Route>
                                          </Route>
                                        </Route>
                                        <Route
                                          path="/stars_updates/:state/:urlUid_update"
                                          element={<SharedStarUpdateModal />}
                                        />
                                        <Route
                                          path="/:username/*"
                                          element={<YourShop />}
                                        >
                                          <Route
                                            path="shopPosts/:product_code?"
                                            element={<YourShopPosts />}
                                          />
                                          <Route
                                            path="soldOut/:product_code?"
                                            element={<YourShopSoldOut />}
                                          />
                                          <Route
                                            path="Orders"
                                            element={<YourShopOrders />}
                                          />
                                        </Route>
                                        {/* Music section routes B for screens below 992px */}
                                        <Route
                                          path="/music/*"
                                          element={<Music />}
                                        >
                                          <Route
                                            path="upload-form"
                                            element={<UploadMusic />}
                                          />
                                          <Route
                                            path="music-posts"
                                            element={<MusicPosts />}
                                          />
                                          <Route
                                            path="your-musics"
                                            element={<YourMusics />}
                                          />
                                        </Route>
                                        <Route
                                          path="/music/:username/:urlUid"
                                          element={<SharedMusicModal />}
                                        />
                                        {/* Other routes for other sections */}
                                        <Route path="/starsupdates">
                                          <Route
                                            path="bestsellings"
                                            element={<BestSelling />}
                                          />
                                          <Route
                                            path="toppriced"
                                            element={<TopPriced />}
                                          />
                                          <Route
                                            path="starawards"
                                            element={<StarAwards />}
                                          />
                                        </Route>
                                      </Routes>
                                    </UploadPostProvider>
                                  </StaticProvider>
                                </PlayersProvider>
                              </OwnModalsProvider>
                            </GetUserCurrentLocationProvider>
                          </LikesCommentsProvider>
                        </LiveVisitorsProvider>
                      </ChatProvider>
                    </ChatAndMusicPostsProvider>
                  </PinnedSelectedUserProvider>
                </FooterProvider>
              </YourShopHeaderPostProvider>
            </Sidebar3Provider>
          </UserLogoutProvider>
        </UserRoleProvider>
      </div>
    </Router>
  );
}

export default App;




import React, { useState, useEffect, useRef } from 'react';
import { usePlayers } from "./PlayersContext";
import './MainVideoControls.css';

function MainVideoControls({ videoRef }) {
   const {
     PlayIcon
   } = usePlayers();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [handlePosition, setHandlePosition] = useState(0);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const handleTimeUpdate = () => {
        const currentTime = video.currentTime;
        const duration = video.duration;
        const progressPercentage = (currentTime / duration) * 100;
        setProgress(progressPercentage);
        setHandlePosition(progressPercentage);
      };

      const handleVideoEnd = () => {
        setIsPlaying(false);
      };

      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('ended', handleVideoEnd);

      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, [videoRef]);

  const togglePlay = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleProgressBarClick = (e) => {
    e.stopPropagation(); 
    const video = videoRef.current;
    if (video) {
      const progressBarWidth = progressBarRef.current.offsetWidth;
      const clickX = e.pageX - progressBarRef.current.offsetLeft;
      const clickPercentage = (clickX / progressBarWidth) * 100;
      const newTime = (clickPercentage / 100) * video.duration;
      video.currentTime = newTime;
      setHandlePosition(clickPercentage);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const progressBarWidth = progressBarRef.current.offsetWidth;
    const clickX = e.pageX - progressBarRef.current.offsetLeft;
    const clickPercentage = (clickX / progressBarWidth) * 100;
    setHandlePosition(clickPercentage);
    const newTime = (clickPercentage / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="main-video-controls" onClick={togglePlay}>
      <div
        className="play-pause-button"
        onClick={(e) => {
          e.stopPropagation();
          togglePlay();
        }}
      >
        {!isPlaying && (
          <div className="play-icon-container">
            <span className="play-icon">
              <PlayIcon />
            </span>
          </div>
        )}
      </div>
      <div
        className="progress-bar"
        ref={progressBarRef}
        onClick={handleProgressBarClick}
      >
        <div
          className="progress-bar-fill"
          style={{ width: `${progress}%` }}
        ></div>
        {isPlaying && (
          <div
            className="progress-handle"
            style={{ left: `${handlePosition}%` }}
            onMouseDown={handleMouseDown}
          ></div>
        )}
      </div>
    </div>
  );
}

export default MainVideoControls;


import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlayers } from '../PlayersContext';
import './UploadMusic.css';
import axios from "axios";
import { BiArrowBack, BiX } from "react-icons/bi";
import { BsMusicNoteList, BsPlusCircle } from "react-icons/bs";
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import { useChatAndMusicPosts } from '../StarNavigationBar/ChatAndMusicPostsContext';


const UploadMusic = () => {
  const {isWideScreen} = useChatAndMusicPosts();
  const {csrftoken} = useContext(YourShopHeaderPostContext);
  const navigate = useNavigate();
  const { LoaderSVG } = usePlayers();
  const [audioFile, setAudioFile] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [artist, setArtist] = useState('');
  const [songTitle, setSongTitle] = useState('');
  const [featuring, setFeaturing] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [submitError, setSubmitError] = useState('');
  const [imageCoverError, setImageCoverError] = useState('');
  const [artistError, setArtistError] = useState('');
  const [featuringError, setFeaturingError] = useState('');
  const [songTitleError, setSongTitleError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [labelPositions, setLabelPositions] = useState({
    artist: {},
    cover_image: {},
    song_title: {},
    featuring: {},
  });

  const handleBackToUpload = () => {
    navigate(`${isWideScreen ? "/home" : ""}/upload`);
  };

  const handleOpenMusicsList = () => {
    navigate(`${isWideScreen ? "/home" : ""}/music/your-musics`);
  };

  const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    // Check if audio file size exceeds 100MB
    if (file.size > 100 * 1024 * 1024) {
      setSubmitError('The music file size should not exceed 100MB.');
      return;
    }

    // Check if audio file type is supported
    const supportedAudioFormats = ['.mp3', '.wav', '.aiff', '.ogg', '.flac', '.m4a'];
    const audioFileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
    if (!supportedAudioFormats.includes(`.${audioFileExtension}`)) {
      setSubmitError('The music file format is not supported.');
      return;
    }

    setAudioFile(file);
    setSubmitError(''); // Clear any previous error
  }
};

const handleCoverImageChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    // Check if image file size exceeds 100MB
    if (file.size > 100 * 1024 * 1024) {
      setImageCoverError('The image file size should not exceed 100MB.');
      return;
    }

    // Check if image file type is supported
    const supportedImageFormats = ['.jpg', '.jpeg', '.png'];
    const imageFileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
    if (!supportedImageFormats.includes(`.${imageFileExtension}`)) {
      setImageCoverError('The image file format is not supported. Only JPG and PNG formats are allowed.');
      return;
    }

    setCoverImage(file);
    setImageCoverError('');
     // Clear the input value to allow re-selecting the same file
    e.target.value = null;
  }
};

const handleFeaturingChange = (e) => {
  const value = e.target.value;
  setFeaturing(value);

  // Clear error if the input is empty
  if (value.trim() === '') {
    setFeaturingError('');
    return;
  }

  // Define the regex for invalid characters and patterns
  const invalidPatterns = /[\-_\(\)\{\}\[\]\\\/|+\-"'.=]/;
  const featuringNames = value.split(',');

  for (let name of featuringNames) {
    name = name.trim();
    if (
      name === '' || 
      invalidPatterns.test(name) || 
      name.toLowerCase().includes(' and ') || 
      name.includes('&') || 
      /\s{2,}/.test(name) // Check for multiple consecutive spaces
    ) {
      setFeaturingError('Please separate different names using commas only. Spaces within names are allowed. Avoid using special characters and patterns, and ensure no multiple consecutive spaces.');
      return;
    }
  }

  // If no errors, clear any previous error message
  setFeaturingError('');
};

  const handleCoverImageRemove = () => {
    setCoverImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    setUploadProgress(0);
    let isUploadComplete = false;

    // Validate form inputs
    if (!audioFile) {
      setSubmitError("Please upload a music file.");
      setIsSubmitLoading(false);
      return;
    }

    if (artist.trim() === "") {
      setArtistError("Please enter the artist name.");
      setIsSubmitLoading(false);
      return;
    }

    if (songTitle.trim() === "") {
      setSongTitleError("Please enter the song title.");
      setIsSubmitLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("audio_file", audioFile);
    formData.append("artist", artist);
    formData.append("song_title", songTitle);
    formData.append("featuring", featuring);
    if (coverImage) {
      formData.append("cover_image", coverImage);
    }

    try {
      const response = await axios.post("/api/music/", formData, {
        headers: {
          "X-CSRFToken": csrftoken,
        },
        onUploadProgress: (progressEvent) => {
          const percentComplete = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentComplete);

          // Mark upload as complete if progress is 100
          if (percentComplete === 100) {
            isUploadComplete = true;
          }
        },
      });

      // Ensure successful response and upload completion before navigating
      if (response.status === 201 && isUploadComplete) {
        setIsSubmitLoading(false);
        navigate(`${isWideScreen ? "/home" : ""}/music/your-musics`);
      } else {
        throw new Error("Upload not complete or response not OK.");
      }
    } catch (error) {
      console.error("Error uploading music:", error);
      setSubmitError("An error occurred while uploading.");
      setIsSubmitLoading(false);
    }
  };


  const handleFocus = (fieldName) => {
    setLabelPositions((prevPositions) => ({
      ...prevPositions,
      [fieldName]: { top: '8px', fontSize: '12px', color: '#f0c14b' }
    }));
  };

  const handleBlur = (fieldName, value) => {
    if (value === '') {
      setLabelPositions((prevPositions) => ({
        ...prevPositions,
        [fieldName]: { color: '#888' }
      }));
    } else {
      setLabelPositions((prevPositions) => ({
        ...prevPositions,
        [fieldName]: { top: '8px', fontSize: '12px', color: '#888' }
      }));
    }
  };

  return (
    <>
      <header className="header-music-post">
        <div className="musicBack-btn" onClick={handleBackToUpload}>
          <BiArrowBack
            size={20}
            color="currentColor"
            className="bi bi-arrow-left"
          />
        </div>
        <div className="upload-music">Upload Music</div>
        <div onClick={handleOpenMusicsList} className="your-music">
          <BsMusicNoteList size={20} />
        </div>
      </header>
      <div className="music-uploadForm">
        <div className="music-upload-main-content">
          <div className="text-upload-your-music">
            <p>
              Upload your music to Gooofan, let's fans listen together and earn.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="form-upload-music">
            {submitError && (
              <div className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {submitError}
              </div>
            )}
            <div className="custom-file-input">
              <input
                id="file-input"
                name="file-input"
                type="file"
                accept=".mp3,.wav,.aiff,.ogg,.flac,.m4a"
                onChange={handleFileChange}
              />
              {!audioFile ? (
                <div className="file-input-overlay">
                  <BsPlusCircle size={50} />
                  <p>Tap here and browse to your file</p>
                  <p>
                    Supported file types: MP3, WAV, AIFF, OGG, FLAC, and M4A.
                    File Size: Up to 100MB per file.
                  </p>
                </div>
              ) : (
                <div className="file-info">
                  <p>
                    <strong>File Name:</strong> {audioFile.name}
                  </p>
                  <p>
                    <strong>File Type:</strong> {audioFile.type}
                  </p>
                  <p>
                    <strong>File Size:</strong>{" "}
                    {(audioFile.size / 1024 / 1024).toFixed(2)} MB
                  </p>
                </div>
              )}
            </div>
            {artistError && (
              <div className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {artistError}
              </div>
            )}
            <div className="uploadmusic_label">
              <label htmlFor="artist" style={labelPositions.artist}>
                Artist
              </label>
              <input
                className="form-control"
                type="text"
                value={artist}
                onChange={(e) => setArtist(e.target.value)}
                name="artist"
                id="artist"
                onFocus={() => handleFocus("artist")}
                onBlur={(e) => handleBlur("artist", e.target.value)}
              />
            </div>
            {songTitleError && (
              <div className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {songTitleError}
              </div>
            )}
            <div className="uploadmusic_label">
              <label htmlFor="song_title" style={labelPositions.song_title}>
                Song Title
              </label>
              <input
                className="form-control"
                type="text"
                value={songTitle}
                onChange={(e) => setSongTitle(e.target.value)}
                name="song_title"
                id="song_title"
                onFocus={() => handleFocus("song_title")}
                onBlur={(e) => handleBlur("song_title", e.target.value)}
              />
            </div>
            {featuringError && (
              <div className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {featuringError}
              </div>
            )}
            <div className="uploadmusic_label">
              <label htmlFor="featuring" style={labelPositions.featuring}>
                Featuring
              </label>
              <input
                className="form-control"
                type="text"
                value={featuring}
                // onChange={(e) => setFeaturing(e.target.value)}
                onChange={handleFeaturingChange}
                name="featuring"
                id="featuring"
                onFocus={() => handleFocus("featuring")}
                onBlur={(e) => handleBlur("featuring", e.target.value)}
              />
            </div>
            <p className="help-text mb-3">
              Separate names using commas ('imma,nana or imma haule,nana john').
            </p>

            {imageCoverError && (
              <div className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {imageCoverError}
              </div>
            )}
            <div className="uploadmusic_label">
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={handleCoverImageChange}
                name="cover_image"
                id="cover_image"
                style={{ display: "none" }}
              />
              <div
                className="cover-image-container"
                onClick={() =>
                  document.querySelector('input[name="cover_image"]').click()
                }
              >
                {coverImage ? (
                  <div className="cover-image-preview">
                    <img
                      src={URL.createObjectURL(coverImage)}
                      alt="Cover"
                      className="cover-thumbnail"
                    />
                    <div
                      className="remove-cover-image"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCoverImageRemove();
                      }}
                    >
                      <BiX size={20} />
                    </div>
                  </div>
                ) : (
                  <div className="cover-image-placeholder">
                    <div className="uploadimgae-icon">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="#888"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                      >
                        <use href="#image-add-icon"></use>
                      </svg>
                    </div>
                    <div className="cover-image">Cover image</div>
                  </div>
                )}
              </div>
            </div>
            <div className="text-center publish">
              <button
                type="submit"
                disabled={isSubmitLoading}
                className={isSubmitLoading ? "loading" : ""}
              >
                {isSubmitLoading ? (
                  <div className="upload-progress">
                    <span>Posting: {uploadProgress}%</span>
                    <div className="progress-bar-posting">
                      <div
                        className="progress-posting"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  "Publish"
                )}
              </button>
            </div>
          </form>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol id="image-add-icon" viewBox="0 0 24 24">
            <path d="M18.75 4C20.5449 4 22 5.45507 22 7.25V18.75C22 20.5449 20.5449 22 18.75 22H7.25C5.45507 22 4 20.5449 4 18.75V12.5019C4.47425 12.6996 4.97687 12.8428 5.50009 12.9236L5.5 18.75C5.5 18.9584 5.53643 19.1583 5.60326 19.3437L11.4258 13.643C12.2589 12.8273 13.5675 12.7885 14.4458 13.5266L14.5742 13.6431L20.3964 19.3447C20.4634 19.159 20.5 18.9588 20.5 18.75V7.25C20.5 6.2835 19.7165 5.5 18.75 5.5L12.9236 5.50009C12.8428 4.97687 12.6996 4.47425 12.5019 4H18.75ZM12.5588 14.644L12.4752 14.7148L6.66845 20.4011C6.8504 20.4651 7.04613 20.5 7.25 20.5H18.75C18.9535 20.5 19.1489 20.4653 19.3305 20.4014L13.5247 14.7148C13.2596 14.4553 12.8501 14.4316 12.5588 14.644ZM16.2521 7.5C17.4959 7.5 18.5042 8.50831 18.5042 9.75212C18.5042 10.9959 17.4959 12.0042 16.2521 12.0042C15.0083 12.0042 14 10.9959 14 9.75212C14 8.50831 15.0083 7.5 16.2521 7.5ZM6.5 1C9.53757 1 12 3.46243 12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1ZM16.2521 9C15.8367 9 15.5 9.33673 15.5 9.75212C15.5 10.1675 15.8367 10.5042 16.2521 10.5042C16.6675 10.5042 17.0042 10.1675 17.0042 9.75212C17.0042 9.33673 16.6675 9 16.2521 9ZM6.5 2.99923L6.41012 3.00729C6.20603 3.04433 6.0451 3.20527 6.00806 3.40936L6 3.49923L5.99965 5.99923L3.49765 6L3.40777 6.00806C3.20368 6.0451 3.04275 6.20603 3.00571 6.41012L2.99765 6.5L3.00571 6.58988C3.04275 6.79397 3.20368 6.9549 3.40777 6.99194L3.49765 7L6.00065 6.99923L6.00111 9.50348L6.00916 9.59336C6.04621 9.79745 6.20714 9.95839 6.41123 9.99543L6.50111 10.0035L6.59098 9.99543C6.79508 9.95839 6.95601 9.79745 6.99305 9.59336L7.00111 9.50348L7.00065 6.99923L9.50457 7L9.59444 6.99194C9.79853 6.9549 9.95947 6.79397 9.99651 6.58988L10.0046 6.5L9.99651 6.41012C9.95947 6.20603 9.79853 6.0451 9.59444 6.00806L9.50457 6L6.99965 5.99923L7 3.49923L6.99194 3.40936C6.9549 3.20527 6.79397 3.04433 6.58988 3.00729L6.5 2.99923Z" />
          </symbol>
        </svg>
      </div>
    </>
  );
};

export default UploadMusic;



 

 
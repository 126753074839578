import React, { useState, createContext } from 'react';

export const PinnedSelectedUserContext = createContext();

export const PinnedSelectedUserProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <PinnedSelectedUserContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children}
    </PinnedSelectedUserContext.Provider>
  );
};

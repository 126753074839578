import React, { useState, useEffect, useRef, useCallback, useContext} from "react";
import { BiArrowToTop, BiX } from "react-icons/bi";
import { YourShopHeaderPostContext } from "./YourShopHeaderPostContext";
import { FooterContext } from "./FooterContext";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./YouOwn.css"

const YouOwn = () => {
const navigate = useNavigate();
   const {
     buyerOrders,
     setBuyerOrders,
     buyerOrderPage,
     setBuyerOrderPage,
     scrollLoadingBuyerOrders,
     setScrollLoadingBuyerOrders,
     hasMoreBuyerOrders,
     setHasMoreBuyerOrders,
   } = useContext(FooterContext);
  const {
      csrftoken,
      currentUserData,
    } = useContext(YourShopHeaderPostContext);
  const [isLoadingBuyerOrders, setIsLoadingBuyerOrders] = useState(false);
  const [firstLoadBuyerOrders, setFirstLoadBuyerOrders] = useState(true);
  const [currentModalOrderId, setCurrentModalOrderId] = useState(null);

  const [currentOwnership, setCurrentOwnership] = useState(null);
  const [unauthorizedError, setUnauthorizedError] = useState(null);
  const statuses = ["Paid", "Packaged", "On Way", "Delivered", "Received"];

  const observerBuyerOrders = useRef();

  // Function to fetch buyer-specific orders
  const fetchBuyerOrders = async () => {
    if (firstLoadBuyerOrders) {
      setIsLoadingBuyerOrders(true);
    } else {
      setScrollLoadingBuyerOrders(true);
    }
    try {
      const response = await fetch(
        `/api/orders/buyer_orders/?page=${buyerOrderPage}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch buyer orders");
      }
      const data = await response.json();

      const fetchedOrders = data?.results?.orders || [];

      setBuyerOrders((prevOrders) => [...prevOrders, ...fetchedOrders]);
      setHasMoreBuyerOrders(data.next !== null);
    } catch (error) {
      console.error("Error fetching buyer orders:", error);
      setHasMoreBuyerOrders(false);
    } finally {
      if (firstLoadBuyerOrders) {
        setIsLoadingBuyerOrders(false);
        setFirstLoadBuyerOrders(false);
      }
      setScrollLoadingBuyerOrders(false);
    }
  };

  useEffect(() => {
    fetchBuyerOrders();
  }, [buyerOrderPage]);

  const lastBuyerOrderElementRef = useCallback(
    (node) => {
      if (scrollLoadingBuyerOrders) return;
      if (observerBuyerOrders.current) observerBuyerOrders.current.disconnect();
      observerBuyerOrders.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreBuyerOrders) {
          setBuyerOrderPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerBuyerOrders.current.observe(node);
    },
    [scrollLoadingBuyerOrders, hasMoreBuyerOrders]
  );
  const toggleModal = (order) => {
    setCurrentOwnership(order);
    setCurrentModalOrderId(order.id);
  };

  const closeModal = () => {
    setCurrentModalOrderId(null);
    setCurrentOwnership(null);
  };


    
  const getStatusIndex = (order) => {
    // Use the existing statusIndex if it's already set
    if (order.statusIndex !== undefined) {
      return order.statusIndex;
    }

    // Otherwise, calculate it based on the original fields
    if (order.is_received) {
      return 4;
    } else if (order.is_delivered) {
      return 3;
    } else if (order.is_on_way) {
      return 2;
    } else if (order.is_packaged) {
      return 1;
    } else {
      return 0;
    }
  };

  const updateOrderStatus = async (orderId, statusIndex) => {
    const updatedStatus = {
      is_packaged: statusIndex >= 1,
      is_on_way: statusIndex >= 2,
      is_delivered: statusIndex >= 3,
      is_received: statusIndex >= 4,
    };

    try {
      const response = await fetch(`/api/orders/${orderId}/update_status/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ status: updatedStatus }),
      });

      if (!response.ok) {
        throw new Error("Failed to update order status");
      }

      return await response.json();
    } catch (error) {
      console.error("Error updating order status:", error);
      throw error;
    }
  };

  const handleStatusChange = async (newStatusIndex, order) => {
    const currentStatusIndex = getStatusIndex(order);

    // Avoid redundant updates
    if (newStatusIndex === currentStatusIndex) return;

    // Check if the user is authorized to make this status change
    const userRole = getUserRole(order);
    const isAuthorized = checkUserAuthorization(
      userRole,
      currentStatusIndex,
      newStatusIndex
    );

    if (!isAuthorized) {
      setUnauthorizedError(
        `You are not authorized to perform this action, ${currentUserData.username}.`
      );
      return;
    }

    setUnauthorizedError(null);

    // // Optimistic Update: Modify the local state
    const updatedOrder = { ...order, statusIndex: newStatusIndex };
    setBuyerOrders((prevOrders) =>
      prevOrders.map((o) => (o.id === order.id ? updatedOrder : o))
    );

    // // Update the backend
    try {
      await updateOrderStatus(order.id, newStatusIndex);

      // Fetch and sync updated order data from the server
      const serverUpdatedOrder = await fetchOrderDetails(order.id);
      if (serverUpdatedOrder) {
        setBuyerOrders((prevOrders) =>
          prevOrders.map((o) =>
            o.id === serverUpdatedOrder.id ? serverUpdatedOrder : o
          )
        );
      }
    } catch (error) {
      console.error("Failed to update status:", error);

      // Rollback the state on failure
      const rolledBackOrder = await fetchOrderDetails(order.id);
      if (rolledBackOrder) {
        setBuyerOrders((prevOrders) =>
          prevOrders.map((o) =>
            o.id === rolledBackOrder.id ? rolledBackOrder : o
          )
        );
      }
    }
  };


  const getUserRole = (order) => {
    // Ensure order.buyer and order.seller are defined
    if (!order.buyer || !order.seller) {
      console.error("Order buyer or seller is undefined");
      return null;
    }

    // Determine role dynamically based on order data
    if (currentUserData?.username === order.seller?.username) {
      return "seller";
    } else if (currentUserData?.username === order.buyer?.username) {
      return "buyer";
    } else if (currentUserData?.is_delivery) {
      return "delivery";
    }
    return null;
  };

  const checkUserAuthorization = (role, currentStatusIndex, newStatusIndex) => {
    if (role === "seller") {
      // Seller can move from "Paid" to "Packaged"
      return currentStatusIndex === 0 && newStatusIndex === 1;
    } else if (role === "delivery") {
      // Delivery personnel can move from "Packaged" to "On Way", and "On Way" to "Delivered"
      return (
        (currentStatusIndex === 0 && newStatusIndex === 1) ||
        (currentStatusIndex === 1 && newStatusIndex === 2) ||
        (currentStatusIndex === 2 && newStatusIndex === 3)
      );
    } else if (role === "buyer") {
      // Buyer can only confirm "Delivered" as "Received"
      return currentStatusIndex === 3 && newStatusIndex === 4;
    }
    return false;
  };

   const handleStatusLineClick = (e, index, order) => {
     handleStatusChange(index, order);
   };

   const handleDragEnd = (e, order) => {
     const lineRect = e.target.parentElement.getBoundingClientRect();
     const dragX = e.clientX - lineRect.left;
     const newIndex = Math.round(
       (dragX / lineRect.width) * (statuses.length - 1)
     );
     const currentStatusIndex = getStatusIndex(order);

     // Only proceed if the user moves to the next or previous status
     if (
       newIndex === currentStatusIndex + 1 ||
       newIndex === currentStatusIndex - 1
     ) {
       handleStatusChange(newIndex, order);
     }
   };

const fetchOrderDetails = async (orderId) => {
  try {
    const response = await fetch(`/api/orders/get_order/?id=${orderId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch order details");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching order details:", error);
    return null;
  }
};

const handleBackToHome = () => {
  navigate("/home");
};

  return (
    <div className="container-you-own">
      <div className="header-you-own">
        <div className="youown-Back-btn" onClick={handleBackToHome}>
          <BiArrowBack
            size={20}
            color="currentColor"
            className="bi bi-arrow-left"
          />
        </div>
        <h1 className="you-own-text">You Own</h1>
        <div className="empty-div-change-it"></div>
      </div>
      <div className="container-order-card">
        {isLoadingBuyerOrders ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : buyerOrders.length === 0 ? (
          <p className="no-you-own">
            The items you own will be shown here. Start shopping to add items!
          </p>
        ) : (
          buyerOrders.map((order, index) => (
            <div
              key={order.id}
              className="order-card"
              ref={
                index === buyerOrders.length - 1
                  ? lastBuyerOrderElementRef
                  : null
              }
            >
              {/* Buyer Profile */}
              <div className="buyer-profile">
                <div className="profile">
                  {order.buyer.profile_picture ? (
                    <img
                      src={order.product.images[0].image}
                      alt={`${order.buyer.username}'s profile`}
                      className="profile-picture"
                      loading="lazy"
                    />
                  ) : (
                    <span className="profile-picture no-profile-picture">
                      {order.buyer.first_name.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>
                <div>
                  <h3>{`${order.buyer.first_name} ${order.buyer.last_name}`}</h3>
                  <p>Ownership History</p>
                </div>
                <button onClick={() => toggleModal(order)}>
                  <BiArrowToTop className="icons-you-own" />
                </button>
              </div>

              {/* Product Details */}
              <div className="you-own-product-details">
                <img
                  src={order.product.images[0]?.image}
                  alt={order.product.product_name}
                  className="you-own-product-image"
                  loading="lazy"
                />
                <div className="you-own-product-info">
                  <p>
                    Total Paid: <strong>{order.total_cost}</strong>
                  </p>
                  <p>
                    <strong>{order.product.product_code}</strong>
                  </p>
                </div>
                <hr />
                {/* Conditional Rendering */}
                {order.is_received ? (
                  <p className="you-own-product-story">
                    {order.product.product_story}
                  </p>
                ) : (
                  <div
                    className="status-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="status-labels">
                      {statuses.map((status, index) => (
                        <div key={index} className="status-item">
                          <span
                            className={`status-label ${
                              getStatusIndex(order) >= index
                                ? index === statuses.indexOf("Received")
                                  ? "completed received"
                                  : "completed"
                                : ""
                            }`}
                          >
                            {status}
                          </span>
                        </div>
                      ))}
                    </div>
                    {unauthorizedError && (
                      <div className="error-message">
                        <i className="bi bi-exclamation-circle-fill error-icon"></i>
                        {unauthorizedError}
                      </div>
                    )}
                    <div className="status-tracker">
                      <div className="status-line-container">
                        <div className="status-line" />
                        <div
                          className="status-line-fill"
                          style={{
                            width: `${
                              (getStatusIndex(order) / (statuses.length - 1)) *
                              100
                            }%`,
                          }}
                        />

                        {statuses.map((status, index) => (
                          <div
                            key={index}
                            className="small-cycle"
                            style={{
                              left: `${(index / (statuses.length - 1)) * 100}%`,
                            }}
                            onClick={(e) =>
                              handleStatusLineClick(e, index, order)
                            }
                          />
                        ))}

                        <div
                          className={`status-tracker-circle ${
                            statuses[getStatusIndex(order)] === "Received"
                              ? "received"
                              : ""
                          }`}
                          style={{
                            left: `${
                              (getStatusIndex(order) / (statuses.length - 1)) *
                              100
                            }%`,
                          }}
                          draggable
                          onDragEnd={(e) => handleDragEnd(e, order)}
                        />
                      </div>
                    </div>

                    {/* Order Status Description Section */}
                    <div className="order-status-description">
                      {getStatusIndex(order) === statuses.indexOf("Paid") && (
                        <p className="status-description">
                          The order has been placed and successfully paid.
                        </p>
                      )}
                      {getStatusIndex(order) ===
                        statuses.indexOf("Packaged") && (
                        <p className="status-description">
                          The order is prepared and ready for delivery.
                        </p>
                      )}
                      {getStatusIndex(order) === statuses.indexOf("On Way") && (
                        <p className="status-description">
                          The package is currently in transit and on its way to
                          your address.
                        </p>
                      )}
                      {getStatusIndex(order) ===
                        statuses.indexOf("Delivered") && (
                        <p className="status-description">
                          The package has been successfully delivered to your
                          address.
                        </p>
                      )}
                      {getStatusIndex(order) ===
                        statuses.indexOf("Received") && (
                        <p className="status-description">
                          Thank you for confirming receipt of your order! The
                          payment has now been released to the seller.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
        <div className="scroll-loader-spinner-container">
          {scrollLoadingBuyerOrders && (
            <div className="scroll-loader-spinner"></div>
          )}
        </div>
      </div>
      {/* Ownership History Modal */}
      {currentModalOrderId && currentOwnership && (
        <div className="model-ownership-overlay" onClick={closeModal}>
          <div
            className="ownership-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="ownership-modal-header">
              <h1>Ownership History</h1>
              <button className="ownership-close-button" onClick={closeModal}>
                <BiX size={30} />
              </button>
            </div>
            <div className="ownership-history-container">
              {/* Current Owner Section */}
              <div className="current-owner-card">
                <div className="owner-profile">
                  <span className="profile-initial no-profile-picture">
                    {currentOwnership.product.current_owner.username
                      .charAt(0)
                      .toUpperCase()}
                  </span>
                  <div className="current-owner-username">
                    <span>
                      {currentOwnership.product.current_owner.username}
                    </span>
                  </div>
                </div>
                <span className="current-owner-tag">Current Owner</span>
              </div>
              {/* Previous Owners Section */}
              <div className="ownership-chain">
                <div className="text-previous-owner">
                  <h1>Previous Owner's</h1>
                </div>
                {currentOwnership.resale_chain
                  .slice()
                  .reverse()
                  .map((owner, index, array) => {
                    const nextOwner = array[index + 1];
                    const ownershipPeriod = nextOwner
                      ? `${new Date(
                          owner.timestamp
                        ).toLocaleDateString()} - ${new Date(
                          nextOwner.timestamp
                        ).toLocaleDateString()}`
                      : `${new Date(owner.timestamp).toLocaleDateString()}`;

                    return (
                      <div className="ownership-chain-item" key={index}>
                        {/* Connecting Line */}
                        {index !== 0 && (
                          <div className="line">
                            <div className="arrow"></div>
                          </div>
                        )}

                        {/* Owner Profile */}
                        <div className="owner-profile">
                          <span className="profile-initial no-profile-picture">
                            {owner.seller.charAt(0).toUpperCase()}
                          </span>
                          <div className="owner-period">
                            <span>{owner.seller}</span>
                            <span className="period-ownership">
                              {ownershipPeriod}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* Original Seller (Star) */}
              {currentOwnership.resale_chain.some(
                (chain) => chain.is_original_sale
              ) && (
                <div className="original-seller">
                  <div className="original-seller-star">
                    <span className="profile-initial no-profile-picture">
                      {currentOwnership.resale_chain
                        .find((chain) => chain.is_original_sale)
                        .seller.charAt(0)
                        .toUpperCase()}
                    </span>
                    <span className="original-seller-name">
                      {
                        currentOwnership.resale_chain.find(
                          (chain) => chain.is_original_sale
                        ).seller
                      }
                    </span>
                  </div>
                  <span className="star-tag">Star</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YouOwn;

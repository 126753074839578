import React, { useState, useContext } from 'react';
import { EditorState, RichUtils, AtomicBlockUtils, convertToRaw, Modifier } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createVideoPlugin from '@draft-js-plugins/video';
import { stateToHTML } from 'draft-js-export-html';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/video/lib/plugin.css';
import './StarsUpdateCustomEditor.css';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import {
  BiLink,
  BiAlignLeft,
  BiAlignRight,
  BiListUl,
  BiListOl,
  BiBold,
  BiItalic,
  BiUnderline,
} from "react-icons/bi";
import {
  BsTypeH1 as BiHeading,
  BsTypeH2,
  BsTypeH3,
  BsTextCenter,
  BsBlockquoteRight,
} from "react-icons/bs";

const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();

const linkIcon = <BiLink size={18} />;
const textLeftIcon = <BiAlignLeft size={18} />;
const textRightIcon = <BiAlignRight size={18} />;
const textCenterIcon = <BsTextCenter size={18} />;
const ulIcon = <BiListUl size={18} />;
const olIcon = <BiListOl size={18} />;
const qouteIcon = <BsBlockquoteRight size={18} />;
const underlineIcon = <BiUnderline size={18} />;
const italicIcon = <BiItalic size={18} />;
const boldIcon = <BiBold size={18} />;
const h1Icon = <BiHeading size={18} />;
const h2Icon = <BsTypeH2 size={18} />;
const h3Icon = <BsTypeH3 size={18} />;

const StarsUpdateCustomEditor = ({ onChange }) => {
  const {
  csrftoken
  } = useContext(YourShopHeaderPostContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [activeButtons, setActiveButtons] = useState(new Set());
  const [showLinkInput, setShowLinkInput] = useState(false); 
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState('');
  const [mediaError, setMediaError] = useState('');

  const handleEditorChange = (state) => {
  setEditorState(state);
  const contentState = state.getCurrentContent();
  const html = stateToHTML(contentState, {
    entityStyleFn: (entity) => {
      const entityType = entity.getType().toLowerCase();
      if (entityType === 'image') {
        const { src } = entity.getData();
        return {
          element: 'img',
          attributes: {
            src,
            style: 'max-width: 100%;'
          },
        };
      }
      if (entityType === 'video') {
        const { src } = entity.getData();
        return {
          element: 'video',
          attributes: {
            src,
            controls: true,
            style: 'width: 100%;'
          },
        };
      }
      if (entityType === 'link') {
        const { url } = entity.getData();
        return {
          element: 'a',
          attributes: {
            href: url,
          },
        };
      }
      return {};
    },
  });
  const rawContent = convertToRaw(contentState);
  onChange(html, rawContent);
};


  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch('/star_update_file_upload/', {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRFToken': csrftoken
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data.file_url;
    } else {
      throw new Error('File upload failed');
    }
  };

  
  const checkVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        if (duration > 300) {
          reject('Video duration exceeds 5 minutes');
        } else {
          resolve();
        }
      };

      video.onerror = () => {
        reject('Failed to load video metadata');
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const insertMedia = async (file, type) => {
    try {
      if (type === 'VIDEO') {
        await checkVideoDuration(file);
      }

      const fileUrl = await handleFileUpload(file);
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', { src: fileUrl });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        EditorState.set(editorState, { currentContent: contentStateWithEntity }),
        entityKey,
        ' '
      );
      handleEditorChange(newEditorState);
      setMediaError('');
    } catch (error) {
      console.error('Error uploading file:', error);
      setMediaError(error);
    }
  };

  const handleMediaUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type.startsWith('image/') ? 'IMAGE' : file.type.startsWith('video/') ? 'VIDEO' : null;
      if (fileType) {
        insertMedia(file, fileType);
      } else {
        console.error('Unsupported file type');
      }
      // Clear the input value to allow re-selecting the same file
      event.target.value = null;

    }
    setShowLinkInput(false);
    
  };

  const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic') {
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      const type = entity.getType();
      if (type === 'IMAGE') {
        return {
          component: ImageComponent,
          editable: false,
        };
      } else if (type === 'VIDEO') {
        return {
          component: VideoComponent,
          editable: false,
        };
      }
    }
    return null;
  };

  const ImageComponent = (props) => {
    const { src } = props.contentState.getEntity(props.block.getEntityAt(0)).getData();
    return <img src={src} alt="uploaded" style={{ maxWidth: '100%' }} />;
  };

  const VideoComponent = (props) => {
    const { src } = props.contentState.getEntity(props.block.getEntityAt(0)).getData();
    return <video controls src={src} style={{ width: '100%' }} />;
  };

  const toggleInlineStyle = (inlineStyle) => {
    handleEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    toggleButtonState(inlineStyle);
    setShowLinkInput(false);
  };

  const toggleBlockType = (blockType) => {
    let newEditorState;
    if (['header-one', 'header-two', 'header-three'].includes(blockType)) {
      newEditorState = RichUtils.toggleBlockType(editorState, blockType);
      ['header-one', 'header-two', 'header-three'].forEach((header) => {
        if (header !== blockType) {
          toggleButtonState(header, false);
        }
      });
    } else if (['unordered-list-item', 'ordered-list-item'].includes(blockType)) {
      newEditorState = RichUtils.toggleBlockType(editorState, blockType);
      ['unordered-list-item', 'ordered-list-item'].forEach((listType) => {
        if (listType !== blockType) {
          toggleButtonState(listType, false);
        }
      });
    } else {
      newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    }
    
    handleEditorChange(newEditorState);
    toggleButtonState(blockType);
    setShowLinkInput(false);
  };

  const toggleAlignment = (alignment) => {
    const newContentState = Modifier.setBlockData(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      { 'text-align': alignment }
    );
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');
    handleEditorChange(newEditorState);

    // Clear other alignment buttons
    ['left', 'center', 'right'].forEach((align) => {
      if (align !== alignment) {
        toggleButtonState(align, false);
      }
    });

    toggleButtonState(alignment);
    setShowLinkInput(false);
  };
 
  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setLink(newLink);

    const linkRegex = /^https:\/\/[^ "]+$/;
    if (!newLink.match(linkRegex)) {
      setLinkError('Please enter a secure (HTTPS) URL.');
    } else {
      setLinkError('');
    }
  };

  const addLink = () => {
    const linkRegex = /^https:\/\/[^ "]+$/;
    if (!link.match(linkRegex)) {
      setLinkError('Please enter a secure (HTTPS) URL.');
      return;
    } else {
      setLinkError('');
    }

    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: link });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = RichUtils.toggleLink(
      editorState,
      selection,
      entityKey
    );
    handleEditorChange(newEditorState);
    setShowLinkInput(false);
    setLink('');
  };

  const toggleButtonState = (button, state = null) => {
    setActiveButtons(prevState => {
      const newState = new Set(prevState);
      if (state === null) {
        if (newState.has(button)) {
          newState.delete(button);
        } else {
          newState.add(button);
        }
      } else {
        if (state) {
          newState.add(button);
        } else {
          newState.delete(button);
        }
      }
      return newState;
    });
  };

  const isInlineActive = (style) => {
    return activeButtons.has(style);
  };

  const blockStyleFn = (block) => {
  const data = block.getData();
  const textAlign = data.get('text-align');
  if (textAlign) {
    return `alignment-${textAlign}`;
  }
  return '';
};


  return (
    <div className="StarsUpdateCustomEditor">
      <div className="editor-toolbar">
        <div className='editor-toolbar-1'>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('BOLD'); }}
            className={isInlineActive('BOLD') ? 'active' : ''}
          >{boldIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('ITALIC'); }}
            className={isInlineActive('ITALIC') ? 'active' : ''}
          >{italicIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleBlockType('header-one'); }}
            className={isInlineActive('header-one') ? 'active' : ''}>
            {h1Icon}
          </button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleBlockType('header-two'); }}
            className={isInlineActive('header-two') ? 'active' : ''}>
            {h2Icon}
          </button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleBlockType('header-three'); }}
            className={isInlineActive('header-three') ? 'active' : ''}>
            {h3Icon}
          </button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('UNDERLINE'); }}
            className={isInlineActive('UNDERLINE') ? 'active' : ''}
          >{underlineIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleBlockType('blockquote'); }}
            className={isInlineActive('blockquote') ? 'active' : ''}
          >{qouteIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleBlockType('unordered-list-item'); }}
            className={isInlineActive('unordered-list-item') ? 'active' : ''}
          >{ulIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleBlockType('ordered-list-item'); }}
            className={isInlineActive('ordered-list-item') ? 'active' : ''}
          >{olIcon}</button>
        </div>
        <div className='editor-toolbar-2'>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleAlignment('left'); }}
            className={isInlineActive('left') ? 'active' : ''}
          >{textLeftIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleAlignment('center'); }}
            className={isInlineActive('center') ? 'active' : ''}>
            {textCenterIcon}</button>
          <button
            type="button"
            onMouseDown={(e) => { e.preventDefault(); toggleAlignment('right'); }}
            className={isInlineActive('right') ? 'active' : ''}
          >{textRightIcon}
          </button>
          <button
            type="button"
            onMouseDown={(e) => { 
              e.preventDefault(); 
              setShowLinkInput(prevState => !prevState); 
            }}>
            {linkIcon}
          </button>
        </div>
        <div className='label-insert-media'>
          <label htmlFor='file-label' className="file-label">
            Insert Media
            <input type="file" id='file-label' name='file-label' accept="image/*, video/*" onChange={handleMediaUpload} className="file-input" />
          </label>
        </div>
      </div>
       {showLinkInput && (
          <div className='button-link'>
            {linkError && <p className="error-message"><i className="bi bi-exclamation-circle-fill error-icon"></i>{linkError}</p>}
            <input
              type="text"
              name='linkbody'
              id='linkbody'
              value={link}
              onChange={handleLinkChange}
              placeholder="Enter a URL"
            />
            <button type="button" onClick={addLink}>Add Link</button>
          </div>
        )}

      {mediaError && <p className="error-message"><i className="bi bi-exclamation-circle-fill error-icon"></i>{mediaError}</p>}
      <div className="editor">
        <Editor
          editorState={editorState}
          onChange={handleEditorChange}
          handleKeyCommand={handleKeyCommand}
          blockRendererFn={mediaBlockRenderer}
          plugins={[imagePlugin, videoPlugin]}
          blockStyleFn={blockStyleFn}
        />
      </div>
    </div>
  );
};

export default StarsUpdateCustomEditor;

import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { useViewportHeight, useHandleResize } from "../hooks/useViewHeightPort";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import ChatHeader from "./ChatHeader";
import ClearChatModal from "./ClearChatModal";
import CurrentMusic from "./CurrentMusic";
import PostRequestMusic from "./PostRequestMusic";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import MessageImageCropper from "./MessageImageCropper";
import MessageListImagePreview from "./MessageListImagePreview";
import { useChat } from "./ChatContext";
import "../StarNavigationBar/chat.css";


const ChatRoom = () => {
  const {
    setAcceptedRequests,
    handleRejectRequest,
    previewImage,
  } = useChat();

  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const {
    currentConversationId,
    receivedRequests,
    setReceivedRequests,
    showRequestAcceptedMessage,
    setShowRequestAcceptedMessage,
    showRequestSentMessage,
    showRequestRejectedMessage,
    setShowRequestRejectedMessage,
    showRequestCanceledMessage,
    setShowRequestCanceledMessage,
    showRequestClosedBySenderMessage,
    setShowRequestClosedBySenderMessage,
    showSessionLeftByRecipientMessage,
    setShowSessionLeftByRecipientMessage,
    showActionMessageError,
    currentMusicPost,
    showClearChatModal,
    messageImagePreview,
    setNewRequestCount,
    socketRequestRef,
  } = useChatAndMusicPosts();

  const [bottom, setBottom] = useState(true);

  useEffect(() => {
    if (currentConversationId) {
      fetchReceivedRequests();
    }
  }, [currentConversationId, receivedRequests]);

  const fetchReceivedRequests = async () => {
    try {
      const response = await fetch(
        `/api/music/received_requests/?conversationId=${currentConversationId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch received requests.");
      const data = await response.json();
      setReceivedRequests(data);
    } catch (error) {
      console.error("Error fetching received requests:", error);
    }
  };

  // Function to handle accepting a request
  const handleAcceptRequest = async (request) => {
    try {
      const response = await fetch(`/api/music/accept_request/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ request_id: request.id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        setAcceptedRequests((prevAcceptedRequests) => [
          ...prevAcceptedRequests,
          request,
        ]);

        setReceivedRequests((prevRequests) =>
          prevRequests.filter((req) => req.id !== request.id)
        );

        socketRequestRef.current.send(
          JSON.stringify({
            type: "accept_request",
            request: {
              id: request.id,
              sender: request.sender,
              recipient: request.recipient,
              conversationId: request.conversation.conversationId,
            },
          })
        );

        // Decrement request count on acceptance
        setNewRequestCount((prevCount) => {
          const newCount = { ...prevCount };
          if (newCount[request.conversation.conversationId]) {
            newCount[request.conversation.conversationId]--;
            if (newCount[request.conversation.conversationId] <= 0) {
              delete newCount[request.conversation.conversationId];
            }
          }
          return newCount;
        });
      } else {
        console.error("Error accepting request:", data.error);
      }
    } catch (error) {
      console.error("Error accepting request:", error);
    }
  };

  // const handleAcceptRequest = async (request) => {
  //   try {
  //     const response = await fetch(`/api/music/accept_request/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-CSRFToken": csrftoken,
  //       },
  //       body: JSON.stringify({ request_id: request.id }),
  //     });
  //     if (!response.ok) throw new Error("Failed to accept request.");

  //     const data = await response.json();
  //     if (data.success) {
  //       setAcceptedRequests((prev) => [...prev, request]);
  //       setReceivedRequests((prev) =>
  //         prev.filter((req) => req.id !== request.id)
  //       );

  //       sendSocketMessage("accept_request", {
  //         request: {
  //           id: request.id,
  //           sender: request.sender,
  //           recipient: request.recipient,
  //           conversationId: request.conversation.conversationId,
  //         },
  //       });

  //       updateRequestCount(request.conversation.conversationId);
  //     } else {
  //       console.error("Error accepting request:", data.error);
  //     }
  //   } catch (error) {
  //     console.error("Error accepting request:", error);
  //   }
  // };

  // Helper: for messages listening together requests
  const handleRequestMessage =
    (messageType, setMessageState) => (requestIdToRemove) => {
      setMessageState((prevMessages) => {
        const updatedMessages = prevMessages.filter(
          (msg) => msg.id !== requestIdToRemove
        );
        localStorage.setItem(messageType, JSON.stringify(updatedMessages));
        return updatedMessages;
      });
    };

  // Helper: to get request message from local storage
  const useStoredMessages = (messageType, setMessageState) => {
    useEffect(() => {
      const storedMessages = localStorage.getItem(messageType);
      if (storedMessages) {
        setMessageState(JSON.parse(storedMessages));
      }
    }, [messageType, setMessageState]);
  };

  const handleRequestAcceptedMessage = handleRequestMessage(
    "showRequestAcceptedMessage",
    setShowRequestAcceptedMessage
  );

  const handleRequestRejectedMessage = handleRequestMessage(
    "showRequestRejectedMessage",
    setShowRequestRejectedMessage
  );

  const handleRequestCanceledMessage = handleRequestMessage(
    "showRequestCanceledMessage",
    setShowRequestCanceledMessage
  );

  const handleRequestClosedBySenderMessage = handleRequestMessage(
    "showRequestClosedBySenderMessage",
    setShowRequestClosedBySenderMessage
  );

  const handleSessionLeftByRecipientMessage = handleRequestMessage(
    "showSessionLeftByRecipientMessage",
    setShowSessionLeftByRecipientMessage
  );

  useStoredMessages(
    "showRequestAcceptedMessage",
    setShowRequestAcceptedMessage
  );
  useStoredMessages(
    "showRequestRejectedMessage",
    setShowRequestRejectedMessage
  );
  useStoredMessages(
    "showRequestCanceledMessage",
    setShowRequestCanceledMessage
  );
  useStoredMessages(
    "showRequestClosedBySenderMessage",
    setShowRequestClosedBySenderMessage
  );
  useStoredMessages(
    "showSessionLeftByRecipientMessage",
    setShowSessionLeftByRecipientMessage
  );

  // useViewportHeight();
  useHandleResize(setBottom);

 
  return (
    <div className="chat-container">
      <ChatHeader />
      <div className="chatroom-wraps">
        {showClearChatModal && <ClearChatModal />}
        {currentMusicPost && <CurrentMusic />}
        {showRequestSentMessage && (
          <div className="request-sent-message">Request sent</div>
        )}
        {showActionMessageError && (
          <div className="error-message-pin">
            You cannot pin your own conversation.
          </div>
        )}
        <PostRequestMusic />
        {receivedRequests
          .filter(
            (request) =>
              request.request_sent &&
              request.conversation.conversationId === currentConversationId
          )
          .map((request) => (
            <div key={request.id} className="request-container">
              <p>
                {request.sender.username} wants to listen to{" "}
                {request.music.song_title} by {request.music.artist} with you
              </p>
              <button
                className="c-accpet-reject-btns"
                onClick={() => handleAcceptRequest(request)}
              >
                Accept
              </button>
              <button
                className="c-accept-reject-btns"
                onClick={() =>
                  handleRejectRequest(request.id, request.music.id)
                }
              >
                Reject
              </button>
            </div>
          ))}
        {showRequestAcceptedMessage
          .filter(
            (request) =>
              request.request_accepted &&
              request.conversation.conversationId === currentConversationId
          )
          .map((request) => (
            <div key={request.id} className="request-accepted-message">
              <p>
                {request.recipient_username.username} accepted your request!
              </p>
              <button
                className="request-btns"
                onClick={() => handleRequestAcceptedMessage(request.id)}
              >
                Ok
              </button>
            </div>
          ))}

        {showRequestRejectedMessage
          .filter(
            (request) =>
              request.conversation.conversationId === currentConversationId
          )
          .map((request) => (
            <div key={request.id} className="request-rejected-message">
              <p>Sorry, {request.recipient_username} rejected your request!</p>
              <button
                className="request-btns"
                onClick={() => handleRequestRejectedMessage(request.id)}
              >
                Ok
              </button>
            </div>
          ))}

        {showRequestCanceledMessage
          .filter(
            (request) =>
              request.conversation.conversationId === currentConversationId
          )
          .map((request) => (
            <div key={request.id} className="request-canceled-message">
              <p>Sorry, {request.sender_username} canceled the request!</p>
              <button
                className="request-btns"
                onClick={() => handleRequestCanceledMessage(request.id)}
              >
                Ok
              </button>
            </div>
          ))}
        {showRequestClosedBySenderMessage.map((request) => (
          <div key={request.id} className="request-closed-by-sender-message">
            <p>{request.sender_username} stopped sharing!</p>
            <button
              className="request-btns"
              onClick={() => handleRequestClosedBySenderMessage(request.id)}
            >
              Ok
            </button>
          </div>
        ))}
        {showSessionLeftByRecipientMessage.map((request) => (
          <div key={request.id} className="session-left-by-recipient-message">
            <p>{request.recipient_username} left listening!</p>
            <button
              className="request-btns"
              onClick={() => handleSessionLeftByRecipientMessage(request.id)}
            >
              Ok
            </button>
          </div>
        ))}
      </div>
      <MessageList />
      <MessageInput />
      {previewImage && <MessageImageCropper />}
      {messageImagePreview && <MessageListImagePreview />}
    </div>
  );
};

export default ChatRoom;





import React, { useEffect, useRef } from 'react';
import './LazyVideo.css';
import lozad from 'lozad';

const LazyVideo = ({ src, className, videoRef }) => {

 useEffect(() => {
   if (videoRef.current) {
     const observer = lozad(videoRef.current);
     observer.observe();

     return () => {
       observer.disconnect();
     };
   } else {
   }
 }, [videoRef]);

  return (
    <div className={`lazy-video-container ${className}`}>
      <video
        ref={videoRef}
        className="lazy-video lozad"
        src={src}

      />
    </div>
  );
};

export default LazyVideo;

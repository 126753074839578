// AdminNavigation.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Admin.css';

function AdminNavigation() {
  const location = useLocation();

  return (
    <div className='header-admin'>
      <Link to="/adminGooofan/homescreen" className={location.pathname.includes("/adminGooofan/homescreen") ? "active-section-admin" : ""}>Screen Post</Link>
      <Link to="/adminGooofan/star-nav/update-form" className={location.pathname.includes("/adminGooofan/star-nav") ? "active-section-admin" : ""}>Stars Update</Link>
    </div>
  );
}

export default AdminNavigation;




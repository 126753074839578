import React, { useEffect, useState } from 'react';
import './StarUpdate.css';
import { useLikesComments } from '../LikesCommentsContext';
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';

const BestSelling = () => {
  const {
    bestSellingPosts, setBestSellingPosts, handleBackToHome, renderPosts,
    fetchPosts, page, setPageHasMore, scrollLoading
  } = useLikesComments();

  useEffect(() => {
    fetchPosts('/api/stars_updates/best_selling/', setBestSellingPosts, page, setPageHasMore);
  }, [page]);

  return (
    <div className='bestselling-container'>
       <StarUpdateHeaderWithSearch
        title="Best Selling"
        handleBackToHome={handleBackToHome}
        category="best_selling"
        isArchived={false}
      />
      <div className='bestselling-contents'>
        {renderPosts(bestSellingPosts, setBestSellingPosts)}
      </div>
      <div className='scroll-loader-spinner-container'>
        {scrollLoading && <div className="scroll-loader-spinner"></div>}
      </div>
    </div>
  );
};

export default BestSelling;



// import React, { useEffect, useState} from 'react';
// import './StarUpdate.css';
// import { useLikesComments } from '../LikesCommentsContext';
// import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';

// const BestSelling = () => {
//   const {
//     bestSellingPosts, setBestSellingPosts, handleBackToHome, renderPosts,
//     fetchPosts, page,  setPageHasMore, scrollLoading
//   } = useLikesComments();

//   useEffect(() => {
//     fetchPosts('/api/stars_updates/best_selling/', setBestSellingPosts, page, setPageHasMore);
//   }, [page]); 

//   return (
//       <div className='bestselling-container'>
//         <StarUpdateHeaderWithSearch
//         title="Best Selling"
//         handleBackToHome={handleBackToHome}
//         />  
//         <div className='bestselling-contents'>
//           {renderPosts(bestSellingPosts, setBestSellingPosts)}
//         </div>
//          <div className='scroll-loader-spinner-container'>
//           {scrollLoading && <div className="scroll-loader-spinner"></div>}
//          </div>
//       </div>
//   );
// };

// export default BestSelling;


   
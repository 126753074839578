import React, {
  useCallback,
  useState
} from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import CustomAudioPlayerWithProvider from "../CustomAudioPlayer";
import { AiOutlineClose } from "react-icons/ai";
import "../StarNavigationBar/chat.css";
import "../MusicSectionComponents/MusicPosts.css";



const CurrentMusic = () => {
  const {
    currentConversationId,
    currentRecipient,
    socketRequestRef,
    currentMusicPost,
    setCurrentMusicPost,
    showRequestMessage,
    setShowRequestMessage,
    setShowRequestSentMessage,
    isWideScreen
  } = useChatAndMusicPosts();
  const [errorMessage, setErrorMessage] = useState("");

  // Function to send request for listening music together
  const sendMusicRequest = useCallback(
    async (recipient, music) => {
      if (!socketRequestRef.current) return;

      const tempRequestId = uuidv4();
      const tempMusicId = uuidv4();

      try {
        // Check if the user has access to the music
        const accessResponse = await fetch(
          `/api/check-music-access/${music.id}/`
        );

        if (!accessResponse.ok) {
          throw new Error("Failed to check music access");
        }

        const accessData = await accessResponse.json();
        const hasAccess = accessData.has_access;

        if (!hasAccess) {
          // Fetch the user's current token balance
          const balanceResponse = await fetch("/api/user-token-balance/");

          if (!balanceResponse.ok) {
            throw new Error("Failed to fetch token balance");
          }

          // Parse the response
          const balanceData = await balanceResponse.json();

          // Handle cases where the response is empty or not as expected
          let balance = 0.0;
          if (Array.isArray(balanceData) && balanceData.length > 0) {
            const userBalanceData = balanceData[0];
            balance = parseFloat(userBalanceData.balance);
          }

          if (isNaN(balance)) {
            throw new Error("Balance is not a valid number");
          }

          // Check if the user has enough tokens
          if (balance < 1) {
            setErrorMessage("You don't have enough GTN, please recharge");
            return;
          }
        }

        // Proceed with sending the request via WebSocket if the balance is sufficient or user has access
        socketRequestRef.current.send(
          JSON.stringify({
            type: "music_request",
            recipient: recipient,
            music_id: music.id,
            tempRequestId: tempRequestId,
            tempMusicId: tempMusicId,
            conversationId: currentConversationId,
          })
        );

        // Update UI to reflect that the request was sent
        setShowRequestMessage(false);
        setShowRequestSentMessage(true);
        setCurrentMusicPost(null);

        setTimeout(() => {
          setShowRequestSentMessage(false);
        }, 3000);
      } catch (error) {
        setErrorMessage(
          "There was an error sending your request. Please try again."
        );
      }
    },
    [currentConversationId]
  );

  const handleCloseMusicPost = () => {
    setCurrentMusicPost(null);
  };

  const handleCloseRequestMessage = () => {
    setShowRequestMessage(false);
  };

  return (
    <>
      <div className="wrapper-musicpost-audioplayer" key={currentMusicPost?.id}>
        <div className="musicPost">
          {currentMusicPost?.cover_image ? (
            <img
              src={currentMusicPost?.cover_image}
              alt={`${currentMusicPost?.song_title} cover`}
              className="chat-musicPost-cover-image"
            />
          ) : (
            <div className="chat-musicPost-cover-placeholder">
              {currentMusicPost?.song_title?.charAt(0).toUpperCase()}
            </div>
          )}
          <div className="musicPost-info">
            <h3 className="musicPost-artist">{currentMusicPost?.artist}</h3>
            <p className="musicPost-song-title">
              {currentMusicPost?.song_title}
            </p>
            {currentMusicPost?.featuring && (
              <p className="musicPost-featuring">
                Feat. {currentMusicPost?.featuring.replace(/,/g, " &")}
              </p>
            )}
          </div>
          <div
            className="musicPost-close"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseMusicPost();
            }}
          >
            <AiOutlineClose size={30} />
          </div>
        </div>
        <CustomAudioPlayerWithProvider
          audioSrc={currentMusicPost?.audio_file}
          musicId={currentMusicPost.id}
        />
      </div>
      {showRequestMessage && (
        <div
          className="request-container"
          key={`request-${currentMusicPost?.id}`}
        >
          {errorMessage && (
            <p className="error-message">
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {errorMessage.includes("recharge") ? (
                <>
                  {errorMessage.replace("recharge", "")}{" "}
                  <Link
                    to={isWideScreen ? "/home/wallet" : "/wallet"}
                    className="recharge-link"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Recharge
                  </Link>
                  .
                </>
              ) : (
                errorMessage
              )}
            </p>
          )}

          <p>
            Do you want to listen to this song together with {currentRecipient}?
          </p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              sendMusicRequest(currentRecipient, currentMusicPost);
            }}
          >
            Send Request
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCloseRequestMessage();
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default CurrentMusic;

import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { BiArrowBack, BiSolidPlusSquare } from "react-icons/bi";
import { YourShopHeaderPostContext } from "../../StarNavigationBar/YourShopHeaderPostContext";
import "./HeaderHome.css";
import "./Report.css";

function Report() {
  const navigate = useNavigate();
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [reports, setReports] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const handleBackToHome = () => {
    navigate('/home');
  };
  useEffect(() => {
    // Fetch reports assigned to the logged-in supporter
    fetchReports();
  }, []);

  const fetchReports = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/reports/");
      const data = await response.json();
      setReports(data);
      setLoading(false);
    } catch (error) {
      setError("Failed to load reports.");
      setLoading(false);
    }
  };


  const handleNewReply = (reportId, newReply) => {
    setReports((prevReports) =>
      prevReports.map((report) =>
        report.id === reportId
          ? { ...report, replies: [...report.replies, newReply] }
          : report
      )
    );
  };

   const handleStatusUpdate = async (reportId, newStatus) => {
    try {
      const response = await fetch(`/api/reports/${reportId}/update-status/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setReports((prevReports) =>
          prevReports.map((report) =>
            report.id === reportId
              ? { ...report, error: errorData.detail || "Failed to update status." }
              : report
          )
        );
      } else {
        setReports((prevReports) =>
          prevReports.map((report) =>
            report.id === reportId ? { ...report, status: newStatus, error: "" } : report
          )
        );
      }
    } catch (error) {
      setReports((prevReports) =>
        prevReports.map((report) =>
          report.id === reportId ? { ...report, error: "Failed to update status." } : report
        )
      );
    }
  };
   return (
     <div className="report-container-react">
       <div className="header-report-react">
         <div className="Report-Back-btn-ract" onClick={handleBackToHome}>
           <BiArrowBack
             size={20}
             color="currentColor"
             className="bi bi-arrow-left"
           />
         </div>
         <h1 className="text-header-report-react">Reports</h1>
         <div className="empty-div-change-it"></div>
       </div>
       <div className="report-contents-react">
         <div className="new-case">
           <a href="/report/">
             <button>
               <BiSolidPlusSquare size={30} color="currentColor" />
               Report New Case
             </button>
           </a>
         </div>
         {loading ? (
           <div className="loader-container">
             <div className="loader"></div>
           </div>
         ) : (
           <ReportList
             reports={reports}
             onStatusUpdate={handleStatusUpdate}
             onNewReply={handleNewReply}
           />
         )}
       </div>
     </div>
   );
  }

// Report List Component
const ReportList = ({ reports, onStatusUpdate, onNewReply }) => {
  return (
    <ul className="report-list">
      {reports.map((report) => (
        <ReportItem
          key={report.id}
          report={report}
          onStatusUpdate={onStatusUpdate}
          onNewReply={onNewReply}
          // error={error}
        />
      ))}
    </ul>
  );
};

// Report Item Component
const ReportItem = ({ report, onStatusUpdate, onNewReply}) => {
  return (
    <li className="report-item">
      <h3>{report.subject}</h3>
      <ReportDetails report={report} />
      {report.error && (
        <p className="error-message">
          <i className="bi bi-exclamation-circle-fill error-icon"></i>
          {report.error}
        </p>
      )}
      <StatusUpdate report={report} onStatusUpdate={onStatusUpdate} />
      <ReplySection report={report} onNewReply={onNewReply} />
    </li>
  );
};

// Report Details Component
const STATUS_MAP = {
  open: "Open",
  in_progress: "In Progress",
  resolved: "Resolved",
  closed: "Closed",
};

const ReportDetails = ({ report }) => (
  <div className="report-details">
    <p>
      <strong>Tracking Code:</strong> {report.tracking_code}
    </p>
    <p>
      <strong>Status:</strong> {STATUS_MAP[report.status] || report.status}
    </p>
    <p>
      <strong>Description:</strong> {report.description}
    </p>
    <p>
      <strong>Category:</strong> {report.category}
    </p>
    <p>
      <strong>Reporter:</strong> {report.reporter?.first_name}{" "}
      {report.reporter?.last_name}
    </p>
    <p>
      <strong>Supporter:</strong> {report.supporter?.first_name}{" "}
      {report.supporter?.last_name}
    </p>
  </div>
);

// Status Update Component
const StatusUpdate = ({ report, onStatusUpdate, error }) => {
  if (report.status === "resolved" || report.status === "closed") return null;

  return (
    <div className="status-update-container">
      <button onClick={() => onStatusUpdate(report.id, "resolved")}>
        Mark as Resolved
      </button>
      <button onClick={() => onStatusUpdate(report.id, "closed")}>
        Mark as Closed
      </button>
    </div>
  );
};

// Reply Section Component
const ReplySection = ({ report, onNewReply }) => (
  <div className="replies-container">
    <h4>Replies</h4>
    {report.replies.length > 0 ? (
      <ul className="replies-list">
        {report.replies.map((reply) => (
          <ReplyItem key={reply.id} reply={reply} />
        ))}
      </ul>
    ) : (
      <p>No replies yet.</p>
    )}
    {report.status !== "resolved" && report.status !== "closed" && (
      <ReplyForm reportId={report.id} onNewReply={onNewReply} />
    )}
  </div>
);

// Reply Item Component
const ReplyItem = ({ reply }) => (
  <li
    className={`reply-item ${
      reply.supporter ? "supporter-reply" : "reporter-reply"
    }`}
  >
    <strong>{reply.user_name}</strong> {reply.message}
    <br />
    <small>{reply.created_at}</small>
  </li>
);

// Reply Form Component
const ReplyForm = ({ reportId, onNewReply }) => {
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const textareaRef = useRef(null); 

  const handleReplySubmit = async (e) => {
    e.preventDefault();

    // Reset success/error messages
    setSuccessMessage("");
    setError("");
    setLoading(true);
    setMessage("");

    try {
      const response = await axios.post(
        `/api/reports/${reportId}/add-reply/`,
        { message },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        }
      );

      if (response.status === 201) {
        setSuccessMessage("Reply added successfully!");
        // Fetch the updated replies
        const updatedReportResponse = await fetch(`/api/reports/${reportId}/`);
        const updatedReport = await updatedReportResponse.json();
        onNewReply(
          reportId,
          updatedReport.replies[updatedReport.replies.length - 1]
        );
         setMessage(""); 
         if (textareaRef.current) {
           textareaRef.current.style.height = "auto";
         }
      } else {
        throw new Error("Failed to add reply.");
      }
    } catch (error) {
      setError("Failed to submit reply.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleReplySubmit} className="reply-form">
      {error && (
        <p className="error-message">
          <i className="bi bi-exclamation-circle-fill error-icon"></i>
          {error}
        </p>
      )}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <textarea
        ref={textareaRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your reply here"
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = e.target.scrollHeight + "px";
        }}
        style={{ maxHeight: "500px" }}
        required
      />
      <button type="submit" disabled={loading || !message}>
        {loading ? "Submitting..." : "Submit Reply"}
      </button>
    </form>
  );
};

export default Report;


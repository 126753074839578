import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function StarUpdateNavigation() {
  const location = useLocation();

  return (
      <div className="homeScreen-links-container">
      <Link to="/adminGooofan/star-nav/update-form" className={location.pathname === "/adminGooofan/star-nav/update-form" ? "active-section-admin" : ""}>Create Post</Link>
      <Link to="/adminGooofan/star-nav/posts" className={location.pathname === "/adminGooofan/star-nav/posts" ? "active-section-admin" : ""}>Posts</Link>
      <Link to="/adminGooofan/star-nav/post-archive" className={location.pathname === "/adminGooofan/star-nav/post-archive" ? "active-section-admin" : ""}>Archived</Link>
      </div>
  );
}

export default StarUpdateNavigation;




// StarAwardPosts.jsx
import React, { useEffect} from 'react';
import "./UpdatesStar.css";
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';
import { useLikesComments } from '../LikesCommentsContext';

function StarAwardPosts() {
  const {
    adminStarAwardPosts, setAdminStarAwardPosts,
    starUpdateRenderPostAdmin,
    fetchPosts, page, setPageHasMore, scrollLoading, 
  } = useLikesComments();
 
 
  useEffect(() => {
    fetchPosts('/api/stars_updates/star_award/', setAdminStarAwardPosts, page, setPageHasMore);
  }, [page]);


    return (
      <>
      <StarUpdateHeaderWithSearch
        category="star_award"
        isArchived={false}
        nonArchived={true}
      />
      {starUpdateRenderPostAdmin(adminStarAwardPosts, setAdminStarAwardPosts)}
       <div className='scroll-loader-spinner-container'>
          {scrollLoading && <div className="scroll-loader-spinner"></div>}
       </div>
      </>
    );
}

export default StarAwardPosts;



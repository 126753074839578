import React, { useState, useRef } from 'react';
import SearchLazyImage from './SearchLazyImage';
import SearchLazyVideo from './SearchLazyVideo';
import './StarHomeComponents/ContentsRecommendation.css';
import './SearchAllResults.css';

function SearchAllResults({ type, user, starposting, loading, onUserClick, onStarPostingClick }) {
    const [imageFailed, setImageFailed] = useState(false);
    const videoRef = useRef(null);

    const Media = () => {
        if (starposting) {
            if (starposting.images && starposting.images.length > 0) {
                return (
                    <SearchLazyImage
                        src={starposting.images[0]}
                        alt={starposting.product_name}
                        className="product-image"
                        onError={() => setImageFailed(true)}
                        loading={loading}
                    />
                );
            } else if (starposting.videos && starposting.videos.length > 0) {
                return (
                    <SearchLazyVideo
                        src={starposting.videos[0]}
                        className="product-video"
                        videoRef={videoRef}
                        loading={loading}
                    />
                );
            }
        }
        return null;
    };

    const handleUserClick = () => {
        if (user && onUserClick) {
            onUserClick(user.username);
        }
    };

    const handleStarPostingClick = () => {
        if (starposting && onStarPostingClick) {
            onStarPostingClick(starposting.user_id, starposting.product_code);
        }
    };

    return (
        <div className="search-result-recommendation">
            {type === 'user' && user && (
                <div className="user-section" onClick={handleUserClick}>
                    {user.profile_picture && !imageFailed ? (
                        <SearchLazyImage
                            src={user.profile_picture}
                            alt={user.first_name}
                            className="profile-picture profile"
                            onError={() => setImageFailed(true)}
                            loading={loading}
                        />
                    ) : (
                        <div className="profile-picture no-profile-picture-results">
                            {user.first_name ? user.first_name.slice(0, 1).toUpperCase() : "?"}
                        </div>
                    )}
                    <div className="user-wrapper">
                        <span>{user.username}</span>
                        <span className="full-name">{`${user.first_name} ${user.last_name}`}</span>
                    </div>
                </div>
            )}
            {type === 'starposting' && starposting && (
                <div className="starposting-wrapper" onClick={handleStarPostingClick}>
                    <div className="media-details">
                        {Media()}
                    </div>
                    <div className="product-details">
                        <div className="product-name-code">
                            <span className="product-name">{starposting.product_name}</span>
                            <span className="product-code">{starposting.product_code}</span>
                        </div>
                        <span className="product-price">{`${starposting.price} ${starposting.currency}`}</span>
                        <span className={`product-status ${starposting.is_soldout ? 'sold-out' : 'available'}`}>
                            {starposting.is_soldout ? 'Sold-Out' : 'Available'}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SearchAllResults;





import React from 'react';
import { Outlet } from 'react-router-dom';
import HomeScreenNavigation from './HomeScreenNavigation';
import './Admin.css';

function HomeScreenOutlet() {
  return (
    <div>
      <HomeScreenNavigation />
      <>
       <Outlet />
      </>
    </div>
  );
}

export default HomeScreenOutlet;


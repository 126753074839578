import React, {useContext, useEffect, useState} from 'react';
import { YourShopHeaderPostContext } from './StarNavigationBar/YourShopHeaderPostContext';
import { useOwnModalsContext } from './OwnModalsContext';
import { BiArrowBack } from "react-icons/bi";

const PaymentMethodModal = () => {
   const { orderSocketRef, selectedOffer, setNotifications, csrftoken } =
     useContext(YourShopHeaderPostContext);
   const [walletBalanceError, setWalletBalanceError] = useState(""); 

  const {
    isPaymentMethodModalOpen,
    setIsPaymentMethodModalOpen,
    setIsNotificationModalOpen,
    country,
    address,
    zipCode,
    houseApartment,
    contactNumber,
    deliveryType,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    deliveryDetails,
    totalCostInUSD,
    walletBalance,
    fetchUserBalance,
  } = useOwnModalsContext();

  useEffect(() => {
    fetchUserBalance();
  }, [selectedPaymentMethod]);

 const handleCloseModalPaymentMethod =() =>{
  setIsPaymentMethodModalOpen(false);
 }

 const handleOfferPay = async (e) => {
   e.preventDefault();

   const missingAmount = (totalCostInUSD - walletBalance).toFixed(2);
   if (
     selectedPaymentMethod === "Goofan Wallet" &&
     walletBalance < totalCostInUSD
   ) {
     setWalletBalanceError(
       `Insufficient Balance. Your current balance is $${walletBalance}. You are short by $${missingAmount}.`
     );
     return;
   }

   const orderData = {
     seller: selectedOffer?.seller,
     buyer: selectedOffer?.buyer,
     seller_address: selectedOffer?.seller_address,
     buyer_contact_number: contactNumber,
     product_id: selectedOffer?.product_id,
     product_price: selectedOffer?.offer_amount,
     product_currency: selectedOffer?.currency,
     delivery_cost: deliveryDetails.deliveryCost,
     total_cost: deliveryDetails?.totalCost,
     total_cost_usd: totalCostInUSD,
     distance: deliveryDetails?.distance,
     delivery_type: deliveryType?.value,
     buyer_address: address,
     country: country?.label,
     house_apartment: houseApartment,
     zip_code: zipCode,
     handling_fee: deliveryDetails?.handlingFee,
     additional_message: deliveryDetails?.additionalMessage,
     payment_method: selectedPaymentMethod,
   };

   // Send the order data via WebSocket (assuming payment is happening)
   if (
     orderSocketRef.current &&
     orderSocketRef.current.readyState === WebSocket.OPEN
   ) {
     orderSocketRef.current.send(JSON.stringify(orderData));
   }
   // API call to update the offer status to 'completed'
   const completeOffer = async () => {
     try {
       const response = await fetch(`/api/offers/complete_offer/`, {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           "X-CSRFToken": csrftoken,
         },
         body: JSON.stringify({
           offer_id: selectedOffer?.offer_id, 
         }),
       });

       if (!response.ok) {
         throw new Error("Failed to update offer status.");
       }

       // Handle success (e.g., remove offer from UI)
       setNotifications((prevAcceptedOffers) =>
         prevAcceptedOffers.filter(
           (offer) => offer.offer_id !== selectedOffer?.offer_id
         )
       );
       setIsNotificationModalOpen(true); 
     } catch (error) {
       console.error("Error completing offer:", error);
     }
   };

   // Call the function to complete the offer
   await completeOffer();
 };

  const handlePaymentMethodChange = (event) => {
  setSelectedPaymentMethod(event.target.value);
};

  return (
    <>
      {/* Payment Method Modal for offers */}
      {isPaymentMethodModalOpen && (
        <div
          className="payment-modal-overlay"
          onClick={handleCloseModalPaymentMethod}
        >
          <div
            className="payment-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="payment-modal-header">
              <div
                className="close-form-modal"
                onClick={handleCloseModalPaymentMethod}
              >
                <BiArrowBack
                  size={20}
                  color="currentColor"
                  className="bi bi-arrow-left"
                />
              </div>
              <div>
                <h4 className="text-choose-payment-method">
                  Choose Payment Method
                </h4>
              </div>
              <div className="empty-div-change-it"></div>
            </div>
            {walletBalanceError && (
              <p className="error-message">
                <i className="bi bi-exclamation-circle-fill error-icon"></i>{" "}
                {walletBalanceError}
              </p>
            )}
            <form onSubmit={handleOfferPay} className="form-send-payment">
              <div className="gooofan-wallet payment-option">
                <label htmlFor="gooofan-wallet">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="Goofan Wallet"
                    checked={selectedPaymentMethod === "Goofan Wallet"}
                    onChange={handlePaymentMethodChange}
                    id="gooofan-wallet"
                  />
                  <span className="custom-radio"></span>
                  Goofan Wallet
                </label>
              </div>
              <div className="card payment-option">
                <label htmlFor="card">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="Card"
                    checked={selectedPaymentMethod === "Card"}
                    onChange={handlePaymentMethodChange}
                    id="card"
                  />
                  <span className="custom-radio"></span>
                  Card
                </label>
              </div>
              <div className="tigo-pesa payment-option">
                <label htmlFor="tigo-pesa">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="Tigo Pesa"
                    checked={selectedPaymentMethod === "Tigo Pesa"}
                    onChange={handlePaymentMethodChange}
                    id="tigo-pesa"
                  />
                  <span className="custom-radio"></span>
                  Tigo Pesa
                </label>
              </div>
              <button type="submit" className="send-payment-btn">
                Pay
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentMethodModal;




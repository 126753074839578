import React, { createContext, useState, useEffect, useContext } from "react";
import { YourShopHeaderPostContext } from "./StarNavigationBar/YourShopHeaderPostContext";
import { BsSkipForward, BsSkipBackward, BsVolumeUp } from "react-icons/bs";
import { BiVolumeMute, BiPlay, BiPause } from "react-icons/bi";

const PlayersContext = createContext();


const NextIcon = () => <BsSkipForward size={22} color="rgba(0, 0, 0, 0.5)" />;
const BackIcon = () => <BsSkipBackward size={22} color="rgba(0, 0, 0, 0.5)" />;
const VolumeOffIcon = () => (
  <BiVolumeMute size={20} color="rgba(0, 0, 0, 0.5)" />
);
const VolumeUpIcon = () => <BsVolumeUp size={22} color="rgba(0, 0, 0, 0.5)" />;
const PauseIcon = () => <BiPause size={22} color="rgba(0, 0, 0, 0.5)" />;
const PlayIcon = () => <BiPlay size={22} color="rgba(0, 0, 0, 0.5)" />;

const LoaderSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="currentColor"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export const PlayersProvider = ({ children, musicId }) => {
  const {csrftoken } = useContext(YourShopHeaderPostContext);
  const [historyId, setHistoryId] = useState(null);

// 1. Start Session API Call
const startSession = async () => {
  try {
      const response = await fetch(
        "/api/play-history/start_playback_session/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({ music_id: musicId }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setHistoryId(data.history_id);
      } else {
        console.error("Failed to start session:", data);
      }
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  // 2. Update Duration API Call
  const updateDuration = async (time) => {
    if (!historyId) return;
    try {
      await fetch("/api/play-history/update_duration/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ history_id: historyId, current_time: time }),
      });
    } catch (error) {
      console.error("Error updating duration:", error);
    }
  };

  // 3. Pause Session API Call
  const pauseSession = async () => {
    if (!historyId) return;
    try {
      await fetch("/api/play-history/pause_playback/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ history_id: historyId }),
      });
    } catch (error) {
      console.error("Error pausing session:", error);
    }
  };

  // 4. Resume Session API Call
  const resumeSession = async () => {
    if (!historyId) return;
    try {
      await fetch("/api/play-history/resume_playback/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ history_id: historyId }),
      });
    } catch (error) {
      console.error("Error resuming session:", error);
    }
  };

  // 5. End Session API Call
  const endSession = async () => {
    if (!historyId) return;
    try {
      await fetch("/api/play-history/end_playback_session/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ history_id: historyId }),
      });
    } catch (error) {
      console.error("Error ending session:", error);
    }
  };

  return (
    <PlayersContext.Provider
      value={{
        startSession,
        updateDuration,
        pauseSession,
        resumeSession,
        endSession,
        historyId,
        VolumeUpIcon,
        VolumeOffIcon,
        PlayIcon,
        PauseIcon,
        NextIcon,
        BackIcon,
        LoaderSVG,
      }}
    >
      {children}
    </PlayersContext.Provider>
  );
};

export const usePlayers = () => useContext(PlayersContext);

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { YourShopHeaderPostContext } from "./YourShopHeaderPostContext";
import { FooterContext } from "./FooterContext";
import "./subscriber.css";

function Subscriber() {
  const { csrftoken, currentUserData } = useContext(YourShopHeaderPostContext);
  const {
    subscriberPage,
    setSubscriberPage,
    subscribers,
    setSubscribers,
    hasMoreSubscribers,
    scrollLoadingSubscribers,
    setScrollLoadingSubscribers,
    fetchSubscribers,
    loading,
  } = useContext(FooterContext);
  const [imageFailed, setImageFailed] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [currentSubscriber, setCurrentSubscriber] = useState(null);
  const observerSubscribers = useRef();

  useEffect(() => {
    fetchSubscribers(subscriberPage);
  }, [subscriberPage]);

  const setCombinedSubscribersRef = (node, index) => {
    if (index === subscribers.length - 1) {
      lastSubscriberElementRef(node);
    }
  };

  const lastSubscriberElementRef = useCallback(
    (node) => {
      if (scrollLoadingSubscribers) return;
      if (observerSubscribers.current) observerSubscribers.current.disconnect();
      observerSubscribers.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreSubscribers) {
          setScrollLoadingSubscribers(true);
          setSubscriberPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerSubscribers.current.observe(node);
    },
    [scrollLoadingSubscribers, hasMoreSubscribers]
  );

  const handleRemoveSubscriber = (subscriber) => {
    setShowModalRemove(true);
    setCurrentSubscriber(subscriber);
  };

  const handleCloseRemoveSubsciberModal = () => {
    setShowModalRemove(false);
  };

  const confirmRemoveSubscriber = () => {
    fetch("/api/subscriptions/remove_subscriber/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({ subscriber_id: currentSubscriber.id }),
    })
      .then((response) => {
        if (response.ok) {
          setSubscribers(
            subscribers.filter(
              (sub) => sub.subscriber.id !== currentSubscriber.id
            )
          );
        } else {
          console.error("Failed to remove subscriber");
        }
        setShowModalRemove(false);
      })
      .catch((error) => {
        console.error("Error removing subscriber:", error);
        setShowModalRemove(false);
      });
  };

  const formatNumber = (count) => {
    if (count >= 1000000000) {
      return `${(Math.floor(count / 100000000) / 10).toFixed(1)}B`;
    } else if (count >= 1000000) {
      return `${(Math.floor(count / 100000) / 10).toFixed(1)}M`;
    } else if (count >= 1000) {
      return `${(Math.floor(count / 100) / 10).toFixed(1)}k`;
    } else {
      return count.toString();
    }
  };

  
return (
  <div className="subscriber-container">
    <header className="header-subscribers">
      {!loading && (
        <>
          <div>{currentUserData.username}</div>
          <div className="subscribers">
            <p>Subscribers</p>
            <p className="num-subscribers">
              {formatNumber(subscribers.length)}
            </p>
          </div>
        </>
      )}
    </header>
    <div className="subscribers-contents">
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : subscribers.length === 0 ? (
        <p className="no-subscriber">No subscriber yet</p>
      ) : (
        <ul className="subscriber-list">
          {subscribers.map(({ subscriber, index }) => (
            <React.Fragment key={subscriber.id}>
              <li
                ref={(node) => setCombinedSubscribersRef(node, index)}
                className="subscriber-item"
              >
                <div className="subscriber-profile">
                  <div className="profile">
                    {subscriber.profile_picture && !imageFailed ? (
                      <img
                        src={subscriber.profile_picture}
                        alt={subscriber.username}
                        className="profile-picture"
                        onError={() => setImageFailed(true)}
                      />
                    ) : (
                      <div className="profile-picture no-profile-picture">
                        {subscriber.username.slice(0, 1).toUpperCase()}
                      </div>
                    )}
                  </div>
                </div>
                <span>{subscriber.username}</span>
                <button
                  onClick={() => handleRemoveSubscriber(subscriber)}
                  className="remove-button"
                >
                  Remove
                </button>
              </li>
              <div className="border-bottom-line"></div>
            </React.Fragment>
          ))}
        </ul>
      )}

      <div className="scroll-loader-spinner-container">
        {scrollLoadingSubscribers && (
          <div className="scroll-loader-spinner"></div>
        )}
      </div>

      {showModalRemove && (
        <div
          className="modal-remove-subscribe"
          onClick={handleCloseRemoveSubsciberModal}
        >
          <div className="modal-remove-subscribe-contents">
            <div className="modal-remove-subscribe-profile">
              <div className="profile">
                {currentSubscriber.profile_picture && !imageFailed ? (
                  <img
                    src={currentSubscriber.profile_picture}
                    alt={currentSubscriber.username}
                    className="profile-picture"
                    onError={() => setImageFailed(true)}
                  />
                ) : (
                  <div className="profile-picture no-profile-picture">
                    {currentSubscriber.username.slice(0, 1).toUpperCase()}
                  </div>
                )}
              </div>
            </div>
            <p className="text-remove-subscribe">Remove Subscriber?</p>
            <p>
              We won't tell {currentSubscriber.username} that they were removed
              from your subscribers.
            </p>
            <div className="modal-remove-subscribe-buttons">
              <button
                className="modal-remove-subscribe-button remove"
                onClick={confirmRemoveSubscriber}
              >
                Remove
              </button>
            </div>
          </div>
          <div className="subscribe-close-modal">
            <button
              className="modal-remove-subscribe-button-cancel"
              onClick={handleCloseRemoveSubsciberModal}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
);
}

export default Subscriber;


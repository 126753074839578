import React, { useState, useEffect, useRef } from "react";
import { usePlayers } from "./PlayersContext";
import "./VideoControls.css";

function VideoControls({ videoId, startTimer, stopTimer, videoRefs }) {
  const { PlayIcon } = usePlayers();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const video = videoRefs.current[videoId];

    if (video) {
      const handlePlay = () => {
        setIsPlaying(true);
        stopTimer();
      };

      const handlePause = () => {
        setIsPlaying(false);
        if (video.currentTime < video.duration - 3) {
          // Video is paused before it ends, don't start the timer
          startTimer();
        } else {
          // Video has ended, start the timer immediately
          setTimeout(() => {
            startTimer(true);
          });
        }
      };

      const handleTimeUpdate = () => {
        if (video) {
          const currentTime = video.currentTime;
          const duration = video.duration;
          const progressPercentage = (currentTime / duration) * 100;
          setProgress(progressPercentage);
        }
      };

      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [videoId, startTimer, stopTimer, videoRefs]);

  const togglePlay = () => {
    const video = videoRefs.current[videoId];
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleProgressBarClick = (e) => {
    const video = videoRefs.current[videoId];
    if (video) {
      const progressBarWidth = progressBarRef.current.offsetWidth;
      const clickX = e.pageX - progressBarRef.current.offsetLeft;
      const clickPercentage = (clickX / progressBarWidth) * 100;
      const newTime = (clickPercentage / 100) * video.duration;
      video.currentTime = newTime;
    }
  };

  return (
    <div className="video-video-controls">
      <div className="play-pause-button" onClick={togglePlay}>
        {!isPlaying && (
          <div className="play-icon-container">
            <span className="play-icon">
              <PlayIcon />
            </span>
          </div>
        )}
      </div>
      <div
        className="progress-bar"
        ref={progressBarRef}
        onClick={handleProgressBarClick}
      >
        <div
          className="progress-bar-fill"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="video-overlay-playAnywhere" onClick={togglePlay}></div>
    </div>
  );
}

export default VideoControls;


// src/components/userLogoutContext.jsx
import React, { createContext, useContext, useEffect, useRef } from "react";

// Get CSRFToken
function getCSRFToken() {
  const csrfTokenCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken"));
  return csrfTokenCookie ? csrfTokenCookie.split("=")[1] : null;
}

const csrftoken = getCSRFToken();

const UserLogoutContext = createContext();

export const UserLogoutProvider = ({ children }) => {
  const handleLogout = async () => {
    try {
      const currentPage = window.location.pathname + window.location.search;

      const response = await fetch("/api/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        credentials: "include",
      });

      if (response.ok) {
        // Clear local storage, session storage, and any tokens
        localStorage.clear();
        sessionStorage.clear();

        // Redirect to login page with the next parameter
        window.location.replace(
          `/login?next=${encodeURIComponent(currentPage)}`
        );
      } else {
        console.error("Logout failed.");
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  useEffect(() => {
    let activityTimeout;
    let heartbeatInterval;

    const sendHeartbeat = async () => {
      try {
        const response = await fetch("/api/session_status/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          credentials: "include",
        });

        if (response.status === 401) {
          handleLogout();
        } else if (response.ok) {
        } else {
          console.error("Failed to check session status.");
        }
      } catch (error) {
        console.error("An error occurred while sending the heartbeat:", error);
      }
    };

    const resetActivityTimeout = () => {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(sendHeartbeat, 10 * 60 * 1000);
    };

    // Add event listeners to detect user activity
    window.addEventListener("mousemove", resetActivityTimeout);
    window.addEventListener("mousedown", resetActivityTimeout);
    window.addEventListener("mouseup", resetActivityTimeout);
    window.addEventListener("keydown", resetActivityTimeout);
    window.addEventListener("keyup", resetActivityTimeout);
    window.addEventListener("click", resetActivityTimeout);
    window.addEventListener("dblclick", resetActivityTimeout);
    window.addEventListener("scroll", resetActivityTimeout);
    window.addEventListener("touchstart", resetActivityTimeout);
    window.addEventListener("touchmove", resetActivityTimeout);
    window.addEventListener("focus", resetActivityTimeout);
    window.addEventListener("blur", resetActivityTimeout);
    document.addEventListener("visibilitychange", resetActivityTimeout);

    // Media Elements Playback
    const mediaElements = document.querySelectorAll("video, audio");

    mediaElements.forEach((media) => {
      media.addEventListener("play", resetActivityTimeout);
      media.addEventListener("pause", resetActivityTimeout);
      media.addEventListener("timeupdate", resetActivityTimeout);
    });

    resetActivityTimeout();

    heartbeatInterval = setInterval(sendHeartbeat, 10 * 60 * 1000); 

    return () => {
      clearTimeout(activityTimeout);
      clearInterval(heartbeatInterval);
      window.removeEventListener("mousemove", resetActivityTimeout);
      window.removeEventListener("mousedown", resetActivityTimeout);
      window.removeEventListener("mouseup", resetActivityTimeout);
      window.removeEventListener("keydown", resetActivityTimeout);
      window.removeEventListener("keyup", resetActivityTimeout);
      window.removeEventListener("click", resetActivityTimeout);
      window.removeEventListener("dblclick", resetActivityTimeout);
      window.removeEventListener("scroll", resetActivityTimeout);
      window.removeEventListener("touchstart", resetActivityTimeout);
      window.removeEventListener("touchmove", resetActivityTimeout);
      window.removeEventListener("focus", resetActivityTimeout);
      window.removeEventListener("blur", resetActivityTimeout);
      document.removeEventListener("visibilitychange", resetActivityTimeout);

      // Remove media listeners
      mediaElements.forEach((media) => {
        media.removeEventListener("play", resetActivityTimeout);
        media.removeEventListener("pause", resetActivityTimeout);
        media.removeEventListener("timeupdate", resetActivityTimeout);
      });
    };
  }, [csrftoken]);

  return (
    <UserLogoutContext.Provider value={{ handleLogout }}>
      {children}
    </UserLogoutContext.Provider>
  );
};

export const useUserLogout = () => useContext(UserLogoutContext);



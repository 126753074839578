//TopPricedArchives.jsx
import React, { useEffect } from 'react';
import "./UpdatesStar.css";
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';
import { useLikesComments } from '../LikesCommentsContext';

const TopPricedArchives = () => {
  const {
    topPricedArchivedPosts, setTopPricedArchivedPosts,
     fetchPosts, starUpdateRenderArchiveAdmin,  page, setPageHasMore, scrollLoading
  } = useLikesComments();


  useEffect(() => {
    fetchPosts('/api/stars_updates/archived_posts/?post_type=top_priced', setTopPricedArchivedPosts, page, setPageHasMore);
  }, ['top_priced', page]);

  return (
    <>
    <StarUpdateHeaderWithSearch
        category="top_priced"
        isArchived={true}
      />
     {starUpdateRenderArchiveAdmin(topPricedArchivedPosts, setTopPricedArchivedPosts)}
     <div className='scroll-loader-spinner-container'>
        {scrollLoading && <div className="scroll-loader-spinner"></div>}
      </div>
    </>
  );
};

export default TopPricedArchives;


import React, {useContext, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BiArrowBack,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import { AiFillPushpin } from "react-icons/ai";
import { BsEarbuds } from "react-icons/bs";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { useChat } from "./ChatContext";
import { FooterContext } from "../StarNavigationBar/FooterContext";
import "../StarNavigationBar/chat.css";

const ChatHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showFooter, setShowFooter } = useContext(FooterContext);
  const { handleBackToConvoList } = useChat();
  const {
    handlePinConversation,
    isCurrentConversationPinned,
    currentConversationId,
    imageFailed,
    setImageFailed,
    setShowClearChatModal,
    showKebabMenu,
    setShowKebabMenu,
    markMessagesAsRead,
    selectedUser,
    isWideScreen,
  } = useChatAndMusicPosts();

  //handle to navigate to musics posts
  const handleMusic = () => {
    // navigate("/music/music-posts");
    navigate(`${isWideScreen ? "/home" : ""}/music/music-posts`);

    //Rendering the footer Nav
    setShowFooter(!showFooter);
    localStorage.setItem("showFooter", JSON.stringify(!showFooter));

    markMessagesAsRead(currentConversationId);
  };

  return (
      <div className="chat-header">
        <div className="wrapper_chat_profile">
          <button onClick={handleBackToConvoList}>
            <BiArrowBack
              size={22}
              color="currentColor"
              className="bi bi-arrow-left"
            />
          </button>
          <div className="profile">
            {selectedUser?.profile_picture && !imageFailed ? (
              <img
                src={selectedUser?.profile_picture}
                alt={selectedUser?.first_name}
                className="profile-picture"
                onError={() => setImageFailed(true)}
              />
            ) : (
              <div className="profile-picture no-profile-picture">
                {selectedUser?.first_name
                  ? selectedUser?.first_name.slice(0, 1).toUpperCase()
                  : "?"}
              </div>
            )}
          </div>
          <div className="chat_username">
            {selectedUser?.first_name && selectedUser?.last_name
              ? `${selectedUser?.first_name} ${selectedUser?.last_name}`
              : ""}
          </div>
        </div>
        <div className="wrapper_chat_functions">
          <button
            className="btn-pin-conversation"
            onClick={(event) => {
              event.stopPropagation();
              if (selectedUser) {
                handlePinConversation(selectedUser, currentConversationId);
              }
            }}
          >
            <AiFillPushpin
              size={22}
              color={isCurrentConversationPinned ? "#f0c14b" : "currentColor"}
            />
          </button>

          <button onClick={handleMusic}>
            <BsEarbuds size={22} />
          </button>

          <button onClick={() => setShowKebabMenu(!showKebabMenu)}>
            <BiDotsVerticalRounded size={22} />
          </button>
          {showKebabMenu && (
            <div className="kebab-menu" onClick={(e) => e.stopPropagation()}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowClearChatModal(true);
                  setShowKebabMenu(false);
                }}
              >
                Clear Chat
              </button>
              {/* Other menu items */}
            </div>
          )}
        </div>
      </div>
  );
};

export default ChatHeader;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import "../StarHomeComponents/HeaderHomeComponents/HeaderHome.css";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { useStatic } from "./StaticContext";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import {
  BiArrowBack,
} from "react-icons/bi";

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip);

function AllStatics() {
  const { csrftoken, currentUserData } = useContext(YourShopHeaderPostContext);
  const {
    selectedMusic,
    setSelectedMusic,
    userAllMusicData,
    musicPaymentData,
    musicDurationData,
    userAllMusicDurationData,
    selectedStatistic,
    setSelectedStatistic,
    visitData,
    earningData,
    percentageChange,
  } = useStatic();

  const [userMusics, setUserMusics] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("7_days");
  const navigate = useNavigate();
  const isScreenWidthInRange = useMediaQuery({
    query: "(max-width: 499.98px)",
  });

  // Utility to check if the user is a musician
  const isMusician = currentUserData?.confirm_notability?.some(
    (notability) => notability.name === "Musician"
  );

  // Options for react-select
  const statisticOptions = [
    { value: "visits", label: "Profile Visits" },
    { value: "earnings", label: "Earnings" },
    // Conditionally render "Music Data" option
    ...(isMusician ? [{ value: "musicData", label: "Music Data" }] : []),
  ];

  // Fetch user's music list
  useEffect(() => {
    const fetchUserMusics = async () => {
      try {
        const response = await fetch("/api/music-payment-history/my-music/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        });
        const data = await response.json();
        setUserMusics(data);
      } catch (error) {
        console.error("Error fetching user musics:", error);
      }
    };

    fetchUserMusics();
  }, [csrftoken]);

  const handleMusicChange = (selectedOption) => {
    setSelectedMusic(selectedOption);
  };

  const handleBackToHome = () => {
    navigate("/home");
  };

  const handleLegendClick = (index) => {
    const timePeriods = ["7_days", "30_days", "90_days"];
    setSelectedTimePeriod(timePeriods[index]);
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    setSelectedTimePeriod("7_days");
  }, []);

  // Select the correct data to display based on the selected statistic
  const selectedData = selectedStatistic === "visits" ? visitData : earningData;

  const chartData = {
    labels: ["Last 7 Days", "Last 30 Days", "Last 90 Days"],
    datasets: [
      {
        label: selectedStatistic === "visits" ? "Visits" : "Earnings",
        data: [
          selectedData[`${selectedStatistic}_7_days`],
          selectedData[`${selectedStatistic}_30_days`],
          selectedData[`${selectedStatistic}_90_days`],
        ],
        backgroundColor: ["lightblue", "hsl(0, 8%, 90%)", "#f0c14b"],
        borderColor: "#fff",
        borderWidth: 1,
        hoverBackgroundColor: ["#87CEFA", "hsl(0, 8%, 75%)", "#e3b035"],
      },
    ],
  };

  // const percentageChange = selectedData.percentage_change || 0;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: state.isFocused ? "none !important" : provided.outline,
      border: state.isFocused
        ? "1px #f0c14b solid !important"
        : provided.border,
      boxShadow: state.isFocused ? "none !important" : provided.boxShadow,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f0c14b"
        : state.isFocused
        ? "#e0e0e0"
        : provided.backgroundColor,
      color: state.isSelected ? "#000" : provided.color,
      cursor: state.isSelected ? "default" : "pointer",
    }),
  };

  return (
    <div className="allstatics-container">
      <header className="header-allstatics">
        {isScreenWidthInRange && (
          <div className="allstatics-Back-btn" onClick={handleBackToHome}>
            <BiArrowBack
              size={20}
              color="currentColor"
              className="bi bi-arrow-left"
            />
          </div>
        )}
        <h1 className="text-your-static">Your statistics</h1>
        <div className="empty-div-remove-it-if-needed"></div>
      </header>

      <div className="allstatics-contents">
        <div className="statistic-type-selector">
          <Select
            value={statisticOptions.find(
              (option) => option.value === selectedStatistic
            )}
            onChange={(selectedOption) =>
              setSelectedStatistic(selectedOption.value)
            }
            options={statisticOptions}
            styles={customStyles}
          />
        </div>

        {/* Conditional rendering based on selected statistic */}
        {selectedStatistic !== "musicData" ? (
          <>
            <div className="legend">
              {chartData.labels.map((label, index) => (
                <div
                  key={index}
                  className={`legend-item ${
                    activeIndex === null || activeIndex === index
                      ? "legend-item-active"
                      : "legend-item-opacity"
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleLegendClick(index);
                  }}
                >
                  <div
                    className={`legend-color-box ${
                      activeIndex === index ? "legend-color-box-active" : ""
                    }`}
                    style={{
                      backgroundColor:
                        chartData.datasets[0].backgroundColor[index],
                    }}
                  />
                  <span>{label}</span>
                </div>
              ))}
            </div>
            <div className="total-visits-earning">
              <span>
                {selectedStatistic === "earnings"
                  ? `$${
                      selectedData[selectedStatistic + "_" + selectedTimePeriod]
                    }`
                  : selectedData[selectedStatistic + "_" + selectedTimePeriod]}
              </span>
              <span>
                {selectedStatistic === "visits" ? "Visits" : "Earnings"}
              </span>
            </div>
            {selectedStatistic === "earnings" &&
              selectedTimePeriod === "7_days" && (
                <div className="percentage-change">
                  <span
                    style={{ color: percentageChange >= 0 ? "#4caf50" : "red" }}
                  >
                    {percentageChange >= 0 ? "+" : ""}
                    {percentageChange.toFixed(2)}% compared to last week
                  </span>
                </div>
              )}
            <div className="pie-chart-container">
              <Pie
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  plugins: { legend: { display: false } },
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="all-music-data">
              <h3>All Music Data</h3>
              <p>
                Total paid listens:{" "}
                {userAllMusicData.total_paid_count_for_all_music}
              </p>
              <p>
                Total Amount: $
                {userAllMusicData.total_amount_for_all_music
                  ? userAllMusicData.total_amount_for_all_music.toFixed(2)
                  : "0.00"}
              </p>
              <p>Total Played Duration: {userAllMusicDurationData}</p>
            </div>
            <div className="music-selector">
              <Select
                value={selectedMusic}
                onChange={handleMusicChange}
                options={userMusics.map((music) => ({
                  value: music.id,
                  label: music.song_title,
                }))}
                placeholder="Select a Music"
                styles={customStyles}
              />
            </div>
            {selectedMusic && (
              <div className="individual-music-data">
                <h4>Selected Music Data</h4>
                <p>
                  Total paid listens: {musicPaymentData.individual_paid_count}
                </p>
                <p>
                  Total Amount: $
                  {musicPaymentData.individual_total_amount
                    ? musicPaymentData.individual_total_amount.toFixed(2)
                    : "0.00"}
                </p>
                <p>Total Played Duration: {musicDurationData}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AllStatics;

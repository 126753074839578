// YourshopHeader.jsx
import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { YourShopHeaderPostContext } from "./YourShopHeaderPostContext";
import { FaShare } from "react-icons/fa";
import { BiGridAlt} from "react-icons/bi";
import { BsBookmarkX, BsCartPlus } from "react-icons/bs";


function YourShopHeader({ username, postStarContainerRef }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [imageFailed, setImageFailed] = useState(false);
  const {
    showHeader,
    setShowHeader,
    isFullScreenPreview,
    paidCount,
    currentUserData,
    csrftoken,
  } = useContext(YourShopHeaderPostContext);
  const [shareMessage, setShareMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = postStarContainerRef.current.scrollTop;
      const maxScrollY =
        postStarContainerRef.current.scrollHeight -
        postStarContainerRef.current.clientHeight;

      if (maxScrollY <= 110) {
        setShowHeader(true);
      } else {
        setShowHeader(currentScrollY <= 110);
      }
    };

    const postStarContainer = postStarContainerRef.current;

    if (postStarContainer) {
      postStarContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (postStarContainer) {
        postStarContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [postStarContainerRef, setShowHeader]);

  useEffect(() => {
    // Fetch user data whenever the username changes
    fetch(`/api/users_data/${username}/`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setImageFailed(false);
      })
      .catch((error) => console.error("Error fetching user data:", error));

    // Fetch subscription status for the current profile being viewed
    fetch(`/api/subscriptions/?subscribed_to=${username}`)
      .then((response) => response.json())
      .then((data) => {
        setIsSubscribed(data.results && data.results.length > 0);
      })
      .catch((error) =>
        console.error("Error fetching subscription status:", error)
      );
  }, [username]); // This effect depends on `username`, so it runs whenever `username` changes

  const handleEditProfile = (event) => {
    event.stopPropagation();
    navigate("/account");
  };

  const handleSubscribeClick = (event) => {
    event.stopPropagation();
    setLoading(true);
    const url = `/api/subscriptions/${
      isSubscribed ? "unsubscribe" : "subscribe"
    }/`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({ subscribed_to_id: userData.id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        if (response.status === 204) {
          return null;
        }

        return response.json();
      })
      .then((data) => {
        setIsSubscribed(!isSubscribed);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error during subscription:", error);
        setLoading(false);
      });
  };
  const handleShareClick = () => {
    const url = userData.profile_url;

    if (navigator.share) {
      navigator
        .share({
          title: `${userData.username}'s Profile`,
          text: "Check out this profile!",
          url: url,
        })
        .catch(() => {
          navigator.clipboard.writeText(url).then(() => {
            setShareMessage("Profile URL copied to clipboard");
            setTimeout(() => {
              setShareMessage("");
            }, 2000);
          });
        });
    } else {
      navigator.clipboard.writeText(url).then(() => {
        setShareMessage("Profile URL copied to clipboard");
        setTimeout(() => {
          setShareMessage("");
        }, 2000);
      });
    }
  };

  return (
    <>
      {!isFullScreenPreview && (
        <div className={`yourshop_header ${showHeader ? "show" : "hide"}`}>
          <div className="bio-edit-profile-username">
            {showHeader && <div className="bio">{userData.bio}</div>}
            <div className="profile-username">
              {showHeader && (
                <div className="yourshop_profile">
                  <div className="profile">
                    {userData.profile_picture && !imageFailed ? (
                      <img
                        src={userData.profile_picture}
                        alt={userData.first_name}
                        className="profile-picture"
                        onError={() => setImageFailed(true)}
                      />
                    ) : (
                      <div className="profile-picture no-profile-picture">
                        {userData.first_name
                          ? userData.first_name.slice(0, 1).toUpperCase()
                          : "?"}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="yourshop_username_display">
                {userData.username}
                <FaShare aria-hidden="true" onClick={handleShareClick} />
              </div>
            </div>
            {showHeader && (
              <div className="edit-profile">
                {currentUserData.username &&
                currentUserData.username === username ? (
                  <button onClick={handleEditProfile}>Edit Profile</button>
                ) : currentUserData.username && isSubscribed !== null ? (
                  <button
                    className={
                      isSubscribed ? "subscribed-button" : "subscribe-button"
                    }
                    onClick={handleSubscribeClick}
                    disabled={loading}
                  >
                    {loading
                      ? "Loading..."
                      : isSubscribed
                      ? "Subscribed"
                      : "Subscribe"}
                  </button>
                ) : (
                  <button className="loading-button" disabled>
                    Loading...
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="shareMessageContainer">
            <div className="shareMessage">
              {shareMessage && (
                <div className="share-message">{shareMessage}</div>
              )}
            </div>
          </div>

          <div className="shop-nav-notability">
            <div
              className={`yourshop-nav ${
                showHeader ? "" : "yourshop-nav-hide"
              }`}
            >
              <div>
                <Link
                  to={`/${username}/shopPosts`}
                  className={
                    location.pathname === `/${username}/shopPosts`
                      ? "active-section-yourshop"
                      : ""
                  }
                >
                  <BiGridAlt
                    size={20}
                    color="currentColor"
                    className="bi bi-grid-3x3"
                  />
                </Link>
              </div>
              <div>
                <Link
                  to={`/${username}/soldOut`}
                  className={
                    location.pathname === `/${username}/soldOut`
                      ? "active-section-yourshop"
                      : ""
                  }
                >
                  <BsBookmarkX size={20} />
                </Link>
              </div>
              {currentUserData.username &&
              currentUserData.username === username ? (
                <div>
                  <Link
                    to={`/${username}/Orders`}
                    className={`${
                      location.pathname === `/${username}/Orders`
                        ? "active-section-yourshop"
                        : ""
                    } shop-orders`}
                  >
                    <BsCartPlus size={20} />
                    {paidCount > 0 && (
                      <span className="notification-badge-paid-count">
                        {paidCount}
                      </span>
                    )}
                  </Link>
                </div>
              ) : null}
            </div>
            {showHeader && (
              <div className="confirm_notability">
                {userData.confirm_notability &&
                  userData.confirm_notability.map((item, index) => (
                    <p key={index}>#{item.name}</p>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

YourShopHeader.propTypes = {
  username: PropTypes.string.isRequired,
  postStarContainerRef: PropTypes.object.isRequired,
};

export default YourShopHeader;


// ChatAndMusicPostsContext.jsx
import React, { createContext, useState, useContext, useRef, useEffect, useCallback} from 'react';
import { debounce } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import  { PinnedSelectedUserContext } from '../StarNavigationBar/pinnedSelectedUserContext';
import  { FooterContext } from '../StarNavigationBar/FooterContext';
import { YourShopHeaderPostContext } from './YourShopHeaderPostContext';
import { BiImage } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
const ChatAndMusicPostsContext = createContext();

// Initializing the Set outside the fetchedMessageIds function, to update the cmessages ids and keep them across hook changes
const fetchedMessageIds = new Set();

//Clearing the fetchedMessageIds
const clearFetchedMessageIds = () => {
  fetchedMessageIds.clear();
};

export const ChatAndMusicPostsProvider = ({ children }) => {
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [currentRecipient, setCurrentRecipient] = useState(null);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [userData, setUserData] = useState({});
  const [unreadMessagesCount, setUnreadMessagesCount] = useState({});
  const [lastReadMessage, setLastReadMessage] = useState({});
  const [firstUnreadMessage, setFirstUnreadMessage] = useState({});
  const [chatOpenStatus, setChatOpenStatus] = useState(false);
  const [pinnedConversations, setPinnedConversations] = useState([]);
  const [lastMessagePreviews, setLastMessagePreviews] = useState({});
  const [messages, setMessages] = useState([]);
  const [imageFailed, setImageFailed] = useState(false);
  const [showClearChatModal, setShowClearChatModal] = useState(false);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const lastMessageRef = useRef(null);
  const [unreadLabel, setUnreadLabel] = useState({});
  const currentConversationIdRef = useRef();
  const [messageImagePreview, setMessageImagePreview] = useState(null);
  const [previewedMessageId, setPreviewedMessageId] = useState(null);
  const [previewedMessageTempId, setPreviewedMessageTempId] = useState(null);
  const [isCurrentConversationPinned, setIsCurrentConversationPinned] =
    useState(false);
  const { selectedUser, setSelectedUser } = useContext(
    PinnedSelectedUserContext
  );
  const { showFooter, setShowFooter } = useContext(FooterContext);
  const socketRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const socketRequestRef = useRef(null);
  const [messagePage, setMessagePage] = useState(1);
  const [messageScrollLoading, setMessageScrollLoading] = useState(false);
  const [fetchedMessagesCount, setFetchedMessagesCount] = useState(0);
  const [isMessageFetching, setMessageIsFetching] = useState(false); //help to prevent scrolling when messages are fetched
  const chatContainerRef = useRef(null);
  const [showActionMessageError, setShowActionMessageError] = useState(false);

  const [messagePageHasMore, setMessagePageHasMore] = useState(true);
  const [showChat, setShowChat] = useState(() => {
    const storedShowChat = localStorage.getItem("showChat") === "true";
    return storedShowChat;
  });

  const [chatState, setChatState] = useState(() => {
    const storedChatState =
      localStorage.getItem("chatState") === "true";
    return storedChatState;
  });

  const [currentMusicPost, setCurrentMusicPost] = useState(() => {
    const storedMusicPost = localStorage.getItem("currentMusicPost");
    return storedMusicPost ? JSON.parse(storedMusicPost) : null;
  });

  const [showRequestMessage, setShowRequestMessage] = useState(() => {
    const storedshowRequestMessage =
      localStorage.getItem("showRequestMessage") === "true";
    return storedshowRequestMessage
      ? JSON.parse(storedshowRequestMessage)
      : false;
  });

  const [receivedRequests, setReceivedRequests] = useState([]);
  const [showRequestAcceptedMessage, setShowRequestAcceptedMessage] = useState(
    []
  );
  const [newRequestCount, setNewRequestCount] = useState(() => {
    const storedNewRequestCount = localStorage.getItem("newRequestCount");
    return storedNewRequestCount ? JSON.parse(storedNewRequestCount) : {};
  });

  const [showRequestSentMessage, setShowRequestSentMessage] = useState(false);
  const [showRequestRejectedMessage, setShowRequestRejectedMessage] = useState(
    []
  );
  const [showRequestCanceledMessage, setShowRequestCanceledMessage] = useState(
    []
  );
  const [
    showRequestClosedBySenderMessage,
    setShowRequestClosedBySenderMessage,
  ] = useState([]);
  const [
    showSessionLeftByRecipientMessage,
    setShowSessionLeftByRecipientMessage,
  ] = useState([]);
  const [postRequestMusic, setPostRequestMusic] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [isNestedRoute, setIsNestedRoute] = useState(false);

  // Detect screen size changes
  const isWideScreenQuery = useMediaQuery({ query: "(min-width: 991.98px)" });

  useEffect(() => {
    setIsWideScreen(isWideScreenQuery);
  }, [isWideScreenQuery]);

 useEffect(() => {
   /**
    * Custom hook logic to manage navigation based on screen size (wide vs small screens).
    * It ensures that the user is automatically redirected to the appropriate route
    * when transitioning between small and wide screen sizes.
    */
   const nestedRoutes = [
     "/home/start-chat",
     "/home/music",
     "/home/upload",
     "/home/you-own",
     "/home/subscribed",
     "/home/report",
     "/home/gift",
     "/home/notifications",
     "/home/wallet",
     "/home/account",
     "/home/subscriber",
     "/home/all-statics",
   ];

   const flatRoutes = nestedRoutes.map((path) => path.replace("/home", ""));
   const currentPath = location.pathname;

   // Ignore the specific music sharing route
   const isMusicShareRoute = /^\/music\/[^/]+\/[^/]+$/.test(currentPath);
   if (isMusicShareRoute) return;

   // Determine if the current path is a nested route
   setIsNestedRoute(nestedRoutes.some((path) => currentPath.startsWith(path)));

   // Transition from small to wide screens
   if (
     isWideScreen &&
     flatRoutes.some((path) => currentPath.startsWith(path))
   ) {
     const matchingRoute = flatRoutes.find((path) =>
       currentPath.startsWith(path)
     );
     if (matchingRoute) {
       const newPath = `/home${matchingRoute}`;
       navigate(newPath, { replace: true });
     }
   }

   // Transition from wide to small screens
   if (!isWideScreen && currentPath.startsWith("/home")) {
     const matchingRoute = nestedRoutes.find((path) =>
       currentPath.startsWith(path)
     );
     if (matchingRoute) {
       const newPath = matchingRoute.replace("/home", "");
       navigate(newPath, { replace: true });
     }
   }
 }, [isWideScreen, location.pathname, navigate]);

 

  //Hook to fetch userData from server
  useEffect(() => {
    fetch("/api/get_user_profile_data/")
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setImageFailed(false);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  const toggleChat = () => {
    setTimeout(() => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }, 100);

    // Set selectedUser to null before fetching new user data
    setSelectedUser(null);

    setShowFooter(!showFooter);
    localStorage.setItem("showFooter", JSON.stringify(!showFooter));

    setShowClearChatModal(false);
    setShowKebabMenu(false);
  };

  // Fetch state from localStorage when the component mounts
  useEffect(() => {
    const storedConversationId = localStorage.getItem("currentConversationId");
    const storedCurrentRecipient = localStorage.getItem("currentRecipient");
    const storedSelectedUser = localStorage.getItem("selectedUser");
    const storedPinnedConversations = localStorage.getItem(
      "pinnedConversations"
    );

    if (storedConversationId && storedSelectedUser && storedCurrentRecipient) {
      setCurrentConversationId(storedConversationId);
      setCurrentRecipient(storedCurrentRecipient);
      setSelectedUser(JSON.parse(storedSelectedUser));
      fetchConversationData(
        storedConversationId,
        JSON.parse(storedSelectedUser)?.username
      );
    }

    if (storedPinnedConversations) {
      setPinnedConversations(JSON.parse(storedPinnedConversations));
    }
  }, []);

  // Save state to localStorage when it changes
  useEffect(() => {
    if (currentConversationId) {
      localStorage.setItem("currentConversationId", currentConversationId);
    }
    if (currentRecipient) {
      localStorage.setItem("currentRecipient", currentRecipient);
    }
    if (selectedUser) {
      localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
    }
    if (pinnedConversations) {
      localStorage.setItem(
        "pinnedConversations",
        JSON.stringify(pinnedConversations)
      );
    }
  }, [
    currentConversationId,
    currentRecipient,
    selectedUser,
    pinnedConversations,
  ]);

  useEffect(() => {
    if (currentMusicPost) {
      localStorage.setItem(
        "currentMusicPost",
        JSON.stringify(currentMusicPost)
      );
      localStorage.setItem(
        "showRequestMessage",
        JSON.stringify(showRequestMessage)
      );
    } else {
      localStorage.removeItem("currentMusicPost");
      localStorage.removeItem("showRequestMessage");
    }
  }, [currentMusicPost, showRequestMessage]);

  // Save newRequestCount to localStorage
  useEffect(() => {
    localStorage.setItem("newRequestCount", JSON.stringify(newRequestCount));
  }, [newRequestCount]);

  // Updated on request count changes
  useEffect(() => {
    localStorage.setItem("newRequestCount", JSON.stringify(newRequestCount));
  }, [newRequestCount]);

  // Function to update chat open status to make it true or false
  const updateChatOpenStatus = async (conversationId, isOpen) => {
    if (!conversationId) {
      console.warn("No conversationId provided.");
      return;
    }
    try {
      const response = await fetch(
        `/api/messages/${conversationId}/update_chat_open_status/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({ chat_open: isOpen }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        return data.chat_open;
      } else {
        const errorData = await response.json();
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const ImageIcon = () => <BiImage size={14} />;

  const updateLastMessagePreview = (message, conversationId) => {
    let preview = "";
    if (message.image && message.text) {
      preview =
        message.text.length > 50
          ? `${message.text.slice(0, 50)}...`
          : message.text;
      preview = (
        <>
          <ImageIcon /> {preview}
        </>
      );
    } else if (message.image) {
      preview = (
        <>
          <ImageIcon /> photo
        </>
      );
    } else if (message.text) {
      preview =
        message.text.length > 50
          ? `${message.text.slice(0, 50)}...`
          : message.text;
    }
    setLastMessagePreviews((prevPreviews) => ({
      ...prevPreviews,
      [conversationId]: preview,
    }));
  };

  // Update fetchLastMessage to use updateLastMessagePreview
  const fetchLastMessage = (conversationId) => {
    fetch(
      `/api/messages/?conversationId=${conversationId}&ordering=-id&page_size=1`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results.length > 0) {
          const lastMessage = data.results[0];
          updateLastMessagePreview(lastMessage, conversationId);
        } else {
          setLastMessagePreviews((prevPreviews) => ({
            ...prevPreviews,
            [conversationId]: "",
          }));
        }
      })
      .catch((error) => console.error("Error fetching last message:", error));
  };

  useEffect(() => {
    // Fetch the last message for the current conversation when the component mounts
    if (currentConversationIdRef.current) {
      fetchLastMessage(currentConversationIdRef.current);
    }
  }, []);

  // Update the ref and toggleChat whenever currentConversationId changes
  useEffect(() => {
    currentConversationIdRef.current = currentConversationId;
  }, [currentConversationId]);

  const handlePinConversation = (user, conversationId) => {
    // Determine if the conversation is currently pinned
    const isPinned = pinnedConversations.some(
      (conversation) =>
        conversation?.pinnedConversation?.conversation?.conversationId ===
        conversationId
    );

    // Prevent user from pinning their own conversation
    if (user.username === userData.username) {
      setShowActionMessageError(true);
      setTimeout(() => setShowActionMessageError(false), 3000);
      return;
    }

    // Optimistically set the state
    setIsCurrentConversationPinned(!isPinned);

    // Determine the API endpoint and method based on the pin state
    const apiEndpoint = `/api/pinned-conversations/${
      user.username
    }/${conversationId}/${isPinned ? "unpin" : "pin"}/`;
    const method = isPinned ? "DELETE" : "PATCH";

    fetch(apiEndpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({ pinned: !isPinned }), // Set pinned state based on pin parameter
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to pin/unpin conversation");
        }
        return response.json();
      })
      .then((data) => {
        // Update pinned state in the UI
        let updatedPinnedConversations;
        if (!isPinned) {
          // If pinning, add the conversation to the pinnedConversations array
          updatedPinnedConversations = [
            ...pinnedConversations,
            { conversationId, selectedUser },
          ];
        } else {
          // If unpinning, remove the conversation from the pinnedConversations array
          updatedPinnedConversations = pinnedConversations.filter(
            (conversation) =>
              conversation?.pinnedConversation?.conversation?.conversationId !==
              conversationId
          );
        }
        setPinnedConversations(updatedPinnedConversations);

        // Fetch the pinned conversations
        fetch(`/api/pinned-conversations/${userData.username}/`)
          .then((response) => response.json())
          .then((data) => {
            setPinnedConversations(data);
            // Fetch the last message for each conversation
            data.forEach((pinnedConversation) => {
              const conversationId =
                pinnedConversation?.pinnedConversation?.conversation
                  ?.conversationId;
              if (conversationId) {
                fetchLastMessage(conversationId);
              }
            });
          })
          .catch((error) =>
            console.error("Error fetching pinned conversations:", error)
          );
      })
      .catch((error) => {
        console.error("Error pinning/unpinning conversation:", error);
        // Revert the state if the request fails
        setIsCurrentConversationPinned(isPinned);
      });
  };

  // Hook to update isCurrentConversationPinned
  useEffect(() => {
    const isPinned = pinnedConversations.some(
      (conversation) =>
        conversation?.pinnedConversation?.conversation?.conversationId ===
        currentConversationId
    );
    setIsCurrentConversationPinned(isPinned);
  }, [currentConversationId]);

  //Hook to fetch pinned-conversation
  useEffect(() => {
    if (userData.username) {
      fetch(`/api/pinned-conversations/${userData.username}/`)
        .then((response) => response.json())
        .then((data) => {
          setPinnedConversations(data);
          // Fetch the last message for each conversation
          data.forEach((pinnedConversation) => {
            const conversationId =
              pinnedConversation?.pinnedConversation?.conversation
                ?.conversationId;
            if (conversationId) {
              fetchLastMessage(conversationId);
            }
          });
        })
        .catch((error) =>
          console.error("Error fetching pinned conversations:", error)
        );
    }
  }, [userData]);

  // Fetch pinned conversations and update state
  const fetchPinnedConversations = async () => {
    if (!userData?.username) {
      console.warn("User data not available yet");
      return;
    }

    try {
      const response = await fetch(
        `/api/pinned-conversations/${userData.username}/`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setPinnedConversations(data);

        // Fetch the last message for each conversation
        data.forEach((pinnedConversation) => {
          const conversationId =
            pinnedConversation?.pinnedConversation?.conversation
              ?.conversationId;
          if (conversationId) {
            fetchLastMessage(conversationId);
          }
        });
      } else {
      }
    } catch (error) {}
  };

  // UseEffect hook to listen for changes in the messages state
  useEffect(() => {
    if (messages.length === 0) return;

    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      fetchPinnedConversations();
    }

    // Fetching unreadMessageCount and firstUnreadMessage whenever message changes
    fetchUnreadMessagesDataAll();

    // Fetching unreadLabel whenever message changes
    fetchAllUnreadLabels();
  }, [messages]);

  // Function to handle Received Messages
  const handleMessage = async (e) => {
    const data = JSON.parse(e.data);

    // Handle temporary message ID acknowledgment (e.g., after saving on the server)
    if (data.acknowledged_temp_id) {
      updateMessageInUI(data.acknowledged_temp_id, {
        isLoading: false,
        isError: false,
      });
      return;
    }

    // Handle received acknowledgment from the server
    if (data.type === "received_acknowledgment") {
      const { message_id, temp_id, conversationId } = data;
      updateMessageInUI(temp_id || message_id, { received: true });
      return;
    }

    // Handle seen acknowledgment
    if (data.type === "seen_acknowledgment") {
      const { message_id, temp_id, conversationId } = data;
      updateMessageInUI(temp_id || message_id, { seen: true });
      return;
    }

    // Handle incoming new messages
    const { message } = data;
    if (message) {
      await handleNewMessage(message);
    }
  };

  // Function to handle new messages
  const handleNewMessage = async (message) => {
    // Manage unread messages if the conversation is not active
    if (
      message.conversationId !== currentConversationIdRef.current ||
      !chatState
    ) {
      handleUnreadMessages(message);
    }

    // Display the message in UI if it's part of the active conversation
    if (message.conversationId === currentConversationIdRef.current) {
      setMessages((prevMessages) => [...prevMessages, message]);
    }

    // Fetch any additional server-provided updates for this message
    const updatedMessageData = await fetchUpdatedMessageData(message.temp_id);
    updateMessageInUI(message.temp_id, updatedMessageData);

    // Handle new conversation pinning if required
    if (message.conversation && message.conversation.is_new) {
      await handleNewConversationPinning(message);
    }

    // Update message previews
    updateMessagePreview(message);

    // Fetch and update pinned conversations
    fetchPinnedConversations();
  };

  // Manage unread messages
  const handleUnreadMessages = (message) => {
    setUnreadMessagesCount((prevCount) => ({
      ...prevCount,
      [message.conversationId]: (prevCount[message.conversationId] || 0) + 1,
    }));

    setFirstUnreadMessage((prevFirstUnread) => {
      if (!prevFirstUnread[message.conversationId]) {
        return {
          ...prevFirstUnread,
          [message.conversationId]: { temp_id: message.temp_id },
        };
      }
      return prevFirstUnread;
    });

    // Update unread labels with server data
    fetchAllUnreadLabels().then((updatedUnreadLabels) =>
      setUnreadLabel((prevLabel) => ({ ...prevLabel, ...updatedUnreadLabels }))
    );
  };

  // Update a specific message in the UI
  const updateMessageInUI = (messageId, updatedData = {}) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.temp_id === messageId || msg.id === messageId
          ? {
              ...msg,
              ...updatedData,
              isLoading: false,
              isError: false,
              isReceived:
                updatedData.received !== undefined
                  ? updatedData.received
                  : msg.isReceived || false,
              isSeen:
                updatedData.seen !== undefined
                  ? updatedData.seen
                  : msg.isSeen || false,
            }
          : msg
      )
    );
  };

  // Handle new conversation pinning logic
  const handleNewConversationPinning = async (message) => {
    const participantUsernames =
      message.conversation.participant_usernames.split("|");
    const selectedUsername = participantUsernames.find(
      (username) => username !== userData.username
    );

    try {
      const user = await fetch(`/api/users/${selectedUsername}/`).then((res) =>
        res.json()
      );
      setSelectedUser(user);
      handlePinConversation(user, message.conversationId);

      setPinnedConversations((prevPinnedConversations) => {
        let updatedPinnedConversations = prevPinnedConversations.map(
          (conversation) =>
            conversation?.pinnedConversation?.conversation?.conversationId ===
            message.conversationId
              ? {
                  ...conversation,
                  pinnedConversation: {
                    ...conversation.pinnedConversation,
                    last_message: message,
                  },
                  selectedUser: user,
                }
              : conversation
        );

        const updatedIndex = updatedPinnedConversations.findIndex(
          (conv) =>
            conv?.pinnedConversation?.conversation?.conversationId ===
            message.conversationId
        );

        if (updatedIndex !== -1) {
          const updatedConversation = updatedPinnedConversations[updatedIndex];
          updatedPinnedConversations = [
            updatedConversation,
            ...updatedPinnedConversations.filter(
              (_, idx) => idx !== updatedIndex
            ),
          ];
        }
        return updatedPinnedConversations;
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Update last message preview
  const updateMessagePreview = (message) => {
    let preview = "";
    if (message.image && message.text) {
      preview =
        message.text.length > 50
          ? `${message.text.slice(0, 50)}...`
          : message.text;
      preview = (
        <>
          <ImageIcon /> {preview}
        </>
      );
    } else if (message.image) {
      preview = (
        <>
          <ImageIcon /> photo
        </>
      );
    } else if (message.text) {
      preview =
        message.text.length > 50
          ? `${message.text.slice(0, 50)}...`
          : message.text;
    }

    setLastMessagePreviews((prevPreviews) => ({
      ...prevPreviews,
      [message.conversationId]: preview,
    }));
  };

  // Track reconnection status to avoid overlapping reconnections
  let isReconnecting = false;

  // Function to handle WebSocket error
  const handleWebsocketError = (e) => {
    console.error("WebSocket error:", e);
  };

  // Function to handle WebSocket close
  const handleWebsocketClose = (e) => {
    console.warn("WebSocket closed:", e.reason || "No reason provided.");
    if (!isReconnecting) reconnectWebSocket(); // Trigger reconnection if not already reconnecting
  };

  // Function to reconnect WebSocket
  const reconnectWebSocket = () => {
    isReconnecting = true;

    // Reset the reference and avoid duplicate reconnect attempts
    if (socketRef.current) {
      socketRef.current = null;
    }

    // Create a new WebSocket after a 5s delay
    setTimeout(() => {
      if (userData.username) {
        initializeWebSocket();
        isReconnecting = false; // Reconnection attempt complete
      }
    }, 5000);
  };

  // Function to initialize the WebSocket
  const initializeWebSocket = () => {
    // Initialize the WebSocket connection only if it doesn't exist
    if (socketRef.current) {
      console.warn("WebSocket already exists, skipping initialization.");
      return;
    }

    socketRef.current = new WebSocket(
      // `ws://localhost:8000/ws/chat/${userData.username}/`
      `${process.env.REACT_APP_WEBSOCKET_HOST}chat/${userData.username}/`
    );

    socketRef.current.onopen = () => {
      if (!socketRef.current) {
        console.error(
          "WebSocket reference is null during onopen. Skipping setup."
        );
        return;
      }
      socketRef.current.onmessage = handleMessage;
    };

    socketRef.current.onerror = handleWebsocketError;
    socketRef.current.onclose = handleWebsocketClose;
  };

  // React useEffect for WebSocket connection management
  useEffect(() => {
    if (userData.username && !socketRef.current) {
      initializeWebSocket(); // Initialize WebSocket connection
    }

    return () => {
      // Cleanup when component unmounts or userData.username changes
      if (socketRef.current) {
        socketRef.current.onclose = null; // Prevent triggering reconnection during cleanup
        socketRef.current.close(); // Close WebSocket connection
        socketRef.current = null; // Clear reference
      }
    };
  }, [userData.username, currentRecipient]);

  //handle for receiving music listening together request
  const handleTogetherListenRequest = async (event) => {
    const data = JSON.parse(event.data);

    if (data.type === "music_request") {
      setCurrentRequestId(data.tempRequestId);
      setReceivedRequests((prevRequests) => {
        if (!prevRequests.some((req) => req.id === data.id)) {
          const updatedRequest = {
            ...data,
            conversation: { conversationId: data.conversationId },
          };
          const updatedRequests = [...prevRequests, updatedRequest];
          return updatedRequests;
        }
        return prevRequests;
      });

      // Increment new request count
      setNewRequestCount((prevCount) => {
        const newCount = {
          ...prevCount,
          [data.conversationId]: (prevCount[data.conversationId] || 0) + 1,
        };
        return newCount;
      });

      // fetchReceivedRequests();
    } else if (data.type === "accepted_request") {
      const requestId = data.request_id;
      const conversationId = data.conversation_id;
      const recipient = data.recipient_username;

      setShowRequestAcceptedMessage((prevMessages) => {
        if (!prevMessages.some((msg) => msg.id === requestId)) {
          const updateAcceptedMessage = {
            id: requestId,
            conversation: { conversationId: conversationId },
            recipient_username: recipient,
            request_accepted: true,
          };
          const updatedMessages = [...prevMessages, updateAcceptedMessage];
          // Save to local storage
          localStorage.setItem(
            "showRequestAcceptedMessage",
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        }
        return prevMessages;
      });
    } else if (data.type === "rejected_request") {
      const requestId = data.request_id;
      const conversationId = data.conversation_id;
      const recipientUsername = data.recipient_username;

      setShowRequestRejectedMessage((prevMessages) => {
        if (!prevMessages.some((msg) => msg.id === requestId)) {
          const updateRejectedMessage = {
            id: requestId,
            conversation: { conversationId: conversationId },
            recipient_username: recipientUsername,
            closed_by_recipient: true,
          };
          const updatedMessages = [...prevMessages, updateRejectedMessage];
          // Save to local storage
          localStorage.setItem(
            "showRequestRejectedMessage",
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        }
        return prevMessages;
      });
    } else if (data.type === "request_canceled") {
      const requestId = data.request_id;
      const conversationId = data.conversation_id;
      const senderUsername = data.sender_username;
      const tempMusicId = data.temp_music_id;
      const tempRequestId = data.temp_request_id;

      setShowRequestCanceledMessage((prevMessages) => {
        if (!prevMessages.some((msg) => msg.id === requestId)) {
          const UpdatedRequestCanceled = {
            id: requestId,
            conversation: { conversationId: conversationId },
            sender_username: senderUsername,
            closed: true,
            closed_by_sender: true,
            temp_music_id: tempMusicId,
            temp_request_id: tempRequestId,
          };
          const updatedMessages = [...prevMessages, UpdatedRequestCanceled];
          // Save to local storage
          localStorage.setItem(
            "showRequestCanceledMessage",
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        }
        return prevMessages;
      });

      //fetchReceivedRequests();
      setReceivedRequests((prevRequests) =>
        prevRequests.filter((msg) => msg.id !== data.request_id)
      );
      setPostRequestMusic((prevMusic) =>
        prevMusic.filter(
          (music) =>
            !(
              music.tempMusicId === tempMusicId &&
              music.tempRequestId === tempRequestId
            )
        )
      );

      // Decrement request count on cancellation
      setNewRequestCount((prevCount) => {
        const newCount = { ...prevCount };
        if (newCount[conversationId]) {
          newCount[conversationId]--;
          if (newCount[conversationId] <= 0) {
            delete newCount[conversationId];
          }
        }
        return newCount;
      });

      // setShowRequestCanceledMessage(prevMessages => [...prevMessages, data]);
    } else if (data.type === "request_canceled_acknowledged") {
      const requestId = data.request_id;
      const tempMusicId = data.temp_music_id;
      const tempRequestId = data.temp_request_id;

      // fetchReceivedRequests();
      setPostRequestMusic((prevMusic) =>
        prevMusic.filter(
          (music) =>
            !(
              music.tempMusicId === tempMusicId &&
              music.tempRequestId === tempRequestId
            )
        )
      );
    } else if (data.type === "request_closed_by_sender") {
      const requestId = data.request_id;
      const conversationId = data.conversation_id;
      const senderUsername = data.sender_username;
      const tempMusicId = data.temp_music_id;
      const tempRequestId = data.temp_request_id;

      setShowRequestClosedBySenderMessage((prevMessages) => {
        if (!prevMessages.some((msg) => msg.id === requestId)) {
          const UpdatedClosedBySender = {
            id: requestId,
            conversation: { conversationId: conversationId },
            sender_username: senderUsername,
            request_accepted: true,
            closed_by_sender: true,
            temp_music_id: tempMusicId,
            temp_request_id: tempRequestId,
          };
          const updatedMessages = [...prevMessages, UpdatedClosedBySender];
          // Save to local storage
          localStorage.setItem(
            "showRequestClosedBySenderMessage",
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        }
        return prevMessages;
      });

      // fetchReceivedRequests();
      setPostRequestMusic((prevMusic) =>
        prevMusic.filter(
          (music) =>
            !(
              music.tempMusicId === tempMusicId &&
              music.tempRequestId === tempRequestId
            )
        )
      );
    } else if (data.type === "request_closed_by_sender_acknowledged") {
      const requestId = data.request_id;
      const tempMusicId = data.temp_music_id;
      const tempRequestId = data.temp_request_id;

      //  fetchReceivedRequests();
      setPostRequestMusic((prevMusic) =>
        prevMusic.filter(
          (music) =>
            !(
              music.tempMusicId === tempMusicId &&
              music.tempRequestId === tempRequestId
            )
        )
      );
    } else if (data.type === "session_left_by_recipient") {
      const requestId = data.request_id;
      const conversationId = data.conversation_id;
      const recipientUsername = data.recipient_username;
      const tempMusicId = data.temp_music_id;
      const tempRequestId = data.temp_request_id;

      setShowSessionLeftByRecipientMessage((prevMessages) => {
        if (!prevMessages.some((msg) => msg.id === requestId)) {
          const updatedSessionLeftByRecipient = {
            id: requestId,
            conversation: { conversationId: conversationId },
            request_accepted: true,
            closed_by_recipient: true,
            recipient_username: recipientUsername,
            temp_music_id: tempMusicId,
            temp_request_id: tempRequestId,
          };
          const updatedMessages = [
            ...prevMessages,
            updatedSessionLeftByRecipient,
          ];
          // Save to local storage
          localStorage.setItem(
            "showSessionLeftByRecipientMessage",
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        }
        return prevMessages;
      });
    } else if (data.type === "session_left_by_recipient_acknowledged") {
      const requestId = data.request_id;
      const tempMusicId = data.temp_music_id;
      const tempRequestId = data.temp_request_id;

      //fetchReceivedRequests();
      setPostRequestMusic((prevMusic) =>
        prevMusic.filter(
          (music) =>
            !(
              music.tempMusicId === tempMusicId &&
              music.tempRequestId === tempRequestId
            )
        )
      );
    }
  };
  useEffect(() => {
    if (userData.username && !socketRequestRef.current) {
      socketRequestRef.current = new WebSocket(
        // `ws://localhost:8000/ws/music_request/${userData.username}/`
        `${process.env.REACT_APP_WEBSOCKET_HOST}music_request/${userData.username}/`
      );
    }

    if (socketRequestRef.current) {
      socketRequestRef.current.onopen = () => {
        socketRequestRef.current.onmessage = handleTogetherListenRequest;
      };
      socketRequestRef.current.onerror = handleWebsocketError;
    }
    return () => {
      if (socketRequestRef.current && !userData.username) {
        socketRequestRef.current.close();
      }
    };
  }, [userData, currentRecipient]);

  // Function to fetch unread messages data for all conversations
  const fetchUnreadMessagesDataAll = async () => {
    try {
      const response = await fetch(`/api/messages/unread_data_all/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const data = await response.json();
      setUnreadMessagesCount((prevState) => {
        const newUnreadCount = { ...prevState };
        data.forEach((item) => {
          newUnreadCount[item.conversation] = item.unread_messages_count;
        });
        return newUnreadCount;
      });

      setFirstUnreadMessage((prevState) => {
        const newFirstUnread = { ...prevState };
        data.forEach((item) => {
          newFirstUnread[item.conversation] = item.first_unread_message;
        });
        return newFirstUnread;
      });
    } catch (error) {
      console.error("Error fetching unread messages data:", error);
    }
  };

  // Function to fetch unread labels for a conversation
  const fetchAllUnreadLabels = async () => {
    try {
      const response = await fetch(`/api/messages/get_all_unread_labels/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const data = await response.json();

      // Update the states for each conversation
      for (let conversationId in data) {
        setUnreadLabel((prevLabel) => {
          const newLabel = {
            ...prevLabel,
            [conversationId]: data[conversationId],
          };
          return newLabel;
        });
      }
    } catch (error) {
      console.error("Error fetching all unread labels:", error);
    }
  };

  // Call fetchUnreadMessagesDataAll manually when the page is first loaded
  useEffect(() => {
    fetchUnreadMessagesDataAll();
    fetchAllUnreadLabels();
  }, []);

  // Call fetchUnreadMessagesDataAll manually when a user switches between different conversations
  useEffect(() => {
    if (currentConversationId) {
      fetchUnreadMessagesDataAll();
      fetchAllUnreadLabels();
    }
  }, [currentConversationId]);

  //Function for updating messages
  const fetchUpdatedMessageData = async (tempId) => {
    // Send a GET request to the new endpoint to fetch the updated message data
    const response = await fetch(
      `/api/messages/get_message/?temp_id=${tempId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      }
    ).catch((error) => console.error("Error:", error));

    if (response.ok) {
      const updatedMessageData = await response.json();

      //Find the message with the corresponding tempId and update it with the server data
      setMessages((prevMessages) => {
        return prevMessages.map((msg) => {
          if (msg.temp_id === tempId) {
            return { ...msg, ...updatedMessageData, isLoading: false };
          }
          return msg;
        });
      });
    } else {
      console.error("Failed to fetch updated message data");
    }
  };

  const handleMessageScroll = useCallback(
    debounce((e) => {
      const { scrollTop } = e.target;
      if (scrollTop <= 5 && messagePageHasMore) {
        setMessageScrollLoading(true);
        setMessagePage((prevPage) => prevPage + 1);
      } else if (scrollTop <= 300 && messagePageHasMore) {
        setMessagePage((prevPage) => prevPage + 1);
      }
    }, 300),
    [messagePageHasMore]
  );
  // Function to fetch messages
  const fetchConversationData = async (conversationId, username) => {
    // Fetch the chat open status from the server to display UnreadLabel or not
    fetch(
      `/api/messages/${conversationId}/get_chat_open_status/?t=${new Date().getTime()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChatOpenStatus(data.chat_open);
      })
      .catch((error) =>
        console.error("Error fetching chat open status:", error)
      );

    // Preserve the current scroll position and the height of the chat container
    const currentScrollTop = chatContainerRef.current?.scrollTop ?? 0;
    const currentScrollHeight = chatContainerRef.current?.scrollHeight ?? 0;

    // Fetch the messages for the conversation with the selected user
    setMessageScrollLoading(true);
    try {
      const response = await fetch(
        `/api/messages/?conversationId=${conversationId}&page=${messagePage}`
      );
      if (!response.ok) {
        if (response.status === 404) {
          setMessagePageHasMore(false);
          return;
        }
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data && data.results) {
        const newMessages = data.results.filter((msg) => {
          // Only add the message to newMessages if its ID is not in fetchedMessageIds
          if (!fetchedMessageIds.has(msg.id)) {
            fetchedMessageIds.add(msg.id);
            return true;
          }
          return false;
        });
        setMessages((prevMessages) => [
          ...newMessages.reverse(),
          ...prevMessages,
        ]);
        setMessagePageHasMore(data.next !== null);
        // Update fetchedMessagesCount which controlls scrolling to bottom when new messages are fetched
        setFetchedMessagesCount((prevCount) => prevCount + newMessages.length);
      }

      if (username) {
        fetch(`/api/users/${username}/`)
          .then((response) => response.json())
          .then((user) => {
            setSelectedUser(user);
          })
          .catch((error) => console.error("Error fetching user data:", error));
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setMessageScrollLoading(false);
    }

    if (!unreadMessagesCount[currentConversationId] && messages.length > 0) {
      setLastReadMessage((prevLastRead) => ({
        ...prevLastRead,
        [currentConversationId]: messages[messages.length - 1].id,
      }));
    }

    // After the new messages have been rendered, adjust the scroll position
    requestAnimationFrame(() => {
      const newScrollHeight = chatContainerRef.current?.scrollHeight ?? 0;
      const addedHeight = newScrollHeight - currentScrollHeight;
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = currentScrollTop + addedHeight;
      }
      setMessageIsFetching(false);
    });
  };

  // Function to get combined total of unread messages and new requests
  const getTotalCount = () => {
    let totalUnreadMessages = Object.values(unreadMessagesCount || {}).reduce(
      (sum, count) => sum + count,
      0
    );
    let totalNewRequests = Object.values(newRequestCount || {}).reduce(
      (sum, count) => sum + count,
      0
    );
    return totalUnreadMessages + totalNewRequests;
  };

  const totalCombinedCount = getTotalCount();

  // Function to mark messages as read
  const markMessagesAsRead = async (conversationId) => {
    if (conversationId) {
      try {
        await fetch(`/api/messages/${conversationId}/mark_as_read/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        });

        setUnreadMessagesCount((prevCount) => ({
          ...prevCount,
          [conversationId]: 0,
        }));

        // Clear unread label for current conversation
        setUnreadLabel((prevLabel) => ({
          ...prevLabel,
          [conversationId]: null,
        }));
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    }
  };

  return (
    <ChatAndMusicPostsContext.Provider
      value={{
        currentRecipient,
        setCurrentRecipient,
        currentConversationId,
        setCurrentConversationId,
        userData,
        setUserData,
        unreadMessagesCount,
        setUnreadMessagesCount,
        lastReadMessage,
        setLastReadMessage,
        firstUnreadMessage,
        setFirstUnreadMessage,
        chatOpenStatus,
        setChatOpenStatus,
        pinnedConversations,
        setPinnedConversations,
        lastMessagePreviews,
        setLastMessagePreviews,
        showChat,
        setShowChat,
        toggleChat,
        showKebabMenu,
        setShowKebabMenu,
        showClearChatModal,
        setShowClearChatModal,
        lastMessageRef,
        messages,
        setMessages,
        currentConversationIdRef,
        unreadLabel,
        setUnreadLabel,
        isCurrentConversationPinned,
        setIsCurrentConversationPinned,
        selectedUser,
        setSelectedUser,
        ImageIcon,
        updateLastMessagePreview,
        handlePinConversation,
        fetchLastMessage,
        imageFailed,
        setImageFailed,
        handleMessage,
        fetchAllUnreadLabels,
        fetchUnreadMessagesDataAll,
        fetchUpdatedMessageData,
        socketRef,
        socketRequestRef,
        handleWebsocketError,
        updateChatOpenStatus,
        fetchConversationData,
        fetchPinnedConversations,
        currentMusicPost,
        setCurrentMusicPost,
        showRequestMessage,
        setShowRequestMessage,
        receivedRequests,
        setReceivedRequests,
        showRequestAcceptedMessage,
        setShowRequestAcceptedMessage,
        newRequestCount,
        setNewRequestCount,
        showRequestSentMessage,
        setShowRequestSentMessage,
        showRequestRejectedMessage,
        setShowRequestRejectedMessage,
        showRequestCanceledMessage,
        setShowRequestCanceledMessage,
        showRequestClosedBySenderMessage,
        setShowRequestClosedBySenderMessage,
        showSessionLeftByRecipientMessage,
        setShowSessionLeftByRecipientMessage,
        postRequestMusic,
        setPostRequestMusic,
        currentRequestId,
        setCurrentRequestId,
        isLoading,
        setIsLoading,
        messagePageHasMore,
        messagePage,
        setMessagePage,
        messageScrollLoading,
        setMessageScrollLoading,
        clearFetchedMessageIds,
        chatContainerRef,
        handleMessageScroll,
        isMessageFetching,
        fetchedMessagesCount,
        totalCombinedCount,
        showActionMessageError,
        csrftoken,
        markMessagesAsRead,
        messageImagePreview,
        setMessageImagePreview,
        previewedMessageId,
        setPreviewedMessageId,
        previewedMessageTempId,
        setPreviewedMessageTempId,
        isWideScreen,
        isNestedRoute,
        chatState,
        setChatState,
      }}
    >
      {children}
    </ChatAndMusicPostsContext.Provider>
  );
};

export const useChatAndMusicPosts = () => useContext(ChatAndMusicPostsContext);



import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import "./YourShopOrders.css";
import useAlignSpinner from '../hooks/useAlignSpinner';

function YouShopOrders() {
  const {
    orders,
    setOrders,
    csrftoken,
    currentUserData,
    orderPage,
    setOrderPage,
    setPaidCount,
    hasMoreOrder,
    scrollLoadingOrder,
    isLoading,
    orderCounts,
    setOrderCounts,
    fetchOrders,
    postStarContainerRef,
  } = useContext(YourShopHeaderPostContext);

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [unauthorizedError, setUnauthorizedError] = useState(null);
  const [isHoldButtonText, setIsHoldButtonText] = useState("");
  const updatedCountsRef = useRef(null);
  const observerOrder = useRef();
  const statuses = ["Paid", "Packaged", "On Way", "Delivered", "Received"];

  useEffect(() => {
    fetchOrders(orderPage);
  }, [orderPage]);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await fetch(`/api/orders/get_order/?id=${orderId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch order details");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching order details:", error);
      return null;
    }
  };

  const handleOrderClick = async (orderId) => {
    if (selectedOrderId === orderId) {
      setSelectedOrderId(null);
    } else {
      const orderDetails = await fetchOrderDetails(orderId);
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, details: orderDetails } : order
        )
      );
      setSelectedOrderId(orderId);
    }
  };

  const handleOpenModalStatus = async (order) => {
    const orderDetails = await fetchOrderDetails(order.id);
    if (orderDetails) {
      setCurrentOrder(orderDetails);
      setIsStatusModalOpen(true);
      setUnauthorizedError(null);

      // Set the initial button text based on the current state
      if (orderDetails.funds_distributed) {
        setIsHoldButtonText("Fund Distributed");
      } else {
        setIsHoldButtonText(
          orderDetails.fund_onhold
            ? "Release Funds (Turn Off Hold)"
            : "Hold Funds"
        );
      }
    }
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
    setCurrentOrder(null);
    setUnauthorizedError(null);
    setIsHoldButtonText("");
    updatedCountsRef.current = null;
  };

  useEffect(() => {
    if (isStatusModalOpen) {
      const statusIndex = getStatusIndex(currentOrder);
      setCurrentOrder((prevOrder) => ({
        ...prevOrder,
        statusIndex: statusIndex,
      }));
    }
  }, [isStatusModalOpen]);

  const getStatusIndex = (order) => {
    // Use the existing statusIndex if it's already set
    if (order.statusIndex !== undefined) {
      return order.statusIndex;
    }

    // Otherwise, calculate it based on the original fields
    if (order.is_received) {
      return 4;
    } else if (order.is_delivered) {
      return 3;
    } else if (order.is_on_way) {
      return 2;
    } else if (order.is_packaged) {
      return 1;
    } else {
      return 0;
    }
  };

  const updateOrderCounts = (
    prevCounts,
    currentStatusIndex,
    newStatusIndex
  ) => {
    if (currentStatusIndex === newStatusIndex) return prevCounts;

    const newCounts = { ...prevCounts };

    // Adjust the counts based on status change
    if (currentStatusIndex >= 0) {
      decrementCount(newCounts, currentStatusIndex);
    }

    if (newStatusIndex >= 0) {
      incrementCount(newCounts, newStatusIndex);
    }

    return newCounts;
  };

  const decrementCount = (counts, statusIndex) => {
    switch (statusIndex) {
      case 0:
        counts.paidCount--;
        break;
      case 1:
        counts.packagedCount--;
        break;
      case 2:
        counts.onWayCount--;
        break;
      case 3:
        counts.deliveredCount--;
        break;
      case 4:
        counts.receivedCount--;
        break;
      default:
        break;
    }
  };

  const incrementCount = (counts, statusIndex) => {
    switch (statusIndex) {
      case 0:
        counts.paidCount++;
        break;
      case 1:
        counts.packagedCount++;
        break;
      case 2:
        counts.onWayCount++;
        break;
      case 3:
        counts.deliveredCount++;
        break;
      case 4:
        counts.receivedCount++;
        break;
      default:
        break;
    }
  };

  const updateOrderStatus = async (orderId, statusIndex) => {
    const updatedStatus = {
      is_packaged: statusIndex >= 1,
      is_on_way: statusIndex >= 2,
      is_delivered: statusIndex >= 3,
      is_received: statusIndex >= 4,
    };

    try {
      const response = await fetch(`/api/orders/${orderId}/update_status/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ status: updatedStatus }),
      });

      if (!response.ok) throw new Error("Failed to update order status");

      const updatedOrder = await response.json();
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === updatedOrder.id ? updatedOrder : order
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Handle fund onhold toggle
  const toggleFundOnHold = async (orderId) => {
    // Optimistically update the UI state
    const optimisticHold = isHoldButtonText === "Release Funds (Turn Off Hold)";
    setIsHoldButtonText(
      optimisticHold ? "Hold Funds" : "Release Funds (Turn Off Hold)"
    );

    try {
      const response = await fetch(`/api/orders/${orderId}/fund_onhold/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to toggle fund_onhold");
      }

      const updatedOrder = await response.json();

      // Update the orders list with the new fund_onhold state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId
            ? { ...order, fund_onhold: updatedOrder.fund_onhold }
            : order
        )
      );

      // Reflect the real server-side update in the button text
      setIsHoldButtonText(
        updatedOrder.fund_onhold
          ? "Release Funds (Turn Off Hold)"
          : "Hold Funds"
      );
    } catch (error) {
      console.error("Error toggling fund_onhold:", error);
      // Revert the button text if the request fails
      setIsHoldButtonText(
        optimisticHold ? "Release Funds (Turn Off Hold)" : "Hold Funds"
      );
    }
  };

  const handleStatusChange = async (newStatusIndex) => {
    if (!currentOrder) return;

    const currentStatusIndex = getStatusIndex(currentOrder);
    if (newStatusIndex === currentStatusIndex) return;

    const userRole = getUserRole(currentOrder);
    const isAuthorized = checkUserAuthorization(
      userRole,
      currentStatusIndex,
      newStatusIndex
    );

    if (!isAuthorized) {
      setUnauthorizedError(
        `You are not authorized to perform this action, ${currentUserData.username}.`
      );
      return;
    }

    setUnauthorizedError(null);

    setOrderCounts((prevCounts) => {
      const updatedCounts = updateOrderCounts(
        prevCounts,
        currentStatusIndex,
        newStatusIndex
      );
      return updatedCounts;
    });

    setCurrentOrder((prevOrder) => ({
      ...prevOrder,
      statusIndex: newStatusIndex,
    }));

    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === currentOrder.id
          ? { ...order, statusIndex: newStatusIndex }
          : order
      )
    );

    try {
      await updateOrderStatus(currentOrder.id, newStatusIndex);
      const updatedOrder = await fetchOrderDetails(currentOrder.id);
      if (updatedOrder) {
        setCurrentOrder(updatedOrder);
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      const rolledBackOrder = await fetchOrderDetails(currentOrder.id);
      if (rolledBackOrder) {
        setCurrentOrder(rolledBackOrder);
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === rolledBackOrder.id ? rolledBackOrder : order
          )
        );
        setOrderCounts((prevCounts) => {
          const rolledBackCounts = updateOrderCounts(
            prevCounts,
            newStatusIndex,
            currentStatusIndex
          );
          return rolledBackCounts;
        });
      }
    }
  };

  // useEffect to update the paidCount state
  useEffect(() => {
    setPaidCount(orderCounts.paidCount);
  }, [orderCounts]);

  const getUserRole = (order) => {
    // Ensure order.buyer and order.seller are defined
    if (!order.buyer || !order.seller) {
      console.error("Order buyer or seller is undefined");
      return null;
    }

    // Determine role dynamically based on order data
    if (currentUserData?.username === order.seller?.username) {
      return "seller";
    } else if (currentUserData?.username === order.buyer?.username) {
      return "buyer";
    } else if (currentUserData?.is_delivery) {
      return "delivery";
    }
    return null;
  };

  const checkUserAuthorization = (role, currentStatusIndex, newStatusIndex) => {
    if (role === "seller") {
      // Seller can move from "Paid" to "Packaged"
      return currentStatusIndex === 0 && newStatusIndex === 1;
    } else if (role === "delivery") {
      // Delivery personnel can move from "Packaged" to "On Way", and "On Way" to "Delivered"
      return (
        (currentStatusIndex === 0 && newStatusIndex === 1) ||
        (currentStatusIndex === 1 && newStatusIndex === 2) ||
        (currentStatusIndex === 2 && newStatusIndex === 3)
      );
    } else if (role === "buyer") {
      // Buyer can only confirm "Delivered" as "Received"
      return currentStatusIndex === 3 && newStatusIndex === 4;
    }
    return false;
  };

  const handleStatusLineClick = (e, index) => {
    handleStatusChange(index);
  };

  const handleDragEnd = (e) => {
    const lineRect = e.target.parentElement.getBoundingClientRect();
    const dragX = e.clientX - lineRect.left;
    const newIndex = Math.round(
      (dragX / lineRect.width) * (statuses.length - 1)
    );
    const currentStatusIndex = getStatusIndex(currentOrder);

    // Only update if the circle is moved to the exact next stage
    if (
      newIndex === currentStatusIndex + 1 ||
      newIndex === currentStatusIndex - 1
    ) {
      handleStatusChange(newIndex);
    }
  };

  const setCombinedOrderRef = (node, id) => {
    if (orders[orders.length - 1].id === id) {
      lastOrderElementRef(node);
    }
  };

  const lastOrderElementRef = useCallback(
    (node) => {
      if (scrollLoadingOrder) return;
      if (observerOrder.current) observerOrder.current.disconnect();
      observerOrder.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreOrder) {
          setOrderPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerOrder.current.observe(node);
    },
    [scrollLoadingOrder, hasMoreOrder]
  );

  const deliveryTypeOptions = [
    { value: "selfPickUp", label: "Self Pick-up" },
    { value: "bike", label: "Bike" },
    { value: "busFerry", label: "Bus or Ferry" },
    { value: "airplane", label: "Airplane" },
  ];

  //hook to align the spinner center horizontally
  useAlignSpinner(postStarContainerRef, ".loader-container");
  return (
    <div className="orders-container">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {orderCounts.totalOrders > 0 && (
            <div className="order-counts">
              <table className="order-counts-table">
                <tbody>
                  <tr>
                    <td>Paid:</td>
                    <td>{orderCounts.paidCount}</td>
                  </tr>
                  <tr>
                    <td>Packaged:</td>
                    <td>{orderCounts.packagedCount}</td>
                  </tr>
                  <tr>
                    <td>On Way:</td>
                    <td>{orderCounts.onWayCount}</td>
                  </tr>
                  <tr>
                    <td>Delivered:</td>
                    <td>{orderCounts.deliveredCount}</td>
                  </tr>
                  <tr>
                    <td>Received:</td>
                    <td>{orderCounts.receivedCount}</td>
                  </tr>
                  <tr>
                    <td className="total-orders">Total Orders:</td>
                    <td>{orderCounts.totalOrders}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {orders.length > 0 ? (
            orders.map((order) => {
              const deliveryTypeLabel =
                deliveryTypeOptions.find(
                  (option) => option.value === order.delivery_type
                )?.label || order.delivery_type;

              return (
                <div
                  key={order.id}
                  className={`order-item ${
                    selectedOrderId === order.id ? "expanded" : ""
                  }`}
                  onClick={() => handleOrderClick(order.id)}
                  ref={(node) => setCombinedOrderRef(node, order.id)}
                >
                  <div className="order-summary">
                    <div className="Order-status">
                      <h3 className="text-order">
                        Order {order.order_reference}{" "}
                        {order.product.product_code}
                      </h3>
                      <div
                        className="status-text"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModalStatus(order);
                        }}
                      >
                        Status:{" "}
                        <span
                          className={`status-label ${
                            getStatusIndex(order) >=
                            statuses.indexOf("Received")
                              ? "received"
                              : ""
                          }`}
                        >
                          {statuses[order.statusIndex || getStatusIndex(order)]}
                        </span>
                      </div>
                    </div>
                    <table className="table-order-summary">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Product name:</strong>
                          </td>
                          <td>{order.product?.product_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Buyer names:</strong>
                          </td>
                          <td>
                            {order.buyer.first_name} {order.buyer.last_name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {selectedOrderId === order.id && order.details && (
                    <div className="order-details">
                      <div className="media-container">
                        {order.details.product.images.length > 0 ? (
                          <img
                            src={order.details.product.images[0].image}
                            alt={order.details.product.product_name}
                            className="product-media"
                          />
                        ) : (
                          order.details.product.videos.length > 0 && (
                            <video controls className="product-media">
                              <source
                                src={order.details.product.videos[0].video}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          )
                        )}
                      </div>
                      <table className="order-details-table">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Seller:</strong>
                            </td>
                            <td>
                              {order.seller.first_name} {order.seller.last_name}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Seller Address:</strong>
                            </td>
                            <td>{order.seller_address}</td>
                          </tr>
                          {order.distance && (
                            <tr>
                              <td>
                                <strong>Distance:</strong>
                              </td>
                              <td>{order.distance}</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <strong>Delivery Type:</strong>
                            </td>
                            <td>{deliveryTypeLabel}</td>
                          </tr>
                          {order.buyer_address && (
                            <tr>
                              <td>
                                <strong>Buyer Address:</strong>
                              </td>
                              <td>{order.buyer_address}</td>
                            </tr>
                          )}
                          {order.zip_code && (
                            <tr>
                              <td>
                                <strong>Zip Code:</strong>
                              </td>
                              <td>{order.zip_code}</td>
                            </tr>
                          )}
                          {order.country && (
                            <tr>
                              <td>
                                <strong>Country:</strong>
                              </td>
                              <td>{order.country}</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <strong>Product Price:</strong>
                            </td>
                            <td>
                              {order.product_price} {order.product_currency}
                            </td>
                          </tr>
                          {order.delivery_cost && (
                            <tr>
                              <td>
                                <strong>Delivery Cost:</strong>
                              </td>
                              <td>{order.delivery_cost}</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <strong>Total Cost:</strong>
                            </td>
                            <td>{order.total_cost}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <p className="no-orders">No orders yet.</p>
          )}

          {/* Status Modal */}
          {isStatusModalOpen && currentOrder && (
            <div
              className="status-modal-overlay"
              onClick={handleCloseStatusModal}
            >
              <div
                className="status-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="status-modal-header">
                  <div className="empty-div-change-it"></div>
                  <h4 className="text-status-update">Order Status</h4>
                  <div className="empty-div-change-it"></div>
                </div>
                <div className="status-labels">
                  {statuses.map((status, index) => (
                    <div key={index} className="status-item">
                      <span
                        className={`status-label ${
                          getStatusIndex(currentOrder) >= index
                            ? index === statuses.indexOf("Received")
                              ? "completed received"
                              : "completed"
                            : ""
                        }`}
                      >
                        {status}
                      </span>
                    </div>
                  ))}
                </div>
                {unauthorizedError && (
                  <div className="error-message">
                    {" "}
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {unauthorizedError}
                  </div>
                )}
                <div className="status-tracker">
                  <div className="status-line-container">
                    <div className="status-line" />
                    <div
                      className="status-line-fill"
                      style={{
                        width: `${
                          (currentOrder?.statusIndex / (statuses.length - 1)) *
                          100
                        }%`,
                      }}
                    />

                    {statuses.map((status, index) => (
                      <div
                        key={index}
                        className="small-cycle"
                        style={{
                          left: `${(index / (statuses.length - 1)) * 100}%`,
                        }}
                        onClick={(e) => handleStatusLineClick(e, index)}
                      />
                    ))}

                    <div
                      className={`status-tracker-circle ${
                        statuses[currentOrder?.statusIndex] === "Received"
                          ? "received"
                          : ""
                      }`}
                      style={{
                        left: `${
                          (currentOrder?.statusIndex / (statuses.length - 1)) *
                          100
                        }%`,
                      }}
                      draggable
                      onDragEnd={handleDragEnd}
                    />
                  </div>
                </div>

                {/* Order Status Description Section */}
                <div className="order-status-description">
                  {getStatusIndex(currentOrder) ===
                    statuses.indexOf("Paid") && (
                    <p className="status-description">
                      The order has been placed and successfully paid.
                    </p>
                  )}
                  {getStatusIndex(currentOrder) ===
                    statuses.indexOf("Packaged") && (
                    <p className="status-description">
                      The order is prepared and ready for delivery.
                    </p>
                  )}
                  {getStatusIndex(currentOrder) ===
                    statuses.indexOf("On Way") && (
                    <p className="status-description">
                      The package is currently in transit and on its way to your
                      address.
                    </p>
                  )}
                  {getStatusIndex(currentOrder) ===
                    statuses.indexOf("Delivered") && (
                    <p className="status-description">
                      The package has been successfully delivered to your
                      address.
                    </p>
                  )}
                  {getStatusIndex(currentOrder) ===
                    statuses.indexOf("Received") && (
                    <p className="status-description">
                      Thank you for confirming receipt of your order! The
                      payment has now been released to the seller.
                    </p>
                  )}
                </div>

                {/* Button for fund hold, disabled if funds_distributed is true */}
                {(currentUserData?.is_order_admin ||
                  currentUserData?.is_superuser) && (
                  <button
                    className="hold-fund-button"
                    onClick={() => toggleFundOnHold(currentOrder.id)}
                    disabled={currentOrder?.funds_distributed}
                  >
                    {isHoldButtonText}
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="scroll-loader-spinner-container">
            {scrollLoadingOrder && (
              <div className="scroll-loader-spinner"></div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default YouShopOrders;



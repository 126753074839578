import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../StarHomeComponents/HeaderHomeComponents/HeaderHome.css';
import { BiArrowBack } from "react-icons/bi";
import { useMediaQuery } from 'react-responsive';

function ExploreShops() {
const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/home');
  };

  const isScreenWidthInRange = useMediaQuery({
        query: '(max-width: 499.98px)'
    });

  return (
    <div className="exploreshops-container">
      <header className="header-exploreshops">
        {isScreenWidthInRange && (
          <div className="exploreshops-Back-btn" onClick={handleBackToHome}>
            <BiArrowBack
              size={20}
              color="currentColor"
              className="bi bi-arrow-left"
            />
          </div>
        )}
        <div>Explore shops</div>
      </header>
      <div className="exploreshops-contents"></div>
    </div>
  );
}
export default ExploreShops;
import React, { createContext, useState, useEffect, useContext } from 'react';

const GetUserCurrentLocationContext = createContext();

export const GetUserCurrentLocationProvider = ({ children }) => {
  const [userLocation, setUserLocation] = useState(null);

  async function getUserLocation() {
    const storedLocation = localStorage.getItem('userLocation');
    let currentLocation = null;

    if (navigator.geolocation) {
      currentLocation = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          try {
            const response = await fetch("/api/get-google-maps-api-key/");
            const data = await response.json();
            const apiKey = data.api_key;

            const geocodeResponse = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
            ); 
            const geocodeData = await geocodeResponse.json();
            if (geocodeResponse.status === 200) {
              const location = geocodeData.results[0].address_components;
              const country = location.find((component) =>
                component.types.includes("country")
              ).short_name;
              const userLocation = { country, location };
              resolve(userLocation);
            } else {
              resolve({ country: "US", location: null }); // Default to the US if location is unavailable 
            }
          } catch (error) {
            resolve({ country: "US", location: null });  // Default to the US if location is unavailable
          }
        }, () => {
          resolve({ country: "US", location: null });  // Default to the US if geolocation fails
        });
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
      currentLocation = { country: "US", location: null };  // Default to the US if location is unavailable
    }

    if (storedLocation) {
      const parsedStoredLocation = JSON.parse(storedLocation);
      if (parsedStoredLocation.country !== currentLocation.country) {
        localStorage.setItem('userLocation', JSON.stringify(currentLocation));
        return currentLocation;
      }
      return parsedStoredLocation;
    } else {
      localStorage.setItem('userLocation', JSON.stringify(currentLocation));
      return currentLocation;
    }
  }

  useEffect(() => {
    async function fetchUserLocation() {
      const location = await getUserLocation();
      setUserLocation(location);
    }

    fetchUserLocation();
  }, []);

  return (
    <GetUserCurrentLocationContext.Provider value={{ userLocation }}>
      {children}
    </GetUserCurrentLocationContext.Provider>
  );
};

export const useUserLocation = () => useContext(GetUserCurrentLocationContext);

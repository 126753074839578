import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { YourShopHeaderPostContext } from '../../StarNavigationBar/YourShopHeaderPostContext';
import DeliveryFormModal from '../../DeliveryFormModal';
import ConfirmationModal from '../../ConfirmationModal';
import PaymentMethodModal from '../../PaymentMethodModal';
import NotificationModal from '../../NotificationModal';
import { useOwnModalsContext } from '../../OwnModalsContext';
import { BiArrowBack } from "react-icons/bi";
import DOMPurify from "dompurify";
import './HeaderHome.css';

function Notification() {
  const { setIsModalOpen } = useOwnModalsContext();

  const [acceptButtonText, setAcceptButtonText] = useState({});
  const [rejectButtonText, setRejectButtonText] = useState({});
  const [acceptError, setAcceptError] = useState("");
  const [productCondition, setProductCondition] = useState({});
  const [conditionError, setConditionError] = useState({});
  const [seenNotifications, setSeenNotifications] = useState(new Set());
  const notificationRefs = useRef([]);
  const observerNotifications = useRef();

  const {
    offerSocketRef,
    setSelectedOffer,
    currentUserData,
    fetchGetOffer,
    notifications,
    setNotifications,
    notificationPage,
    csrftoken,
    setNewNotificationCount,
    setNotificationPage,
    hasMoreNotifications,
    scrollLoadingNotifications,
    isAcceptedWithin24Hours,
    getRemainingTime,
    fetchNotifications,
    clearFetchedNotificationsIds,
  } = useContext(YourShopHeaderPostContext);
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/home");
    setNotificationPage(1);
    clearFetchedNotificationsIds();
    setNotifications([]);
  };

  const handleOpenModal = (offer) => {
    setSelectedOffer(offer);
    setIsModalOpen(true);
  };

  const handleAcceptOffer = async (offer) => {
    if (isAcceptedWithin24Hours(offer)) {
      setAcceptError(
        `You have already accepted an offer for this product within the last 24 hours, try again after ${offer.hours} ${offer.minutes}.`
      );
      return;
    }

    // Ensure product condition is entered
    if (!productCondition[offer.offer_id]) {
      setConditionError((prev) => ({
        ...prev,
        [offer.offer_id]:
          "Please describe the current condition of the product.",
      }));
      return;
    }

    const acceptMessage = {
      action: "accept_offer",
      offer_id: offer.offer_id,
      seller: offer.seller,
      buyer: offer.buyer,
      product_code: offer.product_code,
      offer_amount: offer.offer_amount,
      seller_address: offer.seller_address,
      product_name: offer.product_name,
      product_id: offer.product_id,
      currency: offer.currency,
      accepted_at: offer.accepted_at,
      product_condition: productCondition[offer.offer_id], 
    };
    offerSocketRef.current.send(JSON.stringify(acceptMessage));

    // Change button text to "Offer Accepted"
    setAcceptButtonText((prev) => ({
      ...prev,
      [offer.offer_id]: "Offer Accepted",
    }));

    // Fetch the updated offer after accepting
    const updatedOffer = await fetchGetOffer(offer.offer_id);
    if (updatedOffer) {
      setNotifications((prevNotifications) => [
        updatedOffer,
        ...prevNotifications.filter((o) => o.offer_id !== offer.offer_id),
      ]);
    }

    // Remove offer after 5 seconds
    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((o) => o.offer_id !== offer.offer_id)
      );
    }, 5000);
  };

  const handleRejectOffer = async (offer) => {
    const rejectMessage = {
      action: "reject_offer",
      offer_id: offer.offer_id,
      seller: offer.seller,
      buyer: offer.buyer,
      product_code: offer.product_code,
    };
    offerSocketRef.current.send(JSON.stringify(rejectMessage));

    // Change button text to "Offer Rejected"
    setRejectButtonText((prev) => ({
      ...prev,
      [offer.offer_id]: "Offer Rejected",
    }));

    // Fetch the updated offer after rejecting
    const updatedOffer = await fetchGetOffer(offer.offer_id);
    if (updatedOffer) {
      setNotifications((prevNotifications) => [
        updatedOffer,
        ...prevNotifications.filter((o) => o.offer_id !== offer.offer_id),
      ]);
    }

    // Remove offer after 5 seconds
    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((o) => o.offer_id !== offer.offer_id)
      );
    }, 5000);
  };

  useEffect(() => {
    // Update the accepted/pending offers every minute
    const interval = setInterval(() => {
      const updatedNotifications = notifications.map((notification) => {
        if (["accepted"].includes(notification.status)) {
          return {
            ...notification,
            isAcceptedWithin24Hours: isAcceptedWithin24Hours(notification),
            ...getRemainingTime(notification),
          };
        }
        return notification;
      });
      setNotifications(updatedNotifications);
    }, 60000);

    return () => clearInterval(interval);
  }, [notifications, isAcceptedWithin24Hours, getRemainingTime]);

  useEffect(() => {
    fetchNotifications(notificationPage);
  }, [notificationPage]);

  // Disable accept button if no product condition is entered
  const isAcceptDisabled = (offerId) => !productCondition[offerId];

  // Handle product condition change
  const handleConditionChange = (offerId, condition) => {
    setProductCondition((prev) => ({
      ...prev,
      [offerId]: condition,
    }));
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const notificationId = entry.target.getAttribute(
          "data-notification-id"
        );
        const notificationType = entry.target.getAttribute(
          "data-notification-type"
        );

        if (entry.isIntersecting) {
          const notification = notifications.find(
            (n) => (n.id || n.offer_id) === notificationId
          );
          const hasBeenSeen = notification?.seen_by.includes(
            currentUserData?.id
          );
          const alreadyMarked = seenNotifications.has(notificationId);

          if (!hasBeenSeen && !alreadyMarked) {
            // Set a timeout to mark as seen after 3 seconds
            const timeoutId = setTimeout(() => {
              markAsSeen(notificationId, notificationType, currentUserData?.id)
                .then(() => {
                  // Optimistically update notification state
                  updateNotificationState(notificationId, currentUserData?.id);

                  // Add the notification to the set of seen notifications
                  setSeenNotifications((prev) =>
                    new Set(prev).add(notificationId)
                  );

                  // **Decrement the notification count**
                  setNewNotificationCount((prevCount) =>
                    Math.max(0, prevCount - 1)
                  );
                })
                .catch((error) => {
                  console.error("Error marking notification as seen:", error);
                });
            }, 3000); // 3-second delay before marking as seen

            // Store timeout ID so we can clear it if needed
            entry.target.dataset.timeoutId = timeoutId;
          }
        } else if (entry.target.dataset.timeoutId) {
          // Clear timeout if the notification is no longer visible before 10 seconds
          clearTimeout(entry.target.dataset.timeoutId);
          delete entry.target.dataset.timeoutId;
        }
      });
    });

    // Observe each notification ref
    notificationRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    // Clean up observer and timeouts when the component unmounts
    return () => {
      notificationRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [notifications, seenNotifications]);

  // Function to mark notification as seen
  const markAsSeen = async (notificationId, notificationType, userId) => {
    try {
      const response = await fetch(
        "/api/combined-notifications/mark-as-seen/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({
            id: notificationId,
            type: notificationType,
            user_id: userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to mark notification as seen.");
      }

      // **Decrement notification count**
      setNewNotificationCount((prevCount) => Math.max(0, prevCount - 1));
    } catch (error) {
      console.error("Error marking notification as seen:", error);
    }
  };

  // Function to handle when the user clicks on a notification
  const handleNotificationClick = async (
    notificationId,
    notificationType,
    userId
  ) => {
    try {
      await markAsSeen(notificationId, notificationType, userId);

      // Optimistically update the notification state when the user clicks
      updateNotificationState(notificationId, userId);
    } catch (error) {
      console.error("Error marking notification as seen:", error);
    }
  };

  // Function to optimistically update the notification state (UI)
  const updateNotificationState = (notificationId, userId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        (notification.id || notification.offer_id) === notificationId
          ? { ...notification, seen_by: [...notification.seen_by, userId] }
          : notification
      )
    );
  };

  const setCombinedNotificationsRef = (node, index) => {
    if (index === notifications.length - 1) {
      lastNotificationsElementRef(node);
    }
    notificationRefs.current[index] = node;
  };

  const lastNotificationsElementRef = useCallback(
    (node) => {
      if (scrollLoadingNotifications) return;
      if (observerNotifications.current)
        observerNotifications.current.disconnect();
      observerNotifications.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreNotifications) {
          setNotificationPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerNotifications.current.observe(node);
    },
    [scrollLoadingNotifications, hasMoreNotifications]
  );

  return (
    <div className="notification-container">
      <header className="header-notification">
        <div className="Notification-Back-btn" onClick={handleBackToHome}>
          <BiArrowBack
            size={20}
            color="currentColor"
            className="bi bi-arrow-left"
          />
        </div>
        <h1 className="notification-text">Notifications</h1>
        <div className="empty-div-change-it"></div>
      </header>

      <div className="notification-contents">
        {notifications.filter((notification) => {
          // filtering logic to determine if the notification should be rendered
          return (
            notification.role === "seller" || notification.role === "buyer"
          );
        }).length === 0 ? (
          <div className="no-notifications">No notifications yet</div>
        ) : (
          notifications.map((notification, index) => {
            const isNew =
              notification.seen_by &&
              !notification.seen_by.includes(currentUserData.id);
            const commonProps = {
              key: index,
              ref: (node) => setCombinedNotificationsRef(node, index),
              className: `notification-item ${isNew ? "highlighted" : ""}`,
              "data-notification-id": notification.id || notification.offer_id,
              "data-notification-type": notification.type,
              onClick: () =>
                handleNotificationClick(
                  notification.id || notification.offer_id,
                  notification.type,
                  currentUserData.id
                ),
            };

            if (
              notification.role === "seller" &&
              notification.status === "pending"
            ) {
              return (
                <div {...commonProps}>
                  {acceptError && (
                    <div className="error-message">
                      <i className="bi bi-exclamation-circle-fill error-icon"></i>{" "}
                      {acceptError}
                    </div>
                  )}
                  <p>{notification.message}</p>

                  <textarea
                    placeholder="To accept, describe the product's current condition. Is it the same as when it was initially listed? What has changed? Any imperfections?"
                    value={productCondition[notification.offer_id] || ""}
                    onChange={(e) =>
                      handleConditionChange(
                        notification.offer_id,
                        e.target.value
                      )
                    }
                    rows="4"
                  />

                  {conditionError[notification.offer_id] && (
                    <div className="error-message">
                      <i className="bi bi-exclamation-circle-fill error-icon"></i>
                      {conditionError[notification.offer_id]}
                    </div>
                  )}

                  {notification.current_owner &&
                  notification.current_owner === currentUserData.username ? (
                    <div className="accept-reject-btns">
                      <button
                        onClick={() => handleAcceptOffer(notification)}
                        disabled={
                          isAcceptDisabled(notification.offer_id) ||
                          notifications.some(
                            (notif) =>
                              notif.product_id === notification.product_id &&
                              (isAcceptedWithin24Hours(notif) ||
                                notification.sellerHasAcceptedRecently)
                          )
                        }
                        className="offer-accept-btn"
                      >
                        {acceptButtonText[notification.offer_id] || "Accept"}
                      </button>
                      <button
                        onClick={() => handleRejectOffer(notification)}
                        className="offer-reject-btn"
                      >
                        {rejectButtonText[notification.offer_id] || "Reject"}
                      </button>
                    </div>
                  ) : null}
                </div>
              );
            }

            if (
              notification.role === "buyer" &&
              notification.status === "accepted"
            ) {
              return (
                <div {...commonProps}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `${
                          notification.message
                        } - offer will expire in <span class="${
                          notification.timeClass || ""
                        }">${notification.hours} ${
                          notification.minutes
                        }</span>.`
                      ),
                    }}
                  ></p>

                  <button
                    className="notification-own-it-button"
                    onClick={() => handleOpenModal(notification)}
                    disabled={notification.isExpired}
                  >
                    {notification.isExpired ? "Expired" : "Own it"}
                  </button>
                </div>
              );
            }

            if (
              notification.role === "buyer" &&
              notification.status === "order_delivered"
            ) {
              return (
                <div {...commonProps}>
                  <p>{notification.message}</p>
                </div>
              );
            }

            if (
              notification.role === "buyer" &&
              notification.status === "order_completed"
            ) {
              return (
                <div {...commonProps}>
                  <p>{notification.message}</p>
                  <button className="notification-own-it-button" disabled>
                    Completed
                  </button>
                </div>
              );
            }

            if (
              notification.role === "seller" &&
              notification.status === "fund_onhold"
            ) {
              return (
                <div {...commonProps}>
                  <p>{notification.message}</p>
                </div>
              );
            }

            if (
              notification.role === "buyer" &&
              notification.status === "rejected"
            ) {
              return (
                <div {...commonProps}>
                  <p>{notification.message}</p>
                </div>
              );
            }

            return null;
          })
        )}

        <div className="scroll-loader-spinner-container">
          {scrollLoadingNotifications && (
            <div className="scroll-loader-spinner"></div>
          )}
        </div>
      </div>
      <DeliveryFormModal />
      <ConfirmationModal />
      <PaymentMethodModal />
      <NotificationModal />
    </div>
  );
}
export default Notification; 
import React, { useState, useRef, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import { usePlayers } from '../PlayersContext';
import './AdminPostForm.css';

const AdminPostForm = () => {
    const { csrftoken } = useContext(
      YourShopHeaderPostContext
    );
    const { LoaderSVG } = usePlayers();
    const [title, setTitle] = useState('');
    const [action, setAction] = useState('Learn More');
    const [link, setLink] = useState('');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [imageName, setImageName] = useState('Upload Image'); 
    const [videoName, setVideoName] = useState('Upload Video');
    const [imageButtonDisabled, setImageButtonDisabled] = useState(false);
    const [videoButtonDisabled, setVideoButtonDisabled] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [linkError, setLinkError] = useState('');
    const [postLimitError, setPostLimitError] = useState('');
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
   
    const location = useLocation();
    const navigate = useNavigate();
    const imageInputRef = useRef();
    const videoInputRef = useRef();

    const handleImageUpload = (e) => {
        setImages(Array.from(e.target.files));
        setImageName(e.target.files[0].name);
        setVideoButtonDisabled(true); // Disable the video button
    };

    const handleVideoUpload = (e) => {
        setVideos(Array.from(e.target.files));
        setVideoName(e.target.files[0].name);
        setImageButtonDisabled(true); // Disable the image button
    };

    const removeImage = () => {
        setImages([]);
        setImageName('Upload Image');
        setImageButtonDisabled(false); // Enable the image button
    };

    const removeVideo = () => {
        setVideos([]);
        setVideoName('Upload Video');
        setVideoButtonDisabled(false); // Enable the video button
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitLoading(true);

        // Client-side validation
         if (title.length > 30) {
            setTitleError('Title is too long. Maximum length is 30 characters.');
            setIsSubmitLoading(false);
            return;
        } else {
            setTitleError('');
        }
        
        // Client-side validation for link (secure links only)
    const linkRegex = /^https:\/\/[^ "]+$/;
          if (!link.match(linkRegex)) {
            setLinkError('Please enter a secure (HTTPS) URL.');
            setIsSubmitLoading(false);
            return;
        } else {
            setLinkError('');
        }

        // Reset previous errors
        setPostLimitError('');

        const formData = new FormData();
        formData.append('title', title.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '));
        formData.append('action', action);
        formData.append('link', link);
        Array.from(images).forEach((image) => {
            formData.append('images', image);
        });
        Array.from(videos).forEach((video) => {
            formData.append('videos', video);
        });

        const response = await fetch('/api/AdminPosts/', {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRFToken': csrftoken
            }
        });

        if (response.ok) {
            const data = await response.json();
            setIsSubmitLoading(false);
            navigate('/adminGooofan/homescreen/posts');
        } else {
            const errorData = await response.json();
            if (response.status === 400 && errorData.error === "Exceeded limit: Max 5 posts, either 3 images and 2 videos, or 5 images.") {
                setPostLimitError(errorData.error);
                setIsSubmitLoading(false);
            } else {
            setIsSubmitLoading(false);
            } 
        }
       
     };
 
    return (
      <>
        <div className="container admin-postForm">
          <form onSubmit={handleSubmit}>
            <input
              className="form-control"
              id="title"
              name="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              required
            />
            {titleError && (
              <p className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {titleError}
              </p>
            )}
            <select
              className="form-control"
              value={action}
              id="action"
              name="action"
              onChange={(e) => setAction(e.target.value)}
            >
              <option value="Learn More">Learn More</option>
              <option value="Own it">Own it</option>
              <option value="Explore">Explore</option>
            </select>
            <input
              className="form-control"
              id="link"
              name="link"
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="Link"
              required
            />
            {linkError && (
              <p className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {linkError}
              </p>
            )}
            {postLimitError && (
              <p className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon "></i>
                {postLimitError}
              </p>
            )}
            <div className="upload-btn-wrapper">
              {images.length > 0 && (
                <div className="media-item">
                  <span>{imageName}</span>
                  <button
                    className="btn-remove"
                    onClick={() => {
                      removeImage();
                      setVideoButtonDisabled(false);
                    }}
                  >
                    <i className="bi bi-x"></i>
                  </button>
                </div>
              )}
              <button
                className="btn-upload"
                type="button"
                onClick={() => imageInputRef.current.click()}
                disabled={imageButtonDisabled}
              >
                {imageName}
              </button>
              <input
                ref={imageInputRef}
                style={{ display: "none" }}
                type="file"
                multiple
                onChange={handleImageUpload}
                accept="image/*"
              />
            </div>
            <div className="upload-btn-wrapper">
              {videos.length > 0 && (
                <div className="media-item">
                  <span>{videoName}</span>
                  <button
                    className="btn-remove"
                    onClick={() => {
                      removeVideo();
                      setImageButtonDisabled(false);
                    }}
                  >
                    <i className="bi bi-x"></i>
                  </button>
                </div>
              )}
              <button
                className="btn-upload"
                type="button"
                onClick={() => videoInputRef.current.click()}
                disabled={videoButtonDisabled}
              >
                {videoName}
              </button>
              <input
                ref={videoInputRef}
                style={{ display: "none" }}
                type="file"
                multiple
                onChange={handleVideoUpload}
                accept="video/*"
              />
            </div>
            <div className="text-center mt-3 AdminForm-btn">
              <button
                type="submit"
                disabled={isSubmitLoading}
                className={isSubmitLoading ? "loading" : ""}
              >
                {isSubmitLoading ? (
                  <div className="saving-indicator">
                    <span>Posting</span>
                    <LoaderSVG />
                  </div>
                ) : (
                  "Publish"
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
};

export default AdminPostForm;


// Sidebar3Context.jsx
import React, { createContext, useState, useContext } from "react";

const Sidebar3Context = createContext();
export const useSidebar3 = () => useContext(Sidebar3Context);

export const Sidebar3Provider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [shareMessage, setShareMessage] = useState("");

  // Function to handle sharing
  const handleShareGooofan = () => {
    const url = "https://gooofan.com"; // The Gooofan URL

    if (navigator.share) {
      // Use Web Share API if supported
      navigator
        .share({
          title: "Check out Gooofan!",
          text: "It might be the beginning of something great!",
          url: url,
        })
        .catch(() => {
          // Fallback to copying to clipboard
          navigator.clipboard.writeText(url).then(() => {
            setShareMessage("Gooofan URL copied to clipboard");
            setTimeout(() => {
              setShareMessage("");
            }, 2000);
          });
        });
    } else {
      // Clipboard fallback if Web Share API is not available
      navigator.clipboard.writeText(url).then(() => {
        setShareMessage("Gooofan URL copied to clipboard");
        setTimeout(() => {
          setShareMessage("");
        }, 2000);
      });
    }
  };

  return (
    <Sidebar3Context.Provider
      value={{
        isSidebarOpen,
        setSidebarOpen,
        handleShareGooofan,
        shareMessage,
      }}
    >
      {children}
    </Sidebar3Context.Provider>
  );
};

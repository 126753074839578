import React from 'react';
import DOMPurify from 'dompurify';
import { useLikesComments } from './LikesCommentsContext';
import './StarUpdateSearchResult.css';

function StarUpdateSearchResult({ starupdate, onClick }) {
   const headline = starupdate?.headline;
   const body = starupdate?.body;
   const sanitizedBody = DOMPurify.sanitize(body);
   const textToShow = headline || sanitizedBody;
   const truncatedText = textToShow.length > 70 ? textToShow.substring(0, 70) + '...' : textToShow;
   const { renderMedia } = useLikesComments();

   return (
      <div className="star-update-search-result" onClick={onClick}>
         <div className="star-update-text" dangerouslySetInnerHTML={{ __html: truncatedText }} />
         {renderMedia(starupdate?.news_cover, starupdate?.headline)}
      </div>
   );
}

export default StarUpdateSearchResult;

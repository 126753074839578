import React, { useState, useContext } from 'react';
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { YourShopHeaderPostContext } from './YourShopHeaderPostContext';
import SearchUser from '../SearchUser';
import { useNavigate } from "react-router-dom";

const ConversationList = () => {
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const {
    setCurrentRecipient,
    setCurrentConversationId,
    userData,
    unreadMessagesCount,
    pinnedConversations,
    lastMessagePreviews,
    toggleChat,
    imageFailed,
    setImageFailed,
    fetchConversationData,
    newRequestCount,
    isWideScreen,
    setChatState,
  } = useChatAndMusicPosts();
  const navigate = useNavigate();


  // Function to open the chatroom
  const handleUserClick = async (username) => {
    setCurrentRecipient(username);
    toggleChat();

    setChatState(true);
    localStorage.setItem("chatState", "true");

    const conversationId = [userData.username, username].sort().join("-");
    const uniqueString = btoa(conversationId);

    setCurrentConversationId(conversationId);
    navigate(
      `${
        isWideScreen ? "/home" : ""
      }/start-chat/${conversationId}/${uniqueString}`
    );

    // Call the new endpoint to create a new conversation to the server if it is not created yet
    fetch(`/api/messages/create_conversation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({
        conversationId: conversationId,
        participant_usernames: [userData.username, username].sort().join("|"),
      }),
    })
      .then((response) => {
        if (!response.ok && response.status !== 409) {
          throw new Error("Error creating new conversation");
        }
        return response.json();
      })
      .then((data) => {
        fetchConversationData(conversationId, username);
      })
      .catch((error) =>
        console.error("Error creating new conversation:", error)
      );
  };

  return (
    <div className="container conversation-lists">
      <SearchUser />
      <div className="Pinned-conversations">
        {pinnedConversations.map(
          (pinnedConversation, index) =>
            // Check if selectedUser is defined before trying to access its properties
            pinnedConversation &&
            pinnedConversation.selectedUser && (
              <div
                className="pin-conversation-profile-name"
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserClick(pinnedConversation.selectedUser.username);
                }}
              >
                {/* Render the user details */}
                {pinnedConversation.selectedUser.profile_picture &&
                !imageFailed ? (
                  <img
                    src={pinnedConversation.selectedUser.profile_picture}
                    alt={pinnedConversation.selectedUser.first_name}
                    className="profile-picture"
                    onError={() => setImageFailed(true)}
                  />
                ) : (
                  <div className="profile-picture no-profile-picture">
                    {pinnedConversation.selectedUser.first_name
                      ? pinnedConversation.selectedUser.first_name
                          .slice(0, 1)
                          .toUpperCase()
                      : "?"}
                  </div>
                )}
                <div className="username-lastMessage-Preview-container">
                  <div className="container-chatUsername">
                    <span className="full-name">{`${pinnedConversation.selectedUser.first_name} ${pinnedConversation.selectedUser.last_name}`}</span>
                  </div>

                  <div className="container-lastMessagePreview">
                    <div className="last-message-preview">
                      {
                        lastMessagePreviews[
                          pinnedConversation?.pinnedConversation?.conversation
                            ?.conversationId
                        ]
                      }
                    </div>

                    {unreadMessagesCount[
                      pinnedConversation?.pinnedConversation?.conversation
                        ?.conversationId
                    ] > 0 && (
                      <div className="unread-messages-badge">
                        {
                          unreadMessagesCount[
                            pinnedConversation?.pinnedConversation?.conversation
                              ?.conversationId
                          ]
                        }
                      </div>
                    )}
                    {newRequestCount[
                      pinnedConversation?.pinnedConversation?.conversation
                        ?.conversationId
                    ] > 0 && (
                      <div className="new-request-badge">
                        {
                          newRequestCount[
                            pinnedConversation?.pinnedConversation?.conversation
                              ?.conversationId
                          ]
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ConversationList;



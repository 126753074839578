import React, { useState, useContext } from "react";
import { useChatAndMusicPosts } from "./StarNavigationBar/ChatAndMusicPostsContext";
import { YourShopHeaderPostContext } from "./StarNavigationBar/YourShopHeaderPostContext";
import SearchResult from "./searchResultRecommendation";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./SearchUser.css";

function SearchUser({ sharedMusic }) {
  const {
    setCurrentRecipient,
    setCurrentConversationId,
    userData,
    toggleChat,
    fetchConversationData,
    setCurrentMusicPost,
    setShowRequestMessage,
    isWideScreen,
  } = useChatAndMusicPosts();
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [input, setInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (value) => {
    if (!value) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(`/api/search_users/?query=${value}&size=10`);
      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      setSearchResults(data);
      if (data.length === 0) {
        setTimeout(() => setNoResultsFound(true), 250);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleChange = (value) => {
    setInput(value);
    setNoResultsFound(false);
    fetchData(value);
  };

  //function to open the chatroom
  const handleUserClick = async (username) => {
    setCurrentRecipient(username);
    toggleChat();

    const conversationId = [userData.username, username].sort().join("-");
    const uniqueString = btoa(conversationId);

    setCurrentConversationId(conversationId);
    navigate(
      `${
        isWideScreen ? "/home" : ""
      }/start-chat/${conversationId}/${uniqueString}`
    );

    // Call the new endpoint to create a new conversation to the server if is not created yet
    fetch(`/api/messages/create_conversation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({
        conversationId: conversationId,
        participant_usernames: [userData.username, username].sort().join("|"),
      }),
    })
      .then((response) => {
        if (!response.ok && response.status !== 409) {
          throw new Error("Error creating new conversation");
        }
        return response.json();
      })
      .then((data) => {
        fetchConversationData(conversationId, username);
        // Set the entire sharedMusic object (with all fields) and show the request message
        if (sharedMusic && sharedMusic.audio_file) {
          setCurrentMusicPost(sharedMusic); 
          setShowRequestMessage(true);
        }
      })
      .catch((error) =>
        console.error("Error creating new conversation:", error)
      );
  };

  return (
    <div className="search-user">
      <form className="form-search-messaging" role="search">
        <div className="messaging-search-container">
          <BiSearch size={18} className="message-search-icon" />
          <input
            className="form-control message-search-input"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={input}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </form>

      <div
        className={`message-Searchresults-container ${input ? "active" : ""}`}
      >
        {input && searchResults.length > 0 ? (
          <div className="search-results-container">
            {searchResults.map((user) => (
              <SearchResult
                key={user.username}
                user={user}
                onUserClick={handleUserClick}
              />
            ))}
          </div>
        ) : noResultsFound ? (
          <div className="no-result">No results found</div>
        ) : null}
      </div>
    </div>
  );
}

export default SearchUser;

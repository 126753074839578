// /utils/formatTimeElapsed.jsx


export const formatTimeElapsed = (dateString) => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return 'Just now';
  }

  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = Math.floor(hours / 24);
  if (days < 7) {
    return `${days}d`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks < 52) {
    return `${weeks}w`;
  }

  const years = Math.floor(weeks / 52);
  return `${years}y`;
};
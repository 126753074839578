import React, { useState, useEffect } from 'react';
import { useUserLocation } from '../GetUserCurrentLocationContext';
import { useOwnModalsContext } from '../OwnModalsContext';

const normalizeCountryName = (country) => {
  const countryMap = {
    "Tanzania, United Republic of": "Tanzania",
    "Tanzania": "Tanzania",
    "Kenya": "Kenya",
    "Uganda": "Uganda",
    "Rwanda": "Rwanda",
    "Burundi": "Burundi",
    "Malawi": "Malawi",
    "Congo": "Congo",
    "Democratic Republic of the Congo": "Democratic Republic of the Congo",
  };

  return countryMap[country] || country;
};

const extractCountry = (productLocation) => {
  const addressComponents = productLocation.split(',').map(component => component.trim());
  return normalizeCountryName(addressComponents[addressComponents.length - 1]) || '';
};

const DeliveryCostCalculator = ({
  productLocation,
  buyerAddress,
  deliveryType,
  country,
  productPrice,
  productCurrency,
  onCalculate,
  productPriceUSD,
}) => {
  const [distance, setDistance] = useState(null);
  const [deliveryCost, setDeliveryCost] = useState(null);
  const [handlingFee, setHandlingFee] = useState(null);
  const [additionalMessage, setAdditionalMessage] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const { userLocation } = useUserLocation();

  const [deliveryCostUSD, setDeliveryCostUSD] = useState(null); 
  const { setTotalCostInUSD, setExchangeRateLoading, setDistanceLoading} =
    useOwnModalsContext();

  const regionalCountries = [
    "Tanzania",
    "Kenya",
    "Uganda",
    "Rwanda",
    "Burundi",
    "Malawi",
    "Congo",
    "Democratic Republic of the Congo",
  ];

  const fetchExchangeRate = async (baseCurrency, targetCurrency) => {
    setExchangeRateLoading(true);
    try {
      const response = await fetch(
        `/api/get-exchange-rate/?base=${baseCurrency}`
      );
      const data = await response.json();
      return data.rates[targetCurrency];
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      return null;
    } finally {
      setExchangeRateLoading(false);
    }
  };

  const convertCost = async (amountInUSD, targetCurrency = "TZS") => {
    if (userLocation && userLocation.country === "TZ") {
      const rates = await fetchExchangeRate("USD", targetCurrency);
      const exchangeRate = rates || 2900; // Fallback exchange rate for TZS
      const amountInTZS = (amountInUSD * exchangeRate).toFixed(2);
      return {
        displayCost: amountInTZS + " TZS",
        internalCost: amountInUSD.toFixed(2),
      };
    }
    return {
      displayCost: amountInUSD.toFixed(2) + " USD",
      internalCost: amountInUSD.toFixed(2),
    };
  };

  const calculateDeliveryCost = async () => {
    if (!productLocation || !buyerAddress) return;

    const productCountry = extractCountry(productLocation);
    const buyerCountry = normalizeCountryName(country);

    // Case 1: Bike delivery
    if (deliveryType === "bike") {
      const calculateDistanceAndCost = async () => {
        setDistanceLoading(true);
        const google = window.google;
        if (google && google.maps) {
          const service = new google.maps.DistanceMatrixService();
          service.getDistanceMatrix(
            {
              origins: [productLocation],
              destinations: [buyerAddress],
              travelMode: google.maps.TravelMode.DRIVING,
            },
            async (response, status) => {
              // Make this callback function async
              if (status === "OK") {
                const result = response.rows[0]?.elements[0];
                if (result && result.distance && result.distance.value) {
                  const distanceText = result.distance.text;
                  const distanceValue = parseFloat(
                    result.distance.value / 1000
                  );

                  setDistance(distanceText);

                  const perKmPrice = 1200 / 2200;
                  const calculatedCostUSD =
                    distanceValue < 1 ? perKmPrice : distanceValue * perKmPrice;

                  setDeliveryCostUSD(calculatedCostUSD.toFixed(2)); 
                  const convertedCost = await convertCost(calculatedCostUSD);
                  setDeliveryCost(convertedCost.displayCost); 
                } else {
                  console.error(
                    "Distance or duration data is missing in the response."
                  );
                  setDeliveryCost("Error: Unable to calculate distance.");
                }
              } else {
                console.error("Distance Matrix API error:", status);
                setDeliveryCost("Error: Unable to calculate distance.");
              }
              setDistanceLoading(false);
            }
          );
        }
      };

      if (productLocation === buyerAddress) {
        setDistance("0 km");
        setDeliveryCost("0 TZS");
        setDeliveryCostUSD("0");
      } else {
        calculateDistanceAndCost();
      }
    }

    // Case 2: Bus/Ferry delivery
    else if (deliveryType === "busFerry") {
      if (productCountry === "Tanzania" && buyerCountry === "Tanzania") {
        const costUSD = 34.48;
        setDeliveryCostUSD(costUSD.toFixed(2));
        const convertedCost = await convertCost(costUSD);
        setDeliveryCost(convertedCost.displayCost); 
      } else if (
        productCountry === buyerCountry &&
        productCountry !== "Tanzania"
      ) {
        const cost = await convertCost(100);
        setDeliveryCost(cost.displayCost); // Display in USD or TZS based on user location
        // setDeliveryCostUSD(100);
        setDeliveryCostUSD((100).toFixed(2));
      } else if (
        productCountry !== buyerCountry &&
        regionalCountries.includes(productCountry) &&
        regionalCountries.includes(buyerCountry)
      ) {
        const cost = await convertCost(140);
        setDeliveryCost(cost.displayCost);
        setDeliveryCostUSD((140).toFixed(2));
      } else {
        setDeliveryCost("Location not supported for bus/ferry delivery.");
      }
    }

    // Case 3: Airplane delivery
    else if (deliveryType === "airplane") {
      setHandlingFee("Handling and preparation fee");
      const cost = await convertCost(40);
      setDeliveryCost(cost.displayCost);
      setDeliveryCostUSD((40).toFixed(2));
      setAdditionalMessage(
        "You'll be contacted for courier charges. Use the product details to estimate costs on the courier's website."
      );
    }
  };

  useEffect(() => {
    calculateDeliveryCost();
  }, [productLocation, buyerAddress, deliveryType, country, userLocation]);

  // Always calculate total cost in USD, used for internal transactions, such as wallet charging and etc
  useEffect(() => {
    const deliveryCost = deliveryCostUSD ? parseFloat(deliveryCostUSD) : 0.0;
    if (productPriceUSD) {
      const total = parseFloat(productPriceUSD) + deliveryCost;
      setTotalCostInUSD(total.toFixed(2)); 
    }
  }, [productPriceUSD, deliveryCostUSD]);

  // Calculate total cost for display in ui
  useEffect(() => {
    const deliveryCostValue = deliveryCost
      ? parseFloat(deliveryCost.split(" ")[0].replace(/,/g, ""))
      : 0.0;
    if (productPrice) {
      const total = parseFloat(productPrice) + deliveryCostValue;
      setTotalCost(total.toFixed(2) + " " + productCurrency);
    }
  }, [productPrice, deliveryCost, productCurrency]);

  useEffect(() => {
    if (onCalculate) {
      onCalculate({
        distance,
        deliveryCost,
        handlingFee,
        additionalMessage,
        totalCost,
      });
    }
  }, [
    distance,
    deliveryCost,
    handlingFee,
    additionalMessage,
    totalCost,
    onCalculate,
  ]);
  
    return (
      <div className="container-cost-calculator">
        {distance && (
          <p>
            Distance: <span>{distance}</span>
          </p>
        )}
        <p className="price">
          Price:{" "}
          <span>
            {productPrice} {productCurrency}
          </span>
        </p>
        {deliveryCost && deliveryType !== "airplane" && (
          <p
            className={
              deliveryCost === "Location not supported for bus/ferry delivery."
                ? "unsupported-location"
                : ""
            }
          >
            Delivery Cost: <span>{deliveryCost}</span>
          </p>
        )}
        {handlingFee && deliveryType === "airplane" && (
          <p>
            {handlingFee}: <span>{deliveryCost}</span>
          </p>
        )}
        {additionalMessage && (
          <p>
            Courier Charges: <span>{additionalMessage}</span>
          </p>
        )}
        <div className="total-cost">
          {totalCost && <p>Total Cost:</p>} <span>{totalCost}</span>{" "}
        </div>

      </div>
    );
};

export default DeliveryCostCalculator;


import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Admin.css'; 

function StarUpdateArchieveNav() {
  const location = useLocation();

  return (
    <div className="Poststar-links-container">
      <Link to="/adminGooofan/star-nav/post-archive/archive/best-selling" 
            className={location.pathname === "/adminGooofan/star-nav/post-archive/archive/best-selling" ? "active-section-admin" : ""}>
        Ar. best selling
      </Link>
      <Link to="/adminGooofan/star-nav/post-archive/archive/top-priced" 
            className={location.pathname === "/adminGooofan/star-nav/post-archive/archive/top-priced" ? "active-section-admin" : ""}>
        Ar. top priced
      </Link>
      <Link to="/adminGooofan/star-nav/post-archive/archive/star-award" 
            className={location.pathname === "/adminGooofan/star-nav/post-archive/archive/star-award" ? "active-section-admin" : ""}>
        Ar. star award
      </Link>
    </div>
  );
}

export default StarUpdateArchieveNav;

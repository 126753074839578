// components/StarNavigationBar/Navigation.jsx
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  forwardRef,
} from "react";
import { Link } from "react-router-dom";
import { useSidebar3 } from "../StarNavigationBar/Sidebar3Context";
import { FooterContext } from "../StarNavigationBar/FooterContext";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { useLiveVisitors } from "../LiveVisitorsContext";
import { useLikesComments } from "../LikesCommentsContext";
import { useUserRole } from "../UserRoleContext";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { useUserLogout } from "../UserLogoutContext";
import gooofanLogo from "../../assets/images/gooofan-logo.svg";
import gsStarLogo from "../../assets/images/G&Star-logo.svg";
import FooterSkeleton from "./FooterSkeleton";

import {
  BiUser,
  BiFlag,
  BiHelpCircle,
  BiLock,
  BiShare,
  BiLogOut,
  BiHome,
  BiSearch,
  BiStore,
  BiUpload,
  BiGroup,
  BiBell,
  BiShield,
  BiMessageDetail,
  BiCrown,
} from "react-icons/bi";
import { AiOutlineStar } from "react-icons/ai";
import "./sidebar3.css";

// Polyfill for Element.closest for older browsers
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

function Navigation() {
  const [activeSidebarItem, setActiveSidebarItem] = useState(null);
  const [activeFooterItem, setActiveFooterItem] = useState(null);
  const { role, loading, error } = useUserRole();
  const { isSidebarOpen, setSidebarOpen, handleShareGooofan, shareMessage } =
    useSidebar3();
  const {
    showFooter,
    setShowFooter,
    setMusicPosts,
    musicPosts,
    musicPage,
    setMusicPage,
    setYourMusicPage,
    yourMusicPage,
    clearFetchedMusicPostIds,
    setSubscriberPage,
    subscriberPage,
    clearFetchedSubscribersIds,
    setSubscribers,
    subscribers,
    clearfetchedSubscriptionIds,
    setSubscriptions,
    subscribedPage,
    setSubscribedPage,
    subscriptions,
    buyerOrders,
    setBuyerOrders,
    buyerOrderPage,
    setBuyerOrderPage,
  } = useContext(FooterContext);
  const sidebarRef = useRef();
  const {
    clearFetchedStarPostingIds,
    setStarPostingPage,
    starPostingPage,
    setIsFullScreenPreview,
    isFullScreenPreview,
    setCurrentPostId,
    currentPostId,
    setSoldOutPosts,
    soldOutPosts,
    clearFetchedSoldOutIds,
    setStarPostingSoldOutPage,
    starPostingSoldOutPage,
    setOrderPage,
    orderPage,
    clearFetchedOrderIds,
    setOrders,
    orders,
    clearFetchedTransactionIds,
    setTransactionPage,
    transactionPage,
    clearFetchedNotificationsIds,
    setNotificationPage,
    notificationPage,
    setNotifications,
    notifications,
    setCombinedTransactions,
    combinedTransactions,
    newNotificationCount,
  } = useContext(YourShopHeaderPostContext);
  const { setProfileBeingViewed, profileBeingViewed } = useLiveVisitors();
  const {
    chatState, 
    setChatState,
    setMessagePage,
    messagePage,
    setMessages,
    messages,
    clearFetchedMessageIds,
    userData,
    totalCombinedCount,
    pinnedConversations,
    markMessagesAsRead,
    currentConversationId,
    setCurrentConversationId,
    setCurrentRecipient,
    setSelectedUser,
    isWideScreen,
  } = useChatAndMusicPosts();
  const {
    handleCancelSearchForm,
    setStarUpdatePostFullPreview,
    starUpdatePostFullPreview,
  } = useLikesComments();

  useEffect(() => {
    const storedSidebarItem = localStorage.getItem("activeSidebarItem");
    const storedFooterItem = localStorage.getItem("activeFooterItem");
    const storedShowFooter = localStorage.getItem("showFooter");

    setActiveSidebarItem(storedSidebarItem || "home");
    setActiveFooterItem(storedFooterItem || "home");

    if (storedShowFooter !== null) {
      setShowFooter(JSON.parse(storedShowFooter));
    }

    function handleClickOutside(event) {
      // Check if the click event originated from the profile picture
      if (event.target.closest && event.target.closest(".profile")) {
        return;
      }

      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSidebarItemClick = (id) => {
    // Update sidebar item and store it in localStorage
    if (activeSidebarItem !== id) {
      localStorage.setItem("activeSidebarItem", id);
      setActiveSidebarItem(id);
    }

    // Helper to conditionally clear/reset state
    const clearStateIfNecessary = (
      stateValue,
      setStateFunction,
      defaultValue
    ) => {
      if (stateValue !== defaultValue) {
        setStateFunction(defaultValue);
      }
    };

    // Clear profile-related data
    clearStateIfNecessary(profileBeingViewed, setProfileBeingViewed, "");
    localStorage.removeItem("profileBeingViewed");

    // Reset music-related states only if there is data to clear
    clearFetchedStarPostingIds();
    clearStateIfNecessary(starPostingPage, setStarPostingPage, 1);
    clearStateIfNecessary(currentPostId, setCurrentPostId, null);
    clearStateIfNecessary(isFullScreenPreview, setIsFullScreenPreview, false);
    clearStateIfNecessary(musicPosts, setMusicPosts, []);
    clearStateIfNecessary(musicPage, setMusicPage, 1);
    clearStateIfNecessary(yourMusicPage, setYourMusicPage, 1);
    clearFetchedMusicPostIds();

    // Reset chat-related states
    clearStateIfNecessary(chatState, setChatState, false);
    localStorage.removeItem("chatState");
    handleCancelSearchForm();
    clearStateIfNecessary(
      starUpdatePostFullPreview,
      setStarUpdatePostFullPreview,
      false
    );

    // Remove localStorage keys if they exist
    if (currentConversationId) {
      markMessagesAsRead(currentConversationId);

      [
        "currentConversationId",
        "currentRecipient",
        "selectedUser",
      ].forEach((key) => {
        localStorage.removeItem(key);
      });

      // Reset messages-related states if necessary
      clearStateIfNecessary(messagePage, setMessagePage, 1);
      clearStateIfNecessary(messages, setMessages, []);
      clearFetchedMessageIds();
      setCurrentConversationId(null)
      setCurrentRecipient(null);
      setSelectedUser(null);
    }

    // Reset messages-related states if necessary
    clearStateIfNecessary(messagePage, setMessagePage, 1);
    clearStateIfNecessary(messages, setMessages, []);
    clearFetchedMessageIds();

    // Reset sold-out posts-related states
    clearStateIfNecessary(soldOutPosts, setSoldOutPosts, []);
    clearFetchedSoldOutIds();
    clearStateIfNecessary(starPostingSoldOutPage, setStarPostingSoldOutPage, 1);

    // Reset orders-related states
    clearStateIfNecessary(orderPage, setOrderPage, 1);
    clearStateIfNecessary(orders, setOrders, []);
    clearStateIfNecessary(buyerOrders, setBuyerOrders, []);
    clearStateIfNecessary(buyerOrderPage, setBuyerOrderPage, 1);
    clearFetchedOrderIds();

    // Reset transaction-related states
    clearFetchedTransactionIds();
    clearStateIfNecessary(transactionPage, setTransactionPage, 1);
    clearStateIfNecessary(combinedTransactions, setCombinedTransactions, []);

    // Reset notifications-related states
    clearFetchedNotificationsIds();
    clearStateIfNecessary(notificationPage, setNotificationPage, 1);
    clearStateIfNecessary(notifications, setNotifications, []);

    // Reset subscribers-related states
    clearFetchedSubscribersIds();
    clearStateIfNecessary(subscriberPage, setSubscriberPage, 1);
    clearStateIfNecessary(subscribers, setSubscribers, []);
    clearfetchedSubscriptionIds();
    clearStateIfNecessary(subscribedPage, setSubscribedPage, 1);
    clearStateIfNecessary(subscriptions, setSubscriptions, []);
  };

  const handleFooterItemClick = (id) => {
    localStorage.setItem("activeFooterItem", id);
    setActiveFooterItem(id);
  };
  return (
    <>
      <div id="mySidebar" className="sidebar">
        {/* Sidebar logos */}
        <div className="logo-container">
          <img
            id="logoFanSidebar"
            className="logo-fan-sidebar img-fluid"
            src={gooofanLogo}
            alt="Logo"
          />
          <img
            id="logoFanSidebar2"
            className="logo-fan-sidebar2 img-fluid"
            src={gsStarLogo}
            alt="Logo"
          />
        </div>
        {/* Home link */}
        <Link
          to="/home"
          onClick={() => handleSidebarItemClick("home")}
          className={`link-text ${
            activeSidebarItem === "home" ? "active-gold" : ""
          }`}
        >
          <BiHome className="icon-size-sidebar" />
          <span>Home</span>
        </Link>
        {/* Search link */}
        <Link
          to="/search"
          onClick={() => handleSidebarItemClick("search")}
          className={`hide-bar-search link-text ${
            activeSidebarItem === "search" ? "active-gold" : ""
          } search-lg`}
        >
          <BiSearch className="icon-size-sidebar" />
          <span>Search</span>
        </Link>

        {role === "star" ? (
          <>
            {/* Your Shop link */}
            <Link
              to={`/${userData.username}`}
              onClick={() => handleSidebarItemClick("your-shop")}
              className={`link-text ${
                activeSidebarItem === "your-shop" ? "active-gold" : ""
              }`}
            >
              <BiStore className="icon-size-sidebar" />
              <span>Your Shop</span>
            </Link>

            {/* Upload link */}
            <Link
              to={isWideScreen ? "/home/upload" : "/upload"}
              onClick={() => handleSidebarItemClick("upload")}
              className={`link-text ${
                activeSidebarItem === "upload" ? "active-gold" : ""
              }`}
            >
              <BiUpload className="icon-size-sidebar" />
              <span>Upload</span>
            </Link>

            {/* Subscribers link */}
            <Link
              to={isWideScreen ? "/home/subscriber" : "/subscriber"}
              onClick={() => handleSidebarItemClick("subscriber")}
              className={`link-text ${
                activeSidebarItem === "subscriber" ? "active-gold" : ""
              }`}
            >
              <BiGroup className="icon-size-sidebar" />
              <span>Subscribers</span>
            </Link>
          </>
        ) : (
          <>
            <Link
              to={isWideScreen ? "/home/you-own" : "/you-own"}
              onClick={() => handleSidebarItemClick("you-own")}
              className={`link-text ${
                activeSidebarItem === "you-own" ? "active-gold" : ""
              }`}
            >
              <BiCrown className="icon-size-sidebar" />
              <span>You Own</span>
            </Link>

            <Link
              to={isWideScreen ? "/home/subscribed" : "/subscribed"}
              onClick={() => handleSidebarItemClick("subscribed")}
              className={`link-text ${
                activeSidebarItem === "subscribed" ? "active-gold" : ""
              }`}
            >
              <AiOutlineStar className="icon-size-sidebar" />
              <span>Subscribed</span>
            </Link>
          </>
        )}

        {/* What's New (Notifications) link */}
        <Link
          to={isWideScreen ? "/home/notifications" : "/notifications"}
          onClick={() => handleSidebarItemClick("whats-new")}
          className={`link-text notification-link ${
            activeSidebarItem === "whats-new" ? "active-gold" : ""
          }`}
        >
          <BiBell className="icon-size-sidebar" />
          {newNotificationCount > 0 && (
            <p className="new-notification-badge-count">
              {newNotificationCount}
            </p>
          )}
          <span>What's New</span>
        </Link>

        {/* Start a Chat link */}
        <Link
          to={isWideScreen ? "/home/start-chat" : "/start-chat"}
          onClick={() => handleSidebarItemClick("start-chat")}
          className={`link-text start-chat-link ${
            activeSidebarItem === "start-chat" ? "active-gold" : ""
          }`}
        >
          <BiMessageDetail className="icon-size-sidebar" />
          {totalCombinedCount > 0 && (
            <p className="totalCombinedCount-badge-count">
              {totalCombinedCount}
            </p>
          )}
          <span>
            {pinnedConversations.length === 0 ? "Start a Chat" : "Messages"}
          </span>
        </Link>
        <Link
          to={isWideScreen ? "/home/wallet" : "/wallet"}
          // className="wallet-button"
          onClick={() => handleSidebarItemClick("wallet")}
          className={`link-text ${
            activeSidebarItem === "wallet" ? "active-gold" : ""
          }`}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="34"
            width="34"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="3"
              y="4"
              width="14"
              height="13"
              rx="2"
              ry="2"
              stroke="currentColor"
              strokeWidth="1.2"
              fill="none"
            />

            <path
              d="M4 10C3.44772 10 3 10.4477 3 11C3 11.5523 3.44772 12 4 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H4Z"
              stroke="currentColor"
              strokeWidth="1"
              fill="none"
            />

            <circle
              cx="15"
              cy="13"
              r="1.5"
              stroke="currentColor"
              strokeWidth="1"
              fill="none"
            />
          </svg>
          <span>Wallet</span>
        </Link>
      </div>

      {/* Footer Navigation */}
      {showFooter && (
        <FooterNavigation
          activeItem={activeFooterItem}
          handleItemClick={handleFooterItemClick}
        />
      )}

      {/* Render Sidebar3 */}
      <Sidebar3 ref={sidebarRef} isOpen={isSidebarOpen} />
    </>
  );
}

function FooterNavigation({ activeItem, handleItemClick }) {
  const { setProfileBeingViewed, profileBeingViewed } = useLiveVisitors();
  const { role, loading, error } = useUserRole();
  const {
    chatState,
    setChatState,
    userData,
    clearFetchedMessageIds,
    setMessages,
    messages,
    setMessagePage,
    totalCombinedCount,
    pinnedConversations,
    setCurrentConversationId,
    currentConversationId,
    setCurrentRecipient,
    setSelectedUser,
  } = useChatAndMusicPosts();
  const {
    setMusicPosts,
    musicPosts,
    setMusicPage,
    setYourMusicPage,
    clearFetchedMusicPostIds,
    clearFetchedSubscribersIds,
    setSubscriberPage,
    setSubscribers,
    subscribers,
    clearfetchedSubscriptionIds,
    setSubscriptions,
    setSubscribedPage,
    subscriptions,
    buyerOrders,
    setBuyerOrders,
    setBuyerOrderPage,
  } = useContext(FooterContext);
  const {
    clearFetchedStarPostingIds,
    setStarPostingPage,
    setSoldOutPosts,
    soldOutPosts,
    clearFetchedSoldOutIds,
    setStarPostingSoldOutPage,
    setShowHeader,
    setIsFullScreenPreview,
    setCurrentPostId,
    setOrderPage,
    clearFetchedOrderIds,
    setOrders,
    orders,
    setIsSubscribed,
    clearFetchedTransactionIds,
    setTransactionPage,
    clearFetchedNotificationsIds,
    setNotificationPage,
    setNotifications,
    notifications,
    setCombinedTransactions,
  } = useContext(YourShopHeaderPostContext);
  const {
    setPage,
    setTopPricedArchivedPosts,
    topPricedArchivedPosts,
    setStarAwardArchivedPosts,
    starAwardArchivedPosts,
    setBestSellingArchivedPosts,
    bestSellingArchivedPosts,
    setAdminTopPricedPosts,
    adminTopPricedPosts,
    setAdminStarAwardPosts,
    adminStarAwardPosts,
    setAdminBestSellingPosts,
    adminBestSellingPosts,
    setTopPricedPosts,
    topPricedPosts,
    setBestSellingPosts,
    bestSellingPosts,
    setStarAwardPosts,
    starAwardPosts,
    clearFetchedPostIds,
    handleCancelSearchForm,
  } = useLikesComments();

  const handleFooterClick = (e, id) => {
    e.stopPropagation();
    handleItemClick(id);

    // Reset chat and header-related states
    const clearChatAndHeader = () => {
      if (chatState !== false) {
        setChatState(false);
        localStorage.removeItem("chatState");
      }
      setShowHeader(true);
    };

    // Clear profile-related data
    const clearProfile = () => {
      if (profileBeingViewed !== "") {
        setProfileBeingViewed("");
        localStorage.removeItem("profileBeingViewed");
      }
    };

    // Helper to clear array-based states
    const clearArrayState = (stateArray, setStateFunction) => {
      if (stateArray.length > 0) {
        setStateFunction([]);
      }
    };

    // Clear common post-related states
    const clearPostsAndPages = () => {
      setCurrentPostId(null);
      setIsFullScreenPreview(false);
      setPage(1);
      setStarPostingPage(1);
      setBuyerOrderPage(1);

      clearArrayState(adminTopPricedPosts, setAdminTopPricedPosts);
      clearArrayState(adminStarAwardPosts, setAdminStarAwardPosts);
      clearArrayState(adminBestSellingPosts, setAdminBestSellingPosts);
      clearArrayState(bestSellingArchivedPosts, setBestSellingArchivedPosts);
      clearArrayState(topPricedArchivedPosts, setTopPricedArchivedPosts);
      clearArrayState(starAwardArchivedPosts, setStarAwardArchivedPosts);
      clearArrayState(topPricedPosts, setTopPricedPosts);
      clearArrayState(bestSellingPosts, setBestSellingPosts);
      clearArrayState(starAwardPosts, setStarAwardPosts);
      clearArrayState(buyerOrders, setBuyerOrders);

      clearFetchedPostIds();
      clearFetchedStarPostingIds();
      clearFetchedMusicPostIds();
    };

    // Reset music-related states
    const clearMusicStates = () => {
      clearArrayState(musicPosts, setMusicPosts);
      setMusicPage(1);
      setYourMusicPage(1);
      handleCancelSearchForm();
    };

    // Clear messages-related data
    const clearMessagesAndChat = () => {
      if (currentConversationId) {
        [
          "currentConversationId",
          "currentRecipient",
          "selectedUser",
        ].forEach((key) => {
          localStorage.removeItem(key);
        });
        setMessagePage(1);
        clearArrayState(messages, setMessages);
        clearFetchedMessageIds();
        setCurrentConversationId(null);
        setCurrentRecipient(null);
        setSelectedUser(null);
      }
    };

    // Clear sold-out posts and orders-related states
    const clearSoldOutAndOrders = () => {
      clearArrayState(soldOutPosts, setSoldOutPosts);
      clearFetchedSoldOutIds();
      setStarPostingSoldOutPage(1);
      setOrderPage(1);
      clearFetchedOrderIds();
      clearArrayState(orders, setOrders);
    };

    // Clear subscription and transaction-related states
    const clearSubscriptionAndTransactions = () => {
      setIsSubscribed(null);
      clearFetchedTransactionIds();
      setTransactionPage(1);
      setCombinedTransactions([]);
    };

    // Clear notifications and subscribers
    const clearNotificationsAndSubscribers = () => {
      clearFetchedNotificationsIds();
      setNotificationPage(1);
      clearArrayState(notifications, setNotifications);
      clearFetchedSubscribersIds();
      clearfetchedSubscriptionIds();
      setSubscriberPage(1);
      setSubscribedPage(1);
      clearArrayState(subscribers, setSubscribers);
      clearArrayState(subscriptions, setSubscriptions);
    };

    // Execute clearing functions
    clearChatAndHeader();
    clearProfile();
    clearPostsAndPages();
    clearMusicStates();
    clearMessagesAndChat();
    clearSoldOutAndOrders();
    clearSubscriptionAndTransactions();
    clearNotificationsAndSubscribers();
  };

   if (role === null) {
     return <FooterSkeleton />;
   }

  return (
    <div id="footer">
      <div className="footer-item">
        <Link
          to="/home"
          onClick={(e) => handleFooterClick(e, "home")}
          className={`link-text ${activeItem === "home" ? "active" : ""}`}
        >
          <BiHome size={20} />
        </Link>
        <span className="linkBarFooter-text">Home</span>
      </div>
      {role === "star" ? (
        <>
          <div className="footer-item">
            <Link
              to="/subscriber"
              onClick={(e) => handleFooterClick(e, "subscriber")}
              className={`link-text ${
                activeItem === "subscriber" ? "active" : ""
              }`}
            >
              <BiGroup size={20} />
            </Link>
            <span className="linkBarFooter-text">Subscribers</span>
          </div>
          <Link
            to="/upload"
            onClick={(e) => handleFooterClick(e, "upload")}
            className={`upload ${activeItem === "upload" ? "active" : ""}`}
          >
            <BiUpload size={25} />
          </Link>
          <div className="footer-item">
            <Link
              to={`/${userData.username}`}
              onClick={(e) => handleFooterClick(e, "your-shop")}
              className={`link-text ${
                activeItem === "your-shop" ? "active" : ""
              }`}
            >
              <BiStore size={20} />
            </Link>
            <span className="linkBarFooter-text">Your Shop</span>
          </div>
        </>
      ) : (
        <>
          <div className="footer-item">
            <Link
              to="/subscribed"
              onClick={(e) => handleFooterClick(e, "subscribed")}
              className={`link-text ${
                activeItem === "subscribed" ? "active" : ""
              }`}
            >
              <AiOutlineStar size={20} />
            </Link>
            <span className="linkBarFooter-text">Subscribed</span>
          </div>
          <div className="footer-item">
            <Link
              to="/you-own"
              onClick={(e) => handleFooterClick(e, "you-own")}
              className={`link-text ${
                activeItem === "you-own" ? "active" : ""
              }`}
            >
              <BiCrown size={20} />
            </Link>
            <span className="linkBarFooter-text">You Own</span>
          </div>
        </>
      )}
      <div className="footer-item">
        <Link
          to="/start-chat"
          onClick={(e) => handleFooterClick(e, "start-chat")}
          className={`link-text start-chat-link-footer ${
            activeItem === "start-chat" ? "active" : ""
          }`}
        >
          <BiMessageDetail size={20} />
        </Link>
        {totalCombinedCount > 0 && (
          <p className="totalCombinedCount-badge-count-footer-item">
            {totalCombinedCount}
          </p>
        )}
        <span className="linkBarFooter-text">
          {pinnedConversations.length === 0 ? "Start a Chat" : "Messages"}
        </span>
      </div>
      {/* More footer navigation links */}
    </div>
  );
}

//SideBar3
const Sidebar3 = forwardRef((props, ref) => {
  const {handleShareGooofan, shareMessage } = useSidebar3();
  const [userRole, setUserRole] = useState("");
  const { role, loading, error } = useUserRole();
  const { imageFailed, userData, setImageFailed, isWideScreen } =
    useChatAndMusicPosts();
  const { handleLogout } = useUserLogout();

  //API For validating user if is super user or staff
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await fetch("/api/admin_auth/");
        if (response.ok) {
          const data = await response.json();
          setUserRole(data.role);
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchUserRole();
  }, []);

  return (
    <div
      id="mySidebar3"
      className={`sidebar3 ${props.isOpen ? "open" : ""}`}
      ref={ref}
    >
      <div className="sideBar3-profile">
        <div className="profile">
          {userData.profile_picture && !imageFailed ? (
            <img
              src={userData.profile_picture}
              alt={userData.first_name}
              className="profile-picture"
              onError={() => setImageFailed(true)}
            />
          ) : (
            <div className="profile-picture no-profile-picture">
              {userData.first_name
                ? userData.first_name.slice(0, 1).toUpperCase()
                : null}
            </div>
          )}
        </div>
        <div className="sidebar3_username-display">{userData.username}</div>
      </div>

      {/* Conditionally render "You Own" and "Subscribed" links */}
      {role === "star" && (
        <>
          <Link to={isWideScreen ? "/home/you-own" : "/you-own"}>
            <BiCrown size={22} />
            <span className="link-text-sidebar3">You Own</span>
          </Link>

          <Link to={isWideScreen ? "/home/subscribed" : "/subscribed"}>
            <AiOutlineStar size={22} />
            <span className="link-text-sidebar3">Subscribed</span>
          </Link>
        </>
      )}

      <Link to={isWideScreen ? "/home/account" : "/account"}>
        <BiUser size={22} />
        <span className="link-text-sidebar3">Account</span>
      </Link>

      <Link to={isWideScreen ? "/home/report" : "/report"}>
        <BiFlag size={22} />
        <span className="link-text-sidebar3">Report</span>
      </Link>

      <a href="/faq/">
        <BiHelpCircle size={22} />
        <span className="link-text-sidebar3">Gooofan FAQ</span>
      </a>

      <a href="/privacy-policy/">
        <BiLock size={22} />
        <span className="link-text-sidebar3">Terms & Privacy</span>
      </a>

      <hr className="separator-line" />

      <div>
        <Link onClick={handleShareGooofan}>
          <BiShare size={22} />
          <span className="link-text-sidebar3">Share Gooofan</span>
        </Link>

        {/*show the share message when it appears */}
        {shareMessage && <p className="share-message">{shareMessage}</p>}
      </div>

      {/* Log out link */}
      <Link onClick={handleLogout}>
        <BiLogOut size={22} />
        <span className="link-text-sidebar3">Log out</span>
      </Link>

      {/* Conditionally render the icon and link based on user's role */}
      {userRole === "staff" || userRole === "superuser" ? (
        <Link to="/adminGooofan/homescreen" className="Admin-section">
          <BiShield size={22} />
        </Link>
      ) : null}
    </div>
  );
});

export default Navigation;

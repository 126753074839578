import React, { createContext, useContext, useEffect, useState } from "react";

// Create a context
const UserRoleContext = createContext();

export const UserRoleProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("/api/user-role/", { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user role");
        }
        return response.json();
      })
      .then((data) => {
        setRole(data.role);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError("Unable to determine user role.");
        setLoading(false);
      });
  }, []);

  return (
    <UserRoleContext.Provider value={{ role, loading, error }}>
      {children}
    </UserRoleContext.Provider>
  );
};

// Custom hook for consuming context
export const useUserRole = () => useContext(UserRoleContext);

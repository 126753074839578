import React, { createContext, useState, useContext, useEffect } from 'react';


// Create the context
const OwnModalsContext = createContext();

// Create a provider component
export const OwnModalsProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [country, setCountry] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState('');
  const [houseApartment, setHouseApartment] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [deliveryType, setDeliveryType] = useState({ value: 'selfPickUp', label: 'Self Pick-up' });
  const [favoriteBusOrFerry, setFavoriteBusOrFerry] = useState(null);
  const [favoriteCourier, setFavoriteCourier] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Goofan Wallet');
  const [placesError, setPlacesError] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [totalCostInUSD, setTotalCostInUSD] = useState(null); 
  const [offerPage, setOfferPage] = useState(1);
  const [orderNotificationPage, setOrderNotificationPage] = useState(1);
  const [walletBalance, setWalletBalance] = useState(0);
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false);
  const [distanceLoading, setDistanceLoading] = useState(false);


 const fetchUserBalance = () => {
   if (selectedPaymentMethod === "Goofan Wallet") {
     // Fetch the user balance
     fetch("/api/user-balance/", {
       method: "GET",
     })
       .then((response) => response.json())
       .then((data) => {
         // Ensure the data is an array and contains at least one entry
         if (Array.isArray(data) && data.length > 0) {
           // Convert the balance to a numeric value before setting it
           setWalletBalance(parseFloat(data[0].balance));
         } else {
           setWalletBalance(0);
         }
       })
       .catch((err) => {
         console.error("Failed to fetch wallet balance", err);
       });
   }
 };

  return (
    <OwnModalsContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        isModalConfirmOpen,
        setIsModalConfirmOpen,
        isPaymentMethodModalOpen,
        setIsPaymentMethodModalOpen,
        isNotificationModalOpen,
        setIsNotificationModalOpen,
        country,
        setCountry,
        countryCode,
        setCountryCode,
        address,
        setAddress,
        zipCode,
        setZipCode,
        houseApartment,
        setHouseApartment,
        contactNumber,
        setContactNumber,
        isScriptLoaded,
        setIsScriptLoaded,
        deliveryType,
        setDeliveryType,
        favoriteBusOrFerry,
        setFavoriteBusOrFerry,
        favoriteCourier,
        setFavoriteCourier,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        placesError,
        setPlacesError,
        deliveryDetails,
        setDeliveryDetails,
        offerPage,
        setOfferPage,
        totalCostInUSD,
        setTotalCostInUSD,
        walletBalance,
        setWalletBalance,
        fetchUserBalance,
        orderNotificationPage,
        setOrderNotificationPage,
        exchangeRateLoading,
        setExchangeRateLoading,
        distanceLoading,
        setDistanceLoading,
      }}
    >
      {children}
    </OwnModalsContext.Provider>
  );
};

export const useOwnModalsContext  = () => useContext(OwnModalsContext);


import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Admin.css'; 

function PostStarUpdateNav() {
  const location = useLocation();

  return (
     <div className="Poststar-links-container">
      <Link to="/adminGooofan/star-nav/posts/updates/best-selling" className={location.pathname === "/adminGooofan/star-nav/posts/updates/best-selling" ? "active-section-admin" : ""}>Ps. best selling</Link>
      <Link to="/adminGooofan/star-nav/posts/updates/top-priced" className={location.pathname === "/adminGooofan/star-nav/posts/updates/top-priced" ? "active-section-admin" : ""}> Ps. top priced</Link>
      <Link to="/adminGooofan/star-nav/posts/updates/star-award" className={location.pathname === "/adminGooofan/star-nav/posts/updates/star-award" ? "active-section-admin" : ""}>Ps. star award</Link>
      </div>
  );
}

export default PostStarUpdateNav;
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { useUserLocation } from '../GetUserCurrentLocationContext';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './YourShopSoldOut.css'
import GlobalLazyImage from '../GlobalLazyImage';
import VideoPlayer from '../VideoPlayer';
import useAlignSpinner from "../hooks/useAlignSpinner";
import {
  BiLink,
  BiCheck,
  BiShare,
  BiDotsVerticalRounded,
  BiArrowBack,
} from "react-icons/bi";
import { FaExclamationCircle } from "react-icons/fa";

function YourShopSoldOut() {
  const [isLoading, setIsLoading] = useState(false);
  const { username, product_code } = useParams();
  const { userLocation } = useUserLocation();
  const soldOutRefs = useRef({});
  const [userData, setUserData] = useState({});
  const [imageFailed, setImageFailed] = useState(false);
  const [isMakeAnOfferModalOpen, setIsMakeAnOfferModalOpen] = useState(false);
  const [selectedMakeAnOfferProduct, setSelectedMakeAnOfferProduct] =
    useState(null);

  const [firstLoadSoldOut, setFirstLoadSoldOut] = useState(true);
  const [scrollLoadingSoldOut, setScrollLoadingSoldOut] = useState(false);
  const observerSoldOut = useRef();
  const [hasMoreStarPostingSoldOut, setHasMoreStarPostingSoldOut] =
    useState(true);

  const {
    postStarContainerRef,
    setShowHeader,
    setIsFullScreenPreview,
    setCurrentPostId,
    userCurrency,
    setUserCurrency,
    isLocationFetched,
    setIsLocationFetched,
    fetchedSoldOutIds,
    soldOutPosts,
    setSoldOutPosts,
    starPostingSoldOutPage,
    setStarPostingSoldOutPage,
    currencyMapping,
    currentPostId,
    showMenu,
    setShowMenu,
    menuRef,
    copyLinkText,
    setCopyLinkText,
    iconClass,
    setIconClass,
    sharePostText,
    setSharePostText,
    shareIconClass,
    setShareIconClass,
    expandedSection,
    setExpandedSection,
    expandedPost,
    setExpandedPost,
    currentUserData,
    offerSocketRef,
  } = useContext(YourShopHeaderPostContext);

  //hook to align the spinner center horizontally
  useAlignSpinner(postStarContainerRef, ".loader-container");

  //Get user data based with paremeter
  useEffect(() => {
    fetch(`/api/users_data/${username}/`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setImageFailed(false);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, [username]);

  // Function to generate the URL for fetching sold-out posts
  const generateSoldOutUrl = (page, productCode) => {
    let baseUrl = `/api/StarPostings/user/${username}/?page=${page}&is_soldout=true`;
    if (productCode) {
      baseUrl = `/api/StarPostings/user/${username}/?product_code=${encodeURIComponent(
        productCode
      )}&page=${page}&is_soldout=true`;
    }
    return baseUrl;
  };

  // Function to fetch the sold-out posts data
  const fetchSoldOutPostsData = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error fetching sold-out posts: ${response.status}`);
    }
    return response.json();
  };

  // Function to process the fetched sold-out data
  const processSoldOutData = (data) => {
    if (data && data.results) {
      const newSoldOutItems = data.results.filter((item) => {
        if (!fetchedSoldOutIds.has(item.id)) {
          fetchedSoldOutIds.add(item.id);
          return true;
        }
        return false;
      });

      return newSoldOutItems.map((item) => {
        const total_cost =
          item.converted_total_cost && item.converted_total_cost[userCurrency]
            ? item.converted_total_cost[userCurrency]
            : item.total_cost;
        return {
          ...item,
          currency: userCurrency,
          total_cost: total_cost,
          showStory: true,
          showDescription: false,
          readMore: false,
        };
      });
    } else {
      console.error("Data structure is not as expected:", data);
      return [];
    }
  };

  // Function to update the state with the fetched data
  const updateSoldOutState = (updatedData, productCode, data) => {
    setSoldOutPosts((prevPosts) => [...prevPosts, ...updatedData]);
    setHasMoreStarPostingSoldOut(productCode ? false : data.next !== null);

    if (productCode) {
      const post = updatedData.find(
        (post) => post.product_code === productCode
      );
      if (post) {
        setCurrentPostId(post.id);
        setIsFullScreenPreview(true);
      }
    }

    if (data.page) {
      setStarPostingSoldOutPage(data.page);
    }
  };

  useEffect(() => {
    const fetchSoldOutPosts = async (page, productCode = null) => {
      if (firstLoadSoldOut) {
        setIsLoading(true);
      } else {
        setScrollLoadingSoldOut(true);
      }

      try {
        const url = generateSoldOutUrl(page, productCode);
        const data = await fetchSoldOutPostsData(url);
        const updatedData = processSoldOutData(data);

        updateSoldOutState(updatedData, productCode, data);
      } catch (error) {
        console.error("Error fetching sold-out posts:", error);
        setHasMoreStarPostingSoldOut(false);
      } finally {
        if (firstLoadSoldOut) {
          setIsLoading(false);
          setFirstLoadSoldOut(false);
        }
        setScrollLoadingSoldOut(false);
      }
    };

    if (isLocationFetched) {
      if (product_code) {
        fetchSoldOutPosts(
          starPostingSoldOutPage,
          decodeURIComponent(product_code)
        );
      } else {
        fetchSoldOutPosts(starPostingSoldOutPage);
      }
    }
  }, [starPostingSoldOutPage, username, product_code, isLocationFetched]);

  useEffect(() => {
    async function fetchUserCurrency() {
      if (userLocation) {
        const { country } = userLocation;
        const currency = currencyMapping[country] || "USD";
        setUserCurrency(currency);
        setIsLocationFetched(true);
      }
    }

    fetchUserCurrency();
  }, [userLocation]);

  const setCombinedSoldOutRef = (node, id) => {
    soldOutRefs.current[id] = node;
    if (soldOutPosts[soldOutPosts.length - 1].id === id) {
      lastSoldOutElementRef(node);
    }
  };

  const lastSoldOutElementRef = useCallback(
    (node) => {
      if (scrollLoadingSoldOut) return;
      if (observerSoldOut.current) observerSoldOut.current.disconnect();
      observerSoldOut.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreStarPostingSoldOut) {
          setStarPostingSoldOutPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerSoldOut.current.observe(node);
    },
    [scrollLoadingSoldOut, hasMoreStarPostingSoldOut]
  );

  const toggleStory = (id) => {
    setExpandedSection((prevState) => {
      let newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== id) {
          newState[key] = { ...newState[key], story: false };
        }
      });
      return {
        ...newState,
        [id]: { story: !newState[id]?.story },
      };
    });
  };

  const toggleDescription = (id) => {
    setExpandedSection((prevState) => {
      let newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== id) {
          newState[key] = { ...newState[key], description: false };
        }
      });
      return {
        ...newState,
        [id]: { description: !newState[id]?.description },
      };
    });
  };

  const toggleReadMore = (id) => {
    setSoldOutPosts((prevState) =>
      prevState.map((post) =>
        post.id === id ? { ...post, readMore: !post.readMore } : post
      )
    );
    setExpandedPost(id);
    setExpandedSection((prevState) => ({
      ...prevState,
      [id]: { story: true },
    }));
  };

  const handleThumbnailClick = (id) => {
    const postIndex = soldOutPosts.findIndex((post) => post.id === id);
    if (postIndex !== -1) {
      setCurrentPostId(id);
      setShowHeader(false);
      setIsFullScreenPreview(true);
    }
  };

  const closeFullScreenPreview = () => {
    setCurrentPostId(null);
    setShowHeader(true);
    setIsFullScreenPreview(false);
  };

  useEffect(() => {
    if (currentPostId !== null && soldOutRefs.current[currentPostId]) {
      soldOutRefs.current[currentPostId].scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [currentPostId]);

  useEffect(() => {
    if (soldOutPosts.length > 0) {
      // Create a new showMenu object with a key for each post ID
      const newShowMenu = soldOutPosts.reduce((acc, post) => {
        acc[post.id] = false;
        return acc;
      }, {});
      setShowMenu(newShowMenu);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [soldOutPosts]);

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      // Close all menus
      const newShowMenu = { ...showMenu };
      Object.keys(newShowMenu).forEach((id) => {
        newShowMenu[id] = false;
      });
      setShowMenu(newShowMenu);
    }
  };

  const handleCopyLink = (id) => {
    const post = soldOutPosts.find((post) => post.id === id);
    const url = post?.full_post_url;

    navigator.clipboard.writeText(url).then(() => {
      setCopyLinkText("Copied to clipboard");
      setIconClass(<BiCheck />);

      setTimeout(() => {
        setCopyLinkText("Copy link");
        setIconClass(<BiLink />);

        setShowMenu((prevShowMenu) => {
          const newShowMenu = { ...prevShowMenu };
          newShowMenu[id] = false;
          return newShowMenu;
        });
      }, 2000);
    });
  };

  const handleSharePost = (id) => {
    const post = soldOutPosts.find((post) => post.id === id);
    const url = post.full_post_url;

    if (navigator.share) {
      navigator
        .share({
          title: `Check out this post by ${userData.username}`,
          text: "Check out this post!",
          url: url,
        })
        .then(() => {
          setShowMenu((prevShowMenu) => {
            const newShowMenu = { ...prevShowMenu };
            newShowMenu[id] = false;
            return newShowMenu;
          });
        })
        .catch((error) => {
          navigator.clipboard.writeText(url).then(() => {
            setSharePostText("Post URL copied to clipboard");
            setShareIconClass(<BiCheck />);

            setTimeout(() => {
              setSharePostText("Share");
              setShareIconClass(<BiShare />);
              setShowMenu((prevShowMenu) => {
                const newShowMenu = { ...prevShowMenu };
                newShowMenu[id] = false;
                return newShowMenu;
              });
            }, 2000);
          });
        });
    } else {
      navigator.clipboard.writeText(url).then(() => {
        setSharePostText("Unsupported browser");
        setShareIconClass(<FaExclamationCircle />);

        setTimeout(() => {
          setSharePostText("Share");
          setShareIconClass(<BiShare />);
          setShowMenu((prevShowMenu) => {
            const newShowMenu = { ...prevShowMenu };
            newShowMenu[id] = false;
            return newShowMenu;
          });
        }, 2000);
      });
    }
  };

  const handleToggleMenu = (id) => {
    setShowMenu((prevShowMenu) => {
      // Close all other open menus
      const newShowMenu = Object.keys(prevShowMenu).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Open the menu for the current post
      newShowMenu[id] = !prevShowMenu[id];

      return newShowMenu;
    });
  };

  const handleOpenMakeAnOfferModal = (post) => {
    setSelectedMakeAnOfferProduct(post);
    setIsMakeAnOfferModalOpen(true);
  };

  const handleCloseModalMakeAnOffer = () => {
    setIsMakeAnOfferModalOpen(false);
    setSelectedMakeAnOfferProduct(null);
  };

  const handleSendOffer = (e) => {
    e.preventDefault();
    const offerAmount = e.target["offer-amount"].value;
    const sellerAddress =
      selectedMakeAnOfferProduct?.latest_order?.buyer_address;

    if (offerSocketRef.current && offerAmount) {
      const offerMessage = {
        action: "make_offer",
        seller: selectedMakeAnOfferProduct?.current_owner?.username,
        seller_address: sellerAddress,
        product_id: selectedMakeAnOfferProduct?.id,
        buyer: currentUserData.username,
        product_code: selectedMakeAnOfferProduct?.product_code,
        product_name: selectedMakeAnOfferProduct?.product_name,
        currency: selectedMakeAnOfferProduct?.currency,
        offer_amount: offerAmount,
      };

      offerSocketRef.current.send(JSON.stringify(offerMessage));
      setIsMakeAnOfferModalOpen(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : soldOutPosts.length > 0 ? (
        <>
          {currentPostId === null ? (
            <div className="grid-container">
              {soldOutPosts.map((post) => {
                return (
                  <div
                    key={post.id}
                    className="grid-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleThumbnailClick(post.id);
                    }}
                    ref={(node) => setCombinedSoldOutRef(node, post.id)}
                  >
                    {post.images && post.images.length > 0 ? (
                      <GlobalLazyImage src={post.images[0].image} alt="" />
                    ) : post.videos && post.videos.length > 0 ? (
                      <VideoPlayer src={post.videos[0].video} preload="none" />
                    ) : null}
                  </div>
                );
              })}
              <div className="scroll-loader-spinner-container">
                {scrollLoadingSoldOut && (
                  <div className="scroll-loader-spinner"></div>
                )}
              </div>
            </div>
          ) : (
            <div className="full-screen-preview">
              <div className="posts-container">
                {soldOutPosts.map((post) => {
                  const convertedCost =
                    post.latest_order?.converted_total_cost &&
                    post.latest_order?.converted_total_cost[post.currency];
                  const displayTotalCost = convertedCost
                    ? convertedCost.toFixed(2)
                    : null;
                  const orderCountry = post.latest_order
                    ? post.latest_order.country
                    : null;

                  return (
                    <div
                      key={post.id}
                      className="post-container"
                      ref={(node) => setCombinedSoldOutRef(node, post.id)}
                    >
                      <div className="post-header">
                        <div className="yourshop_post_profile">
                          <div className="profile">
                            {post?.current_owner.profile_picture &&
                            !imageFailed ? (
                              <img
                                src={post?.current_owner.profile_picture}
                                alt={post?.current_owner.first_name}
                                className="profile-picture"
                                onError={() => setImageFailed(true)}
                              />
                            ) : (
                              <div className="profile-picture no-profile-picture">
                                {post?.current_owner.first_name
                                  ? post?.current_owner.first_name
                                      .slice(0, 1)
                                      .toUpperCase()
                                  : null}
                              </div>
                            )}
                          </div>
                          <div className="yourshop_post_username">
                            {post?.current_owner.username}
                          </div>
                        </div>
                        <div className="post-menu" ref={menuRef}>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleToggleMenu(post.id);
                            }}
                          >
                            <BiDotsVerticalRounded size={20} />
                          </button>
                          {showMenu[post.id] && (
                            <div
                              className={`menu ${
                                showMenu[post.id] ? "show" : ""
                              }`}
                            >
                              <div
                                className="share-post-copy"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCopyLink(post.id);
                                }}
                              >
                                {iconClass}
                                <span className="copy-link">
                                  {copyLinkText}
                                </span>
                              </div>
                              <div
                                className="share-post"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSharePost(post.id);
                                }}
                              >
                                {shareIconClass}
                                <span className="share-post-word">
                                  {sharePostText}
                                </span>
                              </div>

                              {/* Other menu items... */}
                            </div>
                          )}
                        </div>
                      </div>
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        observer={true}
                        observeParents={true}
                        initialSlide={0}
                      >
                        {post.images &&
                          post.images.map((image, imageIndex) => (
                            <SwiperSlide key={imageIndex}>
                              <img
                                src={image.image}
                                alt={`Post ${post.id}, Image ${imageIndex + 1}`}
                                className="postStar-media"
                                loading="lazy"
                              />
                            </SwiperSlide>
                          ))}

                        {post.videos &&
                          post.videos.map((video, videoIndex) => (
                            <SwiperSlide key={videoIndex}>
                              <VideoPlayer
                                src={video.video}
                                className="postStar-media"
                                preload="none"
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                      <div className="container-post-details">
                        <div className="container-name-code">
                          <p className="product_name">{post.product_name}</p>
                          <p className="product_code">{post.product_code}</p>
                        </div>
                        <div className="container-price-buy">
                          {displayTotalCost && (
                            <p>
                              <span className="currency">{post.currency}</span>{" "}
                              <span className="price">{displayTotalCost}</span>
                            </p>
                          )}
                          <button
                            onClick={() => handleOpenMakeAnOfferModal(post)}
                          >
                            Make an offer
                          </button>
                        </div>
                      </div>
                      <div className="container-story-description">
                        <div className="story">
                          <p
                            onClick={() => toggleStory(post.id)}
                            className={
                              expandedSection[post.id]?.story
                                ? "active-tab"
                                : ""
                            }
                          >
                            Story
                          </p>
                        </div>
                        <div className="description">
                          <p
                            onClick={() => toggleDescription(post.id)}
                            className={
                              expandedSection[post.id]?.description
                                ? "active-tab"
                                : ""
                            }
                          >
                            Description
                          </p>
                        </div>
                      </div>
                      {(expandedSection[post.id]?.story ||
                        !expandedSection[post.id]?.description) && (
                        <div className="full-width">
                          <p className="story-text">
                            {post.readMore
                              ? post.product_story
                              : `${post.product_story.substring(0, 50)}${
                                  post.product_story.length > 50 ? "..." : ""
                                }`}
                            {post.product_story.length > 50 &&
                              expandedPost !== post.id &&
                              !post.readMore && (
                                <span
                                  onClick={() => toggleReadMore(post.id)}
                                  className="read-more"
                                >
                                  {" "}
                                  Read More
                                </span>
                              )}
                          </p>
                        </div>
                      )}

                      {expandedSection[post.id]?.description && (
                        <div className="full-width">
                          <div className="category_availability">
                            <p>
                              <span className="label">Category:</span>{" "}
                              <span className="data">
                                {post.product_category}
                              </span>
                            </p>
                            <p>
                              <span className="label">Qty available:</span>{" "}
                              <span className="data">
                                {post.product_availability}
                              </span>
                            </p>
                          </div>

                          {(post.length || post.width || post.height) && (
                            <div className="dimensions_weight">
                              <p>
                                <span className="label">
                                  Dimensions (L × W × H):{" "}
                                </span>
                                <span className="data">
                                  {post.length ? `${post.length}` : "-"} ×
                                  {post.width ? `${post.width}` : "-"} ×
                                  {post.height ? `${post.height}` : "-"}
                                </span>
                                <span className="data"> {post.size_unit}</span>
                              </p>
                            </div>
                          )}

                          <div className="weight">
                            <p>
                              <span className="label">Weight:</span>{" "}
                              <span className="data">{post.weight_value}</span>{" "}
                              <span className="data">{post.weight_unit}</span>
                            </p>
                          </div>

                          <div className="location_condition">
                            {orderCountry && (
                              <p>
                                <span className="label">Located at:</span>{" "}
                                <span className="data">{orderCountry}</span>
                              </p>
                            )}
                            <p>
                              <span className="label">Condition:</span>{" "}
                              <span className="data">{post.condition}</span>
                            </p>
                          </div>

                          {post.condition_description && (
                            <p>
                              <span className="imperfect_description">
                                Imperfect description:
                              </span>{" "}
                              <span className="imperfect_description_data">
                                {post.condition_description}
                              </span>
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="scroll-loader-spinner-container">
                  {scrollLoadingSoldOut && (
                    <div className="scroll-loader-spinner"></div>
                  )}
                </div>
              </div>
              <div className="back-button-container">
                <button
                  className="back-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    closeFullScreenPreview();
                  }}
                >
                  <BiArrowBack
                    size={20}
                    color="currentColor"
                    className="bi bi-arrow-left"
                  />{" "}
                  <span>Sold Out</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="no-posts">No posts available.</p>
      )}
      <div className="scroll-loader-spinner-container">
        {scrollLoadingSoldOut && <div className="scroll-loader-spinner"></div>}
      </div>
      {isMakeAnOfferModalOpen && (
        <div
          className="make-an-offer-modal-overlay"
          onClick={handleCloseModalMakeAnOffer}
        >
          <div
            className="make-an-offer-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="make-an-offer-modal-header">
              <div className="empty-div-change-it"></div>
              <h4 className="text-make-an-offer">Enter your offer</h4>
              <div className="empty-div-change-it"></div>
            </div>
            <div className="make-an-offer-info">
              <form onSubmit={handleSendOffer}>
                <input
                  type="number"
                  className="form-control"
                  id="offer-amount"
                  name="offer-amount"
                  placeholder={`Enter the amount in ${selectedMakeAnOfferProduct.currency}`}
                />
                <button
                  type="submit"
                  id="sendoffer"
                  name="sendoffer"
                  className="offer-btn-send"
                >
                  Send Offer
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default YourShopSoldOut;


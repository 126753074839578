// components/StarHome/Statistics2.jsx
//Used for screebs above 992px
import React, { useEffect, useState } from 'react';
import { useLiveVisitors } from '../LiveVisitorsContext';
import { useNavigate } from 'react-router-dom';
import { BiUser } from "react-icons/bi"; 

function Statistics2() {
  const { liveVisitors } = useLiveVisitors();
  const navigate = useNavigate();
  const [percentageEarning, setPercentageEarning] = useState({
    percentage_earning: 0,
  });
  

   const formatVisitorsCount = (count) => {
    if (count >= 1000000000) {
      return `${(count / 1000000000).toFixed(1)}B`;
    } else if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    } else if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}k`;
    } else {
      return count.toString();
    }
  };

   const handleOpenAllStaticsClick = () => {
     navigate("/home/all-statics");
   };

   useEffect(() => {
     const fetchPercentageChange = async () => {
       try {
         const response = await fetch(
           "/api/balance-history/percentage_change/"
         );
         if (!response.ok) {
           throw new Error("Network response was not ok");
         }
         const data = await response.json();
         setPercentageEarning({ percentage_earning: data.percentage_earning });
       } catch (error) {
         console.error("Error fetching percentage change:", error);
       }
     };

     fetchPercentageChange();
   }, []);

   const percentageChange = percentageEarning.percentage_earning || 0;
  
  return (
    <div className="container-statistic2">
      <div
        className="live-visitors"
        onClick={(e) => {
          e.stopPropagation();
          handleOpenAllStaticsClick();
        }}
      >
        <div className="lv-earnings">
          <div className="earning-text">Earnings:</div>
          <span
            style={{
              color: percentageChange >= 0 ? "#4caf50" : "red",
            }}
          >
            {percentageChange >= 0 ? "+" : ""}
            {percentageChange.toFixed(2)}%
          </span>
        </div>
        <div className="lv-visitors">
          <div className="visitors-text">Live Visitors:</div>
          <span className="lv-svg-visitors">
            <BiUser size={18} />
          </span>
          <span className="visitors-total">
            {" "}
            {formatVisitorsCount(liveVisitors)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Statistics2;

import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import MainVideoControls from "../MainVideoControls";
import "./UploadPostModal.css";
import { useUploadPost } from "./UploadPostContext";
import {AiOutlineClose } from "react-icons/ai";

const UploadPostModal = ({}) => {
  const {
    selectedMedia,
    setSelectedMedia,
    currentImageIndex,
    modalIsOpen,
    setModalIsOpen,
    isVideo,
    isPlaying,
    setIsPlaying,
    videoRef,
    setCropModified,
    setIsCropped,
    isReverted,
    setIsReverted,
    setSavedCroppedImage,
    originalURLs,
    onCropModified,
    isSaveDisabled,
    cropper,
    setCropper,
    croppedImage,
    setCroppedImage,
    previewImages,
    setPreviewImages,
    postData,
    setPostData,
    dataURLtoBlob,
  } = useUploadPost();

  //function to save cropped image
  const handleSave = async () => {
    const currentOriginalURL = originalURLs[currentImageIndex];
    let imageToSave;

    // Check if cropper instance exists and get the cropped image
    if (cropper && cropper.getCroppedCanvas()) {
      // Ensuring the cropped canvas is available before getting the data URL
      const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
      imageToSave = croppedDataUrl;
      setCroppedImage(croppedDataUrl); // Update the croppedImage state
    } else {
      // Fall back to the original or previously cropped image
      imageToSave = isReverted
        ? currentOriginalURL
        : croppedImage || currentOriginalURL;
    }

    if (imageToSave) {
      // Update the previewImages state with the new cropped image URL
      setPreviewImages((prevPreviewImages) =>
        prevPreviewImages.map((url, index) =>
          index === currentImageIndex ? imageToSave : url
        )
      );

      // Convert data URL to blob
      const croppedBlob = dataURLtoBlob(imageToSave);

      // Replace the original file with the blob created from the saved image URL
      const newMediaFiles = postData.media_files.map((file, index) => {
        return index === currentImageIndex ? croppedBlob : file;
      });
      setPostData({ ...postData, media_files: newMediaFiles });

      // Save the cropped image
      setSavedCroppedImage(imageToSave);

      // Close the modal
      closeModal();

      // Reset state after saving
      setIsReverted(false);
      setIsCropped(false);
      setCropModified(false);
    } else {
      console.error("No image available to save.");
    }
  };

  const handleRevert = async () => {
    const currentOriginalURL = originalURLs[currentImageIndex];
    // Reset croppedImage state
    setCroppedImage(null);

    // Reset selected media to original URL
    setSelectedMedia(currentOriginalURL);

    // Replace the cropped image URL with the original image URL in the previewImages state
    const newPreviewImages = previewImages.map((url) =>
      url === croppedImage ? currentOriginalURL : url
    );
    setPreviewImages(newPreviewImages);

    // Convert blob URL to data URL if image is reverted
    const response = await fetch(currentOriginalURL);
    const blob = await response.blob();
    const originalDataUrl = await blobToDataURL(blob);

    // Replace the cropped image with the original image in the postData.media_files array
    const newMediaFiles = postData.media_files.map((file, index) =>
      index === currentImageIndex ? dataURLtoBlob(originalDataUrl) : file
    );
    setPostData({ ...postData, media_files: newMediaFiles });

    // Set isReverted to true when an image is reverted
    setIsReverted(true);
  };

  
  const onCrop = () => {
    // Check if cropper instance exists and get the cropped canvas
    if (cropper && cropper.getCroppedCanvas()) {
      const croppedCanvas = cropper.getCroppedCanvas();

      // Making sure croppedCanvas is valid before generating the data URL
      if (croppedCanvas) {
        const croppedImageDataURL = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageDataURL); // Update croppedImage state
        setIsCropped(true); // Set the cropping state to true
      } else {
        console.warn("Cropped canvas is null or undefined.");
      }
    } else {
      console.warn("Cropper is not available.");
    }
  };


  function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }


  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedMedia(null);
  };

  return (
    <div
      className={`upload-post-modal-overlay ${modalIsOpen ? "open" : ""}`}
      onClick={closeModal}
    >
      <div
        className="upload-post-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {(!isPlaying || !isVideo) && (
           <AiOutlineClose size={20} className="bi bi-x close-icon"  onClick={closeModal}/>
        )}
        {isVideo ? (
          <div>
            <MainVideoControls videoRef={videoRef} />
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              src={selectedMedia}
              type="video/mp4"
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
            />
          </div>
        ) : (
          <Cropper
            src={selectedMedia}
            style={{ height: "auto", width: "97%", marginLeft: 5 }}
            guides={true}
            crop={onCrop}
            cropend={() => {
              setIsCropped(true);
              onCropModified();
            }}
            zoomable={true}
            dragMode="crop"
            autoCropArea={1}
            onInitialized={(instance) => setCropper(instance)}
            className="uploadpost-cropper"
          />
        )}
        <div className="btn-modal">
          {!isVideo && (
            <>
              <button onClick={handleSave} disabled={isSaveDisabled}>
                Save
              </button>
              <button
                onClick={handleRevert}
                disabled={selectedMedia === originalURLs[currentImageIndex]}
              >
                Revert
              </button>
            </>
          )}
        </div>
      </div>
    </div>

  );
};

export default UploadPostModal;

import React, { useState } from 'react';
import LazyImage from './LazyImage';
import './StarHomeComponents/ContentsRecommendation.css';

function SearchResult({ user, onUserClick }) {
    const [imageFailed, setImageFailed] = useState(false);

    const handleClick = () => {
        if (onUserClick) {
            onUserClick(user.username);
        }
    };

    return (
        <div className="search-result-recommendation" onClick={handleClick}>
          {/* Render each search result user */}
            {user.profile_picture && !imageFailed ? (
                <LazyImage src={user.profile_picture} alt={user.first_name} className="profile-picture profile" onError={() => setImageFailed(true)} />
            ) : (
                <div className="profile-picture no-profile-picture-results">
                    {user.first_name ? user.first_name.slice(0, 1).toUpperCase() : "?"}
                </div>
            )}
           {/* Render username and full name */}
            <div className="user-wrapper">
                <span>{user.username}</span>
                <span className="full-name">{`${user.first_name} ${user.last_name}`}</span>
            </div>
        </div>
    );
}

export default SearchResult;




// import React, { useState } from 'react';
// import LazyImage from './LazyImage';
// import './StarHomeComponents/ContentsRecommendation.css';

// function SearchResult({ user }) {
//     const [imageFailed, setImageFailed] = useState(false);

//     return (
//         <div className="search-result-recommendation">
//           {/* Render each search result user */}
//             {user.profile_picture && !imageFailed ? (
//                 <LazyImage src={user.profile_picture} alt={user.first_name} className="profile-picture profile" onError={() => setImageFailed(true)} />
//             ) : (
//                 <div className="profile-picture no-profile-picture-results">
//                     {user.first_name ? user.first_name.slice(0, 1).toUpperCase() : null}
//                 </div>
//             )}
//            {/* Render username and full name */}
//             <div className="user-wrapper">
//                 <span>{user.username}</span>
//                 <span className="full-name">{`${user.first_name} ${user.last_name}`}</span>
//             </div>
//         </div>
//     );
// }

// export default SearchResult;


import React, { useEffect} from 'react';
import "./UpdatesStar.css";
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';
import { useLikesComments } from '../LikesCommentsContext';


function BestSellingPosts() {
   const {
    adminBestSellingPosts, setAdminBestSellingPosts,
     fetchPosts, starUpdateRenderPostAdmin,  page, setPageHasMore, scrollLoading,
  } = useLikesComments();
 
  useEffect(() => {
    fetchPosts('/api/stars_updates/best_selling/', setAdminBestSellingPosts, page, setPageHasMore);
  }, [page]);

    return (
      <> 
      <StarUpdateHeaderWithSearch
        category="best_selling"
        isArchived={false}
        nonArchived={true}
      />
      {starUpdateRenderPostAdmin(adminBestSellingPosts, setAdminBestSellingPosts)}
       <div className='scroll-loader-spinner-container'>
          {scrollLoading && <div className="scroll-loader-spinner"></div>}
       </div>
      </>
    );
}

export default BestSellingPosts;


import React, { useEffect, useRef, useState } from 'react';
import lozad from 'lozad';
import './SearchAllResults.css';

const placeholderSrc = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpteGxpbms9Imh0dHA6Ly93d3cub3JnLzE5OTkveGxpbmsiPg0KICAgIDxyZWN0IHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIiBmaWxsPSIjZjBmMGYwIi8+DQogICAgPHRleHQgeD0iMTAwIiB5PSIxMDAiIGZvbnQtc2l6ZT0iMjAiIGZvbnQtZmFtaWx5PSJBcmlhbCwgc2Fucy1zZXJpZiIgZmlsbD0iI2NjYyIgdGV4dC1hbmNob3I9Im1pZGRsZSIgYWxpZ25tLWJhc2VsaW5lPSJtaWRkbGUiPg0KICAgICAgICBMb2FkaW5nLi4uDQogICAgPC90ZXh0Pg0KPC9zdmc+';


function SearchLazyImage({ src, alt, className, onError }) {
    const imageRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        
        const observer = lozad(imageRef.current, {
            loaded: () => {
                setIsLoaded(true);
            },
        });
        observer.observe();

        return () => {
            observer.observer.disconnect();
        };
    }, [src]);

    return (
      <img
        ref={imageRef}
        data-src={src}
        src={placeholderSrc}
        alt={alt}
        className={`${className} ${isLoaded ? "" : "placeholder"}`}
        onError={onError}
      />
    );
}

export default SearchLazyImage;



import { useState, useEffect } from "react";
import FullPreviewModal from "./FullPreviewModal";
import { useLikesComments } from "../LikesCommentsContext";
import { useParams } from "react-router-dom";

function SharedStarUpdateModal() {
  const { state, urlUid_update } = useParams();
  const [starUpdateData, setStarUpdateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Destructure the necessary methods from LikesCommentsContext
  const {
    openFullPreviewModal,
  } = useLikesComments();

  // Function to fetch the star update data
  const fetchStarUpdateData = async () => {
    try {
      const response = await fetch(
        `/api/stars_updates/${state}/${urlUid_update}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch star update data");
      }

      const data = await response.json();
      setStarUpdateData(data);

      // Call openFullPreviewModal with the fetched data
      openFullPreviewModal(data, "shared-starupdate"); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStarUpdateData();
  }, [state, urlUid_update]);

  if (loading) return <div className="shared-loading">Loading...</div>;
  if (error)
    return (
      <div className="shared-error error-message">
        <i className="bi bi-exclamation-circle-fill error-icon"></i>
        {error}
      </div>
    );

  return (
    <div className="shared-starupdate-container">
      {/* FullPreviewModal to automatically open with the post */}
      <FullPreviewModal />
    </div>
  );
}

export default SharedStarUpdateModal;


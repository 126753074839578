import React from 'react';
import { Outlet } from 'react-router-dom';
import StarUpdateNavigation from './StarUpdateNavigation';
import './Admin.css';

function StarsUpdateOutlet() {
  return (
    <div>
      <StarUpdateNavigation />
      <>
      <Outlet />
      </>
    </div>
  );
}

export default StarsUpdateOutlet;

// YourShopHeaderPostContext.jsx
import React, { useState, createContext, useEffect, useRef, useCallback} from 'react';
import { BiLink, BiShare } from "react-icons/bi";
export const YourShopHeaderPostContext = createContext();

// Set to track already fetched sold-out IDs
const fetchedSoldOutIds = new Set();

// Set to track already fetched shop posts IDs
const fetchedStarPostingIds = new Set();


//Clearing the fetchedStarPostings
const clearFetchedStarPostingIds = () => {
  fetchedStarPostingIds.clear();
};


//Clearing the fetched sold out
const clearFetchedSoldOutIds = () => {
  fetchedSoldOutIds.clear();
};

//set to track already fetched orders ids
const fetchedOrderIds = new Set();

//clearing the fetched orders
const clearFetchedOrderIds = () => {
  fetchedOrderIds.clear();
};


//set to track already fetched transactions id
const fetchedTransactionSet = new Set(); 

//Clearing the transactions id
const clearFetchedTransactionIds = () => {
    fetchedTransactionSet.clear();
};
 
const fetchedNotificationsIds = new Set();
  // Function to clear the fetched notifications IDs
const clearFetchedNotificationsIds = () => {
  fetchedNotificationsIds.clear();
};

export const YourShopHeaderPostProvider = ({ children }) => {
  const [showHeader, setShowHeader] = useState(true);
  const [showMenu, setShowMenu] = useState({});
  const menuRef = useRef(null);
  const [copyLinkText, setCopyLinkText] = useState("Copy link");
  const [iconClass, setIconClass] = useState(<BiLink />);
  const [shareIconClass, setShareIconClass] = useState(<BiShare />);
  const [sharePostText, setSharePostText] = useState("Share");
  const [isFullScreenPreview, setIsFullScreenPreview] = useState(false);
  const [starPostingPage, setStarPostingPage] = useState(1);
  const [starPostingSoldOutPage, setStarPostingSoldOutPage] = useState(1);
  const [orderPage, setOrderPage] = useState(1);
  const [firstLoadOrder, setFirstLoadOrder] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollLoadingOrder, setScrollLoadingOrder] = useState(false);
  const [hasMoreOrder, setHasMoreOrder] = useState(true);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [posts, setPosts] = useState([]);
  const [soldOutPosts, setSoldOutPosts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedPostToBuy, setSelectedPostToBuy] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [currentUserData, setCurrentUserData] = useState({});
  const orderSocketRef = useRef(null);
  const offerSocketRef = useRef(null);
  const [userCurrency, setUserCurrency] = useState("USD");
  const [isLocationFetched, setIsLocationFetched] = useState(false);
  const [expandedSection, setExpandedSection] = useState({});
  const [expandedPost, setExpandedPost] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [paidCount, setPaidCount] = useState(0);
  const [transactionPage, setTransactionPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [notificationPage, setNotificationPage] = useState(1);
  const [hasMoreNotifications, setHasMoreNotifications] =useState(true);
  const [scrollLoadingNotifications, setScrollLoadingNotifications] = useState(false);
  const [newNotifications, setNewNotifications] = useState({});
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [combinedTransactions, setCombinedTransactions] = useState([]);
  const postStarContainerRef = useRef(null);
  const [orderCounts, setOrderCounts] = useState({
    totalOrders: 0,
    paidCount: 0,
    packagedCount: 0,
    onWayCount: 0,
    deliveredCount: 0,
    receivedCount: 0,
  });

  //Geg CSRFToken dublicated at UserLongoutContext
  function getCSRFToken() {
    const csrfTokenCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("csrftoken"));
    return csrfTokenCookie ? csrfTokenCookie.split("=")[1] : null;
  }

  const csrftoken = getCSRFToken();

  //Get user data without paremeter only rturn current user
  useEffect(() => {
    fetch("/api/get_user_profile_data/")
      .then((response) => response.json())
      .then((data) => {
        setCurrentUserData(data);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  //Establish WebSocket connection
  useEffect(() => {
    if (currentUserData?.username && !orderSocketRef.current) {
      orderSocketRef.current = new WebSocket(
        // `ws://localhost:8000/ws/order_notifications/${currentUserData.username}_order/`
        `${process.env.REACT_APP_WEBSOCKET_HOST}order_notifications/${currentUserData.username}_order/`
      );
    }

    // Set up WebSocket event handlers if the connection is established
    if (orderSocketRef.current) {
      orderSocketRef.current.onopen = () => {};

      orderSocketRef.current.onmessage = (event) => {
        handleOrder(event);
      };

      orderSocketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    }

    // Cleanup logic when the component unmounts or when the username changes
    return () => {
      if (orderSocketRef.current && !currentUserData?.username) {
        orderSocketRef.current.close();
        orderSocketRef.current = null;
      }
    };
  }, [currentUserData?.username]);

  //handle order
  const handleOrder = async (event) => {
    const eventData = JSON.parse(event.data);

    if (eventData.type === "order_notification") {
      // Update orders in the state
      setOrders((prevOrders) => {
        const updatedOrders = [eventData.order, ...prevOrders];
        return updatedOrders;
      });

      // Update order counts
      setOrderCounts((prevCounts) => ({
        ...prevCounts,
        totalOrders: prevCounts.totalOrders + 1,
        paidCount: prevCounts.paidCount + 1,
      }));

      // Remove the sold-out post
      setPosts((prevPosts) =>
        prevPosts.filter((post) => post.id !== eventData.order.product.id)
      );
    } else if (eventData.type === "order_confirmation") {
      // Handle order confirmation (remove the sold-out post for the buyer)
      setPosts((prevPosts) =>
        prevPosts.filter((post) => post.id !== eventData.product_id)
      );
    } else if (
      ["order_completed", "order_delivered", "fund_onhold"].includes(
        eventData.type
      )
    ) {
      // Directly update notifications with the new order notification from eventData
      const newNotification = eventData.orderNotification;

      setNotifications((prevNotifications) => {
        // Check if a notification for this order_id already exists
        const existingNotificationIndex = prevNotifications.findIndex(
          (notif) => notif.order_id === newNotification.order_id
        );

        // Mark the order notification as highlighted (new)
        setNewNotifications((prev) => ({
          ...prev,
          [newNotification.order_id]: true,
        }));

        if (existingNotificationIndex !== -1) {
          // Update the existing notification
          const updatedNotifications = [...prevNotifications];
          updatedNotifications[existingNotificationIndex] = newNotification;
          return updatedNotifications;
        } else {
          // Add the new notification to the start of the list
          return [newNotification, ...prevNotifications];
        }
      });

      // **Increment the count for intended user**
      const isBuyer = eventData.role === "buyer";
      if (
        (isBuyer &&
          ["order_completed", "order_delivered"].includes(eventData.type)) ||
        (!isBuyer && eventData.type === "fund_onhold")
      ) {
        setNewNotificationCount((prevCount) => prevCount + 1);
      }
    } else if (eventData.message) {
      // Handle other notifications if needed
   
    }
  };

  const currencyMapping = {
    TZ: "TZS",
    US: "USD",
    // Other countries and their corresponding currencies here
  };

  const extractCityAndCountry = (fullAddress) => {
    const addressComponents = fullAddress
      .split(",")
      .map((component) => component.trim());

    let city = "";
    let country = "";

    // Loop over components starting from the end (country is usually last)
    for (let i = addressComponents.length - 1; i >= 0; i--) {
      const component = addressComponents[i];

      // Identify country by checking if it's the last component or a recognized country name
      if (!country && (component.length === 2 || /^[A-Z]/.test(component))) {
        country = component;
        continue;
      }

      // Identify city as the component just before country or based on known formats
      if (!city && i > 0) {
        city = component;
      }
    }

    // Fallback in case city or country are not detected
    if (!city && addressComponents.length > 1) {
      city = addressComponents[addressComponents.length - 2]; // Using the second last component as a fallback for city
    }
    if (!country && addressComponents.length > 0) {
      country = addressComponents[addressComponents.length - 1]; // Using the last component as a fallback for country
    }
    return `${city}, ${country}`.trim();
  };

  useEffect(() => {
    if (currentUserData?.username && !offerSocketRef.current) {
      offerSocketRef.current = new WebSocket(
        // `ws://localhost:8000/ws/offer_notifications/${currentUserData.username}_offer/`
        `${process.env.REACT_APP_WEBSOCKET_HOST}offer_notifications/${currentUserData.username}_offer/`
      );
    }

    if (offerSocketRef.current) {
      offerSocketRef.current.onopen = () => {
      };

      offerSocketRef.current.onmessage = (event) => {
        handleOfferNotification(event);
      };

      offerSocketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    }

    return () => {
      if (offerSocketRef.current && !currentUserData?.username) {
        offerSocketRef.current.close();
        offerSocketRef.current = null;
      }
    };
  }, [currentUserData?.username]);

  const fetchGetOffer = useCallback(async (offerId) => {
    try {
      const response = await fetch(`/api/offers/get_offer/?id=${offerId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch the offer");
      }
      const offerData = await response.json();
      return offerData;
    } catch (error) {
      console.error("Failed to fetch the specific offer:", error);
      return null;
    }
  }, []);

  const handleOfferNotification = (event) => {
    const eventData = JSON.parse(event.data);

    if (
      eventData.type === "offer" ||
      eventData.type === "accepted_offer" ||
      eventData.type === "rejected_offer" ||
      eventData.type === "offer_status"
    ) {
      setNotifications((prevNotifications) => {
        const existingNotificationIndex = prevNotifications.findIndex(
          (notif) => notif.offer_id === eventData.offer_id
        );

        // Mark the notification as highlighted (new)
        setNewNotifications((prev) => ({
          ...prev,
          [eventData.offer_id]: true,
        }));

        if (existingNotificationIndex !== -1) {
          const updatedNotifications = [...prevNotifications];
          updatedNotifications[existingNotificationIndex] = eventData;
          return updatedNotifications;
        } else {
          return [eventData, ...prevNotifications];
        }
      });

      // **Increment the count for intended user**
      const isSeller = eventData.role === "seller";
      if (
        (isSeller && eventData.type === "offer") ||
        (!isSeller &&
          ["accepted_offer", "rejected_offer"].includes(eventData.type))
      ) {
        setNewNotificationCount((prevCount) => prevCount + 1);
      }
    }
  };

  const processFetchedData = (data) => {
    if (data && data.results && data.results.orders) {
      const newOrders = data.results.orders.filter((order) => {
        if (!fetchedOrderIds.has(order.id)) {
          fetchedOrderIds.add(order.id);
          return true;
        }
        return false;
      });

      // Update the order counts with the aggregated data
      const newOrderCounts = {
        totalOrders: data.results.total_orders,
        paidCount: data.results.paid_count,
        packagedCount: data.results.packaged_count,
        onWayCount: data.results.on_way_count,
        deliveredCount: data.results.delivered_count,
        receivedCount: data.results.received_count,
      };

      setOrderCounts(newOrderCounts);

      // Update the global `paidCount` state
      setPaidCount(newOrderCounts.paidCount);

      return newOrders;
    } else {
      console.error("Data structure is not as expected:", data);
      return [];
    }
  };

  const fetchOrders = async () => {
    if (firstLoadOrder) {
      setIsLoading(true);
    } else {
      setScrollLoadingOrder(true);
    }
    try {
      const response = await fetch(`/api/orders/get_orders/?page=${orderPage}`);
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      const data = await response.json();
      const processedData = processFetchedData(data);
      setOrders((prevOrders) => [...prevOrders, ...processedData]);
      setHasMoreOrder(data.next !== null);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setHasMoreOrder(false);
    } finally {
      if (firstLoadOrder) {
        setIsLoading(false);
        setFirstLoadOrder(false);
      }
      setScrollLoadingOrder(false);
    }
  };

  useEffect(() => {
    fetchOrders(orderPage);
  }, [orderPage]);

  // useEffect to update the paidCount state
  useEffect(() => {
    setPaidCount(orderCounts.paidCount);
  }, [orderCounts]);


  const isAcceptedWithin24Hours = useCallback((notification) => {
    if (
      ["accepted"].includes(notification.status) &&
      notification.accepted_at
    ) {
      const acceptedAt = new Date(notification.accepted_at);
      const now = new Date();
      const diffHours = (now - acceptedAt) / (1000 * 60 * 60);
      return diffHours <= 24;
    }
    return false;
  }, []);

  const getRemainingTime = useCallback(
    (notification) => {
      if (notification.status === "accepted" && notification.accepted_at) {
        const acceptedAt = new Date(notification.accepted_at);
        const now = new Date();
        const diffMilliseconds =
          acceptedAt.getTime() + 24 * 60 * 60 * 1000 - now.getTime();
        const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
        const diffMinutes = Math.floor(
          (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
        );

        const isCompleted =
          notification.current_owner === currentUserData.username;
        const isExpired = diffMilliseconds <= 0 && !isCompleted;

        const timeClass =
          !isCompleted &&
          (diffHours <= 5 ? "remaining-time red" : "remaining-time green");

        return {
          hours: isExpired || isCompleted ? "" : `${diffHours}h`,
          minutes: isExpired || isCompleted ? "" : `${diffMinutes}m`,
          timeClass: timeClass || "",
          isCompleted,
          isExpired,
        };
      }
      return {
        hours: "",
        minutes: "",
        timeClass: "remaining-time green",
        isCompleted: false,
        isExpired: false,
      };
    },
    [currentUserData.username]
  );

 const fetchNotifications = async () => {
   setScrollLoadingNotifications(true);
   try {
     const response = await fetch(
       `/api/combined-notifications/combined-notifications/?page=${notificationPage}`
     );
     if (!response.ok) {
       throw new Error(`HTTP error! status: ${response.status}`);
     }
     const data = await response.json();
     const updatedNotifications = data.results.map((notification) => {
       const isAcceptedWithin24HoursFlag =
         isAcceptedWithin24Hours(notification);

       // Check if the seller has accepted any recent offer for the same product
       const sellerHasAcceptedRecently =
         notification.seller_has_accepted_recently;

       return {
         ...notification,
         isAcceptedWithin24Hours: isAcceptedWithin24HoursFlag,
         sellerHasAcceptedRecently,
         ...getRemainingTime(notification),
         seen: notification.seen_by.includes(currentUserData.id),
       };
     });

     const newNotifications = updatedNotifications.filter(
       (notification) =>
         !fetchedNotificationsIds.has(notification.id || notification.offer_id)
     );

     newNotifications.forEach((notification) => {
       fetchedNotificationsIds.add(notification.id || notification.offer_id);
     });

     setNotifications((prevNotifications) => [
       ...prevNotifications,
       ...newNotifications,
     ]);

     // Update the unseen notifications count
     setNewNotificationCount(data.unseen_count);

     // Mark all new notifications as highlighted
     const newHighlights = {};
     updatedNotifications.forEach((notification) => {
       newHighlights[notification.id] = true;
     });
     setNewNotifications(newHighlights);

     // Update the hasMoreNotifications state
     setHasMoreNotifications(data.next !== null);
   } catch (error) {
     console.error("Failed to fetch notifications:", error);
     setHasMoreNotifications(false);
   } finally {
     setScrollLoadingNotifications(false);
   }
 };

   useEffect(() => {
     fetchNotifications(notificationPage);
   }, [notificationPage]);

  return (
    <YourShopHeaderPostContext.Provider
      value={{
        showHeader,
        setShowHeader,
        isFullScreenPreview,
        setIsFullScreenPreview,
        clearFetchedStarPostingIds,
        fetchedStarPostingIds,
        fetchedSoldOutIds,
        clearFetchedSoldOutIds,
        starPostingPage,
        setStarPostingPage,
        currentPostId,
        setCurrentPostId,
        posts,
        setPosts,
        orders,
        setOrders,
        selectedPostToBuy,
        setSelectedPostToBuy,
        currentUserData,
        setCurrentUserData,
        orderSocketRef,
        csrftoken,
        userCurrency,
        setUserCurrency,
        isLocationFetched,
        setIsLocationFetched,
        soldOutPosts,
        setSoldOutPosts,
        starPostingSoldOutPage,
        setStarPostingSoldOutPage,
        orderPage,
        setOrderPage,
        clearFetchedOrderIds,
        fetchedOrderIds,
        currencyMapping,
        showMenu,
        setShowMenu,
        menuRef,
        copyLinkText,
        setCopyLinkText,
        iconClass,
        setIconClass,
        sharePostText,
        setSharePostText,
        shareIconClass,
        setShareIconClass,
        expandedSection,
        setExpandedSection,
        expandedPost,
        setExpandedPost,
        extractCityAndCountry,
        offerSocketRef,
        selectedOffer,
        setSelectedOffer,
        fetchGetOffer,
        paidCount,
        setPaidCount,
        isLoading,
        setIsLoading,
        scrollLoadingOrder,
        setScrollLoadingOrder,
        hasMoreOrder,
        setHasMoreOrder,
        orderCounts,
        setOrderCounts,
        fetchOrders,
        isSubscribed,
        setIsSubscribed,
        clearFetchedTransactionIds,
        transactionPage,
        setTransactionPage,
        fetchedTransactionSet,
        notifications,
        setNotifications,
        notificationPage,
        setNotificationPage,
        newNotifications,
        setNewNotifications,
        newNotificationCount,
        setNewNotificationCount,
        fetchNotifications,
        getRemainingTime,
        isAcceptedWithin24Hours,
        hasMoreNotifications,
        setHasMoreNotifications,
        scrollLoadingNotifications,
        setScrollLoadingNotifications,
        clearFetchedNotificationsIds,
        combinedTransactions,
        setCombinedTransactions,
        postStarContainerRef,
      }}
    >
      {children}
    </YourShopHeaderPostContext.Provider>
  );
};




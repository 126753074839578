import React, {useContext} from "react";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { AiOutlineClose } from "react-icons/ai";
import "../StarNavigationBar/chat.css";

const ClearChatModal = () => {
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const {
    setShowClearChatModal,
    setMessages,
    currentConversationId,
    messagePage,
    updateLastMessagePreview,
    setLastMessagePreviews,
    setMessagePage,
    userData,
    setShowKebabMenu,
  } = useChatAndMusicPosts();


 const fetchMessages = async () => {
   const response = await fetch(
     `/api/messages/?conversationId=${currentConversationId}&page=${messagePage}`
   );
   const data = await response.json();
   const filteredMessages = data.results.filter(
     (msg) => msg.conversationId === currentConversationId
   );
   setMessages(filteredMessages);

   if (filteredMessages.length > 0) {
     const lastMessage = filteredMessages[filteredMessages.length - 1];
     updateLastMessagePreview(lastMessage, currentConversationId);
   } else {
     setLastMessagePreviews((prevPreviews) => ({
       ...prevPreviews,
       [currentConversationId]: "",
     }));
   }
 };
  //function to clear chatroom user-specific
  const clearChat = async () => {
    // Optimistically clear the messages and related states
    setMessages([]);
    setLastMessagePreviews((prevPreviews) => ({
      ...prevPreviews,
      [currentConversationId]: "",
    }));

    // Close the modal and the kebab menu
    setShowClearChatModal(false);
    setShowKebabMenu(false);

    const response = await fetch("/api/messages/clear_chat/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({
        conversationId: currentConversationId,
        username: userData.username,
      }),
    });

    if (response.ok) {
      // Reset the page number to 1
      setMessagePage(1);
    } else {
      console.error("Failed to clear chat");
      // If the server operation failed, re-fetch the messages
      fetchMessages();
    }
  };

  return (
    <div className="clear-chat-modal">
      <p>Are you sure you want to clear this chat?</p>
      <div className="clear-chat-modal-btns">
        <button
          className="btn-Close-clearChat"
          onClick={(e) => {
            e.stopPropagation();
            setShowClearChatModal(false);
          }}
        >
          <AiOutlineClose size={30} />
        </button>
        <button className="btn-clearChat" onClick={clearChat}>
          Clear Chat
        </button>
      </div>
    </div>
  );
};

export default ClearChatModal;


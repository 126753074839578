import React, { useRef, useState, useEffect, useContext } from 'react';
import { usePlayers, PlayersProvider } from './PlayersContext';
import './CustomAudioPlayer.css';

const CustomAudioPlayer = ({ audioSrc }) => {
  const {
    startSession,
    updateDuration,
    pauseSession,
    resumeSession,
    endSession,
    historyId,
    VolumeUpIcon,
    VolumeOffIcon,
    PlayIcon,
    PauseIcon,
  } = usePlayers();
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const progressRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visibleDots, setVisibleDots] = useState(0);
 

  const getKey = (key) => `${audioSrc}-${key}`;

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      setCurrentTime(audio.currentTime);
      if (progressRef.current) {
        const progressValue = (audio.currentTime / audio.duration) * 100;
        progressRef.current.value = progressValue;
        progressRef.current.style.setProperty(
          "--progress",
          `${progressValue}%`
        );
      }
      localStorage.setItem(getKey("currentTime"), audio.currentTime);

      // Update session duration periodically
      updateDuration(audio.currentTime);
    };

    const setAudioData = () => {
      setDuration(audio.duration);
      setLoading(false);

      const savedTime = parseFloat(
        localStorage.getItem(getKey("currentTime")) || "0"
      );
      const savedIsMuted = localStorage.getItem(getKey("isMuted")) === "true";

      if (savedTime && savedTime < audio.duration) {
        audio.currentTime = savedTime;
        setCurrentTime(savedTime);
      }

      audio.muted = savedIsMuted;
      setIsMuted(savedIsMuted);
    };

    const handleAudioEnd = () => {
      setIsPlaying(false);
      localStorage.removeItem(getKey("currentTime"));
      localStorage.removeItem(getKey("isPlaying"));
      localStorage.removeItem(getKey("isMuted"));

      // End the session when the audio finishes
      endSession();
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("canplaythrough", setAudioData);
    audio.addEventListener("ended", handleAudioEnd);

    // Timeout fallback for iOS
    const durationCheckTimeout = setTimeout(() => {
      if (!audio.duration && loading) {
        setLoading(false); // Turn off loading even if duration is not set
        setAudioData(); // Run `setAudioData` to initialize other state variables
      }
    }, 3000); 

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("canplaythrough", setAudioData);
      audio.removeEventListener("ended", handleAudioEnd);
      clearTimeout(durationCheckTimeout);

      localStorage.removeItem(getKey("currentTime"));
      localStorage.removeItem(getKey("isPlaying"));
      localStorage.removeItem(getKey("isMuted"));
    };
  }, [historyId, updateDuration, endSession, audioSrc]); 

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleDots((prev) => (prev >= 5 ? 0 : prev + 1));
    }, 400);

    return () => clearInterval(interval);
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;

    if (audioContext === null) {
      const newAudioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      setAudioContext(newAudioContext);
      const newAnalyser = newAudioContext.createAnalyser();
      setAnalyser(newAnalyser);
      const source = newAudioContext.createMediaElementSource(audio);
      source.connect(newAnalyser);
      newAnalyser.connect(newAudioContext.destination);

      newAnalyser.fftSize = 256;
      const bufferLength = newAnalyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        if (canvasRef.current && canvasRef.current.getContext) {
          const ctx = canvasRef.current.getContext("2d");
          if (ctx) {
            newAnalyser.getByteFrequencyData(dataArray);

            ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
            ctx.fillRect(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );

            const barWidth = (canvasRef.current.width / bufferLength) * 2.5;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              barHeight = dataArray[i];

              ctx.fillStyle = "#f0c14b";
              ctx.fillRect(
                x,
                canvasRef.current.height - barHeight / 2,
                barWidth,
                barHeight / 2
              );

              x += barWidth + 1;
            }
          }
        }
        requestAnimationFrame(draw);
      };

      draw();
    } else if (audioContext.state === "suspended") {
      audioContext.resume();
    }

    if (isPlaying) {
      audio.pause();
      pauseSession(); 
      localStorage.setItem(getKey("isPlaying"), "false");
    } else {
      audio.play();
      if (!historyId) startSession(); // Start session if not already started
      resumeSession(); // Resume session if already started
      localStorage.setItem(getKey("isPlaying"), "true");
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    const audio = audioRef.current;

    // Immediately set local state for faster UI response
    setIsMuted(!audio.muted);
    audio.muted = !audio.muted; // Toggle muted state on audio element
    localStorage.setItem(getKey("isMuted"), audio.muted.toString());

    // Ensure state update is reflected in UI quickly (especially for iOS)
    requestAnimationFrame(() => {
      setIsMuted(audio.muted);
    });
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const progressValue = e.target.value;
    const newTime = (progressValue / 100) * audio.duration;

    if (newTime >= 0 && newTime <= audio.duration) {
      audio.currentTime = newTime;
      localStorage.setItem(getKey("currentTime"), newTime);
      setCurrentTime(newTime);
    } else {
      console.warn("Invalid newTime value:", newTime);
    }
  };

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    const audio = audioRef.current;
    const rect = canvas.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / canvas.width) * audio.duration;

    if (newTime >= 0 && newTime <= audio.duration) {
      audio.currentTime = newTime;
      setCurrentTime(newTime);
      localStorage.setItem(getKey("currentTime"), newTime);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="audio-player">
      <audio ref={audioRef} src={audioSrc} preload="auto"></audio>
      <div className="controls">
        {loading ? (
          <div className="loader-dots-custom-audioPlayer">
            <span className={visibleDots > 0 ? "visible" : ""}>.</span>
            <span className={visibleDots > 1 ? "visible" : ""}>.</span>
            <span className={visibleDots > 2 ? "visible" : ""}>.</span>
            <span className={visibleDots > 3 ? "visible" : ""}>.</span>
            <span className={visibleDots > 4 ? "visible" : ""}>.</span>
          </div>
        ) : (
          <>
            <button onClick={togglePlayPause}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>
            <button onClick={toggleMute}>
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </button>
            <div className="progress-container">
              <canvas
                ref={canvasRef}
                className="progress-bar-audio"
                onClick={handleCanvasClick}
              ></canvas>
              <input
                type="range"
                ref={progressRef}
                className="progress"
                onChange={handleProgressChange}
                value={(currentTime / duration) * 100 || 0}
                style={{
                  "--progress": `${(currentTime / duration) * 100 || 0}%`,
                }}
              />
            </div>
            <div className="time">
              <span>{formatTime(currentTime)}</span> /{" "}
              <span>{formatTime(duration)}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CustomAudioPlayerWithProvider = ({ audioSrc, musicId }) => (
  <PlayersProvider musicId={musicId}>
    <CustomAudioPlayer audioSrc={audioSrc} musicId={musicId} />
  </PlayersProvider>
);

export default CustomAudioPlayerWithProvider;


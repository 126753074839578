import React, { useEffect, useRef, useContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import YourShopHeader from './YourShopHeader';
import { YourShopHeaderPostContext } from './YourShopHeaderPostContext';
import './YourShop.css';

function YourShop() {
  const location = useLocation();
  const navigate = useNavigate();
  const { username } = useParams();  
  // const postStarContainerRef = useRef(null);
  const {
    clearFetchedStarPostingIds,
    setStarPostingPage,
    setPosts,
    setSoldOutPosts,
    clearFetchedSoldOutIds,
    setStarPostingSoldOutPage,
    setOrderPage,
    clearFetchedOrderIds,
    setOrders,
    postStarContainerRef,
  } = useContext(YourShopHeaderPostContext);

  useEffect(() => {
    if (location.pathname === `/${username}`) {
      navigate(`/${username}/shopPosts`);
    }
  }, [location, navigate, username]);
  

   useEffect(() => {
    setStarPostingPage(1);
    clearFetchedStarPostingIds();
    setPosts([]);

    setStarPostingSoldOutPage(1);
    clearFetchedSoldOutIds();
    setSoldOutPosts([]);
    
    setOrders([]);
    setOrderPage(1);
    clearFetchedOrderIds();
  }, [location.pathname, setStarPostingPage, clearFetchedStarPostingIds, setPosts, clearFetchedOrderIds,  setOrders, setOrderPage, clearFetchedSoldOutIds, setSoldOutPosts,  setStarPostingSoldOutPage]);

  return (
    <div className="container-yourshop">
      <YourShopHeader
        username={username}
        postStarContainerRef={postStarContainerRef}
      />
      <div className="postStar-container" ref={postStarContainerRef}>
        <Outlet/>
      </div>
    </div>
  );
}

export default YourShop;

import React, { useState, useEffect } from "react";
import "./StarsUpdateComponents/StarUpdate.css";
import StarUpdateSearchResult from "./StarUpdateSearchResult";
import FullPreviewModal from "./StarsUpdateComponents/FullPreviewModal";
import { useLikesComments } from "./LikesCommentsContext";
import { BiSearch, BiX, BiArrowBack } from "react-icons/bi";


const StarUpdateHeaderWithSearch = ({
  title = "",
  handleBackToHome,
  category,
  isArchived,
  nonArchived,
}) => {
  const {
    openFullPreviewModal,
    searchResults,
    setSearchResults,
    noResultsFound,
    setNoResultsFound,
    showSearch,
    setShowSearch,
    input,
    setInput,
    handleCancelSearchForm,
  } = useLikesComments();
  const [loading, setLoading] = useState(false);
  const [visibleDots, setVisibleDots] = useState(0);

  const handleBsSearchIcon = () => {
    setShowSearch(true);
  };

  const handleChangeStarUpdate = (value) => {
    setInput(value);
    setNoResultsFound(false);
    fetchData(value);
  };

  const fetchData = (value) => {
    if (!value) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    const archived = isArchived ? "true" : "false";
    fetch(
      `/api/search_stars_updates/?query=${value}&category=${category}&archived=${archived}&size=10`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
        if (data.length === 0) {
          setTimeout(() => setNoResultsFound(true), 250);
        }
      })
      .catch((error) => {
        console.error("Error fetching star update data:", error);
        setLoading(false);
      });
  };

  const startLoaderDots = () => {
    const interval = setInterval(() => {
      setVisibleDots((prev) => (prev >= 5 ? 0 : prev + 1));
    }, 400);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (loading) {
      const stopLoaderDots = startLoaderDots();
      return stopLoaderDots;
    }
  }, [loading]);

  const searchIconClassName = `
    ${title ? `${title.toLowerCase().replace(" ", "-")}-search-icon` : ""} 
    ${isArchived ? "archived-search-icon" : ""} 
    ${nonArchived ? "non-archived-search-icon" : ""}
  `.trim();

  return (
    <>
      {showSearch ? (
        <div className="star-updates-searchForm-wrapper">
          <form className="form-search-star-updates" role="search">
            <div className="star-updates-search-container">
              <BiSearch
                size={18}
                className="bi bi-search star-updates-search-icon"
              />
              <input
                className="form-control me-2 star-updates-search-input"
                id="starUpdateSearchInput"
                name="starUpdateSearchInput"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={input}
                onChange={(e) => handleChangeStarUpdate(e.target.value)}
              />
            </div>
          </form>
          <div
            className="CancelSearchUpdateForm"
            onClick={handleCancelSearchForm}
          >
            <BiX size={30} color="currentColor" className="bi bi-x mr-4" />
          </div>
        </div>
      ) : (
        <header className={`header-${title.toLowerCase().replace(" ", "")}`}>
          {handleBackToHome && (
            <div
              className={`${title ? title.replace(" ", "-") : ""}-Back-btn`}
              onClick={handleBackToHome}
            >
              <BiArrowBack
                size={22}
                color="currentColor"
                className="bi bi-arrow-left"
              />
            </div>
          )}
          {title && <div className="bs-header-update">{title} Updates</div>}
          <div
            className={searchIconClassName}
            onClick={(e) => {
              e.stopPropagation();
              handleBsSearchIcon();
            }}
          >
            <BiSearch size={22} color="currentColor" className="bi bi-search" />
          </div>
        </header>
      )}

      <div
        className={`star-updates-Searchresults-container ${
          input ? "active" : ""
        }`}
      >
        {loading ? (
          <div className="loader-dots-general">
            <span className={visibleDots > 0 ? "visible" : ""}>.</span>
            <span className={visibleDots > 1 ? "visible" : ""}>.</span>
            <span className={visibleDots > 2 ? "visible" : ""}>.</span>
            <span className={visibleDots > 3 ? "visible" : ""}>.</span>
            <span className={visibleDots > 4 ? "visible" : ""}>.</span>
          </div>
        ) : input && searchResults.length > 0 ? (
          <div className="star-update-result">
            {searchResults.map((starupdate) => (
              <StarUpdateSearchResult
                key={starupdate.id}
                starupdate={starupdate}
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(
                    starupdate,
                    "StarUpdateHeaderWithSearch"
                  );
                }}
              />
            ))}
          </div>
        ) : noResultsFound ? (
          <div className="no-update">No update found</div>
        ) : null}
      </div>
      <FullPreviewModal />
    </>
  );
};

export default StarUpdateHeaderWithSearch;

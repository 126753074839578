import React, { createContext, useState, useContext, useRef } from "react";

const UploadPostContext = createContext();

export const UploadPostProvider = ({ children}) => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [originalURLs, setOriginalURLs] = useState({});
  const [originalFiles, setOriginalFiles] = useState({});
  const [savedCroppedImage, setSavedCroppedImage] = useState(null);
  const [isReverted, setIsReverted] = useState(false);
  const [isCropped, setIsCropped] = useState(false);
   const [cropper, setCropper] = useState();
   const [croppedImage, setCroppedImage] = useState(null);
  // Initialize cropModified state
  const [cropModified, setCropModified] = useState(false);
  const onCropModified = () => {
    setCropModified(true);
  };
  // Disable Save button if no image is cropped or crop box is not modified
  const isSaveDisabled = !isCropped || !cropModified;
  const [previewImages, setPreviewImages] = useState([]);
   const [postData, setPostData] = useState({
     media_files: [],
     product_location: "",
   });
 
   function dataURLtoBlob(dataurl) {
     var arr = dataurl.split(","),
       mime = arr[0].match(/:(.*?);/)[1],
       bstr = atob(arr[1]),
       n = bstr.length,
       u8arr = new Uint8Array(n);
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
     }
     return new Blob([u8arr], { type: mime });
   }
  return (
    <UploadPostContext.Provider
      value={{
        selectedMedia,
        setSelectedMedia,
        currentImageIndex,
        setCurrentImageIndex,
        modalIsOpen,
        setModalIsOpen,
        isVideo,
        setIsVideo,
        isPlaying,
        setIsPlaying,
        videoRef,
        cropModified,
        setCropModified,
        isCropped,
        setIsCropped,
        isReverted,
        setIsReverted,
        savedCroppedImage,
        setSavedCroppedImage,
        originalURLs,
        setOriginalURLs,
        originalFiles,
        setOriginalFiles,
        onCropModified,
        isSaveDisabled,
        cropper,
        setCropper,
        croppedImage,
        setCroppedImage,
        previewImages,
        setPreviewImages,
        postData,
        setPostData,
        dataURLtoBlob,
      }}
    >
      {children}
    </UploadPostContext.Provider>
  );
};

export const useUploadPost = () => useContext(UploadPostContext);

// TopPriced.jsx
import React, { useEffect } from 'react';
import './StarUpdate.css';
import { useLikesComments } from '../LikesCommentsContext';
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';

const TopPriced = () => {
  const {
    topPricedPosts, setTopPricedPosts,
     fetchPosts,  handleBackToHome, renderPosts, 
     page, setPageHasMore, scrollLoading
  } = useLikesComments();

  useEffect(() => {
    fetchPosts('/api/stars_updates/top_priced/', setTopPricedPosts, page, setPageHasMore);
  }, [page]);


  return (
    <div className='toppriced-container'>
       <StarUpdateHeaderWithSearch
        title="Top Priced"
        handleBackToHome={handleBackToHome}
        category="top_priced"
        isArchived={false}
      />
      <div className='toppriced-contents'>
        {renderPosts(topPricedPosts, setTopPricedPosts)}
      </div>
       <div className='scroll-loader-spinner-container'>
          {scrollLoading && <div className="scroll-loader-spinner"></div>}
         </div>
    </div>
  );
};

export default TopPriced;



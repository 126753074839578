import React, { useState, useEffect, useRef } from "react";
import LazyImage from "../LazyImage";
import LazyVideo from "../LazyVideo";
import VideoControls from "../VideoControls";
import "./Display.css";

function Display() {
  const [posts, setPosts] = useState([]);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [error, setError] = useState(null);
  const timerRef = useRef(null);
  const videoRefs = useRef({}); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/AdminPosts/");
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Unexpected response format.");
        }
        if (!response.ok) {
          const errorBody = await response.json();
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${errorBody.detail}`
          );
        }
        const data = await response.json();
        if (data && data.length > 0) {
          setPosts(data);
          startTimer(data.length > 1); // Start timer only if there's more than one post
        } else {
          setError("No posts available.");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();

    return () => {
      stopTimer(); // Cleanup timer on unmount
    };
  }, []);

  const startTimer = (shouldStart) => {
    if (shouldStart && !timerRef.current) {
      timerRef.current = setInterval(() => {
        setCurrentPostIndex((prevIndex) =>
          prevIndex === posts.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);
    }
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  // Handle video start/stop logic when posts or index changes
  useEffect(() => {
    if (posts.length > 0) {
      stopTimer();
      startTimer(posts.length > 1);
    }
  }, [currentPostIndex, posts]);

  // Render only the active post
  const renderActivePost = (post) => (
    <div key={post.id} className="Admin-post active">
      {/* Render images */}
      {post.images.map((image) => (
        <div key={`image-${post.id}-${image.id}`} className="postDisplay-image">
          <LazyImage src={image.image} alt="" className="postDisplay-image" />
        </div>
      ))}

      {/* Render videos */}
      {post.videos.map((video) => {
        const videoId = `video-${post.id}-${video.id}`;
        return (
          <div key={videoId} className="postDisplay-video">
            <LazyVideo
              src={video.video}
              className="postDisplay-video"
              videoRef={(ref) => {
                if (ref) {
                  videoRefs.current[videoId] = ref; 
                }
              }}
            />
            <VideoControls
              videoId={videoId}
              startTimer={startTimer}
              stopTimer={stopTimer}
              videoRefs={videoRefs}
            />
          </div>
        );
      })}

      {/* Display post title and action button */}
      <div className={`postDisplay-overlay ${!post.link ? "fade-out" : ""}`}>
        <h2>{post.title}</h2>
        {post.link && (
          <a href={post.link} className="btn btn-primary">
            {post.action}
          </a>
        )}
      </div>
    </div>
  );

  return (
    <div className="Carousel-container">
      {error ? (
        <p className="text-center">{error}</p>
      ) : (
        <div className="postDisplay">
          {/* Render only the active post */}
          {posts.length > 0 && renderActivePost(posts[currentPostIndex])}
        </div>
      )}
    </div>
  );
}

export default Display;

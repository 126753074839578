import React from "react";
import { Outlet } from "react-router-dom";
import { useUserRole } from "../UserRoleContext";
import Header from "../StarHomeComponents/Header";
import Statistics from "../StarHomeComponents/Statistics";
import Statistics2 from "../StarHomeComponents/Statistics2";
import Explore from "../StarHomeComponents/Explore";
import Display from "../StarHomeComponents/Display";
import StarsUpdate from "../StarHomeComponents/StarsUpdate";
import ContentsRecommendation from "../StarHomeComponents/ContentsRecommendation";
import { useChatAndMusicPosts } from "./ChatAndMusicPostsContext";

function Home() {
  const { role, loading, error } = useUserRole();
  const { isWideScreen, isNestedRoute } = useChatAndMusicPosts();

  // Handle loading and error states
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Header />
      {role === "star" && <Statistics />}
      <div className="container-main2">
        <div className="container-carouselStar-update">
          {role === "star" && <Statistics2 />}
          <Explore />
          <Display />
          <StarsUpdate />
        </div>
        {isWideScreen ? (
          isNestedRoute ? (
            <Outlet />
          ) : (
            <ContentsRecommendation />
          )
        ) : null}
      </div>
    </div>
  );
}

export default Home;

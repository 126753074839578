import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { FooterContext } from '../StarNavigationBar/FooterContext';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import MusicSearchResult from '../MusicSearchResult';
import { AiOutlineClose } from "react-icons/ai";
import {
  BiDotsVerticalRounded,
} from "react-icons/bi";
import './MusicPosts.css';

const MusicPosts = () => {
  const musicPostsContainerRef = useRef(null);
  const {
    currentConversationId,
    setCurrentMusicPost,
    setShowRequestMessage,
    isWideScreen,
  } = useChatAndMusicPosts();
  const {
    showMenu,
    setShowMenu,
    menuRef,
    copyLinkText,
    iconClass,
    sharePostText,
    shareIconClass,
  } = useContext(YourShopHeaderPostContext);
    
  const {
    setShowFooter,
    musicPosts,
    setMusicPosts,
    musicPage,
    setMusicPage,
    fetchedMusicPostIds,
    clearFetchedMusicPostIds,
    handleMusicOptions,
    handleOutsideClick,
    handleCopyLink,
    handleSharePost,
  } = useContext(FooterContext);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [musicScrollLoading, setMusicScrollLoading] = useState(false);
  const [musicFirstLoad, setMusicFirstLoad] = useState(true);
  const [musicPageHasMore, setMusicPageHasMore] = useState(true);
  const musicPostObserver = useRef();
  const lastMusicPostElementRef = useCallback(
    (node) => {
      if (musicScrollLoading) return;
      if (musicPostObserver.current) musicPostObserver.current.disconnect();
      musicPostObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && musicPageHasMore) {
          setMusicPage((prevPage) => prevPage + 1);
        }
      });
      if (node) musicPostObserver.current.observe(node);
    },
    [musicScrollLoading, musicPageHasMore]
  );

  const fetchMusicPosts = async () => {
    if (musicFirstLoad) {
      setLoading(true);
    } else if (musicPage !== 1) {
      setMusicScrollLoading(true);
    }
    try {
      const response = await fetch(`/api/music/?page=${musicPage}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data && data.results) {
        const newMusicPosts = data.results.filter((music) => {
          // Only add the music to newMusicPosts if its ID is not in fetchedMusicPostIds
          if (!fetchedMusicPostIds.has(music.id)) {
            fetchedMusicPostIds.add(music.id);
            return true;
          }
          return false;
        });
        setMusicPosts((prevMusicPosts) => [
          ...prevMusicPosts,
          ...newMusicPosts,
        ]);
        setMusicPageHasMore(data.next !== null);
      } else if (data) {
        const newMusicPosts = data.results.filter((music) => {
          // Only add the music to newMusicPosts if its ID is not in fetchedMusicPostIds
          if (!fetchedMusicPostIds.has(music.id)) {
            fetchedMusicPostIds.add(music.id);
            return true;
          }
          return false;
        });
        setMusicPosts((prevMusicPosts) => [
          ...prevMusicPosts,
          ...newMusicPosts,
        ]);
        setMusicPageHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching music posts:", error);
    } finally {
      if (musicFirstLoad) {
        setLoading(false);
        setMusicFirstLoad(false);
      }
      if (musicPage !== 1) {
        setMusicScrollLoading(false);
      }
    }
  };

  // Use the fetchMusicPosts function
  useEffect(() => {
    fetchMusicPosts();
  }, [musicPage]);

  const fetchData = (value) => {
    if (!value) {
      setSearchResults([]);
      return;
    }

    fetch(`/api/search_music/?query=${value}&size=4`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSearchResults(data);
        if (data.length === 0) {
          setTimeout(() => setNoResultsFound(true), 250);
        }
      })
      .catch((error) => console.error("Error fetching music data:", error));
  };

  const handleChangeMusicPosts = (value) => {
    setInput(value);
    setNoResultsFound(false);
    fetchData(value);
  };

  const handleBackToChat = () => {
    const uniqueString = btoa(currentConversationId);
    navigate(
      `${
        isWideScreen ? "/home" : ""
      }/start-chat/${currentConversationId}/${uniqueString}`
    );
    setShowFooter(false);
    localStorage.setItem("showFooter", JSON.stringify(false));
    clearFetchedMusicPostIds();
    setMusicPage(1);
    setMusicPosts([]);
  };


  const handleMusicPostClick = (music, event) => {
    event.stopPropagation();
    setCurrentMusicPost(music);
    setShowRequestMessage(true);
    handleBackToChat();
    clearFetchedMusicPostIds();
    setMusicPage(1);
    setMusicPosts([]);
  };

  useEffect(() => {
    if (musicPosts.length > 0) {
      // Create a new showMenu object with a key for each music ID
      const newShowMenu = musicPosts.reduce((acc, music) => {
        acc[music.id] = false;
        return acc;
      }, {});
      setShowMenu(newShowMenu);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [musicPosts]);

  return (
    <>
      <header className="header-music-posts">
        <form className="form-search-musicPost" role="search">
          <div className="musicPosts-search-container">
            <i className="bi bi-search musicPosts-search-icon"></i>
            <input
              className="form-control me-2 musicPosts-search-input"
              type="search"
              placeholder="Artists or Songs"
              aria-label="Search"
              value={input}
              onChange={(e) => handleChangeMusicPosts(e.target.value)}
            />
          </div>
        </form>
        <div className="BackToChat" onClick={handleBackToChat}>
          <AiOutlineClose size={30} />
        </div>
      </header>
      <div className="container music-posts">
        <div
          className={`musicPosts-Searchresults-container ${
            input ? "active" : ""
          }`}
        >
          {input && searchResults.length > 0 ? (
            <div className="search-results-container">
              {searchResults.map((music, index) => (
                <MusicSearchResult
                  key={music.id}
                  music={music}
                  onClick={(event) => handleMusicPostClick(music, event)}
                />
              ))}
            </div>
          ) : noResultsFound ? (
            <div className="no-music">No music found</div>
          ) : null}
        </div>
        <div className="musicPosts-container" ref={musicPostsContainerRef}>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : musicPosts.length === 0 ? (
            <p>No posts yet</p>
          ) : (
            musicPosts.map((music, index) => (
              <div
                key={music.id}
                className="musicPost"
                onClick={(event) => handleMusicPostClick(music, event)}
                ref={
                  musicPosts.length === index + 1
                    ? lastMusicPostElementRef
                    : null
                }
              >
                {music.cover_image ? (
                  <img
                    src={music.cover_image}
                    alt={`${music.song_title} cover`}
                    className="musicPost-cover-image"
                  />
                ) : (
                  <div className="musicPost-cover-placeholder">
                    {music.song_title.charAt(0).toUpperCase()}
                  </div>
                )}
                <div className="musicPost-info">
                  <h3 className="musicPost-artist">{music.artist}</h3>
                  <p className="musicPost-song-title">{music.song_title}</p>
                  {music.featuring && (
                    <p className="musicPost-featuring">
                      Feat. {music.featuring.replace(/,/g, " &")}
                    </p>
                  )}
                </div>
                <div className="musicPost-more-options" ref={menuRef}>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleMusicOptions(music.id);
                    }}
                  >
                    <BiDotsVerticalRounded size={20} />
                  </button>
                  {showMenu[music.id] && (
                    <div className={`menu ${showMenu[music.id] ? "show" : ""}`}>
                      <div
                        className="share-post-copy"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyLink(music.id);
                        }}
                      >
                        {iconClass}
                        <span className="copy-link">{copyLinkText}</span>
                      </div>
                      <div
                        className="share-post"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSharePost(music.id);
                        }}
                      >
                        {shareIconClass}
                        <span className="share-post-word">{sharePostText}</span>
                      </div>
                      {/* Other menu items... */}
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
          <div className="scroll-loader-spinner-container">
            {musicScrollLoading && (
              <div className="scroll-loader-spinner"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MusicPosts;


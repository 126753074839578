import React, {useEffect, useState} from "react";
import { useChat } from "./ChatContext";
import "../StarNavigationBar/chat.css";

const MessageInput = () => {
  const {
    sendMessage,
    messageInput,
    setMessageInput,
    sendButtonDisabled,
    selectedImage,
    uploadProgress,
    isSending,
    setSelectedImage,
    setSelectedFile,
    croppedImage,
    setOriginalImage,
    setPreviewImage,
    setSendButtonDisabled,
    scrollToBottom,
  } = useChat();

  const [error, setError] = useState("");

  //fuction to help pushing message container up, when typing
  const handleTextareaInput = (e) => {
    e.target.style.height = "auto";
    // Limit the maximum height to 120px
    const maxHeightInPixels = 120;
    if (e.target.scrollHeight <= maxHeightInPixels) {
      e.target.style.height = e.target.scrollHeight + "px";
    } else {
      e.target.style.height = maxHeightInPixels + "px";
    }
    setMessageInput(e.target.value);
    scrollToBottom();
    setError("");
  };

  useEffect(() => {
    setSendButtonDisabled(messageInput === "" && !selectedImage);
  }, [messageInput, selectedImage]);

  const handleImageUpload = (event) => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }

    const validImageTypes = [
      "image/bmp",
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/tiff",
      "image/x-icon",
      "image/heic",
      "image/heif",
    ];
    if (!validImageTypes.includes(file.type)) {
      setError("You can only upload image files.");
      return;
    }

    // Modifying the filename
    let filename = file.name.replace(/ /g, "_");
    filename = filename.replace(/[(),]/g, "");

    // Create a new File object with the modified name
    file = new File([file], filename, { type: file.type });

    // Create a temporary URL for the file
    const imageUrl = URL.createObjectURL(file);

    // Set the selected image to the temporary URL
    setSelectedImage(imageUrl);
    setOriginalImage(imageUrl); // Store the original image URL

    // Also keep track of the file
    setSelectedFile(file);
    setError("");
    scrollToBottom();

    // Reset the file input value to allow re-uploading the same file
    event.target.value = null;
  };

  const removeImage = (e) => {
    e.stopPropagation();

    setSelectedImage(null);
  };

  const openPreview = () => {
    setPreviewImage(true);
  };

  return (
    <div className="message-form-wrapper">
      <form
        className="message-form bottom"
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(messageInput);
        }}
      >
        <div
          className="input-form-contents-wrapper"
        >
          {selectedImage && (
            <div className="thumbnail-container" onClick={openPreview}>
              <img
                src={selectedImage || croppedImage}
                alt="Selected"
                className="thumbnail"
              />
              <i className="bi bi-x close-icon" onClick={removeImage}></i>
            </div>
          )}
          {!selectedImage && (
            <label htmlFor="image-upload">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <use href="#image-add-icon"></use>
              </svg>
            </label>
          )}
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <textarea
            name="message"
            maxLength="500"
            rows="1"
            placeholder={
              selectedImage ? "Add a caption..." : "Type a message..."
            }
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onInput={handleTextareaInput}
            className="message-textarea"
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        {uploadProgress !== null ? (
          <span className="chat-parcetage">{uploadProgress}%</span>
        ) : (
          <button type="submit" disabled={sendButtonDisabled || isSending}>
            Send
          </button>
        )}
      </form>

      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="image-add-icon" viewBox="0 0 24 24">
          <path d="M18.75 4C20.5449 4 22 5.45507 22 7.25V18.75C22 20.5449 20.5449 22 18.75 22H7.25C5.45507 22 4 20.5449 4 18.75V12.5019C4.47425 12.6996 4.97687 12.8428 5.50009 12.9236L5.5 18.75C5.5 18.9584 5.53643 19.1583 5.60326 19.3437L11.4258 13.643C12.2589 12.8273 13.5675 12.7885 14.4458 13.5266L14.5742 13.6431L20.3964 19.3447C20.4634 19.159 20.5 18.9588 20.5 18.75V7.25C20.5 6.2835 19.7165 5.5 18.75 5.5L12.9236 5.50009C12.8428 4.97687 12.6996 4.47425 12.5019 4H18.75ZM12.5588 14.644L12.4752 14.7148L6.66845 20.4011C6.8504 20.4651 7.04613 20.5 7.25 20.5H18.75C18.9535 20.5 19.1489 20.4653 19.3305 20.4014L13.5247 14.7148C13.2596 14.4553 12.8501 14.4316 12.5588 14.644ZM16.2521 7.5C17.4959 7.5 18.5042 8.50831 18.5042 9.75212C18.5042 10.9959 17.4959 12.0042 16.2521 12.0042C15.0083 12.0042 14 10.9959 14 9.75212C14 8.50831 15.0083 7.5 16.2521 7.5ZM6.5 1C9.53757 1 12 3.46243 12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1ZM16.2521 9C15.8367 9 15.5 9.33673 15.5 9.75212C15.5 10.1675 15.8367 10.5042 16.2521 10.5042C16.6675 10.5042 17.0042 10.1675 17.0042 9.75212C17.0042 9.33673 16.6675 9 16.2521 9ZM6.5 2.99923L6.41012 3.00729C6.20603 3.04433 6.0451 3.20527 6.00806 3.40936L6 3.49923L5.99965 5.99923L3.49765 6L3.40777 6.00806C3.20368 6.0451 3.04275 6.20603 3.00571 6.41012L2.99765 6.5L3.00571 6.58988C3.04275 6.79397 3.20368 6.9549 3.40777 6.99194L3.49765 7L6.00065 6.99923L6.00111 9.50348L6.00916 9.59336C6.04621 9.79745 6.20714 9.95839 6.41123 9.99543L6.50111 10.0035L6.59098 9.99543C6.79508 9.95839 6.95601 9.79745 6.99305 9.59336L7.00111 9.50348L7.00065 6.99923L9.50457 7L9.59444 6.99194C9.79853 6.9549 9.95947 6.79397 9.99651 6.58988L10.0046 6.5L9.99651 6.41012C9.95947 6.20603 9.79853 6.0451 9.59444 6.00806L9.50457 6L6.99965 5.99923L7 3.49923L6.99194 3.40936C6.9549 3.20527 6.79397 3.04433 6.58988 3.00729L6.5 2.99923Z" />
        </symbol>
      </svg>
    </div>
  );
};

export default MessageInput;


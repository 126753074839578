import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import './CommentModal.css';
import { useLikesComments } from '../LikesCommentsContext';
import { formatTimeElapsed } from '../utils/formatTimeElapsed'; 

const CommentModal = () => {
  const {
    commentModalIsOpen,
    closeCommentModal,
    comments,
    setComments,
    newComment,
    setNewComment,
    handleComment,
    selectedPost,
    commentsLoading,
    topPricedPosts, 
    setTopPricedPosts,
    bestSellingPosts, 
    setBestSellingPosts,
    starAwardPosts, 
    setStarAwardPosts,
    topPricedArchivedPosts, 
    setTopPricedArchivedPosts,
    adminTopPricedPosts, 
    setAdminTopPricedPosts,
    adminStarAwardPosts, 
    setAdminStarAwardPosts,
    adminBestSellingPosts, 
    setAdminBestSellingPosts,
    bestSellingArchivedPosts, 
    setBestSellingArchivedPosts,
    starAwardArchivedPosts,
    setStarAwardArchivedPosts,
    handleCommentClick,
    isCommentEmpty, 
    setIsCommentEmpty,
    updatePostCountComments,
    elapsedTime, 
    setElapsedTime,
    commentInputRef,
    fetchComments,
    commentPage, 
    lastCommentElementRef,
    scrollLoadingComment,
  } = useLikesComments();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setElapsedTime(Date.now());
    }, 60000); 

    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
    setIsCommentEmpty(e.target.value.trim() === '');
  };
  
  //Fetch comments when user opens modal
   useEffect(() => {
        if (selectedPost) {
            fetchComments(selectedPost.id, commentPage);
        }
    }, [commentModalIsOpen, commentPage]);

  const postComment = async () => {
    const newCommentData = await handleComment(
      selectedPost,
      topPricedPosts,
      setTopPricedPosts,
      bestSellingPosts,
      setBestSellingPosts,
      starAwardPosts,
      setStarAwardPosts,
      topPricedArchivedPosts,
      setTopPricedArchivedPosts,
      adminTopPricedPosts,
      setAdminTopPricedPosts,
      adminStarAwardPosts,
      setAdminStarAwardPosts,
      adminBestSellingPosts,
      setAdminBestSellingPosts,
      bestSellingArchivedPosts,
      setBestSellingArchivedPosts,
      starAwardArchivedPosts,
      setStarAwardArchivedPosts,
      updatePostCountComments
    );

    if (newCommentData) {
      setComments([newCommentData, ...comments]);
      setNewComment('');
      setIsCommentEmpty(true);
    }
  };

  if (!commentModalIsOpen) return null;

  const buttonCommentPostStatus = isCommentEmpty ? 'post-button-disabled' : 'post-button-enabled';

  return (
    <div className="comment-modal-overlay">
      <div className="comment-modal">
        <div className="comment-modal-header">
          <h2 className="comment-text">Comments</h2>
          <div ref={commentInputRef}></div>
          <button className="comment-close-button" onClick={closeCommentModal}>
            <AiOutlineClose
              size={30}
              color="currentColor"
              className="bi bi-x"
            />
          </button>
        </div>
        <div className="comments">
          {commentsLoading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : comments.length > 0 ? (
            comments.map((comment, index) => (
              <div
                key={index}
                className="comment"
                onClick={(event) => handleCommentClick(event, comment.id)}
                ref={
                  comments.length === index + 1 ? lastCommentElementRef : null
                }
              >
                <div>
                  <strong>{comment.user}</strong>
                  <span className="comment-date">
                    {formatTimeElapsed(comment.comment_date)}
                  </span>
                </div>
                <p className="comment-text">{comment.comment_body}</p>
              </div>
            ))
          ) : (
            <p className="text-center">No comment, Be first to comment!</p>
          )}
        </div>

        <div className="scroll-loader-spinner-container-comment-modal">
          {scrollLoadingComment && (
            <div className="scroll-loader-spinner"></div>
          )}
        </div>

        <div className="comment-form">
          <input
            type="text"
            value={newComment}
            onChange={handleInputChange}
            placeholder="Add a comment..."
            className="comment-input"
          />
          <button
            onClick={postComment}
            className={`post-button ${buttonCommentPostStatus}`}
            disabled={isCommentEmpty}
          >
            Post
          </button>
        </div>
        {/* <div ref={lastCommentElementRef }></div> */}
      </div>
    </div>
  );
};

export default CommentModal;

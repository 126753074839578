// components/StarNavigation/UploadPosts.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { YourShopHeaderPostContext } from './YourShopHeaderPostContext';
import { usePlayers } from '../PlayersContext';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { BiPlus, BiMusic } from "react-icons/bi";
import UploadPostModal from './UploadPostModal';
import { useUploadPost } from './UploadPostContext';
import { useChatAndMusicPosts } from './ChatAndMusicPostsContext';
import axios from "axios";
import './UploadPost.css';

function UploadPost() {
  const { isWideScreen } = useChatAndMusicPosts();
  const {
      csrftoken,
    } = useContext(YourShopHeaderPostContext);
  const { LoaderSVG } = usePlayers();
  const {
    setSelectedMedia,
    currentImageIndex,
    setCurrentImageIndex,
    modalIsOpen,
    setModalIsOpen,
    setIsVideo,
    isPlaying,
    setIsPlaying,
    setIsCropped,
    isReverted,
    originalURLs,
    setOriginalURLs,
    setOriginalFiles,
    croppedImage,
    previewImages,
    setPreviewImages,
    postData,
    setPostData,
    dataURLtoBlob,
  } = useUploadPost();
   // Function to toggle play state
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

   const [selectedFiles, setSelectedFiles] = useState([]);
   const [numUploaded, setNumUploaded] = useState(0);
   const [uploadedMedia, setUploadedMedia] = useState(false);
   const [userData, setUserData] = useState({});
   const [imageFailed, setImageFailed] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [address, setAddress] = useState('');
  const [sizeUnitType, setSizeUnitType] = useState({ value: 'cm', label: 'cm' });
  const [productAvailability, setProductAvailability] = useState(null); 
  const [condition, setCondition] = useState(null); 
  const [weightUnit, setWeightUnit] = useState(null); 
  const [currency, setCurrency] = useState(null); 
  const [productCategory, setProductCategory] = useState(null); 

  //Erros
  const [mediaError, setMediaError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [productNameError, setProductNameError] = useState('');
  const [productAvailabilityError, setProductAvailabilityError] = useState('');
  const [productStoryError, setProductStoryError] = useState('');
  const [conditionError, setConditionError] = useState('');
  const [conditionDescriptionError, setConditionDescriptionError] = useState('');
  const [sizeError, setSizeError] = useState('');
  const [sizeUnitError, setSizeUnitError] = useState('');
  const [weightValueError, setWeightValueError] = useState('');
  const [weightValueSubmitError, setWeightValueSubmitError] = useState('');
  const [weightUnitError, setWeightUnitError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [priceSubmitError, setPriceSubmitError] = useState('');
  const [currencyError, setCurrencyError] = useState('');
  const [productCategoryError, setProductCategoryError] = useState('');
  const [locationSubmitError, setLocationSubmitError] = useState('');
  const [locationError, setLocationError] = useState('');
  const [placesError, setPlacesError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [labelPositions, setLabelPositions] = useState({
      product_name: {},
      product_story: {},
      product_availability: {},
      condition: {},
      condition_description: {},
      // size: {},
      dimensions: {},
      length: {},
      size_unit: {},
      weight_value: {},
      weight_unit: {},
      price: {},
      currency: {},
      product_category: {},
      product_location: {},
    }); // State to manage label positions for each field

   const [isLoading, setIsLoading] = useState(false);
   const [Loading, setLoading] = useState(true);
   const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  //Variable for navigating to upload music form
  const navigate = useNavigate();

//handle to navigate to uploading music form
const handleUploadMusicClick = () => {
    // navigate('/music/upload-form');
    
     navigate(`${isWideScreen ? "/home" : ""}/music/upload-form`);
    // setShowFooter(!showFooter);
  };

//For user profile
useEffect(() => {
  fetch("/api/get_user_profile_data/")
    .then((response) => response.json())
    .then((data) => {
      setUserData(data);
      setImageFailed(false);
      setLoading(false); 
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
      setLoading(false); 
    });
}, []);

  const handleBlur = (fieldName) => (e) => {
  const value = e.target.value;

  if (value === '') {
    setLabelPositions((prevPositions) => ({
      ...prevPositions,
      [fieldName]: { color: '#888' } // Change color back to original on blur
    }));
  } else {
    setLabelPositions((prevPositions) => ({
      ...prevPositions,
      [fieldName]: { top: '8px', fontSize: '12px', color: '#888' } // Keep label above input if value entered
    }));
  }
};


const handleInputChange = (selectedOption, actionMeta) => {
  if (actionMeta && actionMeta.name) {
    const { name } = actionMeta;
    const { value } = selectedOption;

    // Update the individual state based on the name of the select component setSizeUnitType
    switch (name) {
      case 'size_unit':
        setSizeUnitType(selectedOption);
        setSizeUnitError(''); 
        break;
      case 'product_availability':
        setProductAvailability(selectedOption);
        break;
      case 'condition':
        setCondition(selectedOption);
        setConditionDescriptionError(''); 
        break;
      case 'weight_unit':
        setWeightUnit(selectedOption);
        setWeightUnitError(''); 
        break;
      case 'currency':
        setCurrency(selectedOption);
        setCurrencyError(''); 
        break;
      case 'product_category':
        setProductCategory(selectedOption);
        setProductCategoryError(''); 
        break;
      default:
        break;
    }

    // Update the corresponding field in postData
    setPostData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    return;
  }

  // Handle standard input changes
  if (selectedOption && selectedOption.target) {
    const { name, value } = selectedOption.target;

    // Validation logic
    if (name === 'product_story' && value.length === 500) {
      setProductStoryError('You have reached the maximum character limit of 500.');
    } else {
      setProductStoryError('');
    }

    if ((name === 'length' || name === 'width' || name === 'height') && !/^(\d+\.?\d*|\.\d+)$/.test(value)) {
      setSizeError('Only numbers are allowed.');
      return;
    } else {
      setSizeError('');
    }

    if (name === 'weight_value' && !/^(\d+\.?\d*|\.\d+)$/.test(value)) {
      setWeightValueError('Only numbers are allowed.');
      return;
    } else {
      setWeightValueError('');
    }

    if (name === 'price' && !/^(\d+\.?\d*|\.\d+)$/.test(value)) {
      setPriceError('Only numbers are allowed.');
      return;
    } else {
      setPriceError('');
    }

    if (name === 'condition_description' && value.length === 100) {
      setConditionDescriptionError('You have reached the maximum character limit of 100.');
    } else {
      setConditionDescriptionError('');
    }

    if (name === 'product_location' && value.trim() === '') {
      setLocationError('Location cannot be empty.');
    } else {
      setLocationError('');
    }

    // Clear Errors
    if (name === 'product_name') {
      setProductNameError('');
    }

    if (name === 'product_story') {
      setProductStoryError('');
    }

    if (name === 'product_availability') {
     setProductAvailabilityError("");
    }

    if (name === 'condition') {
      setConditionError('');
    }

    if (name === 'weight_value') {
      setConditionDescriptionError('');
    }

     if (name === 'condition_description') {
      setConditionDescriptionError('');
    }

    // Clear the size unit error when the user enters input
    if (name === 'size_unit') {
      setSizeUnitError('');
    }

    // Clear the weight value error when the user enters input
    if (name === 'weight_value') {
      setWeightValueSubmitError('');
    }

    // Clear the weight unit error when the user enters input
    if (name === 'weight_unit') {
      setWeightUnitError('');
    }

    // Clear the price error when the user enters input
    if (name === 'price') {
      setPriceSubmitError('');
    }

    // Clear the currency error when the user enters input
    if (name === 'currency') {
      setCurrencyError('');
    }

    // Clear the product category error when the user enters input
    if (name === 'product_category') {
      setProductCategoryError('');
    }

    // Clear the location error when the user enters input
    if (name === 'product_location') {
      setLocationSubmitError('');
    }

    // Update the postData state
    setPostData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
};


const handleSelect = async (address) => {
  setAddress(address);

  try {
    const results = await geocodeByAddress(address);
    if (!results.length) {
      throw new Error("No results found for the address.");
    }

    const addressComponents = results[0].address_components;

    // Extract relevant components
    const street = addressComponents.find(component => component.types.includes('route'))?.long_name;
    const establishment = addressComponents.find(component => component.types.includes('establishment'))?.long_name;
    const sublocality = addressComponents.find(component => component.types.includes('sublocality'))?.long_name;
    const locality = addressComponents.find(component => component.types.includes('locality'))?.long_name;
    const administrativeArea = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;
    const country = addressComponents.find(component => component.types.includes('country'))?.long_name;

    // Combine checks for flexibility while ensuring specificity
    if ((!street && !establishment && !sublocality) || !locality || !country) {
      setLocationError("Please provide a complete and specific address. Avoid using only postal codes or abbreviations. Make sure the address includes a meaningful name like a street, landmark, or locality, along with the city and country.");
    } else {
      setLocationError(''); // Clear error if the address is sufficiently detailed
    }

    setPostData((prevData) => ({
      ...prevData,
      product_location: address,
    }));

  } catch (error) {
    if (error.message.includes('ZERO_RESULTS') || error.message.includes('INVALID_REQUEST')) {
      setLocationError('');
    } else {
      console.error('Error fetching address components:', error);
      setLocationError("Invalid address. Please enter a more detailed address.");
    }
  }
};

const handlePlacesError = (status, clearSuggestions) => {
    if (status === 'ZERO_RESULTS') {
      console.warn('No results found for the input.');
    } else {
      console.error('Error fetching data from Google Maps API:', status);
      setPlacesError('There was an error fetching data. Please try again later.');
    }
    clearSuggestions();
  };

  // Function to set script loaded state
window.initMap = () => {
  setIsScriptLoaded(true);
};

// Function to load Google Maps JavaScript API
const loadMapsScript = async () => {
  if (!isScriptLoaded) {
      if (!document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]')) {
        try {
          const response = await fetch('/api/get-google-maps-api-key/');
          const data = await response.json();
          const apiKey = data.api_key;

          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap&loading=async`;
          script.async = true;
          script.defer = true;
          script.onerror = () => {
            console.error('Error loading Google Maps script.');
            setPlacesError('There was an error fetching data. Please try again later.');
          };
          document.body.appendChild(script);
        } catch (error) {
          console.error('Error fetching Google Maps API key:', error);
          setPlacesError('There was an error fetching data. Please try again later.');
        }
      } else {
        setIsScriptLoaded(true);
      }
    }
};

useEffect(() => {
  if (!isScriptLoaded) {
    loadMapsScript();
  }
}, [isScriptLoaded]);


const handleAddressChange = (address) => {
  setAddress(address);
  setLocationError(''); 
  setLocationSubmitError('');

  // If the address is too short, avoid triggering validation
  if (address.length < 5) {
    return;
  }

  // Update product_location in postData
  setPostData((prevData) => ({
    ...prevData,
    product_location: address,
  }));
};

const handleFocus = (fieldName) => {
  setLabelPositions((prevPositions) => ({
    ...prevPositions,
    [fieldName]: { top: '8px', fontSize: '12px', color: '#f0c14b' }
  }));
};


const handleFileChange = (e) => {
  const filesArray = Array.from(e.target.files).filter(file => {
    const ext = file.name.split('.').pop().toLowerCase();
    if (!['jpg', 'png', 'mp4', 'mov'].includes(ext)) {
      setMediaError('Invalid file format. Only .jpg, .png, .mp4, and .mov are allowed.');
      return false;
    }
    if (selectedFiles.includes(file.name)) {
      setMediaError('This file has already been selected.');
      return false;
    }
    return true;
  });

  if (filesArray.length + numUploaded > 5) {
    setMediaError('You can only upload up to 5 media files.');
    return;
  }

  if (filesArray.length > 0) {
    const urls = filesArray.map(file => URL.createObjectURL(file));
    setPostData(prevData => ({ ...prevData, media_files: [...prevData.media_files, ...filesArray] }));
    setPreviewImages(prevImages => [...prevImages, ...urls]);

    setNumUploaded(prevNum => prevNum + filesArray.length); 
    setUploadedMedia(true);
    setSelectedFiles([...selectedFiles, ...filesArray.map(file => file.name)]);
    setMediaError('');
    setSubmitError('');
  }

  e.target.value = null; 
};

// Validate Image
const validateImage = async (file) => {
  // File Size Limit
  const fileSizeInMB = file.size / (1024 * 1024);
  const maxFileSizeInMB = 200; // file size limit
  if (fileSizeInMB > maxFileSizeInMB) {
    return `File size should not exceed ${maxFileSizeInMB} MB.`;
  }
  return null; // No error
};

// Validate Video
const validateVideo = async (file) => {
  // File Size Limit
  const fileSizeInMB = file.size / (1024 * 1024);
  const maxFileSizeInMB = 200; // file size limit
  if (fileSizeInMB > maxFileSizeInMB) {
    return `File size should not exceed ${maxFileSizeInMB} MB.`;
  }

  // Duration Limit for Videos
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.src = URL.createObjectURL(file);

  const error = await new Promise(resolve => {
    video.onloadedmetadata = function() {
      // Duration limit
      if (this.duration > 30) {
        resolve('Video duration should not exceed 30 seconds.');
      }
      resolve(null);
    };
    video.onerror = function() {
      resolve('Failed to load the video.');
    };
  });

  return error;
};
//Itarete Video and Image files and call appropriate validation function
const validateFiles = async () => {
  for (let file of postData.media_files) {
    let error = null;
    if (file.type.startsWith('image/')) {
      error = await validateImage(file);
    } else if (file.type.startsWith('video/')) {
      error = await validateVideo(file);
    }
    if (error) {
      return error;
    }
  }

  return null;
};

const handleRemove = (event, indexToRemove) => {
  event.stopPropagation(); // Prevent event propagation

  const updatedMediaFiles = postData.media_files.filter(
    (_, index) => index !== indexToRemove
  );
  const updatedSelectedFiles = selectedFiles.filter(
    (_, index) => index !== indexToRemove
  );
  setPostData({
    ...postData,
    media_files: updatedMediaFiles,
  });

  setSelectedFiles(updatedSelectedFiles);
  setPreviewImages(previewImages.filter((_, index) => index !== indexToRemove));
  setNumUploaded((prevNum) => prevNum - 1); // Update the number of uploaded files

  // Only set uploadedMedia to false if all media files have been removed
  if (updatedMediaFiles.length === 0) {
    setUploadedMedia(false);
  }
};

useEffect(() => {
   // Reset isCropped to false whenever the modal is opened or closed
   if (!modalIsOpen) {
     setIsCropped(false);
    
   } 
 }, [modalIsOpen]);


const openModal = ( event, url, isVideo, index) => {
  event.stopPropagation()
  setIsLoading(true);  // Start loading

  setSelectedMedia(url);
  setIsVideo(isVideo);
  setModalIsOpen(true);
  
  
  // Check if originalURL and originalFile for this index has been set
  if (!originalURLs[index]) {
    setOriginalURLs(prevURLs => ({ ...prevURLs, [index]: url }));
    setOriginalFiles(prevFiles => ({ ...prevFiles, [index]: postData.media_files[index] }));
  }

  setCurrentImageIndex(index);
   // Set isLoading to false after 3 seconds as a fallback
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitLoading(true);
  setUploadProgress(0);
  let isUploadComplete = false;

  // Validation checks
  if (postData.media_files.length === 0) {
    setSubmitError("Please upload at least one media.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.product_name) {
    setProductNameError("Please provide a product name.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.product_story) {
    setProductStoryError("Please provide a product story.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.product_availability) {
    setProductAvailabilityError("Please select a product's availability.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.condition) {
    setConditionError("Please select a condition.");
    setIsSubmitLoading(false);
    return;
  }

  if (postData.condition === "Imperfect" && !postData.condition_description) {
    setConditionDescriptionError("Please describe the imperfection.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.size_unit) {
    postData.size_unit = sizeUnitType.value;
  }

  if (
    (postData.length || postData.width || postData.height) &&
    !postData.size_unit
  ) {
    setSizeUnitError("Please provide a size unit.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.weight_value) {
    setWeightValueSubmitError("Please provide the product's weight.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.weight_unit) {
    setWeightUnitError("Please provide the product's weight unit.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.price) {
    setPriceSubmitError("Please provide the product's price.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.currency) {
    setCurrencyError("Please select a currency.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.product_category) {
    setProductCategoryError("Please choose a product category.");
    setIsSubmitLoading(false);
    return;
  }

  if (!postData.product_location) {
    setLocationSubmitError("Location cannot be empty.");
    setIsSubmitLoading(false);
    return;
  }

  // Re-check address completeness before submission
  try {
    const results = await geocodeByAddress(postData.product_location);
    if (!results.length) {
      throw new Error("No results found for the address.");
    }

    const addressComponents = results[0].address_components;

    // Extract relevant components
    const street = addressComponents.find((component) =>
      component.types.includes("route")
    )?.long_name;
    const establishment = addressComponents.find((component) =>
      component.types.includes("establishment")
    )?.long_name;
    const sublocality = addressComponents.find((component) =>
      component.types.includes("sublocality")
    )?.long_name;
    const locality = addressComponents.find((component) =>
      component.types.includes("locality")
    )?.long_name;
    const administrativeArea = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_1")
    )?.long_name;
    const country = addressComponents.find((component) =>
      component.types.includes("country")
    )?.long_name;

    // Re-check for sufficient address details
    if ((!street && !establishment && !sublocality) || !locality || !country) {
      setLocationSubmitError(
        "Please provide a complete and specific address. Avoid using only postal codes or abbreviations. Make sure the address includes a meaningful name like a street, landmark, or locality, along with the city and country."
      );
      setIsSubmitLoading(false);
      return;
    }
  } catch (error) {
    setLocationSubmitError(
      "Invalid address. Please provide a valid and detailed address."
    );
    console.error("Error during address validation before submission:", error);
    setIsSubmitLoading(false);
    return;
  }

  // updating original_currency to match currency
  postData.original_currency = postData.currency;

  const error = await validateFiles();
  if (error) {
    setSubmitError(error);
    setIsSubmitLoading(false);
    return;
  }

  const formData = new FormData();
  postData.media_files.forEach((file, index) => {
    const currentOriginalURL = originalURLs[index];
    if (croppedImage && index === currentImageIndex) {
      const imageData = isReverted ? currentOriginalURL : croppedImage;
      const croppedBlob = dataURLtoBlob(croppedImage);
      const mime = croppedImage.split(",")[0].match(/:(.*?);/)[1];
      const fileExtension = mime === "image/jpeg" ? "jpg" : "png";

      const croppedFile = new File(
        [croppedBlob],
        `media_file_${index}.${fileExtension}`,
        { type: mime }
      );
      formData.append(`media_file_${index}`, croppedFile);
    } else {
      formData.append(`media_file_${index}`, file);
    }

    if (file.type.startsWith("image/")) {
      formData.append("images", file);
    } else if (file.type.startsWith("video/")) {
      formData.append("videos", file);
    }
  });

  Object.entries(postData).forEach(([key, value]) => {
    if (key !== "media_files") {
      formData.append(key, value);
    }
  });

  setSubmitError("");
  try {
    const response = await axios.post("/api/StarPostings/", formData, {
      headers: {
        "X-CSRFToken": csrftoken,
      },
      onUploadProgress: (progressEvent) => {
        const percentComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentComplete);

        // Mark upload as complete if progress reaches 100
        if (percentComplete === 100) {
          isUploadComplete = true;
        }
      },
    });

    // Ensure successful response and upload completion before navigating
    if (response.status === 201 && isUploadComplete) {
      setIsSubmitLoading(false);
      navigate(`/${userData.username}/shopPosts`);

      // Clear media files and reset form-related state variables
      setPostData((prevData) => ({
        ...prevData,
        media_files: [],
      }));
      setPreviewImages([]);
      setSelectedFiles([]);
      setNumUploaded(0);
      setUploadedMedia(false);
      setSubmitError("");
    } else {
      throw new Error("Upload not complete or response not OK.");
    }
  } catch (error) {
    console.error("Error uploading media:", error);
    setSubmitError("An error occurred while uploading.");
    setIsSubmitLoading(false);
  }
};



const customStyles = {
  control: (provided, state) => ({
    ...provided,
    outline: state.isFocused ? 'none !important' : provided.outline,
    border: state.isFocused ? '1px #f0c14b solid !important' : provided.border,
    boxShadow: state.isFocused ? 'none !important' : provided.boxShadow,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#f0c14b'
      : state.isFocused
      ? '#e0e0e0'
      : provided.backgroundColor,
    color: state.isSelected
      ? '#000'
      : provided.color,
    cursor: state.isSelected ? 'default' : 'pointer',
  }),
};

const sizeUnitOptions = [
  { value: 'cm', label: 'cm' },
  { value: 'in', label: 'in' },
  { value: 'ft', label: 'ft' },
  { value: 'xl', label: 'xl' },
  { value: 'xxl', label: 'xxl' },
  { value: 'single', label: 'single' },
  { value: 'double', label: 'double' },
];

const productAvailabilityOptions = [
  { value: '1', label: 'Only 1 available' },
  { value: '2', label: 'Only 2 available' },
  { value: '3', label: 'Only 3 available' },
  { value: '4', label: 'Only 4 available' },
  { value: 'More than 4', label: 'More than 4 available' },
];

const conditionOptions = [
  { value: 'Good', label: 'Good' },
  { value: 'Imperfect', label: 'Imperfect' },
];

const weightUnitOptions = [
  { value: 'g', label: 'Grams' },
  { value: 'kg', label: 'Kilograms' },
];

const currencyOptions = [
  { value: 'TZS', label: 'TZS' },
  { value: 'USD', label: 'USD' },
];

const productCategoryOptions = [
  // Clothing
  { value: "Dresses", label: "Dresses" },
  { value: "Suits", label: "Suits" },
  { value: "T-Shirts", label: "T-Shirts" },
  { value: "Jackets", label: "Jackets" },
  { value: "Pants", label: "Pants" },
  { value: "Skirts", label: "Skirts" },
  { value: "Jeans", label: "Jeans" },
  { value: "Shorts", label: "Shorts" },
  { value: "Socks", label: "Socks" },
  { value: "Outerwear", label: "Outerwear" },
  { value: "Lingerie", label: "Lingerie" },
  { value: "Swimwear", label: "Swimwear" },
  { value: "Activewear", label: "Activewear" },
  { value: "Full Outfit Cloth", label: "Full Outfit Cloth" },

  // Footwear
  { value: "Sneakers", label: "Sneakers" },
  { value: "Formal Shoes", label: "Formal Shoes" },
  { value: "Sandals", label: "Sandals" },
  { value: "Boots", label: "Boots" },
  { value: "High Heels", label: "High Heels" },
  { value: "Sports Shoes", label: "Sports Shoes" },
  { value: "Slippers", label: "Slippers" },
  { value: "Loafers", label: "Loafers" },
  { value: "Flip-Flops", label: "Flip-Flops" },
  { value: "Clogs", label: "Clogs" },

  // Accessories
  { value: "Watches", label: "Watches" },
  { value: "Necklaces", label: "Necklaces" },
  { value: "Earrings", label: "Earrings" },
  { value: "Bracelets", label: "Bracelets" },
  { value: "Sunglasses", label: "Sunglasses" },
  { value: "Hats", label: "Hats" },
  { value: "Belts", label: "Belts" },
  { value: "Scarves", label: "Scarves" },
  { value: "Wallets", label: "Wallets" },
  { value: "Backpacks", label: "Backpacks" },
  { value: "Handbags", label: "Handbags" },
  { value: "Gloves", label: "Gloves" },
  { value: "Hair Accessories", label: "Hair Accessories" },

  // Sports Gear
  { value: "Tennis Balls", label: "Tennis Balls" },
  { value: "Jerseys", label: "Jerseys" },
  { value: "Sports Shoes", label: "Sports Shoes" },
  { value: "Rackets", label: "Rackets" },
  { value: "Football Gear", label: "Football Gear" },
  { value: "Yoga Mats", label: "Yoga Mats" },
  { value: "Fitness Bands", label: "Fitness Bands" },
  { value: "Dumbbells", label: "Dumbbells" },
  { value: "Exercise Equipment", label: "Exercise Equipment" },

  // Event Memorabilia
  { value: "Microphones", label: "Microphones" },
  { value: "Autographed Items", label: "Autographed Items" },
  { value: "Awards", label: "Awards" },
  { value: "Event Tickets", label: "Event Tickets" },
  { value: "Posters", label: "Posters" },
  { value: "Programs", label: "Programs" },

  // Music
  { value: "Albums", label: "Albums" },
  { value: "Vinyl Records", label: "Vinyl Records" },
  { value: "Autographed CDs", label: "Autographed CDs" },
  { value: "Instruments", label: "Instruments" },

  // Electronics
  { value: "Phones", label: "Phones" },
  { value: "Cameras", label: "Cameras" },
  { value: "Headphones", label: "Headphones" },
  { value: "Laptops", label: "Laptops" },
  { value: "Tablets", label: "Tablets" },
  { value: "Gaming Consoles", label: "Gaming Consoles" },
  { value: "Smart Watches", label: "Smart Watches" },
  { value: "Power Banks", label: "Power Banks" },
  { value: "Drones", label: "Drones" },
  { value: "Speakers", label: "Speakers" },

  // Art and Collectibles
  { value: "Paintings", label: "Paintings" },
  { value: "Posters", label: "Posters" },
  { value: "Scripts", label: "Scripts" },
  { value: "Figurines", label: "Figurines" },
  { value: "Antiques", label: "Antiques" },

  // Books
  { value: "Fiction", label: "Fiction" },
  { value: "Non-Fiction", label: "Non-Fiction" },
  { value: "Educational", label: "Educational" },
  { value: "Comics", label: "Comics" },
  { value: "Cookbooks", label: "Cookbooks" },
  { value: "Biographies", label: "Biographies" },

  // Miscellaneous
  { value: "Perfumes", label: "Perfumes" },
  { value: "Props from Movies/Videos", label: "Props from Movies/Videos" },
  { value: "Toys", label: "Toys" },
  { value: "Stationery", label: "Stationery" },
  { value: "Board Games", label: "Board Games" },
  { value: "Decor Items", label: "Decor Items" },

  // Custom Categories
  { value: "Custom Items", label: "Custom Items" },
  { value: "Others", label: "Others" },
];


  return (
    <div className="main-container-upload-post">
      <div className="uploadpost_header">
        {!isLoading && (
          <>
            <div className="uploadpost-profile">
              <div className="profile">
                {userData.profile_picture && !imageFailed ? (
                  <img
                    src={userData.profile_picture}
                    alt={userData.first_name}
                    className="profile-picture"
                    onError={() => setImageFailed(true)}
                  />
                ) : (
                  <div className="profile-picture no-profile-picture">
                    {userData.first_name
                      ? userData.first_name.slice(0, 1).toUpperCase()
                      : "?"}
                  </div>
                )}
              </div>
              <div className="uploadpost_username_display">
                {userData.username}
              </div>
            </div>
            <div className="wrapper-textshop-and-upload-music">
              <div className="uploadpost_text">
                <BiPlus size={20} />
                Shop
              </div>
              {userData.confirm_notability &&
                userData.confirm_notability.some(
                  (notability) => notability.name === "Musician"
                ) && (
                  <div
                    className="upload_music"
                    onClick={handleUploadMusicClick}
                  >
                    <BiMusic size={20} />
                  </div>
                )}
            </div>
          </>
        )}
      </div>
      <div className="form-container upload-post-form">
        <form onSubmit={handleSubmit}>
          {mediaError && (
            <div className="error-message">
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {mediaError}
            </div>
          )}
          <div className="upload-labal-thumbnail">
            <div className="image-video-preview">
              {previewImages.map((url, index) => {
                return (
                  <div key={url + index} className="thumbnail">
                    <i
                      className="bi bi-x"
                      onClick={(event) => handleRemove(event, index)}
                    ></i>
                    {/* Close icon */}
                    {postData.media_files[index].type.startsWith("image/") ? (
                      <img
                        src={url}
                        alt="Preview"
                        onClick={(event) => openModal(event, url, false, index)}
                      />
                    ) : (
                      <video
                        width="70"
                        height="70"
                        onClick={(event) => openModal(event, url, true, index)}
                      >
                        <source
                          src={url}
                          type={postData.media_files[index].type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                );
              })}
            </div>
            {postData.media_files.length < 5 && (
              <div
                className={`upload-label-media${
                  uploadedMedia ? " uploaded" : " no-media"
                }`}
              >
                <label
                  className={`upload-label${
                    uploadedMedia ? " uploaded" : " no-media"
                  }`}
                >
                  <div>
                    <i className="bi bi-folder-plus"></i>
                  </div>
                  Add Photo/Video
                  <input
                    type="file"
                    name="media_files"
                    onChange={handleFileChange}
                    multiple
                    className="upload-input"
                  />
                </label>
              </div>
            )}
          </div>
          <div className="help-text1 mb-3">
            Photos/videos: {numUploaded}/5 choose your best media
          </div>

          {productNameError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {productNameError}
            </div>
          )}
          <div className="input-container">
            <label htmlFor="product_name" style={labelPositions.product_name}>
              Product Name
            </label>
            <input
              type="text"
              name="product_name"
              id="product_name"
              maxLength="50"
              onFocus={() => handleFocus("product_name")}
              onBlur={(e) => handleBlur("product_name")}
              onChange={handleInputChange}
            />
          </div>
          <p className="help-text mb-3">Enter the product name</p>

          {productStoryError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {productStoryError}
            </div>
          )}
          <div className="input-container">
            <label htmlFor="product_story" style={labelPositions.product_story}>
              Product Story
            </label>
            <textarea
              name="product_story"
              id="product_story"
              rows="2"
              maxLength="500"
              cols="40"
              onFocus={() => handleFocus("product_story")}
              onBlur={(e) => handleBlur("product_story")}
              onChange={handleInputChange}
              style={{ overflow: "hidden", resize: "none" }}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }}
              className="post-textarea"
            />
          </div>
          <p className="help-text mb-3">
            Share your product’s story with fans - make it fun, real, and
            connected to you or anything interesting! Mention where your product
            has been featured - in music, movies, matches, events, and so on.
            Include specific details like, names, locations, times, dates,
            color, and creators.
          </p>

          {productAvailabilityError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {productAvailabilityError}
            </div>
          )}

          <div className="input-container">
            <label
              htmlFor="product_availability"
              style={labelPositions.product_availability}
            >
              Product Availability
            </label>
            <Select
              inputId="product_availability"
              name="product_availability"
              options={productAvailabilityOptions}
              value={productAvailability}
              onFocus={() => handleFocus("product_availability")}
              onBlur={(e) => handleBlur("product_availability")}
              onChange={handleInputChange}
              styles={customStyles}
              className="availability-selector"
            />
          </div>
          <p className="help-text mb-3">
            Tell fans if it's just this product or if there's more. Uniqueness
            sells.
          </p>

          {conditionError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {conditionError}
            </div>
          )}
          <div className="input-container">
            <label htmlFor="condition" style={labelPositions.condition}>
              Condition
            </label>
            <Select
              inputId="condition"
              name="condition"
              options={conditionOptions}
              value={condition}
              onFocus={() => handleFocus("condition")}
              onBlur={(e) => handleBlur("condition")}
              onChange={handleInputChange}
              styles={customStyles}
              className="condition-selector"
            />
          </div>

          <p className="help-text mb-3">
            Choose the condition of your product.
          </p>

          {postData.condition === "Imperfect" && (
            <>
              {conditionDescriptionError && (
                <div className="error-message">
                  {" "}
                  <i className="bi bi-exclamation-circle-fill error-icon"></i>
                  {conditionDescriptionError}
                </div>
              )}
              <div className="input-container">
                <label
                  htmlFor="condition_description"
                  style={labelPositions.condition_description}
                >
                  Describe Imperfect
                </label>
                <textarea
                  name="condition_description"
                  id="condition_description"
                  rows="1"
                  cols="40"
                  maxLength="100"
                  onFocus={() => handleFocus("condition_description")}
                  onBlur={(e) => handleBlur("condition_description")}
                  onChange={handleInputChange}
                  style={{ overflow: "hidden", resize: "none" }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  className="post-textarea"
                />
              </div>
              <p className="help-text mb-3">
                Inform your clients/fans about any issues with the product. This
                will help them understand the product they are considering to
                purchase and prevent any future problems.
              </p>
            </>
          )}
          {sizeError && (
            <div className="error-message">
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {sizeError}
            </div>
          )}
          {sizeUnitError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {sizeUnitError}
            </div>
          )}
          <div className="input-container input-unit-selector">
            <label
              htmlFor="dimensions"
              style={labelPositions.dimensions}
            ></label>
            <div className="dimensions-input">
              <input
                type="text"
                name="length"
                id="length"
                placeholder="L"
                onFocus={() => handleFocus("length")}
                onBlur={(e) => handleBlur("length")}
                onChange={handleInputChange}
                className="dimension-field"
              />
              <span>x</span>
              <input
                type="text"
                name="width"
                id="width"
                placeholder="W"
                onFocus={() => handleFocus("width")}
                onBlur={(e) => handleBlur("width")}
                onChange={handleInputChange}
                className="dimension-field"
              />
              <span>x</span>
              <input
                type="text"
                name="height"
                id="height"
                placeholder="H"
                onFocus={() => handleFocus("height")}
                onBlur={(e) => handleBlur("height")}
                onChange={handleInputChange}
                className="dimension-field"
              />
              <Select
                inputId="size_unit"
                name="size_unit"
                options={sizeUnitOptions}
                value={sizeUnitType}
                onFocus={() => handleFocus("size_unit")}
                onBlur={(e) => handleBlur("size_unit")}
                onChange={handleInputChange}
                styles={customStyles}
                className="unit-selector"
              />
            </div>
          </div>
          <p className="help-text mb-3">
            Enter the dimensions (L × W × H) and select the unit.
          </p>
          {weightValueError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {weightValueError}
            </div>
          )}
          {weightValueSubmitError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {weightValueSubmitError}
            </div>
          )}
          <div className="input-container">
            <label htmlFor="weight_value" style={labelPositions.weight_value}>
              Weight Value
            </label>
            <input
              type="text"
              name="weight_value"
              id="weight_value"
              onFocus={() => handleFocus("weight_value")}
              onBlur={(e) => handleBlur("weight_value")}
              onChange={handleInputChange}
            />
          </div>
          <p className="help-text mb-3">Enter weight of the product</p>
          {postData.weight_value && (
            <>
              {weightUnitError && (
                <div className="error-message">
                  {" "}
                  <i className="bi bi-exclamation-circle-fill error-icon"></i>
                  {weightUnitError}
                </div>
              )}
              <div className="input-container">
                <label htmlFor="weight_unit" style={labelPositions.weight_unit}>
                  Weight Unit
                </label>
                <Select
                  inputId="weight_unit"
                  name="weight_unit"
                  options={weightUnitOptions}
                  value={weightUnit}
                  onFocus={() => handleFocus("weight_unit")}
                  onBlur={(e) => handleBlur("weight_unit")}
                  onChange={handleInputChange}
                  styles={customStyles}
                  className="weight-unit-selector"
                />
              </div>
              <p className="help-text mb-3">
                Enter the weight’s unit of measurement.
              </p>
            </>
          )}

          {priceError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {priceError}
            </div>
          )}
          {priceSubmitError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {priceSubmitError}
            </div>
          )}
          <div className="input-container">
            <label htmlFor="price" style={labelPositions.price}>
              Price
            </label>
            <input
              type="text"
              name="price"
              id="price"
              onFocus={() => handleFocus("price")}
              onBlur={(e) => handleBlur("price")}
              onChange={handleInputChange}
            />
          </div>
          <p className="help-text mb-3">
            Enter the price at which you are offering your product for sale.
          </p>
          {postData.price && (
            <>
              {currencyError && (
                <div className="error-message">
                  {" "}
                  <i className="bi bi-exclamation-circle-fill error-icon"></i>
                  {currencyError}
                </div>
              )}
              <div className="input-container">
                <label htmlFor="currency" style={labelPositions.currency}>
                  Currency
                </label>
                <Select
                  inputId="currency"
                  name="currency"
                  options={currencyOptions}
                  value={currency}
                  onFocus={() => handleFocus("currency")}
                  onBlur={(e) => handleBlur("currency")}
                  onChange={handleInputChange}
                  styles={customStyles}
                  className="currency-selector"
                />
              </div>
              <p className="help-text mb-3">Choose price's currency</p>
            </>
          )}
          {productCategoryError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {productCategoryError}
            </div>
          )}
          <div className="input-container">
            <label
              htmlFor="product_category"
              style={labelPositions.product_category}
            >
              Product Category
            </label>
            <Select
              inputId="product_category"
              name="product_category"
              options={productCategoryOptions}
              value={productCategory}
              onFocus={() => handleFocus("product_category")}
              onBlur={(e) => handleBlur("product_category")}
              onChange={handleInputChange}
              styles={customStyles}
              className="category-selector"
            />
          </div>
          <p className="help-text mb-3">
            Choose the category to which your product belongs.
          </p>
          {locationSubmitError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {locationSubmitError}
            </div>
          )}
          {locationError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {locationError}
            </div>
          )}
          {placesError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {placesError}
            </div>
          )}
          {submitError && (
            <div className="error-message">
              {" "}
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {submitError}
            </div>
          )}
          <div className="input-container input-container-location-search">
            <label
              htmlFor="product_location"
              style={labelPositions.product_location}
            >
              Location
            </label>
            {isScriptLoaded ? (
              <PlacesAutocomplete
                value={address}
                onChange={handleAddressChange}
                onSelect={handleSelect}
                onError={handlePlacesError}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        className: "location-search-input form-control",
                        onFocus: () => handleFocus("product_location"),
                        onBlur: handleBlur("product_location"),
                        id: "product_location",
                        name: "product_location",
                        autoComplete: "on",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div className="mb-3">Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            ) : (
              <input
                id="product_location"
                name="product_location"
                className="location-search-input-uploadpost"
                onFocus={() => handleFocus("product_location")}
                autoComplete="on"
              />
            )}
          </div>
          <p className="help-text mb-3">
            Enter the location where your product is available. Please provide
            an accurate location, and always ensure that the city and country
            are the last parts of your address.
          </p>
          <div className="text-center publish">
            <button
              type="submit"
              disabled={isSubmitLoading}
              className={isSubmitLoading ? "loading" : ""}
            >
              {isSubmitLoading ? (
                <div className="upload-progress">
                  <span>Posting: {uploadProgress}%</span>
                  <div className="progress-bar-posting">
                    <div
                      className="progress-posting"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                </div>
              ) : (
                "Publish"
              )}
            </button>
          </div>
        </form>
        <UploadPostModal />
      </div>
    </div>
  );
}

export default UploadPost;



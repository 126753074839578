import { useEffect } from "react";

const useAlignSpinner = (parentRef, spinnerSelector) => {
  useEffect(() => {
    const alignSpinner = () => {
      const container = parentRef.current;
      const spinner = document.querySelector(spinnerSelector);

      if (container && spinner) {
        const containerRect = container.getBoundingClientRect();
        spinner.style.left = `${
          containerRect.left + containerRect.width / 2
        }px`;
      }
    };

    // Align spinner initially
    alignSpinner();

    // Align spinner on window resize
    window.addEventListener("resize", alignSpinner);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", alignSpinner);
    };
  }, [parentRef, spinnerSelector]);
};

export default useAlignSpinner;

// import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
// import { useChatAndMusicPosts } from "./StarNavigationBar/ChatAndMusicPostsContext";

// const LiveVisitorsContext = createContext();

// export const useLiveVisitors = () => useContext(LiveVisitorsContext);

// const connectWebSocket = (socketRef, url, onMessage, onError, onClose) => {
//   const socket = new WebSocket(url);

//   socket.onopen = () => {};

//   socket.onmessage = (event) => {
//     onMessage(JSON.parse(event.data));
//   };

//   socket.onerror = (error) => {};

//   socket.onclose = () => {};

//   socketRef.current = socket;
// };



// const LiveVisitorsProvider = ({ children }) => {
//   const { userData } = useChatAndMusicPosts();
//   const [liveVisitors, setLiveVisitors] = useState(0);
//   const [profileBeingViewed, setProfileBeingViewed] = useState(localStorage.getItem('profileBeingViewed') || '');
//   const socketLiveVisitorsRef = useRef(null);
//   const socketProfileOwnerRef = useRef(null);
//   const username = userData.username;

//   const handleVisitorMessage = (data) => {
//     if (data.profile_username === username && data.visitor_count !== undefined) {
//       setLiveVisitors(data.visitor_count);
//     }
//   };

//   useEffect(() => {
//     if (!profileBeingViewed) return;

//     localStorage.setItem('profileBeingViewed', profileBeingViewed);
//     const socketUrl = `ws://localhost:8000/ws/live_visitors/${profileBeingViewed}/`;
//     connectWebSocket(socketLiveVisitorsRef, socketUrl, handleVisitorMessage);

//     return () => {
//       if (socketLiveVisitorsRef.current) {
//         socketLiveVisitorsRef.current.close();
//       }
//     };
//   }, [profileBeingViewed]);

//   useEffect(() => {
//     if (!username) return;
    
//     const socketUrl = `ws://localhost:8000/ws/live_visitors/${username}/`;
//     connectWebSocket(socketProfileOwnerRef, socketUrl, handleVisitorMessage);

//     return () => {
//       if (socketProfileOwnerRef.current && !username) {
//         socketProfileOwnerRef.current.close();
//       }
//     };
//   }, [username]);

//   return (
//     <LiveVisitorsContext.Provider value={{ 
//       liveVisitors, 
//       profileBeingViewed, 
//       setProfileBeingViewed 
//     }}>
//       {children}
//     </LiveVisitorsContext.Provider>
//   );
// };

// export default LiveVisitorsProvider;

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useChatAndMusicPosts } from "./StarNavigationBar/ChatAndMusicPostsContext";

// Import environment variables
const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST;

const LiveVisitorsContext = createContext();

export const useLiveVisitors = () => useContext(LiveVisitorsContext);

const connectWebSocket = (socketRef, url, onMessage, onError, onClose) => {
  const socket = new WebSocket(url);

  socket.onopen = () => {};

  socket.onmessage = (event) => {
    onMessage(JSON.parse(event.data));
  };

  socket.onerror = (error) => {};

  socket.onclose = () => {};

  socketRef.current = socket;
};

const LiveVisitorsProvider = ({ children }) => {
  const { userData } = useChatAndMusicPosts();
  const [liveVisitors, setLiveVisitors] = useState(0);
  const [profileBeingViewed, setProfileBeingViewed] = useState(
    localStorage.getItem("profileBeingViewed") || ""
  );
  const socketLiveVisitorsRef = useRef(null);
  const socketProfileOwnerRef = useRef(null);
  const username = userData.username;

  const handleVisitorMessage = (data) => {
    if (
      data.profile_username === username &&
      data.visitor_count !== undefined
    ) {
      setLiveVisitors(data.visitor_count);
    }
  };

  useEffect(() => {
    if (!profileBeingViewed) return;

    localStorage.setItem("profileBeingViewed", profileBeingViewed);
    const socketUrl = `${WEBSOCKET_HOST}live_visitors/${profileBeingViewed}/`;
    connectWebSocket(socketLiveVisitorsRef, socketUrl, handleVisitorMessage);

    return () => {
      if (socketLiveVisitorsRef.current) {
        socketLiveVisitorsRef.current.close();
      }
    };
  }, [profileBeingViewed]);

  useEffect(() => {
    if (!username) return;

    const socketUrl = `${WEBSOCKET_HOST}live_visitors/${username}/`;
    connectWebSocket(socketProfileOwnerRef, socketUrl, handleVisitorMessage);

    return () => {
      if (socketProfileOwnerRef.current && !username) {
        socketProfileOwnerRef.current.close();
      }
    };
  }, [username]);

  return (
    <LiveVisitorsContext.Provider
      value={{
        liveVisitors,
        profileBeingViewed,
        setProfileBeingViewed,
      }}
    >
      {children}
    </LiveVisitorsContext.Provider>
  );
};

export default LiveVisitorsProvider;




import React, {useState} from "react";
import { useChat } from "./ChatContext";
import { v4 as uuidv4 } from "uuid";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { AiOutlineClose } from "react-icons/ai";
import "../StarNavigationBar/chat.css";

const MessageImageCropper = () => {
  const {
    selectedImage,
    croppedImage,
    originalImage,
    setSelectedImage,
    selectedFile,
    setSelectedFile,
    setCroppedImage,
    setPreviewImage,
  } = useChat();

  const [cropper, setCropper] = useState();
  const [isCropped, setIsCropped] = useState(false);

   const closePreview = () => {
     setPreviewImage(false);
   };

   const stopPropagation = (e) => {
     e.stopPropagation();
   };

   const handleSave = async () => {
     // Save the cropped image
     if (cropper) {
       const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
       setCroppedImage(croppedDataUrl);
       setSelectedImage(croppedDataUrl); // Update the selectedImage state

       // Create a new Blob from the cropped image data URL using fetch API
       const response = await fetch(croppedDataUrl);
       const newBlob = await response.blob();

       // Get the original file extension
       const originalFileExtension = selectedFile.name.split(".").pop();

       // Generate a unique filename for the cropped image using UUID and include the original file extension
       const filename = `${uuidv4()}.${originalFileExtension}`;

       // Convert the Blob back into a File with the unique filename
       const newFile = new File([newBlob], filename, { type: newBlob.type });

       // Update the selectedFile with the new File
       setSelectedFile(newFile);

       setIsCropped(false); // Reset the isCropped state
     }
     closePreview();
   };

   const handleRevert = async () => {
     // Revert to the original image
     setSelectedImage(originalImage); // Revert the selectedImage state to the original image URL
     setCroppedImage(null);
     setIsCropped(false); // Reset the isCropped state

     // Fetch the original image data URL
     const response = await fetch(originalImage);
     const originalBlob = await response.blob();

     // Get the original file extension
     const originalFileExtension = selectedFile.name.split(".").pop();

     // Generate a unique filename for the original image using UUID and include the original file extension
     const filename = `${uuidv4()}.${originalFileExtension}`;

     // Convert the Blob back into a File with the unique filename
     const originalFile = new File([originalBlob], filename, {
       type: originalBlob.type,
     });

     // Update the selectedFile with the original File
     setSelectedFile(originalFile);
   };

   const onCrop = () => {
     if (cropper) {
       const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
       setCroppedImage(croppedDataUrl);
     }
   };
   const onCropStart = () => {
     setIsCropped(true); // Set the isCropped state to true
   };

  return (
    // <div className="message-image-cropper">
    <div className="overlay" onClick={closePreview}>
      <div className="image-preview" onClick={stopPropagation}>
        <Cropper
          src={selectedImage || croppedImage}
          style={{ height: "auto", width: "97%", marginLeft: 5 }}
          preview=".img-preview"
          viewMode={1}
          zoomable={true}
          dragMode="crop"
          guides={true}
          crop={onCrop}
          cropstart={onCropStart}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
        <div className="btn-cropperMessage-container">
          <div className="btn-saveRevert">
            <button
              onClick={handleSave}
              disabled={!isCropped}
              className="save-button"
            >
              Save
            </button>
            <button
              onClick={handleRevert}
              disabled={selectedImage === originalImage}
              className="revert-button"
            >
              Revert
            </button>
          </div>
          <button onClick={closePreview} className="close-button">
            <AiOutlineClose size={30} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageImageCropper;

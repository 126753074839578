// Admin.jsx
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AdminNavigation from './AdminNavigation';
import './Admin.css';

function Admin() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/adminGooofan/homescreen") {
      navigate("/adminGooofan/homescreen/post-form");
    }
  }, [location, navigate]);

  return (
    <div>
      <AdminNavigation />
      <div className="admin-content">
        <Outlet />
      </div>
    </div>
  );
}

export default Admin;


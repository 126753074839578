import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function HomeScreenNavigation() {
  const location = useLocation();

  return (
    <div className="homeScreen-links-container">
      <Link to="/adminGooofan/homescreen/post-form" className={location.pathname === "/adminGooofan/homescreen/post-form" ? "active-section-admin" : ""}>Create Post</Link>
      <Link to="/adminGooofan/homescreen/posts" className={location.pathname === "/adminGooofan/homescreen/posts" ? "active-section-admin" : ""}>Posts</Link>
      <Link to="/adminGooofan/homescreen/post-archive" className={location.pathname === "/adminGooofan/homescreen/post-archive" ? "active-section-admin" : ""}>Archived</Link>
    </div>
  );
}

export default HomeScreenNavigation;


import React, { useEffect, useRef, useState, forwardRef } from "react";
import { usePlayers } from "./PlayersContext";
import "./VideoControls.css";

const VideoPlayer = forwardRef(({ src }, ref) => {
  const { PlayIcon, NextIcon, BackIcon, PauseIcon } = usePlayers();
  const videoRef = useRef();
  const overlayRef = useRef();
  const progressRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showControls, setShowControls] = useState(false);

  // New state variables for time
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Helper function to format time
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    const overlayElement = overlayRef.current;

    const handlePlay = () => {
      overlayElement.classList.add("hidden");
      setIsPlaying(true);
      showControlsForAwhile();
    };

    const handlePauseOrEnd = () => {
      overlayElement.classList.remove("hidden");
      setIsPlaying(false);
      hideControls();
    };

    const updateProgress = () => {
      const progressValue =
        (videoElement.currentTime / videoElement.duration) * 100;
      setProgress(progressValue);
      setCurrentTime(videoElement.currentTime); // Update current time
    };

    const setVideoDuration = () => {
      setDuration(videoElement.duration); // Set total duration
    };

    const checkVideoInView = () => {
      const rect = videoElement.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
      if (!isInView) {
        videoElement.pause();
        overlayElement.classList.remove("hidden");
        setIsPlaying(false);
      }
    };

    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePauseOrEnd);
    videoElement.addEventListener("ended", handlePauseOrEnd);
    videoElement.addEventListener("timeupdate", updateProgress);
    videoElement.addEventListener("loadedmetadata", setVideoDuration); // Capture video duration
    window.addEventListener("scroll", checkVideoInView);

    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePauseOrEnd);
      videoElement.removeEventListener("ended", handlePauseOrEnd);
      videoElement.removeEventListener("timeupdate", updateProgress);
      videoElement.removeEventListener("loadedmetadata", setVideoDuration);
      window.removeEventListener("scroll", checkVideoInView);
    };
  }, [isPlaying]);

  const playVideo = () => {
    videoRef.current.play();
  };

  const pauseVideo = () => {
    videoRef.current.pause();
  };

  const handleOverlayClick = () => {
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
    showControlsForAwhile();
  };

  const skip = (seconds) => {
    videoRef.current.currentTime = Math.min(
      Math.max(videoRef.current.currentTime + seconds, 0),
      videoRef.current.duration
    );
    showControlsForAwhile();
  };

  const handleProgressClick = (e) => {
    const rect = progressRef.current.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const progressPercentage = clickX / rect.width;
    videoRef.current.currentTime =
      progressPercentage * videoRef.current.duration;
    showControlsForAwhile();
  };

  const showControlsForAwhile = () => {
    setShowControls(true);
    setTimeout(() => {
      setShowControls(false);
    }, 10000); // Show controls for 10 seconds
  };

  const hideControls = () => {
    setShowControls(false);
  };

  const handlePlayIconClick = (event) => {
    event.stopPropagation(); // Prevents the click from bubbling up
    playVideo(); // Call the play function
  };

  return (
    <div className="video-container-video-player">
      <video
        ref={videoRef}
        className="video-element-video-player"
        onClick={handleOverlayClick}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div
        ref={overlayRef}
        className={`video-overlay-video-player ${isPlaying ? "hidden" : ""}`}
      >
        <div
          className="playicon-container-video-player"
          onClick={handlePlayIconClick}
        >
          <PlayIcon />
        </div>
      </div>

      <div
        className={`video-controls-video-player ${
          showControls ? "" : "hidden"
        }`}
      >
        <div className="center-controls-video-player">
          <button
            onClick={() => skip(-10)}
            className="control-button-video-player"
          >
            <BackIcon />
          </button>
          <button
            onClick={isPlaying ? pauseVideo : playVideo}
            className="control-button-video-player"
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </button>
          <button
            onClick={() => skip(10)}
            className="control-button-video-player"
          >
            <NextIcon />
          </button>
        </div>

        <div
          ref={progressRef}
          onClick={handleProgressClick}
          className="progress-bar-video-player"
        >
          <div
            className="progress-bar-fill-video-player"
            style={{ width: `${progress}%` }}
          ></div>
        </div>

        {/* Add elapsed time and duration display */}
        <div className="time-display-video-player">
          {formatTime(currentTime)} / {formatTime(duration)}
        </div>
      </div>
    </div>
  );
});

export default VideoPlayer;




// components/StarHomeComponents/Explore.jsx
import React, { useState, useEffect, useRef } from 'react';
import  { useSidebar3 } from '../StarNavigationBar/Sidebar3Context';
import { BiSearch } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';


function Explore() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [imageFailed, setImageFailed] = useState(false);
    const { isSidebarOpen, setSidebarOpen } = useSidebar3();

    useEffect(() => {
        fetch('/api/get_user_profile_data/')  
            .then(response => response.json())
            .then(data => {
                setUserData(data);
                setImageFailed(false);  // Reset the imageFailed state whenever userData changes
            })
            .catch(error => console.error('Error fetching user data:', error));
    }, []);

    useEffect(() => {
    }, [userData]);
    
    const handleClickProfile = () => {
    setSidebarOpen(!isSidebarOpen);
    };

  const handleExploreShopsClick = () =>{
    navigate('/search');
  };

    

 return (
   <div className="headerStar-Search">
     <div
       className="ExploreShopButton"
       onClick={(e) => {
         e.stopPropagation();
         handleExploreShopsClick();
       }}
     >
       <BiSearch
         className='bi-search-icon'
         color="currentColor"
       />
     </div>
     <div
       className="profile"
       onClick={(e) => {
         e.stopPropagation();
         handleClickProfile();
       }}
     >
       {userData.profile_picture && !imageFailed ? (
         <img
           src={userData.profile_picture}
           alt={userData.first_name}
           className="profile-picture"
           onError={() => setImageFailed(true)}
         />
       ) : (
         <div className="profile-picture no-profile-picture">
           {userData.first_name
             ? userData.first_name.slice(0, 1).toUpperCase()
             : "?"}
         </div>
       )}
     </div>
   </div>
 );
}

export default Explore;







// LikesCommentsContext.jsx
import React, { createContext, useContext, useState, useRef, useCallback, useEffect, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CommentModal from './StarsUpdateComponents/CommentModal';
import FullPreviewModal from './StarsUpdateComponents/FullPreviewModal';
import GlobalLazyImage from './GlobalLazyImage';
import VideoPlayer from './VideoPlayer';
import { AiFillHeart, AiOutlineComment, AiOutlineClose } from "react-icons/ai";
import { YourShopHeaderPostContext } from './StarNavigationBar/YourShopHeaderPostContext';


const LikesCommentsContext = createContext();

const allowedExtensions = ['.jpg', '.bmp', '.dib', '.gif', '.tiff', '.tif', '.jfif', '.png', '.jpeg', '.mp4', '.mov'];


const checkVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            const duration = video.duration;
            if (duration > 30) {
                reject('Video duration exceeds 30 seconds');
            } else {
                resolve();
            }
        };

        video.onerror = () => {
            reject('Failed to load video metadata');
        };

        video.src = URL.createObjectURL(file);
    });
};

// Validation Functions
const validateHeadline = (headline) => {
    if (headline.length > 70) {
        return 'Headline cannot exceed 70 characters.';
    }
    return '';
};

const validateBody = (body) => {
    if (body.length > 5000) {
        return 'Body cannot exceed 5000 characters.';
    }
    return '';
};

const validateFile = async (file) => {
    if (file) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(`.${fileExtension}`)) {
            return 'File type not allowed. Allowed types are: ' + allowedExtensions.join(', ');
        }
        if (file.type.startsWith('video/')) {
            try {
                await checkVideoDuration(file);
                return '';
            } catch (error) {
                return error;
            }
        }
    }
    return '';
};

// Initializing the Set outside the fetchPosts function, to update the posts ids and keep them across hook changes
const fetchedPostIds = new Set();

//Clearing the fetchedPostIds
const clearFetchedPostIds = () => {
  fetchedPostIds.clear();
};

// Initializing the Set outside the fetchComments function, to update the comments ids and keep them across hook changes
const fetchedCommentIds = new Set();

//Clearing the fetchedCommentIds
const clearFetchedCommentIds = () => {
  fetchedCommentIds.clear();
};

 
export const LikesCommentsProvider = ({ children }) => {
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [topPricedPosts, setTopPricedPosts] = useState([]);
  const [bestSellingPosts, setBestSellingPosts] = useState([]);
  const [starAwardPosts, setStarAwardPosts] = useState([]);
  const [adminTopPricedPosts, setAdminTopPricedPosts] = useState([]);
  const [adminStarAwardPosts, setAdminStarAwardPosts] = useState([]);
  const [adminBestSellingPosts, setAdminBestSellingPosts] = useState([]);
  const [bestSellingArchivedPosts, setBestSellingArchivedPosts] = useState([]);
  const [starAwardArchivedPosts, setStarAwardArchivedPosts] = useState([]);
  const [topPricedArchivedPosts, setTopPricedArchivedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [scrollLoadingComment, setScrollLoadingComment] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [firstLoadComment, setFirstLoadComment] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [totalComments, setTotalComments] = useState(0);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [commentClickCounts, setCommentClickCounts] = useState({});
  const [commentLastClicked, setCommentLastClicked] = useState(null);
  const [isCommentEmpty, setIsCommentEmpty] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(Date.now());
  const [starUpdatePostFullPreview, setStarUpdatePostFullPreview] =
    useState(false);
  const [fullPreviewModalOpenSource, setFullPreviewModalOpenSource] =
    useState(null);
  const commentInputRef = useRef(null);
  const [editingPost, setEditingPost] = useState(null);
  const [formData, setFormData] = useState({
    headline: "",
    news_cover: null,
    body: "",
  });
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [commentPage, setCommentPage] = useState(1);
  const [pageHasMore, setPageHasMore] = useState(true);
  const [commentPageHasMore, setCommentPageHasMore] = useState(true);
  const observer = useRef();
  const commentObserver = useRef();
  const [coverHeadline, setCoverHeadline] = useState(null);
  const [formEditHeadlineError, setFormEditHeadlineError] = useState("");
  const [formEditNewsCoverError, setFormEditNewsCoverError] = useState("");
  const [formEditBodyError, setFormEditBodyError] = useState("");


  const [searchResults, setSearchResults] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [input, setInput] = useState("");
  const navigateToPostRefs = useRef({});

  const lastPostElementRef = useCallback(
    (node) => {
      if (scrollLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pageHasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [scrollLoading, pageHasMore]
  );

  const lastCommentElementRef = useCallback(
    (node) => {
      if (scrollLoadingComment) return;
      if (commentObserver.current) commentObserver.current.disconnect();
      commentObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && commentPageHasMore) {
          setCommentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) commentObserver.current.observe(node);
    },
    [scrollLoadingComment, commentPageHasMore]
  );

  //Function to fetch posts used by different components
  const fetchPosts = async (
    url,
    setPosts,
    page = 1,
    setPageHasMore,
    postId = null
  ) => {
    if (firstLoad) {
      setLoading(true);
    } else if (page !== 1) {
      setScrollLoading(true);
    }
    try {
      // Check if URL already contains a query string
      const separator = url.includes("?") ? "&" : "?";
      const fetchUrl = postId
        ? `${url}post-id/${postId}/`
        : `${url}${separator}page=${page}`;
      const response = await fetch(fetchUrl);
      if (!response.ok) {
        setPageHasMore(false);
        return;
      }
      const data = await response.json();
      if (data && data.results) {
        const newPosts = data.results.filter((post) => {
          // Only add the post to newPosts if its ID is not in fetchedPostIds
          if (!fetchedPostIds.has(post.id)) {
            fetchedPostIds.add(post.id);
            navigateToPostRefs.current[post.id] = createRef();
            return true;
          }
          return false;
        });
        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
        setPageHasMore(data.next !== null);
      } else if (data) {
        const newPosts = data.filter((post) => {
          // Only add the post to newPosts if its ID is not in fetchedPostIds
          if (!fetchedPostIds.has(post.id)) {
            fetchedPostIds.add(post.id);
            return true;
          }
          return false;
        });
        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
        setPageHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:");
    } finally {
      if (firstLoad) {
        setLoading(false);
        setFirstLoad(false);
      }
      if (page !== 1) {
        setScrollLoading(false);
      }
    }
  };

  //function to fetch comments
  const fetchComments = async (postId, commentPage = 1) => {
    if (firstLoadComment) {
      setCommentsLoading(true);
    } else if (commentPage !== 1) {
      setScrollLoadingComment(true);
    }

    try {
      const response = await fetch(
        `/api/comments/?stars_update=${postId}&page=${commentPage}`
      );
      const data = await response.json();

      if (data && data.results) {
        const newComments = data.results.filter((comment) => {
          // Only add the comment to newComments if its ID is not in fetchedCommentIds
          if (!fetchedCommentIds.has(comment.id)) {
            fetchedCommentIds.add(comment.id);
            return true;
          }
          return false;
        });
        setComments((prevComments) => [...prevComments, ...newComments]);
        setCommentPageHasMore(data.next !== null);
        // Update the total comment count
        setTotalComments(data.count);
      } else if (data) {
        const newComments = data.results.filter((comment) => {
          // Only add the comment to newComments if its ID is not in fetchedCommentIds
          if (!fetchedCommentIds.has(comment.id)) {
            fetchedCommentIds.add(comment.id);
            return true;
          }
          return false;
        });
        setComments((prevComments) => [...prevComments, ...newComments]);
        setCommentPageHasMore(false);

        // Update the total comment count
        setTotalComments(data.count);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      if (firstLoadComment) {
        setCommentsLoading(false);
        setFirstLoadComment(false);
      }
      if (commentPage !== 1) {
        setScrollLoadingComment(false);
      }
    }
  };

  // It update the like of posts in the parent components when post is liked/unliked, in handleLike
  const updatePostsLikeState = (postId, liked, totalLikes) => {
    const updatePosts = (posts, setPosts) => {
      if (
        posts.some(
          (p) =>
            p.id === postId &&
            p.liked_by_user === liked &&
            p.total_likes === totalLikes
        )
      ) {
        return; // If the post is already updated, do nothing
      }
      setPosts(
        posts.map((p) =>
          p.id === postId
            ? { ...p, liked_by_user: liked, total_likes: totalLikes }
            : p
        )
      );
    };

    updatePosts(starAwardPosts, setStarAwardPosts);
    updatePosts(bestSellingPosts, setBestSellingPosts);
    updatePosts(topPricedPosts, setTopPricedPosts);
    updatePosts(adminTopPricedPosts, setAdminTopPricedPosts);
    updatePosts(adminStarAwardPosts, setAdminStarAwardPosts);
    updatePosts(adminBestSellingPosts, setAdminBestSellingPosts);
    updatePosts(bestSellingArchivedPosts, setBestSellingArchivedPosts);
    updatePosts(starAwardArchivedPosts, setStarAwardArchivedPosts);
    updatePosts(topPricedArchivedPosts, setTopPricedArchivedPosts);
  };

  //Handle to update likes
  const handleLike = async (
    post,
    posts,
    setPosts,
    updatePost,
    updatePostsState
  ) => {
    try {
      // Optimistically update the UI before the server response
      const optimisticLike = !post.liked_by_user;
      const optimisticTotalLikes = optimisticLike
        ? post.total_likes
          ? post.total_likes + 1
          : 1
        : post.total_likes - 1 === 0
        ? null
        : post.total_likes - 1;

      if (updatePost) {
        updatePost({
          ...post,
          liked_by_user: optimisticLike,
          total_likes: optimisticTotalLikes,
        });
      }

      if (posts && setPosts) {
        setPosts(
          posts.map((p) =>
            p.id === post.id
              ? {
                  ...p,
                  liked_by_user: optimisticLike,
                  total_likes: optimisticTotalLikes,
                }
              : p
          )
        );
      } else if (updatePostsState) {
        updatePostsState(post.id, optimisticLike, optimisticTotalLikes);
      }

      // Make the API request
      const response = await fetch(`/api/stars_updates/${post.id}/like/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
      const data = await response.json();

      // Update the list of posts if posts and setPosts are provided
      if (posts && setPosts) {
        setPosts(
          posts.map((p) =>
            p.id === post.id
              ? {
                  ...p,
                  liked_by_user: data.liked,
                  total_likes: data.total_likes,
                }
              : p
          )
        );
      } else if (updatePostsState) {
        updatePostsState(post.id, data.liked, data.total_likes);
      }

      // Update a single post if updatePost is provided
      if (updatePost) {
        updatePost({
          ...post,
          liked_by_user: data.liked,
          total_likes: data.total_likes,
        });
      }
    } catch (error) {
      console.error("Error liking post:", error);
      // Roll back the optimistic update in case of an error
      if (updatePost) {
        updatePost({
          ...post,
          liked_by_user: post.liked_by_user,
          total_likes: post.total_likes,
        });
      }

      if (posts && setPosts) {
        setPosts(
          posts.map((p) =>
            p.id === post.id
              ? {
                  ...p,
                  liked_by_user: post.liked_by_user,
                  total_likes: post.total_likes,
                }
              : p
          )
        );
      } else if (updatePostsState) {
        updatePostsState(post.id, post.liked_by_user, post.total_likes);
      }
    }
  };

  //function to update the comment count optimistically
  const updatePostCountComments = (postId, newCommentCount) => {
    const updateComments = (posts, setPosts) => {
      if (
        posts.some(
          (p) => p.id === postId && p.total_comments === newCommentCount
        )
      ) {
        return; // If the post's comment count is already updated, do nothing
      }
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, total_comments: newCommentCount } : p
        )
      );
    };

    updateComments(bestSellingPosts, setBestSellingPosts);
    updateComments(starAwardPosts, setStarAwardPosts);
    updateComments(topPricedPosts, setTopPricedPosts);
    updateComments(adminTopPricedPosts, setAdminTopPricedPosts);
    updateComments(adminStarAwardPosts, setAdminStarAwardPosts);
    updateComments(adminBestSellingPosts, setAdminBestSellingPosts);
    updateComments(bestSellingArchivedPosts, setBestSellingArchivedPosts);
    updateComments(starAwardArchivedPosts, setStarAwardArchivedPosts);
    updateComments(topPricedArchivedPosts, setTopPricedArchivedPosts);
  };

  const handleComment = async (post) => {
    try {
      const response = await fetch(`/api/stars_updates/${post.id}/comment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ comment_body: newComment }),
      });
      const data = await response.json();

      // Optimistically update the comments
      const newCommentData = {
        ...data.comment,
        comment_date: new Date().toISOString(),
      };
      setComments((prevComments) => [newCommentData, ...prevComments]);
      setNewComment("");
      setIsCommentEmpty(true);

      // Update the total comment count
      setTotalComments((prevCount) => prevCount + 1);

      // Optimistically update the comment count in the post
      updatePostCountComments(post.id, totalComments + 1);

      // Scroll to the comment input
      setTimeout(() => {
        if (commentInputRef.current) {
          commentInputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    } catch (error) {
      console.error("Error commenting:", error);
      // If the comment fails, revert the optimistic update
      fetchComments(post.id);
    }
  };

  const openCommentModal = (post) => {
    setSelectedPost(post);
    setCommentModalIsOpen(true);
  };

  const closeCommentModal = () => {
    setCommentModalIsOpen(false);
    setSelectedPost(null);
    setComments([]);
    setCommentPage(1);
    clearFetchedCommentIds();
  };

  const openFullPreviewModal = (post, fullPreviewModalOpenSource) => {
    setSelectedPost(post);
    setStarUpdatePostFullPreview(true);
    setFullPreviewModalOpenSource(fullPreviewModalOpenSource);
  };

  // const openFullPreviewModal = (post, fullPreviewModalOpenSource) => {
  //   // Reset state to default values if there is a post or fullPreviewModalOpenSource
  //   if (post || fullPreviewModalOpenSource) {
  //     setSelectedPost(null);
  //     setStarUpdatePostFullPreview(false); 
  //     setFullPreviewModalOpenSource(null); 
  //   }

  //   // Update state with the new post and source
  //   setSelectedPost(post);
  //   setStarUpdatePostFullPreview(true);
  //   setFullPreviewModalOpenSource(fullPreviewModalOpenSource);
  // };


  const closeFullPreviewModal = () => {
    // Close the modal
    setStarUpdatePostFullPreview(false);
    setSelectedPost(null);
    setComments([]);
    setCommentPage(1);
    clearFetchedCommentIds();

    // Check if the modal was opened from 'shared-starupdate'
    if (fullPreviewModalOpenSource === "shared-starupdate") {
      // Navigate to '/home' if the source was 'shared-starupdate'
      navigate("/home");
    }

    // Clear the source after handling the close
    setFullPreviewModalOpenSource(null);
  };


  const handleBackToHome = () => {
    navigate("/home");
    setPage(1);
    clearFetchedPostIds();
    setTopPricedPosts([]);
    setBestSellingPosts([]);
    setStarAwardPosts([]);
  };

  const handleCancelSearchForm = () => {
    setShowSearch(false);
    setSearchResults([]);
    setInput("");
  };

  const handleDeleteComment = async (id) => {
    try {
      const response = await fetch(`/api/comments/${id}/delete_comment/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      if (response.ok) {
        // Optimistically update the comments
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== id)
        );

        // Optimistically update the comment count in the post
        updatePostCountComments(selectedPost.id, comments.length - 1);
      } else {
        console.error("Failed to delete comment");
      }
    } catch (error) {
      console.error("Error deleting comment:");
    }
  };

  const handleCommentClick = (event, id) => {
    event.stopPropagation();

    setCommentClickCounts((prevCounts) => {
      let newCount = 1;

      if (id === commentLastClicked) {
        newCount = (prevCounts[id] || 0) + 1;
      }

      if (newCount >= 3) {
        handleDeleteComment(id);
        newCount = 0;
      }

      return { ...prevCounts, [id]: newCount };
    });

    setCommentLastClicked(id);
  };

  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${day}/${month}/${year}`;
  };


 const renderMedia = (coverUrl, headline) => {
   if (typeof coverUrl !== "string") {
     return <p>No media available.</p>;
   }

   const fileExtension = coverUrl.split(".").pop().toLowerCase();

   // Image and video extensions based on the provided list
   const validImageExtensions = [
     "jpg",
     "dib",
     "gif",
     "tiff",
     "tif",
     "jfif",
     "png",
     "jpeg",
     "gif",
     "bmp",
     "webp",
     "svg",,
     "heic",
     "ico",
   ];
   const validVideoExtensions = [
     "mp4",
     "mov",
     "webm",
     "avi",
     "mkv",
     "flv",
     "wmv",
     "m4v",
     "3gp",
   ];

   if (validVideoExtensions.includes(fileExtension)) {
     return (
       <VideoPlayer src={coverUrl} className="media-video" preload="none" />
     );
   } else if (validImageExtensions.includes(fileExtension)) {
     return (
       <GlobalLazyImage src={coverUrl} alt={headline} className="media-image" />
     );
   } else {
     return <p>Unsupported media type.</p>;
   }
 };


  //Render posts for normal user section

  const renderPosts = (posts, setPosts) => {
    const setCombinedRef = (node, id) => {
      if (node) {
        navigateToPostRefs.current[id] = { current: node };
      }
      if (posts[posts.length - 1]?.id === id) {
        lastPostElementRef(node);
      }
    };
    return (
      <>
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : posts.length > 0 ? (
          posts.map((post) => (
            <div
              key={post.id}
              className="post-container-star-update"
              ref={(node) => setCombinedRef(node, post.id)}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(post);
                }}
                className='media-render'
              >
                {renderMedia(post.news_cover, post.headline)}
              </div>
              <h2
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(post);
                }}
              >
                {post.headline}
              </h2>
              <div className="published-date-by">
                <p>Published on: {formatDateString(post.publication_date)}</p>
                <p>
                  By:{" "}
                  {post.posted_by
                    ? `${post.posted_by.first_name} ${post.posted_by.last_name}`
                    : "Unknown"}
                </p>
              </div>
              <div className="post-actions">
                <div className="post-likes-container">
                  <AiFillHeart
                    size={20}
                    color={post.liked_by_user ? "#f0c14b" : "currentColor"}
                    className="bi bi-heart"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLike(post, posts, setPosts);
                    }}
                  />
                  <span
                    style={{
                      color: post.liked_by_user ? "#f0c14b" : "currentColor",
                    }}
                  >
                    {post.liked_by_user ? " Like" : " Like"}
                  </span>
                  <span className="post-total-likes">
                    {post.total_likes ?? ""}
                  </span>
                </div>
                <div className="post-comments-container">
                  <AiOutlineComment
                    size={20}
                    color="currentColor"
                    className="bi bi-chat"
                    onClick={(e) => {
                      e.stopPropagation();
                      openCommentModal(post);
                    }}
                  />
                  <span>
                    {" "}
                    Comment
                    <span className="post-total-likes">
                      {post.total_comments ?? ""}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Update</p>
        )}
        <CommentModal />
        <FullPreviewModal setPosts={setPosts} />
      </>
    );
  };

  //Render post for admin section
  const handleEditClick = (post) => {
    setEditingPost(post);
    setFormData({
      headline: post.headline,
      news_cover: post.news_cover,
      body: post.body,
    });
  };

  const handleSaveClick = async (posts, setPosts) => {
    const { headline, news_cover, body } = formData;
    const errors = {};
    const headlineError = validateHeadline(headline);
    if (headlineError) errors.headline = headlineError;
    const bodyError = validateBody(body);
    if (bodyError) errors.body = bodyError;

    if (news_cover instanceof File) {
      const fileError = await validateFile(news_cover);
      if (fileError) errors.news_cover = fileError;
    }

    if (Object.keys(errors).length > 0) {
      setFormEditHeadlineError(errors.headline || "");
      setFormEditNewsCoverError(errors.news_cover || "");
      setFormEditBodyError(errors.body || "");
      return;
    }

    const form = new FormData();
    form.append("headline", headline);
    if (news_cover instanceof File) {
      form.append("news_cover", news_cover);
    }
    form.append("body", body);

    try {
      const response = await fetch(
        `/api/stars_updates/${editingPost.id}/edit_post/`,
        {
          method: "PUT",
          headers: {
            "X-CSRFToken": csrftoken,
          },
          body: form,
        }
      );

      if (response.ok) {
        const updatedPost = await response.json();
        setPosts(
          posts.map((post) => (post.id === updatedPost.id ? updatedPost : post))
        );
        setEditingPost(null);
      } else {
        const errorData = await response.json();
        console.error("Failed to save changes:");
      }
    } catch (error) {
      console.error("Error saving changes:");
    }
  };

  const handleArchiveClick = async (post, setPosts, posts) => {
    try {
      await fetch(`/api/stars_updates/${post.id}/archive_post/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
      setPosts(posts.filter((p) => p.id !== post.id));
    } catch (error) {
      console.error("Error archiving post:");
    }
  };

  const handleCancelClick = () => {
    setEditingPost(null);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const error = await validateFile(file);
        setFormEditNewsCoverError(error);
        if (!error) {
          setFormData({ ...formData, news_cover: file });
          setCoverHeadline(file);
        } else {
          setFormData({ ...formData, news_cover: null });
          setCoverHeadline(null);
        }
      } catch (error) {
        setFormEditNewsCoverError(error);
        setFormData({ ...formData, news_cover: null });
        setCoverHeadline(null);
      }
    }
  };

  // Handlers for field changes
  const handleHeadlineChange = (e) => {
    const newHeadline = e.target.value;
    setFormData({ ...formData, headline: newHeadline });
    const error = validateHeadline(newHeadline);
    setFormEditHeadlineError(error);
  };

  const handleBodyChange = (e) => {
    const newBody = e.target.value;
    setFormData({ ...formData, body: newBody });
    const error = validateBody(newBody);
    setFormEditBodyError(error);
  };

  const handleCoverHeadlineRemove = () => {
    setFormData({ ...formData, news_cover: null });
    setCoverHeadline(null);
  };

  const renderHeadlineCover = () => {
    if (!coverHeadline) return null;

    const fileURL = URL.createObjectURL(coverHeadline);
    const fileType = coverHeadline.type.split("/")[0];

    if (fileType === "image") {
      return (
        <img src={fileURL} alt="Cover" className="headline-cover-thumbnail" />
      );
    } else if (fileType === "video") {
      return (
        <video src={fileURL} controls className="headline-cover-thumbnail" />
      );
    }

    return null;
  };

  const starUpdateRenderPostAdmin = (posts, setPosts) => {
    const setCombinedRef = (node, id) => {
      if (node) {
        navigateToPostRefs.current[id] = { current: node };
      }
      if (posts[posts.length - 1]?.id === id) {
        lastPostElementRef(node);
      }
    };

    return (
      <div className="updates-container">
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : posts.length > 0 ? (
          posts.map((post, index) => (
            <div
              key={post.id}
              className="admin-star-update-posts"
              ref={(node) => setCombinedRef(node, post.id)}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(post);
                }}
              >
                {renderMedia(post.news_cover, post.headline)}
              </div>
              <h2
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(post);
                }}
              >
                {post.headline}
              </h2>
              <div className="published-date-by">
                <p>Published on: {formatDateString(post.publication_date)}</p>
                <p>
                  By:{" "}
                  {post.posted_by
                    ? `${post.posted_by.first_name} ${post.posted_by.last_name}`
                    : "Unknown"}
                </p>
              </div>
              <div className="post-actions">
                <div className="star-update-admin-likes-comments">
                  <AiFillHeart
                    size={20}
                    color={post.liked_by_user ? "#f0c14b" : "currentColor"}
                    className="bi bi-heart"
                    onClick={() => handleLike(post, posts, setPosts)}
                  />
                  <span
                    style={{
                      color: post.liked_by_user ? "#f0c14b" : "currentColor",
                    }}
                  >
                    {post.liked_by_user ? " Like" : " Like"}
                  </span>
                  <span className="post-total-likes">{post.total_likes}</span>
                  <AiOutlineComment
                    size={20}
                    color="currentColor"
                    className="bi bi-chat star-update-comment"
                    onClick={() => openCommentModal(post)}
                  />
                  <span>
                    {" "}
                    Comment
                    <span className="post-total-likes">
                      {post.total_comments}
                    </span>
                  </span>
                </div>
                <div className="star-update-admin-edit-archive">
                  <button onClick={() => handleEditClick(post)}>Edit</button>
                  <button
                    onClick={() => handleArchiveClick(post, setPosts, posts)}
                  >
                    Archive
                  </button>
                </div>
              </div>
              {editingPost && editingPost.id === post.id && (
                <div className="star-update-edit-form">
                  {formEditHeadlineError && (
                    <p className="error-message">
                      <i className="bi bi-exclamation-circle-fill error-icon"></i>
                      {formEditHeadlineError}
                    </p>
                  )}
                  <textarea
                    className="star-update-edit-headline form-control"
                    cols="20"
                    style={{ overflow: "hidden", resize: "none" }}
                    onInput={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }}
                    value={formData.headline}
                    onChange={handleHeadlineChange}
                  />
                  <div className="star-update-headline-label">
                    {formEditNewsCoverError && (
                      <p className="error-message">
                        <i className="bi bi-exclamation-circle-fill error-icon"></i>
                        {formEditNewsCoverError}
                      </p>
                    )}
                    <input
                      className="form-control"
                      type="file"
                      accept="image/*,video/*"
                      onChange={handleFileChange}
                      name="cover_headline"
                      style={{ display: "none" }}
                    />
                    <div
                      className="star-update-headline-cover-container"
                      onClick={() =>
                        document
                          .querySelector('input[name="cover_headline"]')
                          .click()
                      }
                    >
                      {coverHeadline ? (
                        <div className="star-update-headline-cover-preview">
                          {renderHeadlineCover()}
                          <div
                            className="remove-headline-cover"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCoverHeadlineRemove();
                            }}
                          >
                            <AiOutlineClose
                              size={18}
                              color="currentColor"
                              className="bi bi-x"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="star-update-headline-cover-placeholder">
                          <div className="uploadimgae-icon">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              fill="#888"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ cursor: "pointer" }}
                            >
                              <use href="#image-add-icon"></use>
                            </svg>
                          </div>
                          <div className="cover-image">Headline Cover</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {formEditBodyError && (
                    <p className="error-message">
                      <i className="bi bi-exclamation-circle-fill error-icon"></i>
                      {formEditBodyError}
                    </p>
                  )}
                  <textarea
                    className="star-update-edit-body form-control"
                    value={formData.body}
                    onChange={handleBodyChange}
                    style={{ overflow: "hidden", resize: "none" }}
                    onInput={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }}
                  ></textarea>
                  <button onClick={() => handleSaveClick(posts, setPosts)}>
                    Save changes
                  </button>
                  <button onClick={handleCancelClick}>Cancel</button>
                </div>
              )}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "none" }}
              >
                <symbol id="image-add-icon" viewBox="0 0 24 24">
                  <path d="M18.75 4C20.5449 4 22 5.45507 22 7.25V18.75C22 20.5449 20.5449 22 18.75 22H7.25C5.45507 22 4 20.5449 4 18.75V12.5019C4.47425 12.6996 4.97687 12.8428 5.50009 12.9236L5.5 18.75C5.5 18.9584 5.53643 19.1583 5.60326 19.3437L11.4258 13.643C12.2589 12.8273 13.5675 12.7885 14.4458 13.5266L14.5742 13.6431L20.3964 19.3447C20.4634 19.159 20.5 18.9588 20.5 18.75V7.25C20.5 6.2835 19.7165 5.5 18.75 5.5L12.9236 5.50009C12.8428 4.97687 12.6996 4.47425 12.5019 4H18.75ZM12.5588 14.644L12.4752 14.7148L6.66845 20.4011C6.8504 20.4651 7.04613 20.5 7.25 20.5H18.75C18.9535 20.5 19.1489 20.4653 19.3305 20.4014L13.5247 14.7148C13.2596 14.4553 12.8501 14.4316 12.5588 14.644ZM16.2521 7.5C17.4959 7.5 18.5042 8.50831 18.5042 9.75212C18.5042 10.9959 17.4959 12.0042 16.2521 12.0042C15.0083 12.0042 14 10.9959 14 9.75212C14 8.50831 15.0083 7.5 16.2521 7.5ZM6.5 1C9.53757 1 12 3.46243 12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1ZM16.2521 9C15.8367 9 15.5 9.33673 15.5 9.75212C15.5 10.1675 15.8367 10.5042 16.2521 10.5042C16.6675 10.5042 17.0042 10.1675 17.0042 9.75212C17.0042 9.33673 16.6675 9 16.2521 9ZM6.5 2.99923L6.41012 3.00729C6.20603 3.04433 6.0451 3.20527 6.00806 3.40936L6 3.49923L5.99965 5.99923L3.49765 6L3.40777 6.00806C3.20368 6.0451 3.04275 6.20603 3.00571 6.41012L2.99765 6.5L3.00571 6.58988C3.04275 6.79397 3.20368 6.9549 3.40777 6.99194L3.49765 7L6.00065 6.99923L6.00111 9.50348L6.00916 9.59336C6.04621 9.79745 6.20714 9.95839 6.41123 9.99543L6.50111 10.0035L6.59098 9.99543C6.79508 9.95839 6.95601 9.79745 6.99305 9.59336L7.00111 9.50348L7.00065 6.99923L9.50457 7L9.59444 6.99194C9.79853 6.9549 9.95947 6.79397 9.99651 6.58988L10.0046 6.5L9.99651 6.41012C9.95947 6.20603 9.79853 6.0451 9.59444 6.00806L9.50457 6L6.99965 5.99923L7 3.49923L6.99194 3.40936C6.9549 3.20527 6.79397 3.04433 6.58988 3.00729L6.5 2.99923Z" />
                </symbol>
              </svg>
            </div>
          ))
        ) : (
          <p className="text-center">No update</p>
        )}
        <CommentModal />
        <FullPreviewModal setPosts={setPosts} />
      </div>
    );
  };

  //For rendering admin Archive posts

  const handleRepost = async (id, setPosts, posts) => {
    try {
      await fetch(`/api/stars_updates/${id}/repost_post/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
      setPosts(posts.filter((post) => post.id !== id));
    } catch (error) {
      console.error("Error reposting the post:");
    }
  };

  const handleDelete = async (id, setPosts, posts) => {
    try {
      await fetch(`/api/stars_updates/${id}/delete_post/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
      });
      setPosts(posts.filter((post) => post.id !== id));
    } catch (error) {
      console.error("Error deleting the post:");
    }
  };

  const starUpdateRenderArchiveAdmin = (posts, setPosts) => {
    const setCombinedRef = (node, id) => {
      if (node) {
        navigateToPostRefs.current[id] = { current: node };
      }
      if (posts[posts.length - 1]?.id === id) {
        lastPostElementRef(node);
      }
    };
    return (
      <div className="admin-archived-container">
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : posts.length > 0 ? (
          posts.map((post, index) => (
            <div
              key={post.id}
              className="admin-archived-posts"
              ref={(node) => setCombinedRef(node, post.id)}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(post);
                }}
              >
                {renderMedia(post.news_cover, post.headline)}
              </div>
              <h2
                onClick={(e) => {
                  e.stopPropagation();
                  openFullPreviewModal(post);
                }}
              >
                {post.headline}
              </h2>
              <div className="published-date-by">
                <p>Published on: {formatDateString(post.publication_date)}</p>
                <p>
                  By:{" "}
                  {post.posted_by
                    ? `${post.posted_by.first_name} ${post.posted_by.last_name}`
                    : "Unknown"}
                </p>
              </div>
              <div className="post-actions">
                <div className="star-update-admin-likes-comments">
                  <AiFillHeart
                    size={20}
                    color={post.liked_by_user ? "#f0c14b" : "currentColor"}
                    className="bi bi-heart"
                    onClick={() => handleLike(post, posts, setPosts)}
                  />
                  <span
                    style={{
                      color: post.liked_by_user ? "#f0c14b" : "currentColor",
                    }}
                  >
                    {post.liked_by_user ? " Like" : " Like"}
                  </span>
                  <span className="post-total-likes">{post.total_likes}</span>
                  <AiOutlineComment
                    size={20}
                    color="currentColor"
                    className="bi bi-chat star-update-comment"
                    onClick={() => openCommentModal(post)}
                  />
                  <span>
                    {" "}
                    Comment
                    <span className="post-total-likes">
                      {post.total_comments}
                    </span>
                  </span>
                </div>
                <div className="star-update-admin-repost-delete">
                  <button
                    onClick={() => handleRepost(post.id, setPosts, posts)}
                  >
                    Repost
                  </button>
                  <button
                    onClick={() => handleDelete(post.id, setPosts, posts)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No archived posts</p>
        )}
        <CommentModal />
        <FullPreviewModal setPosts={setPosts} />
      </div>
    );
  };

  //handle to navigate to specific post directly
  const handleNavigateToSelectedPost = async (postId, setPosts) => {
    setShowSearch(false);
    setSearchResults([]);
    setInput("");

    // Fetch the post by its ID and update posts and page
    await fetchPosts(
      "/api/stars_updates/",
      setPosts,
      1,
      setPageHasMore,
      postId
    );

    // Timeout to ensure the DOM has updated before scrolling
    setTimeout(() => {
      if (
        navigateToPostRefs.current[postId] &&
        navigateToPostRefs.current[postId].current
      ) {
        const element = navigateToPostRefs.current[postId].current;
        if (element && element.scrollIntoView) {
          element.scrollIntoView({
            behavior: "auto",
            block: "center",
          });
          // Close the full preview modal after successful scroll
          closeFullPreviewModal();
        }
      }
    }, 400);
  };
  return (
    <LikesCommentsContext.Provider
      value={{
        topPricedPosts,
        setTopPricedPosts,
        bestSellingPosts,
        setBestSellingPosts,
        topPricedArchivedPosts,
        setTopPricedArchivedPosts,
        adminTopPricedPosts,
        setAdminTopPricedPosts,
        adminBestSellingPosts,
        setAdminBestSellingPosts,
        starAwardPosts,
        setStarAwardPosts,
        adminStarAwardPosts,
        setAdminStarAwardPosts,
        bestSellingArchivedPosts,
        setBestSellingArchivedPosts,
        starAwardArchivedPosts,
        setStarAwardArchivedPosts,
        loading,
        setLoading,
        comments,
        setComments,
        newComment,
        setNewComment,
        commentModalIsOpen,
        setCommentModalIsOpen,
        selectedPost,
        setSelectedPost,
        commentsLoading,
        setCommentsLoading,
        fetchPosts,
        fetchComments,
        handleLike,
        handleComment,
        openCommentModal,
        closeCommentModal,
        openFullPreviewModal,
        closeFullPreviewModal,
        handleBackToHome,
        renderMedia,
        handleCommentClick,
        isCommentEmpty,
        setIsCommentEmpty,
        elapsedTime,
        setElapsedTime,
        starUpdatePostFullPreview,
        formatDateString,
        renderPosts,
        commentInputRef,
        starUpdateRenderPostAdmin,
        page,
        setPage,
        commentPageHasMore,
        setCommentPageHasMore,
        commentPage,
        setCommentPage,
        pageHasMore,
        setPageHasMore,
        starUpdateRenderArchiveAdmin,
        scrollLoading,
        setScrollLoading,
        lastPostElementRef,
        firstLoad,
        setFirstLoad,
        firstLoadComment,
        setFirstLoadComment,
        setScrollLoadingComment,
        scrollLoadingComment,
        clearFetchedPostIds,
        updatePostsLikeState,
        updatePostCountComments,
        lastCommentElementRef,
        handleNavigateToSelectedPost,
        searchResults,
        setSearchResults,
        noResultsFound,
        setNoResultsFound,
        showSearch,
        setShowSearch,
        input,
        setInput,
        navigateToPostRefs,
        fullPreviewModalOpenSource,
        handleCancelSearchForm,
        starUpdatePostFullPreview,
        setStarUpdatePostFullPreview,
        setFullPreviewModalOpenSource,
      }}
    >
      {children}
    </LikesCommentsContext.Provider>
  );
};

export const useLikesComments = () => useContext(LikesCommentsContext);




// StarAwardArchives.jsx
import React, { useEffect} from 'react';
import "./UpdatesStar.css";
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';
import { useLikesComments } from '../LikesCommentsContext';


function StarAwardArchives() {
    const {
    starAwardArchivedPosts, setStarAwardArchivedPosts, starUpdateRenderArchiveAdmin, 
    fetchPosts, page, setPageHasMore, scrollLoading
  } = useLikesComments();
   
  useEffect(() => {
    fetchPosts('/api/stars_updates/archived_posts/?post_type=star_award', setStarAwardArchivedPosts, page, setPageHasMore);
  }, [page], 'star_award');

    return (
      <>
      <StarUpdateHeaderWithSearch
        category="star_award"
        isArchived={true}
      />
     {starUpdateRenderArchiveAdmin(starAwardArchivedPosts, setStarAwardArchivedPosts)}
      <div className='scroll-loader-spinner-container'>
          {scrollLoading && <div className="scroll-loader-spinner"></div>}
       </div>
    </>
    );
}

export default StarAwardArchives;



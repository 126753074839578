// BestSellingArchives.jsx
import React, {useEffect} from 'react';
import "./UpdatesStar.css";
import { useLikesComments } from '../LikesCommentsContext';
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';

function BestSellingArchives() {
    const {
    bestSellingArchivedPosts, setBestSellingArchivedPosts, starUpdateRenderArchiveAdmin,
   fetchPosts, page, setPageHasMore, scrollLoading,
  } = useLikesComments();

  useEffect(() => {
    fetchPosts('/api/stars_updates/archived_posts/?post_type=best_selling', setBestSellingArchivedPosts, page, setPageHasMore);
  }, ['best_selling', page]);
    
    return (
     <>
       <StarUpdateHeaderWithSearch
        category="best_selling"
        isArchived={true}
      />
     {starUpdateRenderArchiveAdmin(bestSellingArchivedPosts, setBestSellingArchivedPosts)}
       <div className='scroll-loader-spinner-container'>
          {scrollLoading && <div className="scroll-loader-spinner"></div>}
       </div>
    </>
    );
}

export default BestSellingArchives;

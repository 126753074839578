// music.jsx
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useChatAndMusicPosts } from '../StarNavigationBar/ChatAndMusicPostsContext';
import MusicNav from './MusicNav';
import './Music.css';

function Music() {
  const { isWideScreen } = useChatAndMusicPosts();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/music" || location.pathname === "/home/music") {
      navigate(
        `${isWideScreen ? "/home/music/upload-form" : "/music/upload-form"}`
      );
    }
  }, [location, navigate, isWideScreen]);

  return (
    <div className="music-outlet-container">
      {location.pathname !== "/music/upload-form" &&
        location.pathname !== "/music/music-posts" &&
        location.pathname !== "/music/your-musics" && <MusicNav />}
      <>
       <Outlet />
      </>
    </div>
  );
}

export default Music;


import React, {useContext} from 'react';
import { useOwnModalsContext } from './OwnModalsContext';
import { YourShopHeaderPostContext } from './StarNavigationBar/YourShopHeaderPostContext';

const NotificationModal = () => {
      const {
        setShowHeader,
        setIsFullScreenPreview,
        setCurrentPostId,
      } = useContext(YourShopHeaderPostContext);

    const {
      setIsModalOpen,
      setIsModalConfirmOpen,
      setIsPaymentMethodModalOpen,
      isNotificationModalOpen,
      setIsNotificationModalOpen,
      setSelectedPaymentMethod,
    } = useOwnModalsContext();

    const handleCloseModalNotification = () => {
      setTimeout(() => {
        setIsNotificationModalOpen(false);

        // Close only when payment succeeds
        setIsModalConfirmOpen(false);
        setIsPaymentMethodModalOpen(false);
        setIsModalOpen(false);
        setSelectedPaymentMethod(null);
        setCurrentPostId(null);
        setShowHeader(true);
        setIsFullScreenPreview(false);
      }, 1000); 
    };


  return (
    <>
     {/* Notification Modal */}
      {isNotificationModalOpen && (
        <div className='notification-modal-overlay' onClick={handleCloseModalNotification}>
          <div className='notification-modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='notification-modal-header'>
            <div>
            <h4 className='text-success-fail'>Payment successful</h4>
            </div>
            <div className='empty-div-change-it'>
            </div>
            </div>
            <div className="notification-info">
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationModal;

//Command,  npm run build:sentry, production server:npx serve -s build -l 3000
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/browser"; 
import App from "../App";

// Initialize Sentry with Replay integration for production
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
    browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.01, 
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: ["https://gooofan.com", "gooofan.com"],
  release: process.env.REACT_APP_SENTRY_RELEASE, 
});

// Fallback UI to display when an error occurs
const Fallback = () => (
  <div className="error-boundary-fallback">
    <h2>Oops, something went wrong.</h2>
    <p>We’re working to fix this issue. Please try again later.</p>
    <button onClick={() => window.location.reload()}>Reload Page</button>
    <button onClick={() => window.history.back()}>Go Back</button>
    <p>
      If the problem persists, please <a href="/report/">contact support</a>.
    </p>
  </div>
);

const SentryWrappedApp = () => {
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("/api/get_user_profile_data/");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCurrentUserData(data);
        // Set Sentry user information
        Sentry.setUser({
          id: data.id,
          username: data.username,
          email: data.email,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<Fallback />} showDialog>
      <App />
    </Sentry.ErrorBoundary>
  );
};

export default SentryWrappedApp;

import React from "react";
import { BiHome, BiUpload, BiStore } from "react-icons/bi";
import { AiOutlineStar } from "react-icons/ai";
import "./Skeleton.css";

const FooterSkeleton = () => {
  return (
    <div className="footer-shimmer">
      <div className="icon-placeholder">
        <BiHome size={20} />
      </div>
      <div className="text-placeholder"></div>

      <div className="icon-placeholder">
        <BiUpload size={20} />
      </div>
      <div className="text-placeholder"></div>

      <div className="icon-placeholder">
        <BiStore size={20} />
      </div>
      <div className="text-placeholder"></div>

      <div className="icon-placeholder">
        <AiOutlineStar size={20} />
      </div>
      <div className="text-placeholder"></div>
    </div>
  );
};

export default FooterSkeleton;

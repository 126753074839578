import React, { useState, useEffect, useContext } from 'react';
import './AdminPosts.css'; 
import LazyImage from '../LazyImage';
import VideoPlayer from '../VideoPlayer';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';

// AdminPost.jsx
const AdminPost = ({ post, onEdit, onArchive}) => {
    const { title, action, link, images, videos } = post;
    const [isEditing, setIsEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(title);
    const [newAction, setNewAction] = useState(action);
    const [newLink, setNewLink] = useState(link);
    const [newtitleError, setNewTitleError] = useState('');
    const [newlinkError, setNewLinkError] = useState('');
    
    const actions = ['Learn More', 'Own it', 'Explore'];

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Client-side validation
        if (newTitle.length > 30) {
            setNewTitleError('Title is too long. Maximum length is 30 characters.');
            return;
        } else {
            setNewTitleError('');
        }
        
        // Client-side validation for link (secure links only)
        const linkRegex = /^https:\/\/[^ "]+$/;
        if (!newLink.match(linkRegex)) {
            setNewLinkError('Please enter a secure (HTTPS) URL.');
            return;
        } else {
            setNewLinkError('');
        }

        onEdit({ ...post, title: newTitle, action: newAction, link: newLink });
        setIsEditing(false);
    };
       
    if (isEditing) {
        return (
            <div className='admin-postPanel admin-postPanelEdits '>
                <input className='input-AdminEditForm' value={newTitle} onChange={e => setNewTitle(e.target.value)} />
                  {newtitleError && <p className="error-message"> <i className="bi bi-exclamation-circle-fill error-icon"></i>{newtitleError}</p>}
                  <select className='choice-AdminEditForm' value={newAction} onChange={e => setNewAction(e.target.value)}>
                    {actions.map((actionOption, index) => (
                        <option key={index} value={actionOption}>{actionOption}</option>
                    ))}
                </select>
                {/* <input value={newAction} onChange={e => setNewAction(e.target.value)} /> */}
                <input className='input-AdminEditForm' value={newLink} onChange={e => setNewLink(e.target.value)} />
                 {newlinkError && <p className="error-message"> <i className="bi bi-exclamation-circle-fill error-icon"></i>{newlinkError}</p>}
                <button className='btn-AdmninEdit' onClick={handleSaveClick}>Save Change</button>
                <button className='btn-AdmninEdit' onClick={() => setIsEditing(false)}>Cancel</button>
            </div>
        );
    } else {
        return (
          <div className="admin-postPanel">
            <div className="admin-post-title">{title}</div>
            {link && (
              <div className="admin-post-link">
                <a href={link}>{action}</a>
              </div>
            )}
            <div className="admin-post-media">
              {images.map((image, index) => (
                <LazyImage
                  key={image.id}
                  src={image.image}
                  alt=""
                  className="admin-post-image"
                />
              ))}

              {videos.map((video, index) => (
                <VideoPlayer
                  key={video.id}
                  src={video.video}
                  type={video.type}
                  preload="none"
                />
              ))}
            </div>
            <div className="admin-post-actions">
              {onEdit && <button onClick={handleEditClick}>Edit</button>}
              {onArchive && (
                <button onClick={() => onArchive(post)}>Archive</button>
              )}
            </div>
          </div>
        );
    }
};

// AdminPostList.jsx
const AdminPostList = () => {
    const { csrftoken } = useContext(YourShopHeaderPostContext);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    
    
    useEffect(() => {
        fetch('/api/AdminPosts/')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch posts');
                }
            })
            .then(data => {
             setPosts(data);
                // Delay setting the posts and loading state by 2 seconds
              setTimeout(() => {
                setIsLoading(false);
              }, 2000);

            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false); 
            });
    }, []);
   
    //HandleEdit
    const handleEdit = (post) => {
        fetch(`/api/AdminPosts/${post.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
            body: JSON.stringify(post),
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else if (response.status === 403) {
                throw new Error('Unauthorized action.');
            } else {
                throw new Error('Failed to edit post');
            }
        })
        .then(data => {
            setPosts(posts.map(p => p.id === data.id ? data : p));
        })
        .catch((error) => {
            alert(error.message); 
        });
    };

    //handleArchive
   const handleArchive = (post) => {
    fetch(`/api/AdminPosts/${post.id}/archive/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken
        },
    })
    .then(() => {
        setPosts(posts.filter(p => p.id !== post.id));
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

    return (

         <div className='Container admin-post-list-container'>
            {isLoading ? (
                <p>Loading posts...</p>
            ) : error ? (
                <p>{error}</p>
            ) : posts.length > 0 ? (
                <div className='admin-post-list'>
                    {posts.map((post, index) => (
                    <AdminPost 
                    key={index} 
                    post={post} 
                    onEdit={handleEdit} 
                    onArchive={handleArchive} 
                    />
                    ))}
                </div>
            ) : (
                <p>No post available.</p>
            )}
        </div>
    );
};

export default AdminPostList;




import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import './CustomAudioPlayer.css';
import './CustomListenTogetherPlayer.css';
import { useChatAndMusicPosts } from "./StarNavigationBar/ChatAndMusicPostsContext";
import { usePlayers, PlayersProvider } from "./PlayersContext";

const CustomListenTogetherPlayer = ({ audioSrc, tempMusicId, tempRequestId, recipient, sender }) => {
   const {
     startSession,
     updateDuration,
     pauseSession,
     resumeSession,
     endSession,
     historyId,
     VolumeUpIcon,
     VolumeOffIcon,
     PlayIcon,
     PauseIcon,
   } = usePlayers();
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const progressRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const [visibleDots, setVisibleDots] = useState(0);
  const socketListenRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [bothLoaded, setBothLoaded] = useState(false);
  const { userData, currentRecipient, unreadMessagesCount } =
    useChatAndMusicPosts();
  const [waitingForPartner, setWaitingForPartner] = useState(false);
  const [partnerUsername, setPartnerUsername] = useState("");
  
  function getCSRFToken() {
    const csrfTokenCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("csrftoken"));
    return csrfTokenCookie ? csrfTokenCookie.split("=")[1] : null;
  }

  const csrftoken = getCSRFToken();
  // const MAX_RETRIES = 2;
  // const RETRY_DELAY = 1000; // ms

  // const retrySendActionMessage = (message, attempt = 1) => {
  //   const success = sendActionMessage(message);
  //   if (!success && attempt < MAX_RETRIES) {
  //     setTimeout(() => retrySendActionMessage(message, attempt + 1), RETRY_DELAY);
  //   }
  // };

  // console.log("both loaded current state:", bothLoaded)
  // console.log("waiting for partner:", waitingForPartner)

  //Fetch playback state from the server
  useEffect(() => {
    const fetchPlaybackState = async () => {
      try {
        const response = await fetch(`/api/music/get_playback_state/?tempMusicId=${tempMusicId}&tempRequestId=${tempRequestId}`);
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const playbackState = await response.json();
        setIsPlaying(false);
        setCurrentTime(playbackState.state.currentTime);
        setIsMuted(playbackState.state.isMuted);
        if (audioRef.current) {
          audioRef.current.currentTime = playbackState.state.currentTime;
          audioRef.current.muted = playbackState.state.isMuted;
        }
      } catch (error) {
      }
    };

    fetchPlaybackState();
  }, [tempMusicId, tempRequestId]);

  // Update playback state on the server
  const updatePlaybackState = async (newState) => {
    try {
      await fetch(`/api/music/update_playback_state/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          tempMusicId,
          tempRequestId,
          state: newState,
        }),
      });
    } catch (error) {}
  };

  // Periodically update playback state while playing
  useEffect(() => {
    let intervalId;

    if (isPlaying) {
      intervalId = setInterval(() => {
        if (audioRef.current) {
          updatePlaybackState({
            isPlaying: true,
            currentTime: audioRef.current.currentTime,
            isMuted,
          });
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPlaying, unreadMessagesCount, isMuted]);

  // Save playback state on page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (audioRef.current) {
        updatePlaybackState({
          isPlaying: false,
          currentTime: audioRef.current.currentTime,
          isMuted,
        });
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isMuted]);

  const sendActionMessage = (type = "action", additionalData = {}) => {
    if (
      socketListenRef.current &&
      socketListenRef.current.readyState === WebSocket.OPEN
    ) {
      const message = {
        type,
        recipient: recipient,
        sender: sender,
        tempMusicId,
        tempRequestId,
        ...additionalData,
      };
      socketListenRef.current.send(JSON.stringify(message));
    }
  };

  const handleWebSocketMessage = async (event) => {
    const data = JSON.parse(event.data);
    const audio = audioRef.current;
    // Check if the message is for the current music and request
    if (
      data.tempMusicId !== tempMusicId ||
      data.tempRequestId !== tempRequestId
    ) {
      return;
    }
    switch (data.type) {
      case "play":
        try {
          if (!historyId) {
            await startSession(); 
          }
          audio
            .play()
            .catch((error) => console.error("Error playing audio:", error));
          setIsPlaying(true);
          resumeSession(); 
          initializeAudioContext();
        } catch (error) {
          console.error("Failed to start session:", error);
        }
        break;
      case "pause":
        audio.pause();
        setIsPlaying(false);
        pauseSession(); 
        break;
      case "mute":
        audio.muted = true;
        setIsMuted(true);
        break;
      case "unmute":
        audio.muted = false;
        setIsMuted(false);
        break;
      case "volume":
        audio.volume = data.volume;
        break;
      case "forward":
      case "backward":
        audio.currentTime = data.new_time;
        setCurrentTime(data.new_time);
        break;
      case "waiting_for_partner":
        setWaitingForPartner(true);
        if (data.sender !== userData.username) {
          setPartnerUsername(data.sender);
        }
        break;
      case "both_loaded":
        setBothLoaded(true);
        setWaitingForPartner(false);
        break;
      default:
        break;
    }
  };

const setAudioData = useCallback(() => {
  const audio = audioRef.current;
  let dataLoaded = false;

  // Set the duration and initially disable loading
  setDuration(audio.duration);
  setLoading(false);

  // Set a timeout fallback to ensure loading state is updated if needed
  const loadTimeout = setTimeout(() => {
    dataLoaded = true;
    setLoading(false);
  }, 1000);

  // Single function to handle all relevant loading events
  const handleDataLoaded = () => {
    if (!dataLoaded) {
      clearTimeout(loadTimeout);
      setLoading(false);
      dataLoaded = true; 
    }
  };

  // Add listeners for all three events: loadeddata, loadedmetadata, and canplaythrough
  audio.addEventListener("loadeddata", handleDataLoaded);
  audio.addEventListener("loadedmetadata", handleDataLoaded);
  audio.addEventListener("canplaythrough", handleDataLoaded);

  // Cleanup listeners and timeout when unmounting
  return () => {
    clearTimeout(loadTimeout);
    audio.removeEventListener("loadeddata", handleDataLoaded);
    audio.removeEventListener("loadedmetadata", handleDataLoaded);
    audio.removeEventListener("canplaythrough", handleDataLoaded);
  };
}, []);



  useEffect(() => {
    if (!loading && !bothLoaded) {
      sendActionMessage("loaded");
    }
  }, [loading, waitingForPartner]);

  useEffect(() => {
    if (userData.username && !socketListenRef.current) {
      socketListenRef.current = new WebSocket(
        // `ws://localhost:8000/ws/music_listen/${userData.username}/${tempMusicId}/${tempRequestId}/`
        `${process.env.REACT_APP_WEBSOCKET_HOST}music_listen/${userData.username}/${tempMusicId}/${tempRequestId}/`
      );
    }

    if (socketListenRef.current) {
      socketListenRef.current.onopen = () => {};

      socketListenRef.current.onmessage = handleWebSocketMessage;

      socketListenRef.current.onerror = (error) => {};

      return () => {
        if (socketListenRef.current) {
          socketListenRef.current.close();
        }
      };
    }
  }, [
    userData,
    tempMusicId,
    tempRequestId,
  ]);

  const updateProgress = useCallback(() => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
    if (progressRef.current) {
      const progressValue = (audio.currentTime / audio.duration) * 100;
      progressRef.current.value = progressValue;
      progressRef.current.style.setProperty("--progress", `${progressValue}%`);
    }

    // Update session duration periodically
    updateDuration(audio.currentTime);
  }, [historyId]);

  const handleAudioEnd = useCallback(() => {
    if (!historyId) return; 
    setIsPlaying(false);
    updatePlaybackState({ isPlaying: false, currentTime: 0 });
    endSession(); 
  }, [historyId]);


  useEffect(() => {
    const audio = audioRef.current;

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("canplaythrough", setAudioData);
    audio.addEventListener("ended", handleAudioEnd);

    // Timeout fallback for iOS
    const durationCheckTimeout = setTimeout(() => {
      if (!audio.duration && loading) {
        setLoading(false); // Turn off loading even if duration is not set
        setAudioData(); // Run `setAudioData` to initialize other state variables
      }
    }, 3000);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("canplaythrough", setAudioData);
      audio.removeEventListener("ended", handleAudioEnd);
      clearTimeout(durationCheckTimeout);
    };
  }, [
    updateProgress,
    setAudioData,
    handleAudioEnd,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleDots((prev) => (prev >= 5 ? 0 : prev + 1));
    }, 400);

    return () => clearInterval(interval);
  }, []);

  const initializeAudioContext = () => {
    if (!audioContextRef.current) {
      // @ts-ignore: Ignoring TypeScript error for webkitAudioContext
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const audio = audioRef.current;
      const sourceNode = audioContext.createMediaElementSource(audio);

      sourceNode.connect(analyser);
      analyser.connect(audioContext.destination);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        if (canvasRef.current && canvasRef.current.getContext) {
          const ctx = canvasRef.current.getContext("2d");
          if (ctx) {
            analyser.getByteFrequencyData(dataArray);

            ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
            ctx.fillRect(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );

            const barWidth = (canvasRef.current.width / bufferLength) * 2.5;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              barHeight = dataArray[i];

              ctx.fillStyle = "#f0c14b";
              ctx.fillRect(
                x,
                canvasRef.current.height - barHeight / 2,
                barWidth,
                barHeight / 2
              );

              x += barWidth + 1;
            }
          }
        }
        requestAnimationFrame(draw);
      };

      draw();

      audioContextRef.current = audioContext;
      analyserRef.current = analyser;
      sourceNodeRef.current = sourceNode;
    } else if (audioContextRef.current.state === "suspended") {
      audioContextRef.current.resume();
    }
  };

  const togglePlayPause = () => {
    const audio = audioRef.current;
    initializeAudioContext();

    if (isPlaying) {
      audio.pause();
      sendActionMessage("pause");
      updatePlaybackState({
        isPlaying: false,
        currentTime: audio.currentTime,
        isMuted,
      });
    } else {
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
      sendActionMessage("play");
      updatePlaybackState({
        isPlaying: true,
        currentTime: audio.currentTime,
        isMuted,
      });
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !audio.muted;
    setIsMuted(audio.muted);
    sendActionMessage(audio.muted ? "mute" : "unmute");
    updatePlaybackState({
      isPlaying,
      currentTime: audio.currentTime,
      isMuted: audio.muted,
    });
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const progressValue = e.target.value;
    const newTime = (progressValue / 100) * audio.duration;

    if (newTime >= 0 && newTime <= audio.duration) {
      audio.currentTime = newTime;
      setCurrentTime(newTime);
      sendActionMessage("forward", { new_time: newTime });
      updatePlaybackState({ isPlaying, currentTime: newTime });
    }
  };

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    const audio = audioRef.current;
    const rect = canvas.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / canvas.width) * audio.duration;

    if (newTime >= 0 && newTime <= audio.duration) {
      audio.currentTime = newTime;
      setCurrentTime(newTime);
      sendActionMessage("forward", { new_time: newTime });
      updatePlaybackState({ isPlaying, currentTime: newTime });
    }
  };

  const formatTime = (time) => {
    if (!isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    }
    return "0:00";
  };

  return (
    <div className="audio-player">
      <audio ref={audioRef} src={audioSrc} preload="auto"></audio>
      <div className="controls">
        {!loading && (
          <>
            {waitingForPartner && (
              <div className="waiting-indicator">
                Waiting for {partnerUsername} connection...
              </div>
            )}
            {!waitingForPartner && bothLoaded && (
              <>
                <button onClick={togglePlayPause}>
                  {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </button>
                <button onClick={toggleMute}>
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
                <div className="progress-container">
                  <canvas
                    ref={canvasRef}
                    className="progress-bar-audio"
                    onClick={handleCanvasClick}
                  ></canvas>
                  <input
                    type="range"
                    ref={progressRef}
                    className="progress"
                    onChange={handleProgressChange}
                    value={(currentTime / duration) * 100 || 0}
                    style={{
                      "--progress": `${(currentTime / duration) * 100 || 0}%`,
                    }}
                  />
                </div>
                <div className="time">
                  <span>{formatTime(currentTime)}</span> /{" "}
                  <span>{formatTime(duration)}</span>
                </div>
              </>
            )}
          </>
        )}
        {loading && (
          <div className="loader-dots">
            <span className={visibleDots > 0 ? "visible" : ""}>.</span>
            <span className={visibleDots > 1 ? "visible" : ""}>.</span>
            <span className={visibleDots > 2 ? "visible" : ""}>.</span>
            <span className={visibleDots > 3 ? "visible" : ""}>.</span>
            <span className={visibleDots > 4 ? "visible" : ""}>.</span>
          </div>
        )}
      </div>
    </div>
  );
};

const CustomListenTogetherPlayerWithProvider = ({
  audioSrc,
  musicId,
  tempMusicId,
  tempRequestId,
  recipient,
  sender,
}) => (
  <PlayersProvider musicId={musicId}>
    <CustomListenTogetherPlayer
      audioSrc={audioSrc}
      tempMusicId={tempMusicId}
      tempRequestId={tempRequestId}
      recipient={recipient}
      sender={sender}
      musicId={musicId}
    />
  </PlayersProvider>
);

export default CustomListenTogetherPlayerWithProvider;
// export default CustomListenTogetherPlayer;


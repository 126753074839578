// FooterContext.jsx
import React, { useState, createContext, useEffect , useContext} from "react";
import { YourShopHeaderPostContext } from "./YourShopHeaderPostContext";
import { useLocation } from "react-router-dom";
import {
  BiLink,
  BiCheck,
  BiShare,
} from "react-icons/bi";
import { FaExclamationCircle } from "react-icons/fa";

export const FooterContext = createContext();

const fetchedMusicPostIds = new Set();
const clearFetchedMusicPostIds = () => {
  fetchedMusicPostIds.clear();
};

const fetchedSubscribersIds = new Set();
const clearFetchedSubscribersIds = () => {
  fetchedSubscribersIds.clear();
};

const fetchedSubscriptionIds = new Set();
 const clearfetchedSubscriptionIds = () => {
   fetchedSubscriptionIds.clear();
 };

export const FooterProvider = ({ children }) => {
  const {
    showMenu,
    setShowMenu,
    menuRef,
    setCopyLinkText,
    setIconClass,
    setSharePostText,
    setShareIconClass,
  } = useContext(YourShopHeaderPostContext);
  const [showFooter, setShowFooter] = useState(() => {
    const storedShowFooter = localStorage.getItem("showFooter");
    return storedShowFooter !== null ? JSON.parse(storedShowFooter) : true;
  });

  const [musicPosts, setMusicPosts] = useState([]);
  const [yourMusicPage, setYourMusicPage] = useState(1);
  const [musicPage, setMusicPage] = useState(1);
  const [subscribers, setSubscribers] = useState([]);
  const [subscriberPage, setSubscriberPage] = useState(1);
  const [hasMoreSubscribers, setHasMoreSubscribers] = useState(true);
  const [firstLoadSubscribers, setFirstLoadSubscribers] = useState(true);
  const [loading, setLoading] = useState(true);
  const [scrollLoadingSubscribers, setScrollLoadingSubscribers] =
    useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscribedScrollLoading, setSubscribedScrollLoading] = useState(false);
  const [subscribedPage, setSubscribedPage] = useState(1);
  const [hasMoreSubscribed, setHasMoreSubscribed] = useState(true);
   const [buyerOrders, setBuyerOrders] = useState([]);
   const [buyerOrderPage, setBuyerOrderPage] = useState(1);
   const [scrollLoadingBuyerOrders, setScrollLoadingBuyerOrders] =
     useState(false);
   const [hasMoreBuyerOrders, setHasMoreBuyerOrders] = useState(true);

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("showFooter", JSON.stringify(showFooter));
  }, [showFooter]);

  useEffect(() => {
    if (location.pathname.includes("/home")) {
      setShowFooter(true);
    }
  }, [location]);

  const fetchSubscribers = async () => {
    if (firstLoadSubscribers) {
      setLoading(true);
    } else {
      setScrollLoadingSubscribers(true);
    }
    try {
      const response = await fetch(
        `/api/subscriptions/subscriber_list/?page=${subscriberPage}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      if (!data.results) {
        throw new Error(
          "Invalid data structure: 'results' property is missing"
        );
      }

      const newSubscribers = data.results.filter(
        (subscriber) => !fetchedSubscribersIds.has(subscriber.id)
      );
      newSubscribers.forEach((subscriber) =>
        fetchedSubscribersIds.add(subscriber.id)
      );
      setSubscribers((prevSubscribers) => [
        ...prevSubscribers,
        ...newSubscribers,
      ]);
      setHasMoreSubscribers(data.next !== null);
    } catch (error) {
      console.error("Error fetching subscribers:", error);
      setHasMoreSubscribers(false);
    } finally {
      if (firstLoadSubscribers) {
        setLoading(false);
        setFirstLoadSubscribers(false);
      }
      setScrollLoadingSubscribers(false);
    }
  };

   const handleMusicOptions = (id) => {
     setShowMenu((prevShowMenu) => {
       // Close all other open menus
       const newShowMenu = Object.keys(prevShowMenu).reduce((acc, key) => {
         acc[key] = false;
         return acc;
       }, {});

       // Open the menu for the current post
       newShowMenu[id] = !prevShowMenu[id];

       return newShowMenu;
     });
   };

   const handleOutsideClick = (e) => {
     if (menuRef.current && !menuRef.current.contains(e.target)) {
       // Close all menus
       const newShowMenu = { ...showMenu };
       Object.keys(newShowMenu).forEach((id) => {
         newShowMenu[id] = false;
       });
       setShowMenu(newShowMenu);
     }
   };

   const handleCopyLink = (id) => {
     const music = musicPosts.find((music) => music.id === id);
     const url = music?.music_url;

     navigator.clipboard.writeText(url).then(() => {
       setCopyLinkText("Copied to clipboard");
       setIconClass(<BiCheck />);

       setTimeout(() => {
         setCopyLinkText("Copy link");
         setIconClass(<BiLink />);

         setShowMenu((prevShowMenu) => {
           const newShowMenu = { ...prevShowMenu };
           newShowMenu[id] = false;
           return newShowMenu;
         });
       }, 2000);
     });
   };

   const handleSharePost = (id) => {
     const music = musicPosts.find((music) => music.id === id);
     const url = music.music_url;

     if (navigator.share) {
       navigator
         .share({
           title: `Check out this music by ${music.artist}`,
           text: "Check out this music!",
           url: url,
         })
         .then(() => {
           setShowMenu((prevShowMenu) => {
             const newShowMenu = { ...prevShowMenu };
             newShowMenu[id] = false;
             return newShowMenu;
           });
         })
         .catch((error) => {
           navigator.clipboard.writeText(url).then(() => {
             setSharePostText("URL copied to clipboard");
             setShareIconClass(<BiCheck />);

             setTimeout(() => {
               setSharePostText("Share");
               setShareIconClass(<BiShare />);
               setShowMenu((prevShowMenu) => {
                 const newShowMenu = { ...prevShowMenu };
                 newShowMenu[id] = false;
                 return newShowMenu;
               });
             }, 2000);
           });
         });
     } else {
       navigator.clipboard.writeText(url).then(() => {
         setSharePostText("Unsupported browser");
         setShareIconClass(<FaExclamationCircle />);

         setTimeout(() => {
           setSharePostText("Share");
           setShareIconClass(<BiShare />);
           setShowMenu((prevShowMenu) => {
             const newShowMenu = { ...prevShowMenu };
             newShowMenu[id] = false;
             return newShowMenu;
           });
         }, 2000);
       });
     }
   };

   const handleClick = (e) => {
     e.stopPropagation();
   };

  return (
    <FooterContext.Provider
      value={{
        showFooter,
        setShowFooter,
        musicPosts,
        setMusicPosts,
        yourMusicPage,
        setYourMusicPage,
        musicPage,
        setMusicPage,
        fetchedMusicPostIds,
        clearFetchedMusicPostIds,
        subscriberPage,
        setSubscriberPage,
        subscribers,
        setSubscribers,
        hasMoreSubscribers,
        setHasMoreSubscribers,
        scrollLoadingSubscribers,
        setScrollLoadingSubscribers,
        clearFetchedSubscribersIds,
        fetchSubscribers,
        loading,
        handleMusicOptions,
        handleOutsideClick,
        handleCopyLink,
        handleSharePost,
        fetchedSubscriptionIds,
        clearfetchedSubscriptionIds,
        subscriptions,
        setSubscriptions,
        subscribedScrollLoading,
        setSubscribedScrollLoading,
        subscribedPage,
        setSubscribedPage,
        hasMoreSubscribed,
        setHasMoreSubscribed,
        buyerOrders,
        setBuyerOrders,
        buyerOrderPage,
        setBuyerOrderPage,
        scrollLoadingBuyerOrders,
        setScrollLoadingBuyerOrders,
        hasMoreBuyerOrders,
        setHasMoreBuyerOrders,
        handleClick,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};



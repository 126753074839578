import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import StarUpdateArchieveNav from './StarUpdateArchieveNav';
import { useLikesComments } from '../LikesCommentsContext'; 
import './Admin.css';

function StarUpdateArchieveOutlet() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setPage, setTopPricedArchivedPosts, setStarAwardArchivedPosts, setBestSellingArchivedPosts, clearFetchedPostIds, handleCancelSearchForm } = useLikesComments();
 

  useEffect(() => {
    if (location.pathname === "/adminGooofan/star-nav/post-archive") {
      navigate("/adminGooofan/star-nav/post-archive/archive/best-selling");
    }
  }, [location, navigate]);

  useEffect(() => {
    setPage(1);
    setTopPricedArchivedPosts([]);
    setStarAwardArchivedPosts([]);
    setBestSellingArchivedPosts([]);
    clearFetchedPostIds();
    handleCancelSearchForm();
  }, [location.pathname, setPage, setBestSellingArchivedPosts, setStarAwardArchivedPosts,  setTopPricedArchivedPosts]);

  return (
    <div>
      <StarUpdateArchieveNav />
      <>
       <Outlet />
      </>
    </div>
  );
}

export default StarUpdateArchieveOutlet;


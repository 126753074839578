import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { YourShopHeaderPostContext } from "./YourShopHeaderPostContext";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./Subscribed.css";
import { FooterContext } from "./FooterContext";

function Subscribed() {
  const navigate = useNavigate();
  const {
    fetchedSubscriptionIds,
    subscriptions,
    setSubscriptions,
    subscribedScrollLoading,
    setSubscribedScrollLoading,
    subscribedPage,
    setSubscribedPage,
    hasMoreSubscribed,
    setHasMoreSubscribed,
  } = useContext(FooterContext);
  const { csrftoken } = useContext(YourShopHeaderPostContext);
  const [loading, setLoading] = useState(true);
  const [firstLoadSubscribed, setFirstLoadSubscribed] = useState(true);
  const observer = useRef();
  const subscribedContainerRef = useRef(null);

  const fetchSubscriptions = useCallback(async () => {
    if (firstLoadSubscribed) {
      setLoading(true);
    } else {
      setSubscribedScrollLoading(true);
    }

    try {
      const response = await fetch(
        `/api/subscriptions/subscribed_list/?page=${subscribedPage}`
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      if (data && data.results) {
        const newSubscriptions = data.results.filter((sub) => {
          if (!fetchedSubscriptionIds.has(sub.id)) {
            fetchedSubscriptionIds.add(sub.id);
            return true;
          }
          return false;
        });

        setSubscriptions((prev) => [...prev, ...newSubscriptions]);
        setHasMoreSubscribed(data.next !== null);
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      setHasMoreSubscribed(false);
    } finally {
      if (firstLoadSubscribed) {
        setLoading(false);
        setFirstLoadSubscribed(false);
      }
      setSubscribedScrollLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptions();
  }, [subscribedPage]);

  const lastSubscriptionElementRef = useCallback(
    (node) => {
      if (subscribedScrollLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreSubscribed) {
          setSubscribedPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [subscribedScrollLoading, hasMoreSubscribed]
  );

  const handleUnsubscribe = async (subscribedToId) => {
    try {
      const response = await fetch("/api/subscriptions/unsubscribe/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ subscribed_to_id: subscribedToId }),
      });

      if (!response.ok) throw new Error("Failed to unsubscribe");

      if (response.status === 204) {
        setSubscriptions((prev) =>
          prev.filter((sub) => sub.subscribed_to.id !== subscribedToId)
        );
        fetchedSubscriptionIds.delete(subscribedToId);
      }
    } catch (error) {
      console.error("Error during unsubscribe:", error);
    }
  };

 const handleBackToHome = () => {
   navigate("/home");
 };

return (
  <div className="subscribed-container">
    <header className="header-subscribed">
      <div className="subscribed-Back-btn" onClick={handleBackToHome}>
        <BiArrowBack
          size={20}
          color="currentColor"
          className="bi bi-arrow-left"
        />
      </div>
      <h1>Subscriptions</h1>
      <div className="empty-div-change-it"></div>
    </header>

    <div className="subscribed-contents">
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : subscriptions.length === 0 ? (
        <p className="no-subscriber">You are not subscribed to anyone.</p>
      ) : (
        <ul className="subscription-list">
          {subscriptions.map((subscription) => {
            const user = subscription.subscribed_to;

            return (
              <li
                key={user.id}
                ref={
                  subscriptions.length &&
                  subscriptions[subscriptions.length - 1].subscribed_to.id ===
                    user.id
                    ? lastSubscriptionElementRef
                    : null
                }
                className="subscription-item"
              >
                <div className="subscription-user">
                  <div className="profile">
                    {user.profile_picture ? (
                      <img
                        src={user.profile_picture}
                        alt={`${user.username}'s profile`}
                        className="profile-picture"
                      />
                    ) : (
                      <span className="profile-picture no-profile-picture">
                        {user.first_name.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </div>
                  <span className="user-username">{user.username}</span>
                </div>
                <button
                  onClick={() => handleUnsubscribe(user.id)}
                  className="unsubscribe-button"
                >
                  Unsubscribe
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>

    <div className="scroll-loader-spinner-container">
      {subscribedScrollLoading && (
        <div className="scroll-loader-spinner"></div>
      )}
    </div>
  </div>
);
}

export default Subscribed;

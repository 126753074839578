import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { YourShopHeaderPostContext } from "../../StarNavigationBar/YourShopHeaderPostContext";
import { FaCamera } from "react-icons/fa";
import { usePlayers } from "../../PlayersContext";
import { BiArrowBack } from "react-icons/bi";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./HeaderHome.css";

function Account() {
  const { csrftoken, currentUserData } = useContext(YourShopHeaderPostContext);
  const { LoaderSVG } = usePlayers();
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    profile_picture: null,
    username: "",
    first_name: "",
    last_name: "",
    bio: "",
    phone_number: "",
    date_of_birth: "",
    email: "",
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [newImageUploaded, setNewImageUploaded] = useState(false); 
  const [hasChanges, setHasChanges] = useState(false); 
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentUserData && currentUserData.username) {
      fetch(`/api/users_data/${currentUserData.username}/`)
        .then((response) => response.json())
        .then((data) => {
          setFormData({
            profile_picture: data.profile_picture || "",
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            bio: data.bio || "",
            phone_number: data.phone_number || "",
            date_of_birth: data.date_of_birth || "",
            email: data.email || "",
            username: data.username || "",
          });
          setImagePreview(data.profile_picture || "");
          setLoading(false);
        })
        .catch((error) => console.error("Error fetching user data:", error));
      setLoading(false);
    }
  }, [currentUserData]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors("");
    setHasChanges(true); 
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, profile_picture: file });
      setImagePreview(URL.createObjectURL(file));
      setNewImageUploaded(true); 
      setHasChanges(true); 
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== "" && formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    });

    // Check if cropperRef exists and has a valid cropper instance
    const cropper = cropperRef.current ? cropperRef.current.cropper : null;

   if (cropper) {
     cropper.getCroppedCanvas().toBlob((blob) => {
       const uniqueFileName = `${currentUserData.username}_${Date.now()}.png`; 
       const pngBlob = new File([blob], uniqueFileName, {
         type: "image/png",
       });
       formDataToSend.append("profile_picture", pngBlob);

       fetch(`/api/users_data/${currentUserData.username}/update/`, {
         method: "POST",
         body: formDataToSend,
         headers: {
           "X-CSRFToken": csrftoken,
         },
       })
         .then((response) => response.json())
         .then((data) => {
           if (data.status === "success") {
             navigate("/home");
             setIsLoading(false);
             setHasChanges(false);
           } else {
             setErrors(data.errors);
             setIsLoading(false);
           }
         })
         .catch((error) => console.error("Error updating profile:", error));
       setIsLoading(false);
     }, "image/png");
   } else {
     // Handle case when there's no cropper instance
     fetch(`/api/users_data/${currentUserData.username}/update/`, {
       method: "POST",
       body: formDataToSend,
       headers: {
         "X-CSRFToken": csrftoken,
       },
     })
       .then((response) => response.json())
       .then((data) => {
         if (data.status === "success") {
           setIsLoading(false);
           navigate("/home");
         } else {
           setErrors(data.errors); 
           setIsLoading(false);
         }
       })
       .catch((error) => console.error("Error updating profile:", error));
     setIsLoading(false);
   }
  };

  const handleBackToHome = () => {
    navigate("/home");
  };

  return (
    <div className="account-container">
      <header className="header-account">
        <div className="Account-Back-btn" onClick={handleBackToHome}>
          <BiArrowBack
            size={20}
            color="currentColor"
            className="bi bi-arrow-left"
          />
        </div>
        <h1 className="edit-profile-text">Edit Profile</h1>
        <div className="empty-change-it"></div>
      </header>

      <div className="account-contents">
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="profile">
              {newImageUploaded ? (
                <Cropper
                  src={imagePreview}
                  dragMode="move"
                  scalable={true}
                  zoomable={true}
                  guides={false}
                  ref={cropperRef}
                />
              ) : formData.profile_picture ? (
                <img
                  src={imagePreview}
                  alt="Profile"
                  className="profile-picture"
                />
              ) : (
                <div className="profile-picture no-profile-picture">
                  {formData.first_name
                    ? formData.first_name.slice(0, 1).toUpperCase()
                    : "?"}
                </div>
              )}
              <label className="upload-icon" htmlFor="profilePictureInput">
                <FaCamera />
              </label>
              <input
                type="file"
                id="profilePictureInput"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>

            <form onSubmit={handleSubmit}>
              <div>
                <label>Username</label>
                {errors.username && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.username}
                  </div>
                )}
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label>Email</label>
                {errors.email && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.email}
                  </div>
                )}
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label>First Name</label>
                {errors.first_name && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.first_name}
                  </div>
                )}
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label>Last Name</label>
                {errors.last_name && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.last_name}
                  </div>
                )}
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label>Bio</label>
                {errors.bio && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.bio}
                  </div>
                )}
                <textarea
                  name="bio"
                  id="bio"
                  value={formData.bio}
                  onChange={handleInputChange}
                  maxLength="40"
                />
              </div>

              <div>
                <label>Phone Number</label>
                {errors.phone_number && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.phone_number}
                  </div>
                )}
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label>Date of Birth</label>
                {errors.date_of_birth && (
                  <div className="error-message">
                    <i className="bi bi-exclamation-circle-fill error-icon"></i>
                    {errors.date_of_birth}
                  </div>
                )}
                <input
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  value={formData.date_of_birth}
                  onChange={handleInputChange}
                />
              </div>

              <div className="saving-profile-update">
                <button
                  type="submit"
                  disabled={isLoading || !hasChanges}
                  className={isLoading ? "loading" : ""}
                >
                  {isLoading ? (
                    <div className="saving-indicator">
                      <span>Saving</span>
                      <LoaderSVG />
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );

}

export default Account;

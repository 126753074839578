// StarsUpdateForm.jsx
import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import StarsUpdateCustomEditor from '../AdminComponents/StarsUpdateCustomEditor';
import { YourShopHeaderPostContext } from "../StarNavigationBar/YourShopHeaderPostContext";
import { usePlayers } from "../PlayersContext";
import './StarsUpdateForm.css';

const StarsUpdateForm = () => {
    const { csrftoken } = useContext(YourShopHeaderPostContext);
    const { LoaderSVG } = usePlayers();
    const navigate = useNavigate();
    const [headline, setHeadline] = useState('');
    const [newsCover, setNewsCover] = useState(null);
    const [body, setBody] = useState('');
    const [rawBody, setRawBody] = useState(null);
    const [category, setCategory] = useState('');
    const [coverHeadline, setCoverHeadline] = useState(null);
    const [headlineError, setHeadlineError] = useState('');
    const [newsCoverError, setNewsCoverError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [bodyError, setBodyError] = useState('');
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const handleEditorChange = useCallback((html, rawContent) => {
        const contentLength = rawContent.blocks.reduce((length, block) => length + block.text.length, 0);
        if (contentLength > 5000) {
            setBodyError('Body cannot exceed 5000 characters.');
        } else {
            setBodyError('');
        }
        setBody(html);
        setRawBody(rawContent);
    }, []);

     const checkVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;
                if (duration > 30) {
                    reject('Headline Cover Video should not exceeds 30 seconds');
                } else {
                    resolve();
                }
            };

            video.onerror = () => {
                reject('Failed to load video metadata');
            };

            video.src = URL.createObjectURL(file);
        });
    };

      const handleFileChange = useCallback(async (e) => {
        const file = e.target.files[0];
        const allowedExtensions = ['.jpg', '.bmp', '.dib', '.gif', '.tiff', '.tif', '.jfif', '.png', '.jpeg', '.mp4', '.mov'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (allowedExtensions.includes(`.${fileExtension}`)) {
            try {
                if (file.type.startsWith('video/')) {
                    await checkVideoDuration(file);
                }
                setNewsCover(file);
                setCoverHeadline(file);
                setNewsCoverError('');
                e.target.value = null;
            } catch (error) {
                setNewsCoverError(error);
            }
        } else {
            setNewsCoverError('Please select a valid image or video file (jpg, bmp, gif, tiff, tif, jtif, png, jpeg, mp4 or mov).');
        }
    }, []);

    const handleCoverHeadlineRemove = () => {
        setNewsCover(null);
        setCoverHeadline(null);
    };

     const renderHeadlineCover = useMemo(() => {
        if (!coverHeadline) return null;

        const fileURL = URL.createObjectURL(coverHeadline);
        const fileType = coverHeadline.type.split('/')[0];

        if (fileType === 'image') {
            return <img src={fileURL} alt="Cover" className="headline-cover-thumbnail" />;
        } else if (fileType === 'video') {
            return <video src={fileURL} controls className="headline-cover-thumbnail" />;
        }

        return null;
    }, [coverHeadline]);

     const handleHeadlineChange = (e) => {
        const newHeadline = e.target.value;
        setHeadline(newHeadline);
        if (newHeadline.length > 70) {
            setHeadlineError('Headline cannot exceed 70 characters.');
        } else {
            setHeadlineError('');
        }
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setCategoryError('');
    };

       const handleSubmit = async (e) => {
         e.preventDefault();
         setIsSubmitLoading(true);

         // Check validation before submission
         if (!category) {
           setCategoryError("Category is required.");
           setIsSubmitLoading(false);
         }

         if (headline.length > 70) {
           setHeadlineError("Headline cannot exceed 70 characters.");
           setIsSubmitLoading(false);
         }

         if (categoryError || headlineError || newsCoverError || bodyError) {
           return;
         }

         const formData = new FormData();
         formData.append("headline", headline);
         formData.append("news_cover", newsCover);
         formData.append("body", body);
         formData.append("raw_body", JSON.stringify(rawBody));
         formData.append("best_selling", category === "best_selling");
         formData.append("star_award", category === "star_award");
         formData.append("top_priced", category === "top_priced");

         try {
           const response = await fetch(
             "/api/stars_updates/star_update_post/",
             {
               method: "POST",
               body: formData,
               headers: {
                 "X-CSRFToken": csrftoken,
               },
             }
           );
           if (response.ok) {
             const data = await response.json();
             setIsSubmitLoading(false);
             // Redirect based on category
             if (category === "best_selling") {
               navigate("/adminGooofan/star-nav/posts/updates/best-selling");
             } else if (category === "top_priced") {
               navigate("/adminGooofan/star-nav/posts/updates/top-priced");
             } else if (category === "star_award") {
               navigate("/adminGooofan/star-nav/posts/updates/star-award");
             }
           } else {
             console.error("Error:", response.statusText);
             setIsSubmitLoading(false);
           }
         } catch (error) {
           console.error("There was an error!", error);
           setIsSubmitLoading(false);
         }
       };

    return (
      <div className="StarsUpdateForm">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="category" className="star-update-form-label">
              Category
            </label>
            {categoryError && (
              <p className="error-message">
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {categoryError}
              </p>
            )}
            <select
              id="category"
              name="category"
              value={category}
              onChange={handleCategoryChange}
              className="form-control"
            >
              <option value="">Select category</option>
              <option value="best_selling">Best Selling</option>
              <option value="star_award">Star Award</option>
              <option value="top_priced">Top Priced</option>
            </select>
          </div>
          <div>
            <label htmlFor="headline" className="star-update-form-label">
              Headline
            </label>
            {headlineError && (
              <p className="error-message">
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {headlineError}
              </p>
            )}
            <input
              name="headline"
              id="headline"
              type="text"
              value={headline}
              onChange={handleHeadlineChange}
            />
          </div>
          {/* Same integration/dublicated as in LikesCommentsContext.jsx a bit different from UploadMusic.jsx*/}
          {newsCoverError && (
            <p className="error-message">
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {newsCoverError}
            </p>
          )}
          <div className="star-update-headline-label">
            <input
              className="form-control"
              type="file"
              id="cover_headline"
              accept="image/*,video/*"
              onChange={handleFileChange}
              name="cover_headline"
              style={{ display: "none" }}
            />
            <div
              className="star-update-headline-cover-container"
              onClick={() =>
                document.querySelector('input[name="cover_headline"]').click()
              }
            >
              {coverHeadline ? (
                <div className="star-update-headline-cover-preview">
                  {renderHeadlineCover}
                  <div
                    className="remove-headline-cover"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCoverHeadlineRemove();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="star-update-headline-cover-placeholder">
                  <div className="uploadimgae-icon">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="#888"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                    >
                      <use href="#image-add-icon"></use>
                    </svg>
                  </div>
                  <div className="cover-image">Headline Cover</div>
                </div>
              )}
            </div>
          </div>
          <div className="star-update-form-body">
            <label className="star-update-form-label">Body</label>
            <StarsUpdateCustomEditor onChange={handleEditorChange} />
          </div>
          {bodyError && (
            <p className="error-message">
              <i className="bi bi-exclamation-circle-fill error-icon"></i>
              {bodyError}
            </p>
          )}
          <div className="star-update-form-submit">
            <button
              type="submit"
              disabled={isSubmitLoading}
              className={isSubmitLoading ? "loading" : ""}
            >
              {isSubmitLoading ? (
                <div className="saving-indicator">
                  <span>Posting</span>
                  <LoaderSVG />
                </div>
              ) : (
                "Publish"
              )}
            </button>
          </div>
        </form>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol id="image-add-icon" viewBox="0 0 24 24">
            <path d="M18.75 4C20.5449 4 22 5.45507 22 7.25V18.75C22 20.5449 20.5449 22 18.75 22H7.25C5.45507 22 4 20.5449 4 18.75V12.5019C4.47425 12.6996 4.97687 12.8428 5.50009 12.9236L5.5 18.75C5.5 18.9584 5.53643 19.1583 5.60326 19.3437L11.4258 13.643C12.2589 12.8273 13.5675 12.7885 14.4458 13.5266L14.5742 13.6431L20.3964 19.3447C20.4634 19.159 20.5 18.9588 20.5 18.75V7.25C20.5 6.2835 19.7165 5.5 18.75 5.5L12.9236 5.50009C12.8428 4.97687 12.6996 4.47425 12.5019 4H18.75ZM12.5588 14.644L12.4752 14.7148L6.66845 20.4011C6.8504 20.4651 7.04613 20.5 7.25 20.5H18.75C18.9535 20.5 19.1489 20.4653 19.3305 20.4014L13.5247 14.7148C13.2596 14.4553 12.8501 14.4316 12.5588 14.644ZM16.2521 7.5C17.4959 7.5 18.5042 8.50831 18.5042 9.75212C18.5042 10.9959 17.4959 12.0042 16.2521 12.0042C15.0083 12.0042 14 10.9959 14 9.75212C14 8.50831 15.0083 7.5 16.2521 7.5ZM6.5 1C9.53757 1 12 3.46243 12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1ZM16.2521 9C15.8367 9 15.5 9.33673 15.5 9.75212C15.5 10.1675 15.8367 10.5042 16.2521 10.5042C16.6675 10.5042 17.0042 10.1675 17.0042 9.75212C17.0042 9.33673 16.6675 9 16.2521 9ZM6.5 2.99923L6.41012 3.00729C6.20603 3.04433 6.0451 3.20527 6.00806 3.40936L6 3.49923L5.99965 5.99923L3.49765 6L3.40777 6.00806C3.20368 6.0451 3.04275 6.20603 3.00571 6.41012L2.99765 6.5L3.00571 6.58988C3.04275 6.79397 3.20368 6.9549 3.40777 6.99194L3.49765 7L6.00065 6.99923L6.00111 9.50348L6.00916 9.59336C6.04621 9.79745 6.20714 9.95839 6.41123 9.99543L6.50111 10.0035L6.59098 9.99543C6.79508 9.95839 6.95601 9.79745 6.99305 9.59336L7.00111 9.50348L7.00065 6.99923L9.50457 7L9.59444 6.99194C9.79853 6.9549 9.95947 6.79397 9.99651 6.58988L10.0046 6.5L9.99651 6.41012C9.95947 6.20603 9.79853 6.0451 9.59444 6.00806L9.50457 6L6.99965 5.99923L7 3.49923L6.99194 3.40936C6.9549 3.20527 6.79397 3.04433 6.58988 3.00729L6.5 2.99923Z" />
          </symbol>
        </svg>
      </div>
    );
};

export default StarsUpdateForm;


import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { FooterContext } from "../StarNavigationBar/FooterContext";
import {
  AiOutlineStar,
  AiOutlineDollarCircle,
  AiOutlineTrophy,
} from "react-icons/ai";
import "./StarsUpdate.css";

function StarsUpdate() {
   const {
     handleClick,
   } = useContext(FooterContext);

  return (
    <div className="container stars-Update-Container">
      <h4>Star's Update</h4>
      <Link
        to="/starsupdates/bestsellings"
        className="Best-selling-Btn link-button"
        onClick={handleClick}
      >
        <AiOutlineStar size={30} color="currentColor" className="bi bi-stars" />
        <span>Best-Selling Stars</span>
        <BiChevronRight className="bi bi-chevron-right" />
      </Link>
      <Link
        to="/starsupdates/toppriced"
        className="Top-Priced-Btn link-button"
        onClick={handleClick}
      >
        <AiOutlineDollarCircle size={20} color="currentColor" />
        <span>Top-Priced Products</span>
        <BiChevronRight className="bi bi-chevron-right" />
      </Link>
      <Link
        to="/starsupdates/starawards"
        className="Star-Rewards link-button"
        onClick={handleClick}
      >
        <AiOutlineTrophy
          size={20}
          color="currentColor"
          className="bi bi-trophy"
        />
        <span>Star Awards</span>
        <BiChevronRight className="bi bi-chevron-right" />
      </Link>
    </div>
  );
}

export default StarsUpdate;

//  import React from "react";
//  import { createRoot } from "react-dom/client";
//  import App from "./App";
//  import reportWebVitals from "./reportWebVitals";
 
//  document.addEventListener("DOMContentLoaded", (event) => {
//    const root = createRoot(document.getElementById("navigation"));
//    root.render(<App />);
//  });

//  reportWebVitals();


import React from "react";
import { createRoot } from "react-dom/client";
import SentryWrappedApp from "./components/SentryWrappedApp";
import reportWebVitals from "./reportWebVitals";

// Wait for DOMContentLoaded to render the app
document.addEventListener("DOMContentLoaded", (event) => {
  const root = createRoot(document.getElementById("navigation"));
  root.render(<SentryWrappedApp />);
});

// For measuring performance (optional)
reportWebVitals();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


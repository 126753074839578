import { useEffect } from "react";

/**
 * Updates the viewport height CSS variable (`--vh`) dynamically.
 * Fixes the mobile browser behavior where viewport height
 * changes when the keyboard opens or browser footer appears.
 */
const updateViewportHeight = () => {
  const viewportHeight = window.visualViewport
    ? window.visualViewport.height
    : window.innerHeight;

  document.documentElement.style.setProperty(
    "--vh",
    `${viewportHeight * 0.01}px`
  );
};

/**
 * Custom Hook: useViewportHeight
 * - Updates viewport height on mount.
 * - Recalculates height on `resize` and `visualViewport` changes.
 */

const useViewportHeight = () => {
  useEffect(() => {
    updateViewportHeight();

    const handleViewportChange = () => updateViewportHeight();

    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleViewportChange);
      window.visualViewport.addEventListener("scroll", handleViewportChange);
    }

    window.addEventListener("resize", updateViewportHeight);

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          "resize",
          handleViewportChange
        );
        window.visualViewport.removeEventListener(
          "scroll",
          handleViewportChange
        );
      }
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);
};

/**
 * Custom Hook: useHandleResize
 * Tracks window size and updates layout accordingly.
 */
const useHandleResize = (setBottom) => {
  useEffect(() => {
    const handleResize = () => {
      const isPortrait = window.innerHeight > window.innerWidth;
      setBottom(isPortrait); // Update state based on orientation
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setBottom]);
};

export { useViewportHeight, useHandleResize };


// import { useEffect } from "react";

// /**
//  * Updates the viewport height CSS variable (`--vh`) dynamically.
//  * This ensures mobile browsers calculate viewport height correctly,
//  * even when the keyboard opens/closes and the address bar shows/hides.
//  */
// const updateViewportHeight = () => {
//   const viewportHeight = window.visualViewport?.height || window.innerHeight;
//   document.documentElement.style.setProperty("--vh", `${viewportHeight}px`);
// };

// /**
//  * Custom Hook: useViewportHeight
//  * - Sets viewport height on mount.
//  * - Listens for viewport changes (keyboard open/close).
//  * - Recalculates height on `resize` and `visualViewport` events.
//  */
// const useViewportHeight = () => {
//   useEffect(() => {
//     // Initial viewport height setup
//     updateViewportHeight();

//     // Update viewport on visual viewport changes
//     const handleViewportChange = () => updateViewportHeight();

//     // Attach event listeners
//     if (window.visualViewport) {
//       window.visualViewport.addEventListener("resize", handleViewportChange);
//       window.visualViewport.addEventListener("scroll", handleViewportChange);
//     }
//     window.addEventListener("resize", updateViewportHeight);

//     // Cleanup listeners on unmount
//     return () => {
//       if (window.visualViewport) {
//         window.visualViewport.removeEventListener(
//           "resize",
//           handleViewportChange
//         );
//         window.visualViewport.removeEventListener(
//           "scroll",
//           handleViewportChange
//         );
//       }
//       window.removeEventListener("resize", updateViewportHeight);
//     };
//   }, []);
// };

// /**
//  * Custom Hook: useHandleResize
//  * Tracks window size and updates layout accordingly.
//  */
// const useHandleResize = (setBottom) => {
//   useEffect(() => {
//     const handleResize = () => {
//       const isPortrait = window.innerHeight > window.innerWidth;
//       setBottom(isPortrait); // Update state based on orientation
//     };

//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, [setBottom]);
// };

// export { useViewportHeight, useHandleResize };



import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SearchUser from "../SearchUser";
import "./SharedMusicModal.css";

function SharedMusicModal() {
  const { username, urlUid } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [musicUrlData, setMusicUrlData] = useState(null);
  

  const fetchMusicUrlData = async () => {
    try {
      const response = await fetch(`/api/music/${username}/${urlUid}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch music data");
      }

      const data = await response.json();
      setMusicUrlData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMusicUrlData();
  }, [username, urlUid]);

  if (loading) return <div className="shared-loading">Loading...</div>;
  if (error) return (
    <div className="shared-error error-message">
      <i className="bi bi-exclamation-circle-fill error-icon"></i>
      {error}
    </div>
  );

  const { song_title, artist, featuring, cover_image } = musicUrlData;

  return (
    <div className="shared-music-container">
      <div
        className="shared-music-conents background-image"
        style={{ backgroundImage: `url(${cover_image})` }}
      >
        <div className="shared-music-overlay">
          <h1>{song_title}</h1>
          <p>
            Song by {artist}
            {featuring && ` feat. ${featuring}`}
          </p>
        </div>
      </div>
      <p className="search-message">Search for someone to listen with</p>
      {/* Search Component to find someone to listen with */}
      <SearchUser sharedMusic={musicUrlData} />
    </div>
  );
}

export default SharedMusicModal;

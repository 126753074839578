// TopPricedPosts.jsx
import React, { useEffect} from 'react';
import "./UpdatesStar.css";
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';
import { useLikesComments } from '../LikesCommentsContext';


const TopPricedPosts = () => {
  const {
    adminTopPricedPosts, setAdminTopPricedPosts,
    starUpdateRenderPostAdmin, fetchPosts, page, setPageHasMore, scrollLoading, 
  } = useLikesComments();

  useEffect(() => {
    fetchPosts('/api/stars_updates/top_priced/', setAdminTopPricedPosts, page, setPageHasMore);
  }, [page]);

  return (
    <>
    <StarUpdateHeaderWithSearch
        category="top_priced"
        isArchived={false}
        nonArchived={true}
      />
     {starUpdateRenderPostAdmin(adminTopPricedPosts, setAdminTopPricedPosts)}
     <div className='scroll-loader-spinner-container'>
      {scrollLoading && <div className="scroll-loader-spinner"></div>}
     </div>
    </>
  );
};

export default TopPricedPosts;
// StarAwards.jsx
import React, { useEffect } from 'react';
import './StarUpdate.css';
import { useLikesComments } from '../LikesCommentsContext';
import StarUpdateHeaderWithSearch from '../StarUpdateHeaderWithSearch';

const StarAwards = () => {
  const {
    starAwardPosts, 
    setStarAwardPosts,
    fetchPosts,  handleBackToHome, renderPosts  ,
    page, setPageHasMore, scrollLoading, 
  } = useLikesComments();

  useEffect(() => {
   fetchPosts('/api/stars_updates/star_award/', setStarAwardPosts, page, setPageHasMore );
  }, [page]);

  return (
    <div className='staraward-container'>
       <StarUpdateHeaderWithSearch
        title="Star Award"
        handleBackToHome={handleBackToHome}
        category="star_award"
        isArchived={false}
      /> 
      <div className='staraward-contents'>
        {renderPosts(starAwardPosts, setStarAwardPosts)}
      </div>
      <div className='scroll-loader-spinner-container'>
          {scrollLoading && <div className="scroll-loader-spinner"></div>}
      </div>
    </div>
  );
};

export default StarAwards;





import React, {
  useEffect,
  useState
} from "react";
import CustomListenTogetherPlayerWithProvider from "../CustomListenTogetherPlayer";
import { useChatAndMusicPosts } from "../StarNavigationBar/ChatAndMusicPostsContext";
import { usePlayers } from "../PlayersContext";
import { AiOutlineClose } from "react-icons/ai";
import { useChat } from "./ChatContext";
import "../StarNavigationBar/chat.css";
import "../MusicSectionComponents/MusicPosts.css";



const PostRequestMusic = () => {
  const {
    acceptedRequests,
    setAcceptedRequests,
    handleCloseMusicRequest,
    fetchUserRequests,
  } = useChat();
  const { LoaderSVG } = usePlayers();
  const {
    currentConversationId,
    userData,
    receivedRequests,
    showRequestAcceptedMessage,
    showRequestSentMessage,
    showRequestRejectedMessage,
    showRequestCanceledMessage,
    postRequestMusic,
    setPostRequestMusic,
  } = useChatAndMusicPosts();
  const [loadingRequests, setLoadingRequests] = useState({});

  useEffect(() => {
    const initializeRequests = async () => {
      const userRequests = await fetchUserRequests();
      setAcceptedRequests(
        userRequests.filter(
          (request) => !request.closed && request.request_accepted
        )
      );

      //Setting PostRequestMusic with information
      const requestMusicData = userRequests.map((request) => ({
        ...request.music,
        tempMusicId: request.music.tempMusicId,
        tempRequestId: request.tempRequestId,
        conversationId: request.conversation.conversationId,
        requestAccepted: request.request_accepted,
        closed: request.closed,
        closed_by_sender: request.closed_by_sender,
        closed_by_recipient: request.closed_by_recipient,
        sender: request.sender,
        recipient: request.recipient,
      }));

      setPostRequestMusic(requestMusicData);
    };

    initializeRequests();
  }, [
    currentConversationId,
    showRequestSentMessage,
    receivedRequests,
    showRequestAcceptedMessage,
    showRequestRejectedMessage,
    showRequestCanceledMessage,
  ]);

const handleRequestClose = async (tempRequestId) => {
  setLoadingRequests((prev) => ({ ...prev, [tempRequestId]: true }));

  await handleCloseMusicRequest(tempRequestId);

  setPostRequestMusic((prev) =>
    prev.filter((music) => music.tempRequestId !== tempRequestId)
  );

  setLoadingRequests((prev) => {
    const newLoading = { ...prev };
    delete newLoading[tempRequestId];
    return newLoading;
  });
};

  return (
    <>
      {postRequestMusic
        .filter((music) => {
          const isSender = music.sender?.username === userData.username;
          const isRecipient = music.recipient?.username === userData.username;
          const isAccepted = acceptedRequests.some(
            (req) =>
              req.music.tempMusicId === music.tempMusicId &&
              req.tempRequestId === music.tempRequestId
          );
          const isPendingInCurrentConversation =
            music.conversationId === currentConversationId && !isAccepted;

          if (music.closed_by_sender) {
            // Case when sender closes: Do not render on either side
            return false;
          } else if (music.closed_by_recipient) {
            // Case when recipient closes: Render only on sender's side

            return isSender && music.conversationId === currentConversationId;
            // return isSender;
          } else {
            // Default case: Render if not closed and either accepted or pending in current conversation
            return (
              !music.closed && (isAccepted || isPendingInCurrentConversation)
            );
          }
        })
        .map((music) => (
          <div
            className="wrapper-musicpost-audioplayer"
            key={`${music.tempMusicId}-${music.tempRequestId}`}
          >
            <div className="musicPost">
              {music.cover_image ? (
                <img
                  src={music.cover_image}
                  alt={`${music.song_title} cover`}
                  className="chat-musicPost-cover-image"
                />
              ) : (
                <div className="chat-musicPost-cover-placeholder">
                  {music.song_title.charAt(0).toUpperCase()}
                </div>
              )}
              <div className="musicPost-info">
                <h3 className="musicPost-artist">{music.artist}</h3>
                <p className="musicPost-song-title">{music.song_title}</p>
                {music.featuring && (
                  <p className="musicPost-featuring">
                    Feat. {music.featuring.replace(/,/g, " &")}
                  </p>
                )}
              </div>
              {/* <div
                className="musicPost-close"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseMusicRequest(music.tempRequestId);
                }}
              >
                <AiOutlineClose size={30} />
              </div> */}
              <div
                className="musicPost-close"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRequestClose(music.tempRequestId);
                }}
              >
                {loadingRequests[music.tempRequestId] ? (
                  <LoaderSVG className="musicPost-loader-svg" />
                ) : (
                  <AiOutlineClose size={30} />
                )}
              </div>
            </div>
            {acceptedRequests.some(
              (req) =>
                req.music.tempMusicId === music.tempMusicId &&
                req.tempRequestId === music.tempRequestId
            ) && (
              <CustomListenTogetherPlayerWithProvider
                audioSrc={music.audio_file}
                tempMusicId={music.tempMusicId}
                tempRequestId={music.tempRequestId}
                recipient={music.recipient.username}
                sender={music.sender.username}
                musicId={music.id}
              />
            )}
          </div>
        ))}
    </>
  );
};

export default PostRequestMusic;



// import React, { useState } from 'react';
// import LazyImage from './LazyImage';
// import { YourShopHeaderPostContext } from './StarNavigationBar/YourShopHeaderPostContext';
// import { FooterContext } from './StarNavigationBar/FooterContext';
// import { BiDotsVerticalRounded } from "react-icons/bi";
// import './StarHomeComponents/ContentsRecommendation.css';
// import './MusicSearchResult.css';

// function MusicSearchResult({ music, onClick }) {
//   const {
//     showMenu,
//     setShowMenu,
//     menuRef,
//     copyLinkText,
//     iconClass,
//     sharePostText,
//     shareIconClass,
//   } = useContext(YourShopHeaderPostContext);
//   const {
//     handleMusicOptions,
//     handleOutsideClick,
//     handleCopyLink,
//     handleSharePost,
//   } = useContext(FooterContext);
    
//   const [imageFailed, setImageFailed] = useState(false);
//    useEffect(() => {
//      if (musicPosts.length > 0) {
//        // Create a new showMenu object with a key for each music ID
//        const newShowMenu = musicPosts.reduce((acc, music) => {
//          acc[music.id] = false;
//          return acc;
//        }, {});
//        setShowMenu(newShowMenu);
//      }

//      document.addEventListener("click", handleOutsideClick);

//      return () => {
//        document.removeEventListener("click", handleOutsideClick);
//      };
//    }, [musicPosts]);



//     return (
//       <div className="music-search-result" onClick={onClick}>
//         {/* Render each search result music */}
//         {music.cover_image && !imageFailed ? (
//           <LazyImage
//             src={music.cover_image}
//             alt={music.song_title}
//             className="musicSearch-cover-image"
//             onError={() => setImageFailed(true)}
//           />
//         ) : (
//           <div className="musicSearch-cover-placeholder">
//             {music.song_title
//               ? music.song_title.slice(0, 1).toUpperCase()
//               : "?"}
//           </div>
//         )}
//         {/* Render artist and song title */}
//         <div className="musicSearch-info">
//           <h3 className="musicSearch-artist">{music.artist}</h3>
//           <p className="musicSearch-song-title">{music.song_title}</p>
//           {music.featuring && (
//             <p className="musicSearch-featuring">
//               Feat. {music.featuring.replace(/,/g, " &")}
//             </p>
//           )}
//         </div>
//         <div className="musicPost-more-options" ref={menuRef}>
//           <button
//             onClick={(event) => {
//               event.stopPropagation();
//               handleMusicOptions(music.id);
//             }}
//           >
//             <BiDotsVerticalRounded size={20} />
//           </button>
//           {showMenu[music.id] && (
//             <div className={`menu ${showMenu[music.id] ? "show" : ""}`}>
//               <div
//                 className="share-post-copy"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleCopyLink(music.id);
//                 }}
//               >
//                 {iconClass}
//                 <span className="copy-link">{copyLinkText}</span>
//               </div>
//               <div
//                 className="share-post"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleSharePost(music.id);
//                 }}
//               >
//                 {shareIconClass}
//                 <span className="share-post-word">{sharePostText}</span>
//               </div>
//               {/* Other menu items... */}
//             </div>
//           )}
//         </div>
//       </div>
//     );
// }

// export default MusicSearchResult;
import React, { useState, useEffect, useContext } from "react";
import LazyImage from "./LazyImage";
import { YourShopHeaderPostContext } from "./StarNavigationBar/YourShopHeaderPostContext";
import { FooterContext } from "./StarNavigationBar/FooterContext";
import {
  BiLink,
  BiCheck,
  BiShare,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import { FaExclamationCircle } from "react-icons/fa";
import "./StarHomeComponents/ContentsRecommendation.css";
import "./MusicSearchResult.css";

function MusicSearchResult({ music, onClick }) {
  const [showMenu, setShowMenu] = useState({});
  const {
    menuRef,
    copyLinkText,
    iconClass,
    sharePostText,
    shareIconClass,
    setCopyLinkText,
    setIconClass,
    setSharePostText,
    setShareIconClass,
  } = useContext(YourShopHeaderPostContext);
  const {
      musicPosts
  } = useContext(FooterContext);

  const [imageFailed, setImageFailed] = useState(false);


  const handleMusicOptions = (id) => {
    setShowMenu((prevShowMenu) => {
      // Close all other open menus
      const newShowMenu = Object.keys(prevShowMenu).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Open the menu for the current post
      newShowMenu[id] = !prevShowMenu[id];

      return newShowMenu;
    });
  };

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      // Close all menus
      const newShowMenu = { ...showMenu };
      Object.keys(newShowMenu).forEach((id) => {
        newShowMenu[id] = false;
      });
      setShowMenu(newShowMenu);
    }
  };

  const handleCopyLink = (id) => {
    const music = musicPosts.find((music) => music.id === id);
    const url = music?.music_url;

    navigator.clipboard.writeText(url).then(() => {
      setCopyLinkText("Copied to clipboard");
      setIconClass(<BiCheck />);

      setTimeout(() => {
        setCopyLinkText("Copy link");
        setIconClass(<BiLink />);

        setShowMenu((prevShowMenu) => {
          const newShowMenu = { ...prevShowMenu };
          newShowMenu[id] = false;
          return newShowMenu;
        });
      }, 2000);
    });
  };

  const handleSharePost = (id) => {
    const music = musicPosts.find((music) => music.id === id);
    const url = music.music_url;

    if (navigator.share) {
      navigator
        .share({
          title: `Check out this music by ${music.artist}`,
          text: "Check out this music!",
          url: url,
        })
        .then(() => {
          setShowMenu((prevShowMenu) => {
            const newShowMenu = { ...prevShowMenu };
            newShowMenu[id] = false;
            return newShowMenu;
          });
        })
        .catch((error) => {
          navigator.clipboard.writeText(url).then(() => {
            setSharePostText("URL copied to clipboard");
            setShareIconClass(<BiCheck />);

            setTimeout(() => {
              setSharePostText("Share");
              setShareIconClass(<BiShare />);
              setShowMenu((prevShowMenu) => {
                const newShowMenu = { ...prevShowMenu };
                newShowMenu[id] = false;
                return newShowMenu;
              });
            }, 2000);
          });
        });
    } else {
      navigator.clipboard.writeText(url).then(() => {
        setSharePostText("Unsupported browser");
        setShareIconClass(<FaExclamationCircle />);

        setTimeout(() => {
          setSharePostText("Share");
          setShareIconClass(<BiShare />);
          setShowMenu((prevShowMenu) => {
            const newShowMenu = { ...prevShowMenu };
            newShowMenu[id] = false;
            return newShowMenu;
          });
        }, 2000);
      });
    }
  };

  useEffect(() => {
    // Initialize showMenu state for the single music item
    setShowMenu({ [music.id]: false });

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [music.id]);

  return (
    <div className="music-search-result" onClick={onClick}>
      {/* Render each search result music */}
      {music.cover_image && !imageFailed ? (
        <LazyImage
          src={music.cover_image}
          alt={music.song_title}
          className="musicSearch-cover-image"
          onError={() => setImageFailed(true)}
        />
      ) : (
        <div className="musicSearch-cover-placeholder">
          {music.song_title ? music.song_title.slice(0, 1).toUpperCase() : "?"}
        </div>
      )}
      {/* Render artist and song title */}
      <div className="musicSearch-info">
        <h3 className="musicSearch-artist">{music.artist}</h3>
        <p className="musicSearch-song-title">{music.song_title}</p>
        {music.featuring && (
          <p className="musicSearch-featuring">
            Feat. {music.featuring.replace(/,/g, " &")}
          </p>
        )}
      </div>
      <div className="musicPost-more-options" ref={menuRef}>
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleMusicOptions(music.id);
          }}
        >
          <BiDotsVerticalRounded size={20} />
        </button>
        {showMenu[music.id] && (
          <div className={`menu ${showMenu[music.id] ? "show" : ""}`}>
            <div
              className="share-post-copy"
              onClick={(e) => {
                e.stopPropagation();
                handleCopyLink(music.id);
              }}
            >
              {iconClass}
              <span className="copy-link">{copyLinkText}</span>
            </div>
            <div
              className="share-post"
              onClick={(e) => {
                e.stopPropagation();
                handleSharePost(music.id);
              }}
            >
              {shareIconClass}
              <span className="share-post-word">{sharePostText}</span>
            </div>
            {/* Other menu items... */}
          </div>
        )}
      </div>
    </div>
  );
}

export default MusicSearchResult;

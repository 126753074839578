import React, { useEffect, useState } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import "flag-icon-css/css/flag-icons.min.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useOwnModalsContext } from "./OwnModalsContext";
import { BiArrowBack } from "react-icons/bi";

const DeliveryFormModal = () => {
  const {
    isModalOpen,
    setIsModalOpen,
    setIsModalConfirmOpen,
    country,
    setCountry,
    countryCode,
    setCountryCode,
    address,
    setAddress,
    zipCode,
    setZipCode,
    houseApartment,
    setHouseApartment,
    contactNumber,
    setContactNumber,
    isScriptLoaded,
    setIsScriptLoaded,
    deliveryType,
    setDeliveryType,
    favoriteBusOrFerry,
    setFavoriteBusOrFerry,
    favoriteCourier,
    setFavoriteCourier,
    placesError,
    setPlacesError,
  } = useOwnModalsContext();
  const countries = countryList().getData();

  
  const [zipError, setZipError] = useState("");
  const [favouriteCourierError, setFavouriteCourierError] = useState("");
  const [fouriteBusOrFerryError, setFouriteBusOrFerryError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [addressOnSubmitError, setAddressOnSubmitError] = useState("");
  const [countryError, setCountryError] = useState("");

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNext = () => {
    let hasError = false;

    // Validate zip code for 'airplane' delivery type
    if (deliveryType.value === "airplane") {
      if (!zipCode) {
        setZipError("Zip code is required for Airplane delivery.");
        hasError = true;
      } else {
        setZipError("");
      }

      if (!favoriteCourier) {
        setFavouriteCourierError(
          "Favorite courier is required for Airplane delivery."
        );
        hasError = true;
      } else {
        setFavouriteCourierError("");
      }
    }

    // Validate favorite bus or ferry for 'busFerry' delivery type
    if (deliveryType.value === "busFerry") {
      if (!favoriteBusOrFerry) {
        setFouriteBusOrFerryError(
          "Favorite Bus or Ferry is required for Bus or Ferry delivery."
        );
        hasError = true;
      } else {
        setFouriteBusOrFerryError("");
      }
    }

    // Validate address and country
    if (deliveryType.value !== "selfPickUp") {
      if (!address) {
        setAddressOnSubmitError("Address is required.");
        hasError = true;
      } else {
        setAddressOnSubmitError("");
      }

      if (!country) {
        setCountryError("Country is required.");
        hasError = true;
      } else {
        setCountryError("");
      }
    }

    if (!hasError) {
      setIsModalConfirmOpen(true);
    }
  };

 const handleZipCodeChange = (e) => {
   const value = e.target.value;
   setZipCode(value);
   if (value) {
     setZipError(""); 
   }
 };

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    setCountryCode(selectedOption.value);

    setAddress("");
    if (selectedOption) {
    setCountryError("");
    }
  };

  const handleDeliveryTypeChange = (selectedOption) => {
    setDeliveryType(selectedOption);

    //Resets when changes
    setFavoriteBusOrFerry(null);
    setFavoriteCourier(null);
    setCountry(null);
    setAddress("");
    setZipCode("");
    setHouseApartment("");
    setContactNumber("");
  };

  const handleFavoriteBusOrFerryChange = (selectedOption) => {
    setFavoriteBusOrFerry(selectedOption);
    if (selectedOption) {
      setFouriteBusOrFerryError(""); 
    }
     
  };

  const handleFavoriteCourierChange = (selectedOption) => {
    setFavoriteCourier(selectedOption);

    if (selectedOption) {
      setFavouriteCourierError("");
    }
  };

  const deliveryTypeOptions = [
    { value: "selfPickUp", label: "Self Pick-up" },
    { value: "bike", label: "Bike" },
    { value: "busFerry", label: "Bus or Ferry" },
    { value: "airplane", label: "Airplane" },
  ];
  const busOrFerryOptions = [
    { value: "kidinilo", label: "Kidinilo" },
    { value: "advinture", label: "Adventure" },
    { value: "abood", label: "Abood" },
    // Add more options as needed
  ];
  const courierOptions = [
    { value: "ups", label: "UPS" },
    { value: "fedex", label: "FedEx" },
    { value: "dhl", label: "DHL" },
    // Add more options as needed
  ];

  const handleAddressChange = (address) => {
    setAddress(address);
    setAddressError("");
    setAddressOnSubmitError("")

    // If the address is too short, avoid triggering validation
    if (address.length < 5) {
      return;
    }
  };

  const handleSelect = async (address) => {
    setAddress(address);

    try {
      const results = await geocodeByAddress(address);
      if (!results.length) {
        throw new Error("No results found for the address.");
      }

      const addressComponents = results[0].address_components;

      // Extract relevant components
      const street = addressComponents.find((component) =>
        component.types.includes("route")
      )?.long_name;
      const establishment = addressComponents.find((component) =>
        component.types.includes("establishment")
      )?.long_name;
      const sublocality = addressComponents.find((component) =>
        component.types.includes("sublocality")
      )?.long_name;
      const locality = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const administrativeArea = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;

      // Combine checks for flexibility while ensuring specificity
      if ((!street && !establishment && !sublocality) || !locality) {
        setAddressError(
        "Please provide a complete and specific address. Avoid using only postal codes or abbreviations. Make sure the address includes a meaningful name like a street, landmark, or locality, along with the city."
        );
      } else {
        setAddressError(""); // Clear error if the address is sufficiently detailed
      }
    } catch (error) {
      if (
        error.message.includes("ZERO_RESULTS") ||
        error.message.includes("INVALID_REQUEST")
      ) {
        setAddressError("");
      } else {
        console.error("Error fetching address components:", error);
        setAddressError(
          "Invalid address. Please enter a more detailed address."
        );
      }
    }
  };

  // custom component to render flags with country names
  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    const countryCode = data.value.toLowerCase();
    return (
      <div ref={innerRef} {...innerProps}>
        <span
          className={`flag-icon flag-icon-${countryCode}`}
          style={{ marginRight: 10 }}
        ></span>
        {data.label}
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: state.isFocused ? "none !important" : provided.outline,
      border: state.isFocused
        ? "1px #f0c14b solid !important"
        : provided.border,
      boxShadow: state.isFocused ? "none !important" : provided.boxShadow,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f0c14b"
        : state.isFocused
        ? "#e0e0e0"
        : provided.backgroundColor,
      color: state.isSelected ? "#000" : provided.color,
      cursor: state.isSelected ? "default" : "pointer",
    }),
  };

  const handlePlacesError = (status, clearSuggestions) => {
    if (status === "ZERO_RESULTS") {
      console.warn("No results found for the input.");
    } else {
      console.error("Error fetching data from Google Maps API:", status);
      setPlacesError(
        "There was an error fetching data. Please try again later."
      );
    }
    clearSuggestions();
  };

  // Function to set script loaded state
  window.initMap = () => {
    setIsScriptLoaded(true);
  };

  // Function to load Google Maps JavaScript API
  const loadGoogleMapsScript = async () => {
    if (!isScriptLoaded) {
      if (
        !document.querySelector(
          'script[src*="maps.googleapis.com/maps/api/js"]'
        )
      ) {
        try {
          const response = await fetch("/api/get-google-maps-api-key/");
          const data = await response.json();
          const apiKey = data.api_key;

          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap&loading=async`;
          script.async = true;
          script.defer = true;
          script.onerror = () => {
            console.error("Error loading Google Maps script.");
            setPlacesError(
              "There was an error fetching data. Please try again later."
            );
          };
          document.body.appendChild(script);
        } catch (error) {
          console.error("Error fetching Google Maps API key:", error);
          setPlacesError(
            "There was an error fetching data. Please try again later."
          );
        }
      } else {
        setIsScriptLoaded(true);
      }
    }
  };

  useEffect(() => {
    if (!isScriptLoaded) {
      loadGoogleMapsScript();
    }
  }, [isScriptLoaded]);

  const handleInputFocus = () => {};

  return (
    <>
      {/* Own Modal */}
      {isModalOpen && (
        <div className="own-modal-overlay" onClick={handleCloseModal}>
          <div
            className="own-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="own-modal-header">
              <div className="close-form-modal" onClick={handleCloseModal}>
                <BiArrowBack
                  size={20}
                  color="currentColor"
                  className="bi bi-arrow-left"
                />
              </div>
              <div>
                <h4 className="text-delivery-form">Delivery Form</h4>
              </div>
              <div className="empty-div-change-it"></div>
            </div>
            <form>
              <div className="deliveryType">
                <label htmlFor="deliveryType" className="deliveryType">
                  Delivery Type
                </label>
                <Select
                  inputId="deliveryType"
                  name="deliveryType"
                  options={deliveryTypeOptions}
                  value={deliveryType}
                  onChange={handleDeliveryTypeChange}
                  styles={customStyles}
                />
                {deliveryType.value === "bike" && (
                  <p className="help-text-deliverytype">
                    For now, bike service is only available in Dar es Salaam and
                    for weights below 100kg.
                  </p>
                )}
                {deliveryType.value === "busFerry" && (
                  <p className="help-text-deliverytype">
                    Bus or Ferry delivery might be affected by location. Make
                    sure the selected option is available in your area.
                  </p>
                )}
              </div>

              {deliveryType.value === "selfPickUp" ? (
                <div className="selfPickUpMessage">
                  <p>
                    Once the seller receives your order, they will send you a
                    location where you can pick up your package. However, you
                    can still reject the location and choose other means of
                    delivery.
                  </p>
                </div>
              ) : (
                <>
                  {deliveryType.value === "busFerry" && (
                    <div className="favoriteBusOrFerry">
                      {fouriteBusOrFerryError && (
                        <div className="error-message">
                          {" "}
                          <i className="bi bi-exclamation-circle-fill error-icon"></i>
                          {fouriteBusOrFerryError}
                        </div>
                      )}
                      <label
                        htmlFor="favoriteBusOrFerry"
                        className="favoriteBusOrFerry"
                      >
                        Favorite Bus or Ferry
                      </label>
                      <Select
                        inputId="favoriteBusOrFerry"
                        name="favoriteBusOrFerry"
                        options={busOrFerryOptions}
                        value={favoriteBusOrFerry}
                        onChange={handleFavoriteBusOrFerryChange}
                        styles={customStyles}
                      />
                    </div>
                  )}

                  {deliveryType.value === "airplane" && (
                    <>
                      <div className="zipCode">
                        {zipError && (
                          <div className="error-message">
                            {" "}
                            <i className="bi bi-exclamation-circle-fill error-icon"></i>
                            {zipError}
                          </div>
                        )}
                        <label htmlFor="zipCode" className="label">
                          Zip Code or Postal Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="zipCode"
                          name="zipCode"
                          placeholder="Type here"
                          value={zipCode}
                          onChange={handleZipCodeChange}
                        />
                      </div>
                      <div className="favoriteCourier">
                        {favouriteCourierError && (
                          <div className="error-message">
                            {" "}
                            <i className="bi bi-exclamation-circle-fill error-icon"></i>
                            {favouriteCourierError}
                          </div>
                        )}
                        <label
                          htmlFor="favoriteCourier"
                          className="favoriteCourier"
                        >
                          Favorite Courier
                        </label>
                        <Select
                          inputId="favoriteCourier"
                          name="favoriteCourier"
                          options={courierOptions}
                          value={favoriteCourier}
                          onChange={handleFavoriteCourierChange}
                          styles={customStyles}
                        />
                      </div>
                    </>
                  )}

                  <div className="country">
                    {countryError && (
                      <div className="error-message">
                        {" "}
                        <i className="bi bi-exclamation-circle-fill error-icon"></i>
                        {countryError}
                      </div>
                    )}
                    <label htmlFor="country" className="label">
                      Country
                    </label>
                    <Select
                      inputId="country"
                      name="country"
                      options={countries}
                      value={country}
                      onChange={handleCountryChange}
                      components={{ Option: customOption }}
                      styles={customStyles}
                    />
                  </div>
                  <div className="address">
                    {addressError && (
                      <div className="error-message">
                        {" "}
                        <i className="bi bi-exclamation-circle-fill error-icon"></i>
                        {addressError}
                      </div>
                    )}
                    {addressOnSubmitError && (
                      <div className="error-message">
                        {" "}
                        <i className="bi bi-exclamation-circle-fill error-icon"></i>
                        {addressOnSubmitError}
                      </div>
                    )}
                    <label htmlFor="address" className="address">
                      Address
                    </label>
                    {isScriptLoaded ? (
                      <PlacesAutocomplete
                        value={address}
                        name="address"
                        inputId="address"
                        onChange={handleAddressChange}
                        onSelect={handleSelect}
                        onError={handlePlacesError}
                        searchOptions={{
                          componentRestrictions: { country: countryCode },
                        }}
                        autoComplete="on"
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Search your place and select...",
                                className: "location-search-input form-control",
                                onFocus: handleInputFocus,
                                id: "address",
                                name: "address",
                                autoComplete: "on",
                                disabled: !countryCode,
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && (
                                <div className="mb-3">Loading...</div>
                              )}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                return (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    ) : (
                      <input
                        id="address"
                        name="address"
                        placeholder="Search your place and select..."
                        className="location-search-input form-control"
                        onFocus={handleInputFocus}
                        autoComplete="on"
                        disabled={!countryCode}
                      />
                    )}
                  </div>
                  <div className="houseApartment">
                    <label htmlFor="houseApartment" className="label">
                      House/Apartment
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="houseApartment"
                      name="houseApartment"
                      autoComplete="address-line2"
                      placeholder="Type here"
                      value={houseApartment}
                      onChange={(e) => setHouseApartment(e.target.value)}
                    />
                  </div>
                  <div className="contactNumber">
                    <label htmlFor="contactNumber" className="label">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contactNumber"
                      name="contactNumber"
                      autoComplete="tel"
                      placeholder="Type here"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </div>
                </>
              )}
            </form>
            {placesError && (
              <div className="error-message">
                {" "}
                <i className="bi bi-exclamation-circle-fill error-icon"></i>
                {placesError}
              </div>
            )}
            <div className="text-center">
              <button className="own-next-button" onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeliveryFormModal;


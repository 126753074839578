import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { YourShopHeaderPostContext } from '../StarNavigationBar/YourShopHeaderPostContext';
import { useUserLocation } from '../GetUserCurrentLocationContext';
import DeliveryFormModal from '../DeliveryFormModal';
import ConfirmationModalYourShopPosts from '../ConfirmationModalYourShopPosts';
import NotificationModal from '../NotificationModal';
import PaymentMethodModalYourShopPosts from '../PaymentMethodModalYourShopPosts';
import { useOwnModalsContext } from '../OwnModalsContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import VideoPlayer from '../VideoPlayer';
import GlobalLazyImage from '../GlobalLazyImage';
import {
  BiLink,
  BiCheck,
  BiShare,
  BiDotsVerticalRounded,
  BiArrowBack,
} from "react-icons/bi";
import { FaExclamationCircle } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";
import useAlignSpinner from '../hooks/useAlignSpinner';

function YourShopPosts() {
  const [isLoading, setIsLoading] = useState(true);
  const {
    setShowHeader,
    setIsFullScreenPreview,
    currentPostId,
    setCurrentPostId,
    fetchedStarPostingIds,
    starPostingPage,
    setStarPostingPage,
    posts,
    setPosts,
    setSelectedPostToBuy,
    expandedSection,
    setExpandedSection,
    expandedPost,
    setExpandedPost,
    userCurrency,
    setUserCurrency,
    isLocationFetched,
    setIsLocationFetched,
    currencyMapping,
    showMenu,
    setShowMenu,
    menuRef,
    copyLinkText,
    setCopyLinkText,
    iconClass,
    setIconClass,
    sharePostText,
    setSharePostText,
    shareIconClass,
    setShareIconClass,
    extractCityAndCountry,
    currentUserData,
    csrftoken,
    postStarContainerRef,
  } = useContext(YourShopHeaderPostContext);
  const { userLocation } = useUserLocation();
  const { setIsModalOpen } = useOwnModalsContext();

  const { username, product_code } = useParams();
  const [userData, setUserData] = useState({});
  const [imageFailed, setImageFailed] = useState(false);
  const postRefs = useRef({});

  const [firstLoadStarPosting, setFirstLoadStarPosting] = useState(true);
  const [hasMoreStarPosting, setHasMoreStarPosting] = useState(true);
  const [scrollLoadingStarPosting, setScrollLoadingStarPosting] =
    useState(false);
  const observerStarPosting = useRef();

  const lastPostElementRef = useCallback(
    (node) => {
      if (scrollLoadingStarPosting) return;
      if (observerStarPosting.current) observerStarPosting.current.disconnect();
      observerStarPosting.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreStarPosting) {
          setStarPostingPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerStarPosting.current.observe(node);
    },
    [scrollLoadingStarPosting, hasMoreStarPosting]
  );

  //Get user data based with paremeter
  useEffect(() => {
    fetch(`/api/users_data/${username}/`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setImageFailed(false);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, [username]);

  // Function to generate the API URL based on the inputs
  const generateStarPostingsUrl = (page, productCode, isSoldOut) => {
    let baseUrl = `/api/StarPostings/user/${username}/?page=${page}&is_soldout=${isSoldOut}`;
    if (productCode) {
      baseUrl = `/api/StarPostings/user/${username}/?product_code=${encodeURIComponent(
        productCode
      )}&is_soldout=${isSoldOut}`;
    }
    return baseUrl;
  };

  // Function to fetch the data
  const fetchStarPostingsData = async (url) => {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error fetching starpostings: ${response.status}`);
    }

    return response.json();
  };

  // Function to process the fetched data
  const processFetchedData = (data) => {
    if (data && data.results) {
      const newPosts = data.results.filter((post) => {
        if (!fetchedStarPostingIds.has(post.id)) {
          fetchedStarPostingIds.add(post.id);
          return true;
        }
        return false;
      });

      return newPosts.map((item) => {
        const price =
          item.converted_price && item.converted_price[userCurrency]
            ? item.converted_price[userCurrency]
            : item.price;

        return {
          ...item,
          currency: userCurrency,
          price: price,
          showStory: true,
          showDescription: false,
          readMore: false,
        };
      });
    } else {
      console.error("Data structure is not as expected:", data);
      return [];
    }
  };

  // Function to handle state updates
  const updateStateWithFetchedData = (updatedData, productCode, data) => {
    setPosts((prevPosts) => [...prevPosts, ...updatedData]);
    setHasMoreStarPosting(productCode ? false : data.next !== null);

    if (productCode) {
      const post = updatedData.find(
        (post) => post.product_code === productCode
      );
      if (post) {
        setCurrentPostId(post.id);
        setIsFullScreenPreview(true);
      }
    }

    if (data.page) {
      setStarPostingPage(data.page);
    }
  };

  // Main function to handle the fetch operation
  const fetchStarPostings = async (
    page,
    productCode = null,
    isSoldOut = false
  ) => {
    if (firstLoadStarPosting) {
      setIsLoading(true);
    } else {
      setScrollLoadingStarPosting(true);
    }

    try {
      const url = generateStarPostingsUrl(page, productCode, isSoldOut);
      const data = await fetchStarPostingsData(url);
      const updatedData = processFetchedData(data);
      updateStateWithFetchedData(updatedData, productCode, data);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setHasMoreStarPosting(false);
    } finally {
      if (firstLoadStarPosting) {
        setIsLoading(false);
        setFirstLoadStarPosting(false);
      }
      setScrollLoadingStarPosting(false);
    }
  };

  // Effect hook for fetching posts when dependencies change
  useEffect(() => {
    if (isLocationFetched) {
      if (product_code) {
        fetchStarPostings(starPostingPage, decodeURIComponent(product_code));
      } else {
        fetchStarPostings(starPostingPage);
      }
    }
  }, [starPostingPage, username, product_code, isLocationFetched]);

  const toggleStory = (id) => {
    setExpandedSection((prevState) => {
      let newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== id) {
          newState[key] = { ...newState[key], story: false };
        }
      });
      return {
        ...newState,
        [id]: { story: !newState[id]?.story },
      };
    });
  };

  const toggleDescription = (id) => {
    setExpandedSection((prevState) => {
      let newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== id) {
          newState[key] = { ...newState[key], description: false };
        }
      });
      return {
        ...newState,
        [id]: { description: !newState[id]?.description },
      };
    });
  };

  const toggleReadMore = (id) => {
    setPosts((prevState) =>
      prevState.map((post) =>
        post.id === id ? { ...post, readMore: !post.readMore } : post
      )
    );
    setExpandedPost(id);
    setExpandedSection((prevState) => ({
      ...prevState,
      [id]: { story: true },
    }));
  };

  const handleThumbnailClick = (id) => {
    const postIndex = posts.findIndex((post) => post.id === id);
    if (postIndex !== -1) {
      setCurrentPostId(id);
      setShowHeader(false);

      setIsFullScreenPreview(true);
    }
  };

  const closeFullScreenPreview = () => {
    setCurrentPostId(null);
    setShowHeader(true);
    setIsFullScreenPreview(false);
  };

  useEffect(() => {
    if (currentPostId !== null && postRefs.current[currentPostId]) {
      postRefs.current[currentPostId].scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [currentPostId]);

  useEffect(() => {
    if (posts.length > 0) {
      // Create a new showMenu object with a key for each post ID
      const newShowMenu = posts.reduce((acc, post) => {
        acc[post.id] = false;
        return acc;
      }, {});
      setShowMenu(newShowMenu);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [posts]);

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      // Close all menus
      const newShowMenu = { ...showMenu };
      Object.keys(newShowMenu).forEach((id) => {
        newShowMenu[id] = false;
      });
      setShowMenu(newShowMenu);
    }
  };

  const handleCopyLink = (id) => {
    const post = posts.find((post) => post.id === id);
    const url = post?.full_post_url;

    navigator.clipboard.writeText(url).then(() => {
      setCopyLinkText("Copied to clipboard");
      setIconClass(<BiCheck />);

      setTimeout(() => {
        setCopyLinkText("Copy link");
        setIconClass(<BiLink />);

        setShowMenu((prevShowMenu) => {
          const newShowMenu = { ...prevShowMenu };
          newShowMenu[id] = false;
          return newShowMenu;
        });
      }, 2000);
    });
  };

  const handleSharePost = (id) => {
    const post = posts.find((post) => post.id === id);
    const url = post.full_post_url;

    if (navigator.share) {
      navigator
        .share({
          title: `Check out this post by ${userData.username}`,
          text: "Check out this post!",
          url: url,
        })
        .then(() => {
          setShowMenu((prevShowMenu) => {
            const newShowMenu = { ...prevShowMenu };
            newShowMenu[id] = false;
            return newShowMenu;
          });
        })
        .catch((error) => {
          navigator.clipboard.writeText(url).then(() => {
            setSharePostText("Post URL copied to clipboard");
            setShareIconClass(<BiCheck />);

            setTimeout(() => {
              setSharePostText("Share");
              setShareIconClass(<BiShare />);
              setShowMenu((prevShowMenu) => {
                const newShowMenu = { ...prevShowMenu };
                newShowMenu[id] = false;
                return newShowMenu;
              });
            }, 2000);
          });
        });
    } else {
      navigator.clipboard.writeText(url).then(() => {
        setSharePostText("Unsupported browser");
        setShareIconClass(<FaExclamationCircle />);

        setTimeout(() => {
          setSharePostText("Share");
          setShareIconClass(<BiShare />);
          setShowMenu((prevShowMenu) => {
            const newShowMenu = { ...prevShowMenu };
            newShowMenu[id] = false;
            return newShowMenu;
          });
        }, 2000);
      });
    }
  };

  const handleToggleMenu = (id) => {
    setShowMenu((prevShowMenu) => {
      // Close all other open menus
      const newShowMenu = Object.keys(prevShowMenu).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Open the menu for the current post
      newShowMenu[id] = !prevShowMenu[id];

      return newShowMenu;
    });
  };

  const setCombinedRef = (node, id) => {
    postRefs.current[id] = node;
    if (posts[posts.length - 1].id === id) {
      lastPostElementRef(node);
    }
  };

  const handleOpenModal = (post) => {
    setSelectedPostToBuy(post);
    setIsModalOpen(true);
  };

  useEffect(() => {
    async function fetchUserCurrency() {
      if (userLocation) {
        const { country } = userLocation;
        const currency = currencyMapping[country] || "USD";
        setUserCurrency(currency);
        setIsLocationFetched(true);
      }
    }

    fetchUserCurrency();
  }, [userLocation]);

  const handleDeletePost = async (postId) => {
    try {
      const response = await fetch(`/api/StarPostings/delete-post`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ post_id: postId }),
      });

      if (response.ok) {
        // Remove the post from the UI
        setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
      } else {
        const errorData = await response.json();
        console.error("Error deleting post:", errorData);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  //hook to align the spinner center horizontally
  useAlignSpinner(postStarContainerRef, ".loader-container");
  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : posts.length > 0 ? (
        <>
          {currentPostId === null ? (
            <div className="grid-container">
              {posts.map((post) => {
                return (
                  <div
                    key={post.id}
                    className="grid-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleThumbnailClick(post.id);
                    }}
                    ref={
                      posts.length === posts.indexOf(post) + 1
                        ? lastPostElementRef
                        : null
                    }
                  >
                    {post.images && post.images.length > 0 ? (
                      <GlobalLazyImage src={post.images[0].image} alt="" />
                    ) : post.videos && post.videos.length > 0 ? (
                      <VideoPlayer src={post.videos[0].video} preload="none" />
                    ) : null}
                  </div>
                );
              })}
              <div className="scroll-loader-spinner-container">
                {scrollLoadingStarPosting && (
                  <div className="scroll-loader-spinner"></div>
                )}
              </div>
            </div>
          ) : (
            <div className="full-screen-preview">
              <div className="posts-container">
                {posts.map((post) => (
                  <div
                    key={post.id}
                    className="post-container"
                    ref={(node) => setCombinedRef(node, post.id)}
                  >
                    <div className="post-header">
                      <div className="yourshop_post_profile">
                        <div className="profile">
                          {userData.profile_picture && !imageFailed ? (
                            <img
                              src={userData.profile_picture}
                              alt={userData.first_name}
                              className="profile-picture"
                              onError={() => setImageFailed(true)}
                            />
                          ) : (
                            <div className="profile-picture no-profile-picture">
                              {userData.first_name
                                ? userData.first_name.slice(0, 1).toUpperCase()
                                : "?"}
                            </div>
                          )}
                        </div>
                        <div className="yourshop_post_username">
                          {userData.username}
                        </div>
                      </div>
                      <div className="post-menu" ref={menuRef}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleMenu(post.id);
                          }}
                        >
                          <BiDotsVerticalRounded size={20} />
                        </button>

                        {showMenu[post.id] && (
                          <div
                            className={`menu ${
                              showMenu[post.id] ? "show" : ""
                            }`}
                          >
                            <div
                              className="share-post-copy"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopyLink(post.id);
                              }}
                            >
                              {iconClass}
                              <span className="copy-link">{copyLinkText}</span>
                            </div>
                            <div
                              className="share-post"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSharePost(post.id);
                              }}
                            >
                              {shareIconClass}
                              <span className="share-post-word">
                                {sharePostText}
                              </span>
                            </div>
                            {currentUserData.username &&
                              currentUserData.username === username &&
                              !post.is_soldout && (
                                <div
                                  className="delete-post"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeletePost(post.id);
                                  }}
                                >
                                  <BiTrash size={16} />
                                  <span className="text-delete">Delete</span>
                                </div>
                              )}

                            {/* Other menu items... */}
                          </div>
                        )}
                      </div>
                    </div>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      pagination={{ clickable: true }}
                      modules={[Pagination]}
                      observer={true}
                      observeParents={true}
                      initialSlide={0}
                    >
                      {post.images &&
                        post.images.map((image, imageIndex) => (
                          <SwiperSlide key={imageIndex}>
                            <img
                              src={image.image}
                              alt=""
                              className="postStar-media"
                              loading="lazy"
                            />
                          </SwiperSlide>
                        ))}

                      {post.videos &&
                        post.videos.map((video, videoIndex) => (
                          <SwiperSlide key={videoIndex}>
                            <VideoPlayer
                              src={video.video}
                              className="postStar-media"
                              preload="none"
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="container-post-details">
                      <div className="container-name-code">
                        <p className="product_name">{post.product_name}</p>
                        <p className="product_code">{post.product_code}</p>
                      </div>
                      <div className="container-price-buy">
                        <p>
                          <span className="currency">{post.currency}</span>{" "}
                          <span className="price">{post.price}</span>
                        </p>
                        <button onClick={() => handleOpenModal(post)}>
                          Own it
                        </button>
                      </div>
                    </div>
                    <div className="container-story-description">
                      <div className="story">
                        <p
                          onClick={() => toggleStory(post.id)}
                          className={
                            expandedSection[post.id]?.story ? "active-tab" : ""
                          }
                        >
                          Story
                        </p>
                      </div>
                      <div className="description">
                        <p
                          onClick={() => toggleDescription(post.id)}
                          className={
                            expandedSection[post.id]?.description
                              ? "active-tab"
                              : ""
                          }
                        >
                          Description
                        </p>
                      </div>
                    </div>
                    {(expandedSection[post.id]?.story ||
                      !expandedSection[post.id]?.description) && (
                      <div className="full-width">
                        <p className="story-text">
                          {post.readMore
                            ? post.product_story
                            : `${post.product_story.substring(0, 50)}${
                                post.product_story.length > 50 ? "..." : ""
                              }`}
                          {post.product_story.length > 50 &&
                            expandedPost !== post.id &&
                            !post.readMore && (
                              <span
                                onClick={() => toggleReadMore(post.id)}
                                className="read-more"
                              >
                                {" "}
                                Read More
                              </span>
                            )}
                        </p>
                      </div>
                    )}

                    {expandedSection[post.id]?.description && (
                      <div className="full-width">
                        <div className="category_availability">
                          <p>
                            <span className="label">Category:</span>{" "}
                            <span className="data">
                              {post.product_category}
                            </span>
                          </p>
                          <p>
                            <span className="label">Qty available:</span>{" "}
                            <span className="data">
                              {post.product_availability}
                            </span>
                          </p>
                        </div>

                        {(post.length || post.width || post.height) && (
                          <div className="dimensions_weight">
                            <p>
                              <span className="label">
                                Dimensions (L × W × H):{" "}
                              </span>
                              <span className="data">
                                {post.length ? `${post.length}` : "-"} ×
                                {post.width ? `${post.width}` : "-"} ×
                                {post.height ? `${post.height}` : "-"}
                              </span>
                              <span className="data"> {post.size_unit}</span>
                            </p>
                          </div>
                        )}

                        <div className="weight">
                          <p>
                            <span className="label">Weight:</span>{" "}
                            <span className="data">{post.weight_value}</span>{" "}
                            <span className="data">{post.weight_unit}</span>
                          </p>
                        </div>

                        <div className="location_condition">
                          <p>
                            <span className="label">Located at:</span>{" "}
                            <span className="data">
                              {extractCityAndCountry(post.product_location)}
                            </span>
                          </p>
                          <p>
                            <span className="label">Condition:</span>{" "}
                            <span className="data">{post.condition}</span>
                          </p>
                        </div>

                        {post.condition_description && (
                          <p>
                            <span className="imperfect_description">
                              Imperfect description:
                            </span>{" "}
                            <span className="imperfect_description_data">
                              {post.condition_description}
                            </span>
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                <div className="scroll-loader-spinner-container">
                  {scrollLoadingStarPosting && (
                    <div className="scroll-loader-spinner"></div>
                  )}
                </div>
              </div>
              <div className="back-button-container">
                <button
                  className="back-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    closeFullScreenPreview();
                  }}
                >
                  <BiArrowBack
                    size={20}
                    color="currentColor"
                    className="bi bi-arrow-left"
                  />{" "}
                  <span>Posts</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="no-posts">No posts available.</p>
      )}
      <DeliveryFormModal />
      <ConfirmationModalYourShopPosts />
      <PaymentMethodModalYourShopPosts />
      <NotificationModal />
    </>
  );
}

export default YourShopPosts;
